import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { create } from 'zustand';

const useAllCitiesQuery = () => {
  return useQuery({
    queryKey: ['allCities'],
    queryFn: async () => {
      const resp = await fetch('/api/cities/list', {
        headers: {
          Authorization: process.env.NEXT_PUBLIC_API_KEY,
        },
      });
      const data = await resp.json();
      return data;
    },
    refetchInterval: 120000,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useCitiesStore = create((set) => ({
  allCities: [],
  setAllCities: (allCities) => set({ allCities }),
  loading: true,
  setLoading: (loading) => set({ loading }),
}));

export const useCitiesResolver = () => {
  const allCitiesQuery = useAllCitiesQuery();
  const allCities = allCitiesQuery.data ?? [];

  const { setAllCities, setLoading } = useCitiesStore();

  useEffect(() => {
    if (allCities) {
      setAllCities(allCities);
    }
  }, [allCities?.length]);

  useEffect(() => {
    setLoading(allCitiesQuery.isLoading);
  }, [allCitiesQuery.isLoading]);
};
