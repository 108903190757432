import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { create } from 'zustand';
import { useUserAssetsStore } from './useUserAssetsStore';

export const useBalanceStore = create((set) => ({
  walletBalance: 0,
  vaultBalance: 0,
  vmTokenBalance: 0,
  energyDrinkBalance: 0,
  setBalance: (walletBalance, vaultBalance, vmTokenBalance, energyDrinkBalance) =>
    set({ walletBalance, vaultBalance, vmTokenBalance, energyDrinkBalance }),
}));

export const useBalanceResolver = () => {
  const { data: session } = useSession();
  const { userAssets } = useUserAssetsStore();
  const { setBalance } = useBalanceStore();

  const walletBalance = userAssets.find((asset) => asset['asset-id'] === parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID))?.amount || 0;
  const vmTokenBalance = userAssets.find((asset) => asset['asset-id'] === parseInt(process.env.NEXT_PUBLIC_SVMT_ID))?.amount || 0;
  const energyDrinkBalance = userAssets.find((asset) => asset['asset-id'] === parseInt(process.env.NEXT_PUBLIC_DRINK_ID))?.amount || 0;

  useEffect(() => {
    setBalance(walletBalance, session?.user?.vaultTreats || 0, vmTokenBalance, energyDrinkBalance);
  }, [walletBalance, vmTokenBalance, energyDrinkBalance]);
};
