const ASSETS = [
  284074520, 284075145, 284079320, 284079976, 284080585, 284084406, 284086617, 284086990, 284089958, 284090355, 284090659, 284091092, 284093038,
  284094198, 284098171, 284100427, 284101211, 284105982, 284107400, 284108166, 284108532, 284110516, 284110962, 284112291, 284113013, 284113381,
  284114636, 284281874, 284282249, 284282447, 284285456, 284285990, 284286231, 284290436, 284290866, 284293052, 284293844, 284294235, 284294417,
  284294668, 284294920, 284295175, 284295436, 284295635, 284296910, 284297424, 284299312, 284299565, 284302194, 284302457, 284302836, 284303115,
  284303356, 284304379, 284304697, 284307044, 284307162, 284307352, 284307581, 284308871, 284309455, 284309691, 284309896, 284310262, 284310463,
  284310673, 284310906, 284311092, 284311282, 284311536, 284313469, 284313717, 284314837, 284315059, 284315211, 284317291, 284317606, 284317840,
  284318974, 284319200, 284320262, 284356935, 284357167, 284357341, 284357521, 284358184, 284358419, 284359539, 284360324, 284361199, 284361460,
  284361632, 284361785, 284361988, 284362209, 284362796, 284363255, 284363712, 284363915, 284364125, 284364291, 284364500, 284364722, 284364919,
  284365130, 284367113, 284367380, 284396840, 287294811, 287314200, 287314512, 287314849, 287315119, 287315435, 287315716, 287316002, 287316427,
  287316727, 287317109, 287317477, 287317790, 287318082, 287318506, 287318843, 287330161, 287330740, 287331398, 287331707, 287332167, 287332662,
  287333125, 287334280, 287334746, 287335275, 287335672, 287336097, 287337548, 287337941, 287338337, 287338838, 287339139, 287339743, 287340218,
  287341282, 287341622, 287342065, 287342400, 287342842, 287343614, 287344153, 287345342, 287345888, 287346294, 287346687, 287347052, 287347974,
  287348431, 287348821, 287349375, 287349692, 287350023, 287350337, 287350862, 287351361, 287351853, 287352396, 287353006, 287353489, 287353905,
  287355460, 287356269, 287356977, 287357242, 287357977, 287358320, 287358608, 287358926, 287359347, 287359655, 287359980, 287360289, 287360539,
  287360818, 287361050, 287361565, 287361899, 287362186, 287810378, 287864513, 287864737, 287865210, 287865558, 287865904, 287866262, 287866472,
  287866770, 287867024, 287867254, 287867424, 287867576, 287867816, 287867997, 287868338, 287933963, 287934324, 287934636, 287934907, 287935439,
  287936066, 287936377, 291903875, 292996406, 293553952, 293554816, 293555656, 293556451, 293557360, 293557921, 293558609, 293560016, 293560796,
  293562231, 293622679, 293623153, 293623704, 293624530, 293625074, 293625623, 293626958, 293627604, 293628316, 293628956, 293629654, 293630507,
  293631116, 293631861, 293633161, 293634048, 293634697, 293635568, 293636309, 293636944, 293637605, 293638081, 293638679, 293639337, 293639918,
  293640578, 293641689, 293643549, 293644045, 293644569, 293645102, 293645359, 293645703, 293645906, 293646221, 293646559, 293647087, 293647544,
  293648070, 293679875, 293682313, 293685430, 293719010, 293719635, 293719989, 293720878, 293721752, 293722358, 293723000, 293723755, 293724714,
  293725864, 293726658, 293727484, 293729000, 293730148, 293730615, 293731829, 293732729, 293733290, 293734477, 293735395, 293736121, 293737075,
  293737875, 293738704, 293739493, 293740444, 293741412, 293742296, 293743007, 293743607, 293744285, 293744963, 293745801, 293747014, 293748358,
  293750761, 293752248, 293753484, 293754152, 293754866, 293756005, 293757103, 293758146, 293760492, 293761486, 293762240, 293763225, 293763882,
  293764681, 298715074, 298730994, 298742045, 298766869, 299690872, 304493076, 304494695, 304496866, 304497431, 304498108, 304502630, 304505492,
  304505964, 304506492, 304506836, 304507189, 304507766, 304508051, 304512074, 304512550, 304513002, 304518840, 304519327, 304519719, 304520058,
  304520501, 304520826, 304521187, 304866402, 304866776, 304867040, 304867258, 304867503, 304867980, 304868248, 304868582, 304868830, 304869056,
  304869439, 304869750, 304869997, 304870389, 304870788, 304871068, 304871394, 304871816, 304872118, 304872458, 304872743, 304873082, 304873374,
  304873686, 304874014, 304874291, 304874620, 304874914, 304875250, 304875506, 304875926, 304876217, 304876509, 304876755, 304877042, 304877344,
  304877717, 304878213, 304878562, 304878845, 304879666, 304880091, 304880433, 304880743, 304881070, 304881352, 304881667, 304881934, 304882268,
  304882762, 304883012, 304883259, 304883724, 304884180, 304884441, 304887981, 304888253, 304888570, 304890290, 304890487, 304890731, 304890995,
  304891355, 312979339, 312980370, 312981408, 312981881, 312983892, 312984907, 312986323, 312987421, 312987967, 312988634, 312989288, 312990042,
  312992601, 312993179, 313000969, 313001377, 313001841, 313002420, 313002932, 313003302, 313003785, 313004223, 313004882, 313005353, 313005827,
  313006221, 313006716, 313007123, 313007611, 313009015, 313293270, 313293707, 313294211, 313294629, 313295066, 313295461, 313295941, 313296532,
  313297497, 313298134, 313298458, 313298971, 313299437, 313299891, 313304497, 313305843, 313306445, 313306979, 313307500, 313307922, 313308241,
  313309444, 313309826, 313310223, 313310618, 313311156, 313311640, 313312118, 313312647, 313313347, 313314266, 313314977, 313315516, 313316339,
  313317170, 313317923, 313318684, 313319307, 313320281, 313320895, 313321348, 314653302, 314653929, 314654376, 314654887, 314655458, 314655985,
  314656573, 314657632, 314658208, 314659011, 314661996, 314731863, 314732232, 314732981, 314733387, 314733752, 314734109, 314734540, 314734879,
  314735627, 314736183, 314736637, 314737024, 315675438, 315878457, 316784470, 338810325, 545994979, 638892878, 730231009, 771664341,
];

export default ASSETS;
