import React from 'react';

const IconCrown = () => {
  return (
    <svg class='w-8 h-8' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
      <path
        opacity='.4'
        d='M248 72a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zM0 136a40 40 0 1 1 80 0A40 40 0 1 1 0 136zM536 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z'
        fill='currentColor'
      />
      <path
        d='M504 160l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106c-6.1 3.8-13.3 6-21 6s-14.9-2.2-21-6L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c-7.2 9.5-18.5 15.8-31.3 16L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c-12.8-.2-24.1-6.5-31.3-16z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconCrown;
