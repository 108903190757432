const assets = [
  823042316, 823042865, 823044230, 823044680, 823045197, 823046378, 823062850, 823064215, 823065300, 823066016, 823066704, 823067135, 823067722,
  823408191, 823408446, 823408987, 823409374, 823410655, 823412159, 823412617, 823413045, 823413398, 823413728, 823414710, 823415627, 823415967,
  823417154, 823417475, 823418416, 823418752, 823419341, 823419906, 823420365, 823420720, 823421304, 823422098, 823422420, 823422685, 823423273,
  823423534, 823423793, 823424106, 823424371, 823424758, 823425023, 823425333, 823426475, 823426945, 823427340, 823427754, 823438258, 829424610,
  838239461, 838240028, 838243655, 838244086, 838245480, 838254078, 838254842, 838255694, 838256661, 838258344, 838258656, 838259168, 838261187,
  838261551, 838262033, 838262260, 838263047, 838263390, 838264852, 838265101, 838265683, 838265914, 838266285, 838266900, 838267180, 838267871,
  838268237, 838268574, 838268928, 838269176, 838269468, 838269782, 838270033, 838270355, 838270864, 838271679, 838271878, 838272081, 838273233,
  838273400, 838273641, 838273929, 838274171, 838274422, 838274616, 838274862, 838275068, 838275244, 838275786, 838276363, 838276993, 838277233,
  838277458, 838277661, 838277910, 838278260, 838278504, 838278664, 838278917, 838279118, 838279389, 838279605, 838279960, 838280389, 838280768,
  838280954, 838281153, 838281594, 838281865, 838282207, 838282447, 838282766, 838284812, 838285361, 838285937, 838286411, 838286932, 838287276,
  838287557, 838287865, 838288502, 838288833, 838289475, 838290246, 838290559, 838290846, 838291103, 838291326, 838291562, 838291751, 838291963,
  838292230, 838292483, 838292750, 838292946, 838293290, 838293586, 838293716, 838299512, 854542606, 868150212, 868764984, 869190838, 889190330,
  891632920, 929929725, 930991059, 930995278, 956414529, 982749300, 1048045717, 1084490075, 1088678867, 1163313644,
];

export default assets;
