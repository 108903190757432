const assets = [
  485050492, 485058720, 485067417, 485080673, 485090381, 485097286, 485104516, 485111582, 485158520, 485169990, 485179924, 485183134, 485187202,
  485189509, 485193016, 485196638, 485200349, 485205835, 485214743, 485222781, 490658593, 507928715, 507930886, 507931943, 507933331, 507935091,
  507936196, 507937689, 507939718, 507941080, 507945898, 507947592, 507949793, 507952884, 507956242, 507958942, 507962709, 507965296, 507967863,
  512722813, 518725485, 518729233, 525359757, 533106974, 533108562, 533110063, 541375156, 541379137, 541382310, 541385324, 541388003, 541392246,
  541395021, 541397420, 546403545, 546407165, 548381968, 548383427, 548385072, 548386922, 548388445, 548389506, 548391829, 548393316, 548394827,
  548396688, 556208338, 562444573, 562449012, 562460190, 562465328, 562468926, 574771310, 576968123, 576970823, 576976454, 576980019, 576983647,
  580707641, 582916776, 582917858, 582919422, 585276831, 588658577, 588660305, 588660906, 592932601, 596475153, 596476465, 596477986, 600594469,
  602126935, 602130961, 602133845, 609147384, 613277253, 615459880, 615465928, 624700796, 624704868, 626920704, 642829721, 644114533, 644511256,
  652924780, 663958812, 665786225, 668614524, 668970860, 672119958, 672122913, 675521642, 677278318, 677280044, 682428776, 682437482, 682439817,
  686646418, 686993758, 688060705, 688271631, 690918588, 693568324, 693571104, 695668141, 695668856, 697464317, 697465356, 698637056, 698638556,
  698639661, 704603213, 704833239, 705624431, 712001534, 712153632, 712549373, 712550449, 713801230, 718919176, 720057949, 720059118, 723249937,
  723258190, 724450267, 724452804, 732751418, 733779786, 736210872, 740857058, 750559619, 750567174, 750574996, 750576592, 750578683, 758504558,
  760805018, 761820108, 761825368, 761827006, 761836288, 761843798, 770303371, 774328016, 840292503, 840293562, 878705658, 880614244, 895108853,
  895668651, 901478354, 925803016, 925804528, 925805924, 925807141, 925808470, 925809854, 925811253, 925813078, 925814807, 925818787, 925821213,
  925825467, 925827639, 925829457, 925831374, 925832795, 925834686, 925836435, 925838119, 925839724, 925841636, 925843681, 925845313, 925846867,
  925848426, 925851354, 925854383, 925856530, 925859238, 925862604, 925865610, 925868396, 925872011, 925874766, 925877141, 925880030, 925882817,
  925885396, 925888569, 925891932, 925894663, 925896543, 926070656, 926081903, 929951495, 939023425, 1055124314, 1058612253, 1142908079,
];

export default assets;
