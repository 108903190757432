const assets = [
  1016176227, 1016176955, 1016178769, 1016180002, 1016182409, 1016196592, 1016196594, 1016196596, 1016196598, 1016196600, 1016196602, 1016196604,
  1016196607, 1016196609, 1016196611, 1016196613, 1016196615, 1016196617, 1016196619, 1016196621, 1016196623, 1016196625, 1016196627, 1016196629,
  1016196631, 1016196633, 1016196635, 1016196637, 1016196639, 1016196641, 1016196643, 1016196645, 1016196647, 1016196649, 1016196651, 1016196653,
  1016196655, 1016196657, 1016196659, 1016196661, 1016196664, 1016196666, 1016196668, 1016196670, 1016196672, 1016196674, 1016196676, 1016196678,
  1016196680, 1016196682, 1016196684, 1016196686, 1016196688, 1016196690, 1016196692, 1016196735, 1016196737, 1016196739, 1016196741, 1016196743,
  1016196745, 1016196747, 1016196749, 1016196751, 1016196753, 1016196755, 1016196757, 1016196759, 1016196761, 1016196763, 1016196765, 1016196767,
  1016196769, 1016196771, 1016196773, 1016196775, 1016196777, 1016196779, 1016196781, 1016196783, 1016196785, 1016196787, 1016196789, 1016196791,
  1016196793, 1016196795, 1016196797, 1016196799, 1016196801, 1016196803, 1016196805, 1016196807, 1016196809, 1016196811, 1016196813, 1016196815,
  1016196817, 1016196819, 1016196821, 1016196823, 1016196825, 1016196827, 1016196829, 1016196831, 1016196833, 1016196864, 1016196866, 1016196868,
  1016196870, 1016196872, 1016196874, 1016196876, 1016196881, 1016196883, 1016196885, 1016196887, 1016196889, 1016196891, 1016196893, 1016196895,
  1016196897, 1016196899, 1016196901, 1016196903, 1016196905, 1016196907, 1016196909, 1016196911, 1016196913, 1016196915, 1016196917, 1016196919,
  1016196921, 1016196923, 1016196925, 1016196927, 1016196929, 1016196931, 1016196933, 1016196935, 1016196939, 1016196941, 1016196943, 1016196945,
  1016196947, 1016196949, 1016196951, 1016196954, 1016196956, 1016196958, 1016196960, 1016196962, 1016196964, 1016196966, 1016196968, 1016196990,
  1016196992, 1016196994, 1016196996, 1016196998, 1016197000, 1016197002, 1016197006, 1016197008, 1016197010, 1016197012, 1016197014, 1016197016,
  1016197018, 1016197020, 1016197022, 1016197024, 1016197026, 1016197028, 1016197030, 1016197032, 1016197034, 1016197036, 1016197038, 1016197040,
  1016197042, 1016197044, 1016197046, 1016197048, 1016197050, 1016197052, 1016197054, 1016197056, 1016197058, 1016197060, 1016197062, 1016197064,
  1016197066, 1016197068, 1016197070, 1016197072, 1016197074, 1016197076, 1016197078, 1016197080, 1016197082, 1016197084, 1016197086, 1016197088,
  1016197095, 1016197142, 1016197144, 1016197146, 1016197148, 1016197150, 1016197152, 1016197154, 1016197157, 1016197159, 1016197161, 1016197163,
  1016197165, 1016197167, 1016197169, 1016197171, 1016197173, 1016197175, 1016197177, 1016197179, 1016197181, 1016197183, 1016197186, 1016197188,
  1016197190, 1016197192, 1016197194, 1016197196, 1016197198, 1016197201, 1016197203, 1016197205, 1016197207, 1016197209, 1016197211, 1016197213,
  1016197216, 1016197218, 1016197220, 1016197222, 1016197224, 1016197226, 1016197228, 1016197231, 1016197233, 1016197235, 1016197237, 1016197239,
  1016197241, 1016197243, 1016197245, 1016197269, 1016197271, 1016197273, 1016197275, 1016197277, 1016197279, 1016197281, 1016197285, 1016197287,
  1016197289, 1016197291, 1016197293, 1016197295, 1016197297, 1016197299, 1016197301, 1016197303, 1016197305, 1016197307, 1016197309, 1016197311,
  1016197313, 1016197315, 1016197317, 1016197319, 1016197321, 1016197323, 1016197325, 1016197328, 1016197330, 1016197332, 1016197334, 1016197336,
  1016197338, 1016197340, 1016197342, 1016197344, 1016197346, 1016197348, 1016197350, 1016197352, 1016197354, 1016197356, 1016197358, 1016197360,
  1016197362, 1016197364, 1016197366, 1016197368, 1016197370, 1016197465, 1016197467, 1016197469, 1016197471, 1016197473, 1016197475, 1016197477,
  1016197479, 1016197481, 1016197483, 1016197485, 1016197487, 1016197489, 1016197491, 1016197493, 1016197495, 1016197497, 1016197499, 1016197501,
  1016197503, 1016197505, 1016197507, 1016197509, 1016197511, 1016197513, 1016197515, 1016197517, 1016197519, 1016197521, 1016197523, 1016197525,
  1016197527, 1016197529, 1016197531, 1016197533, 1016197535, 1016197537, 1016197539, 1016197541, 1016197543, 1016197545, 1016197547, 1016197549,
  1016197551, 1016197553, 1016197555, 1016197557, 1016197559, 1016197561, 1016197564, 1016197577, 1016197579, 1016197581, 1016197583, 1016197585,
  1016197587, 1016197589, 1016197592, 1016197594, 1016197596, 1016197598, 1016197600, 1016197602, 1016197604, 1016197609, 1016197611, 1016197613,
  1016197615, 1016197617, 1016197619, 1016197621, 1016197623, 1016197625, 1016197627, 1016197629, 1016197631, 1016197633, 1016197635, 1016197638,
  1016197640, 1016197642, 1016197644, 1016197646, 1016197648, 1016197650, 1016197652, 1016197654, 1016197656, 1016197658, 1016197660, 1016197662,
  1016197664, 1016197666, 1016197668, 1016197670, 1016197672, 1016197674, 1016197676, 1016197678, 1016197680, 1016197706, 1016197708, 1016197710,
  1016197712, 1016197714, 1016197716, 1016197718, 1016197720, 1016197722, 1016197724, 1016197726, 1016197728, 1016197730, 1016197732, 1016197734,
  1016197736, 1016197738, 1016197740, 1016197742, 1016197744, 1016197746, 1016197748, 1016197750, 1016197752, 1016197754, 1016197756, 1016197758,
  1016197760, 1016197762, 1016197764, 1016197766, 1016197768, 1016197770, 1016197772, 1016197774, 1016197776, 1016197778, 1016197780, 1016197782,
  1016197784, 1016197786, 1016197788, 1016197790, 1016197792, 1016197794, 1016197796, 1016197798, 1016197800, 1016197802, 1016197804, 1016197831,
  1016197833, 1016197835, 1016197837, 1016197839, 1016197841, 1016197843, 1016197845, 1016197847, 1016197849, 1016197851, 1016197853, 1016197855,
  1016197857, 1016197863, 1016197865, 1016197867, 1016197869, 1016197871, 1016197873, 1016197875, 1016197879, 1016197881, 1016197883, 1016197885,
  1016197887, 1016197889, 1016197891, 1016197894, 1016197896, 1016197898, 1016197900, 1016197902, 1016197904, 1016197906, 1016197912, 1016197914,
  1016197916, 1016197918, 1016197920, 1016197922, 1016197924, 1016197927, 1016197929, 1016197931, 1016197933, 1016197935, 1016197937, 1016197939,
  1016197941, 1016198014, 1016198016, 1016198018, 1016198020, 1016198022, 1016198024, 1016198026, 1016198028, 1016198030, 1016198032, 1016198034,
  1016198036, 1016198038, 1016198040, 1016198042, 1016198044, 1016198046, 1016198048, 1016198050, 1016198052, 1016198054, 1016198057, 1016198059,
  1016198061, 1016198063, 1016198065, 1016198067, 1016198069, 1016198073, 1016198075, 1016198077, 1016198079, 1016198081, 1016198083, 1016198085,
  1016198089, 1016198091, 1016198093, 1016198095, 1016198097, 1016198099, 1016198101, 1016198103, 1016198105, 1016198107, 1016198109, 1016198111,
  1016198113, 1016198115, 1016198117, 1016198157, 1016198159, 1016198161, 1016198163, 1016198165, 1016198167, 1016198169, 1016198172, 1016198174,
  1016198176, 1016198178, 1016198180, 1016198182, 1016198184, 1016198186, 1016198188, 1016198190, 1016198192, 1016198194, 1016198196, 1016198198,
  1016198200, 1016198202, 1016198204, 1016198206, 1016198208, 1016198210, 1016198212, 1016198214, 1016198216, 1016198218, 1016198220, 1016198222,
  1016198224, 1016198226, 1016198228, 1016198230, 1016198232, 1016198234, 1016198236, 1016198238, 1016198240, 1016198244, 1016198246, 1016198248,
  1016198250, 1016198252, 1016198254, 1016198256, 1016198258, 1016198294, 1016198296, 1016198298, 1016198300, 1016198302, 1016198304, 1016198306,
  1016198308, 1016198310, 1016198312, 1016198314, 1016198316, 1016198318, 1016198320, 1016198323, 1016198325, 1016198327, 1016198329, 1016198331,
  1016198333, 1016198335, 1016198337, 1016198339, 1016198341, 1016198343, 1016198345, 1016198347, 1016198349, 1016198352, 1016198354, 1016198356,
  1016198358, 1016198360, 1016198362, 1016198364, 1016198366, 1016198368, 1016198370, 1016198372, 1016198374, 1016198376, 1016198378, 1016198380,
  1016198382, 1016198384, 1016198386, 1016198388, 1016198390, 1016198392, 1016198394, 1016198493, 1016198495, 1016198497, 1016198499, 1016198501,
  1016198503, 1016198505, 1016198508, 1016198510, 1016198512, 1016198514, 1016198516, 1016198518, 1016198520, 1016198523, 1016198525, 1016198527,
  1016198529, 1016198531, 1016198533, 1016198535, 1016198537, 1016198539, 1016198541, 1016198543, 1016198545, 1016198547, 1016198549, 1016198552,
  1016198554, 1016198556, 1016198558, 1016198560, 1016198562, 1016198564, 1016198566, 1016198568, 1016198570, 1016198572, 1016198574, 1016198576,
  1016198578, 1016198580, 1016198582, 1016198584, 1016198586, 1016198588, 1016198590, 1016198592, 1016198594, 1016198612, 1016198614, 1016198616,
  1016198618, 1016198620, 1016198622, 1016198624, 1016198629, 1016198631, 1016198633, 1016198635, 1016198637, 1016198639, 1016198641, 1016198643,
  1016198645, 1016198647, 1016198649, 1016198651, 1016198653, 1016198655, 1016198658, 1016198660, 1016198662, 1016198664, 1016198666, 1016198668,
  1016198670, 1016198672, 1016198674, 1016198676, 1016198678, 1016198680, 1016198682, 1016198684, 1016198688, 1016198690, 1016198692, 1016198694,
  1016198696, 1025931759, 1025931761, 1025931763, 1025931765, 1025931767,
];

export default assets;
