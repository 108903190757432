const ASSETS = [
  783352241, 783354264, 783358022, 783359174, 783360212, 783363892, 783365803, 784195207, 784196527, 784202704, 784214951, 785058894, 790580532,
  790581974, 790582212, 790582597, 790582890, 795188360, 795189000, 795189668, 795190359, 795191013, 795192096, 802779570, 802783379, 802784452,
  802784952, 802785642, 802786345, 802787402, 802787871, 807087063, 807087733, 811580667, 811581099, 811582100, 811582808, 811853429, 812257036,
  813172770, 813173012, 818046897, 818047462, 818047860, 818048640, 818049371, 819145565, 819146172, 819146646, 819147200, 825659841, 825660451,
  825660998, 825661530, 825662272, 825663039, 825663969, 825664878, 829399783, 829446758, 829520128, 831365384, 833360516, 833361528, 833364131,
  833364629, 833365795, 833367723, 833369630, 833370635, 833371174, 834101165, 835947506, 838835078, 838835843, 838836543, 838837435, 838837889,
  840049404, 843146729, 843148072, 843148895, 843149421, 847625759, 847632999, 847635781, 847636386, 847638676, 847640187, 847641700, 847643133,
  847646984, 852098424, 854044378, 856975444, 856975817, 856976253, 856977220, 856977450, 856978055, 856978468, 856979218, 856980215, 862868961,
  862869944, 862871675, 862875459, 862876028, 862876576, 862883220, 862884336, 862885678, 862886586, 862887553, 870821120, 870821596, 870821963,
  870823371, 870824942, 871192217, 871260292, 873330299, 873330762, 873331552, 873332219, 875169402, 875169702, 875169901, 875170199, 875170504,
  875170853, 875172099, 875174728, 876734213, 878280465, 882359406, 882360325, 882361044, 882361954, 882362544, 882363866, 882365823, 882366994,
  882367790, 882373772, 885338388, 892227366, 892230219, 892230766, 892234661, 892237051, 892239453, 892242882, 892244425, 895438987, 895441485,
  898370180, 899392261, 899395894, 899397385, 899398160, 899401188, 899402963, 899404135, 899404877, 899409315, 899411837, 901528233, 901531822,
  905598785, 905599352, 905599923, 905600542, 905601288, 905602911, 905605270, 905606942, 905610192, 905612413, 910146198, 914823040, 914825516,
  914829285, 914839996, 914864660, 914877168, 914878036, 914879469, 914885465, 914888008, 917727050, 917728592, 917729797, 923497994, 923501295,
  925769476, 925769919, 925770488, 925772297, 925774130, 925775688, 925777306, 925781833, 925782338, 925785078, 925785782, 925786321, 925787017,
  925787374, 925792934, 928472270, 932225948, 934476613, 934955832, 934959493, 934966698, 934972536, 934973999, 936484378, 937532153, 938067783,
  940842347, 941711750, 944379849, 944380577, 945537199, 945537836, 945539619, 946337356, 946338384, 946339084, 946340055, 946344953, 946347218,
  948074381, 950618777, 950691131, 953088848, 953089637, 954216488, 954217203, 954217956, 955101547, 956056825, 956314374, 956316585, 956320512,
  957129755, 957314230, 960197363, 960581130, 961494044, 961494955, 961496433, 961496902, 961499807, 961500363, 961502602, 961503194, 961504473,
  962265895, 962286518, 966838640, 966838857, 966839310, 966840043, 966840376, 966840829, 966842621, 966843520, 966844042, 966844335, 972518199,
  973383001, 977477900, 978524136, 983605646, 985566007, 986439702, 988238364, 992379513, 1005534015, 1014136903, 1014137462, 1014138191, 1014138625,
  1014139361, 1019295488, 1019296269, 1019296448, 1028257167, 1067410891, 1090110981, 1144420123, 1169179469,
];

export default ASSETS;
