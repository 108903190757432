export const getAssets = async (addr) => {
  if (!addr) {
    return [];
  }
  try {
    const response = await fetch(`/api/assets?address=${addr}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
