import IconFeather from '../../components/icons/IconFeather';
import { achievementLevel } from './config';

const accessoryAchievements = [
  {
    name: 'Accessorizer',
    description: 'Hold 1 Shitty Accessory',
    category: 'Accessories',
    level: achievementLevel.bronze,
    icon: <IconFeather />,
    requirements: {
      accessories: 1,
    },
  },
  {
    name: 'Accessorizer 5',
    description: 'Hold 5 Shitty Accessory',
    category: 'Accessories',
    level: achievementLevel.silver,
    icon: <IconFeather />,
    requirements: {
      accessories: 5,
    },
  },
  {
    name: 'Accessorizer 10',
    description: 'Hold 10 Shitty Accessories',
    category: 'Accessories',
    level: achievementLevel.gold,
    icon: <IconFeather />,
    requirements: {
      accessories: 10,
    },
  },
];

export default accessoryAchievements;
