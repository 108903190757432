/* eslint-disable @next/next/no-img-element */
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getNftImage } from '../utils/getNftImage';

const NftImage = ({ nft, className, width, height }) => {
  const imageQuery = useQuery({
    queryKey: ['nftImage', nft.id || nft.index || nft.assetId || nft.asa_id],
    queryFn: () => getNftImage(nft),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
  const image = imageQuery.data || '';

  if (!image) return <></>;

  return <LazyLoadImage src={image} alt={nft?.name} loading='lazy' className={className} width={width} height={height} />;
};

export default NftImage;
