const ASSETS = [
  379252947, 379255099, 379256299, 379260427, 379263257, 381410865, 381412448, 381413752, 381415207, 381640740, 382942005, 383925533, 386525809,
  388627994, 388629476, 388631086, 388632699, 388634188, 388635320, 388636535, 388638137, 388639884, 390412250, 390782885, 393374523, 393906528,
  398247375, 399202159, 400561864, 402244537, 402246160, 402247693, 402248955, 402250498, 402251923, 402253500, 410167820, 417537155, 417539062,
  419570089, 421695434, 421698565, 421701962, 421705470, 421708909, 421712625, 421719542, 421725769, 421731220, 429429940, 436166649, 438097984,
  438102390, 438103885, 438104609, 438211480, 438497134, 438500052, 438570852, 438691073, 440217427, 444600167, 450473490, 452205001, 452206345,
  452266710, 452667055, 452669595, 452672301, 452674523, 452677237, 459441061, 460632024, 460642780, 461442609, 462232376, 462233345, 462404904,
  462491865, 462573360, 462664760, 464776179, 470190462, 472098555, 472106424, 479015619, 479129381, 479130690, 487477467, 508282140, 508282935,
  532631280, 537069923, 537070412, 537070991, 539546459, 539548531, 541120671, 541121921, 541203840, 543461964, 1008402705,
];

export default ASSETS;
