import { getAsset } from './getAsset';
import getIpfsImageUrl from './getIpfsImageUrl';
import parseCID from './parseCID';

export const getNftImage = async (nft) => {
  if (!nft) return '';

  const assetDetails = await getAsset(nft.id || nft.index || nft.assetId || nft.asa_id);

  if (!assetDetails.params || !assetDetails.params.url) return '';

  if (assetDetails.params.url.includes('https://gateway.pinata.cloud/ipfs/')) {
    return assetDetails.params.url;
  }

  if (assetDetails.params.url.includes('https://ipfs.infura.io/ipfs')) {
    const ipfs = assetDetails.params.url.split('.io/ipfs')[1];
    return getIpfsImageUrl(ipfs);
  }

  if (assetDetails.params.url.includes('ipfs://') && !assetDetails.params.url.includes('template-ipfs')) {
    return getIpfsImageUrl(assetDetails.params.url.split('ipfs://')[1]);
  }

  if (
    assetDetails.params.url.includes('template-ipfs://{ipfscid:1:raw:reserve:sha2-256}') ||
    assetDetails.params.url.includes('template-ipfs://{ipfscid:0:dag-pb:reserve:sha2-256}')
  ) {
    const reserve = assetDetails.params.reserve;
    const cid = parseCID(assetDetails.params.url, reserve);
    const ipfs = cid.toString();

    try {
      const resp = await fetch(getIpfsImageUrl(ipfs));

      if (resp.ok) {
        const json = await resp.json();
        return getIpfsImageUrl(json.image.split('ipfs://')[1]);
      }
    } catch (error) {}
    return getIpfsImageUrl(ipfs);
  } else {
    const ipfs = nft.params.url.split('ipfs://')[1];
    return getIpfsImageUrl(ipfs);
  }
};
