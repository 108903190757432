import accessories from './config/accessories';
import achievements from './config/achievements';
import adventures from './config/adventures';
import allItems from './config/allItems';
import cityUpgrades from './config/cityUpgrades';
import collabBlocks from './config/collabBlocks';
import flipTokenChoices from './config/flipTokenChoices';
import memes from './config/memes';
import raffleOwners from './config/raffleOwners';
import treasureBoxes from './config/treasureBoxes';

const config = {
  siteName: 'Shitty Kitties',
  twitter: 'https://twitter.com/ShittyKitties_',
  discord: 'https://www.discord.gg/qRafNzFjkN',
  buyNow: 'https://www.asalytic.app/collection/shitty-kitties',
  buyNowSecondLitter: 'https://www.asalytic.app/collection/shitty-kitties-slt',
  buyNowThirdLitter: 'https://www.asalytic.app/collection/shitty-kitties-third-litte',

  docs: 'https://docs.shittykitties.art/',
  allAccessories: accessories,
  allItems: allItems,
  flipTokenChoices: flipTokenChoices,
  raffleOwners: raffleOwners,
  treasureBoxes: treasureBoxes,
  adventures: adventures,
  achievements: achievements,
  memes: memes,

  citiesCollectionName: 'shitty_cities',
  citizensCollectionName: 'shitty_cities_citizens',
  collabBlockStakingCollectionName: 'collab_block_staking',

  collabBlocks: collabBlocks,
  cityUpgrades: cityUpgrades,

  blockTypes: {
    restaurants: [1102790639, 1102791660, 1102793225, 1102793354, 1102795932, 1102797240],
    entertainment: [
      1102790776, 1102791858, 1102792471, 1102792839, 1102792942, 1102792373, 1102792980, 1102793144, 1102793193, 1102793315, 1102793536, 1102793572,
      1102794250, 1102794370, 1102795000, 1102795248, 1102796177, 1102796452, 1102796191, 1102796597, 1102796840, 1102797363, 1102797697, 1102797547,
      1195948055,
    ],
    wasteCleanUp: [1102796615],
  },

  traitSwapMaxNfts: 2,
  traitSwapMaxLayers: 4,

  traitSwapTreatsCost: parseInt(process.env.NEXT_PUBLIC_TRAIT_SWAP_TREATS_COST),
  traitSwapAlgoCost: parseInt(process.env.NEXT_PUBLIC_TRAIT_SWAP_ALGO_COST),

  fourthLitterTraitLayers: ['Background', 'Fur', 'Clothing', 'Accessory', 'Whiskers', 'Mouth', 'Eyes', 'Head'],

  // todo - some of these traits just require other specific traits, fix later
  bannedTraits: [
    'Space Helmet',
    'Slime Wig',
    'Muscle Suit',
    'Hero Costume',
    'Magician Costume',
    'Zombie',
    'Adventure Hat',
    'Brown Wig with Sideburns',
    'Ivory Himation',
    'Puffy Winter Coat',
    'Skis',
    'Umbrella',
  ],

  flaggableTraits: ['Umbrella', 'Skis', 'Lazers'],
  traitsWithMultipleOptions: [
    'Thermal Cup',
    'Baseball Bat',
    'Boxing Gloves',
    'Lightsaber',
    'Milk',
    'Video Game',
    'Soccer Uniform',
    'Boxing Shorts',
    'Beanie',
    'Headband',
    'Pigtails',
    'Bow Tie',
  ],
};

export default config;
