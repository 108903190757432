const assets = [
  805168778, 805168782, 805168786, 805168788, 805168792, 805168802, 805168804, 805168808, 805168813, 805168821, 805168824, 805168829, 805168838,
  805168841, 805168845, 805168849, 805168851, 805168856, 805168858, 805168861, 805168865, 805168872, 805168877, 805168882, 805168896, 805168920,
  805168928, 805168941, 805168968, 805168979, 805168993, 805169007, 805169021, 805169032, 805169061, 805169075, 805169085, 805169097, 805169110,
  805169121, 805169130, 805169137, 805169144, 805169156, 805169165, 805169179, 805169197, 805169217, 805169229, 805169239, 805169254, 805169280,
  805169293, 805169308, 805169317, 805169329, 805169354, 805169369, 805169378, 805169394, 805169405, 805169416, 805169430, 805169441, 805169470,
  805169484, 805169497, 805169507, 805169526, 805169536, 805169552, 805169582, 805169591, 805169607, 805169623, 805169632, 805169646, 805169658,
  805169678, 805169693, 805169701, 805169714, 805169744, 805169776, 805169807, 805169834, 805169853, 805169869, 805169891, 805169936, 805169949,
  805170004, 805170015, 805170042, 805170079, 805170112, 805170138, 805170156, 805170179, 805170190, 805170215, 805170262, 805170298, 805170313,
  805170349, 805170363, 805170443, 805170569, 805170716, 805170774, 805170885, 805170916, 805170943, 805170965, 805170976, 805171238, 805171311,
  805171481, 805171578, 805171608, 805171643, 805171657, 805171676, 805171740, 805171834, 805171913, 805172003, 805172014, 805172121, 805172139,
  805172157, 805172172, 805172199, 805172395, 805172889, 805172925, 805173007, 805173027, 805173045, 805173065, 805173078, 805173161, 805173200,
  805173225, 805173241, 805173262, 805173277, 805173306, 805173317, 805173336, 805173357, 805173372, 805173390, 805173400, 805173457, 805173471,
  805173487, 805173501, 805173537, 805173547, 805173567, 805173577, 805173591, 805173647, 805173663, 805173694, 805173726, 805173766, 805173788,
  805173804, 805173815, 805173839, 805173868, 805173885, 805173900, 805173917, 805173928, 805173955, 805173968, 805173980, 805174032, 805174046,
  805174059, 805174073, 805174083, 805174094, 805174104, 805174118, 805174130, 805174142, 805174156, 805174168, 805174189, 805174202, 805174214,
  805174228, 805174246, 805174261, 805174279, 805174286, 805174299, 805174310, 805174337, 805174351, 805174357, 805174371, 805174380, 805174407,
  805174434, 805174456, 805174474, 805174503, 805174517, 805174528, 805174535, 805174542, 805174564, 805174576, 805174586, 805174596, 805174611,
  805174622, 805174678, 805174700, 805174712, 805174739, 805174751, 805174767, 805174780, 805174790, 805174805, 805174817, 805174829, 805174845,
  805174883, 805174893, 805174909, 805174920, 805174949, 805174967, 805174986, 805174996, 805175020, 805175031, 805175061, 805175088, 805175102,
  805175113, 805175125, 805175151, 805175163, 805175177, 805175188, 805175200, 805175228, 805175251, 805175274, 805175287, 805175302, 805175316,
  805175337, 805175356, 805175367, 805175382, 805175396, 805175409, 805175420, 805175437, 805175453, 805175468, 805175484, 805175498, 805175533,
  805175551, 805175564, 805175574, 805175584, 805175600, 805175627, 805175638, 805175660, 805175672, 805175691, 805175703, 805175711, 805175720,
  805175730, 805175741, 805175752, 805175763, 805175775, 805175784, 805175796, 805175808, 805175819, 805175831, 805175857, 805175869, 805175884,
  805175894, 805175909, 805175922, 805175932, 805175946, 805175955, 805175968, 805175980, 805176004, 805176018, 805176030, 805176044, 805176064,
  805176080, 805176090, 805176109, 805176128, 805176143, 805176158, 805176166, 805176178, 805176190, 805176201, 805176216, 805176227, 805176236,
  805176254, 805176268, 805176285, 805176310, 805176322, 805176333, 805176344, 805176351, 805176361, 805176374, 805176386, 805176400, 805176409,
  805176420, 805176432, 805176440, 805176452, 805176468, 805176481, 805176493, 805176503, 805176512, 805176526, 805176535, 805176542, 805176554,
  805176572, 805176584, 805176591, 805176598, 805176608, 805176623, 805176641, 805176653, 805176664, 805176675, 805176700, 805176708, 805176726,
  805176738, 805176752, 805176766, 805176775, 805176788, 805176797, 805176816, 805176827, 805176836, 805176846, 805176877, 805176890, 805176899,
  805176916, 805176930, 805176940, 805176950, 805176962, 805176972, 805176989, 805177001, 805177010, 805177025, 805177038, 805177050, 805177064,
  805177071, 805177082, 805177089, 805177114, 805177126, 805177140, 805177161, 805177173, 805177186, 805177196, 805177213, 805177223, 805177232,
  805177249, 805177261, 805177273, 805177282, 805177296, 805177309, 805177336, 805177344, 805177360, 805177374, 805177390, 805177404, 805177421,
  805177434, 805177446, 805177454, 805177478, 805177495, 805177504, 805177527, 805177557, 805177566, 805177576, 805177586, 805177604, 805177623,
  805177639, 805177662, 805177676, 805177682, 805177693, 805177706, 805177723, 805177737, 805177750, 805177762, 805177776, 805177787, 805177799,
  805177827, 805177836, 805177847, 805177861, 805177870, 805177883, 805177905, 805177917, 805177929, 805177956, 805177963, 805177971, 805177979,
  805178007, 805178020, 805178034, 805178044, 805178056, 805178067, 805178080, 805178089, 805178098, 805178108, 805178118, 805178126, 805178184,
  805178196, 805178206, 805178219, 805178240, 805178255, 805178270, 805178279, 805178292, 805178302, 805178312, 805178332, 805178340, 805178352,
  805178365, 805178384, 805178392, 805178401, 805178411, 805178424, 805178439, 805178453, 805178472, 805178484, 805178495, 805178505, 805178518,
  805178530, 805178544, 805178556, 805178567, 805178579, 805178591, 805178605, 805178614, 805178627, 805178646, 805178652, 805178660, 805178671,
  805178681, 805178693, 805178713, 805178727, 805178743, 805178753, 805178764, 805178777, 805178788, 805178808, 805178819, 805178829, 805178841,
  805178847, 805178862, 805178873, 805178881, 805178893, 805178898, 805178913, 805178926, 805178943, 805178959, 805178972, 805178997, 805179010,
  805179019, 805179026, 805179044, 805179090, 805179109, 805179128, 805179146, 805179162, 805179181, 805179199, 805179210, 805179220, 805179235,
  805179246, 805179257, 805179270, 805179286, 805179303, 805179320, 805179327, 805179340, 805179353, 805179371, 805179384, 805179400, 805179419,
  805179429, 805179439, 805179448, 805179463, 805179476, 805179493, 805179503, 805179512, 805179528, 805179537, 805179548, 805179560, 805179590,
  805179604, 805179616, 805179626, 805179645, 805179664, 805179678, 805179705, 805179717, 805179731, 805179740, 805179751, 805179761, 805179779,
  805179799, 805179829, 805179846, 805179860, 805179867, 805179883, 805179895, 805179912, 805179930, 805179944, 805179958, 805179974, 805179984,
  805179996, 805180007, 805180023, 805180047, 805180063, 805180078, 805180087, 805180105, 805180119, 805180130, 805180143, 805180155, 805180166,
  805180173, 805180182, 805180196, 805180207, 805180223, 805180234, 805180246, 805180264, 805180272, 805180283, 805180294, 805180316, 805180332,
  805180340, 805180351, 805180372, 805180386, 805180406, 805180421, 805180432, 805180447, 805180460, 805180492, 805180506, 805180514, 805180526,
  805180535, 805180545, 805180559, 805180589, 805180601, 805180610, 805180640, 805180653, 805180661, 805180674, 805180691, 805180705, 805180724,
  805180760, 805180790, 805180804, 805180815, 805180829, 805180837, 805180844, 805180857, 805180866, 805180883, 805180898, 805180911, 805180922,
  805180942, 805180956, 805180968, 805180987, 805180992, 805181000, 805181013, 805181027, 805181040, 805181050, 805181057, 805181088, 805181099,
  805181102, 805181107, 805181112, 805181115, 805181118, 805181126, 805181129, 805181132, 805181137, 805181143, 805181147, 805181149, 805181153,
  805181156, 805181169, 805181199, 805181228, 805181254, 805181265, 805181268, 805181275, 805181280, 805181284, 805181288, 805181299, 805181310,
  805181326, 805181341, 805181354, 805181371, 805181381, 805181395, 805181409, 805181421, 805181448, 805181535, 805181546, 805181564, 805181576,
  805181589, 805181623, 805181636, 805181658, 805181668, 805181689, 805181700, 805181718, 805181731, 805181743, 805181757, 805181768, 805181781,
  805181808, 805181815, 805181821, 805181824, 805181829, 805181833, 805181840, 805181843, 805181855, 805181868, 805181880, 805181892, 805181919,
  805181944, 805181957, 805181974, 805182006, 805182018, 805182027, 805182044, 805182069, 805182083, 805182099, 805182108, 805182121, 805182130,
  805182154, 805182162, 805182175, 805182185, 805182199, 805182217, 805182229, 805182256, 805182277, 805182301, 805182314, 805182394, 805182405,
  805182414, 805182433, 805182451, 805182466, 805182481, 805182492, 805182541, 805182557, 805182577, 805182591, 805182608, 805182629, 805182644,
  805182657, 805182667, 805182689, 805182703, 805182723, 805182746, 805182768, 805182782, 805182791, 805182815, 805182830, 805182848, 805182866,
  805182892, 805182904, 805182918, 805182931, 805182949, 805182973, 805182986, 805183000, 805183015, 805183040, 805183056, 805183064, 805183074,
  805183097, 805183114, 805183128, 805183156, 805183168, 805183181, 805183202, 805183216, 805183229, 805183242, 805183303, 805183333, 805183343,
  805183349, 805183359, 805183386, 805183401, 805183415, 805183423, 805183437, 805183490, 805183503, 805183515, 805183530, 805183542, 805183557,
  805183576, 805183594, 805183604, 805183616, 805183630, 805183643, 805183661, 805183676, 805183692, 805183702, 805183716, 805183728, 805183751,
  805183766, 805183775, 805183791, 805183801, 805183814, 805183826, 805183834, 805183862, 805183876, 805183881, 805183897, 805183909, 805183923,
  805183936, 805183946, 805183979, 805184008, 805184019, 805184030, 805184046, 805184058, 805184086, 805184096, 805184174, 805184200, 805184216,
  805184227, 805184240, 805184259, 805184270, 805184283, 805184293, 805184312, 805184321, 805184329, 805184341, 805184362, 805184385, 805184414,
  805184430, 805184436, 805184447, 805184459, 805184470, 805184491, 805184520, 805184539, 805184550, 805184572, 805184580, 805184592, 805184604,
  805184617, 805184628, 805184640, 805184658, 805184670, 805184683, 805184695, 805184710, 805184720, 805184730, 805184743, 805184755, 805184766,
  805184819, 805184827, 805184839, 805184855, 805184872, 805184885, 805184894, 805184904, 805184913, 805184933, 805184958, 805185015, 805185034,
  805185048, 805185061, 805185071, 805185078, 805185091, 805185103, 805185120, 805185127, 805185141, 805185157, 805185165, 805185177, 805185191,
  805185209, 805185222, 805185236, 805185245, 805185253, 805185264, 805185278, 805185289, 805185304, 805185327, 805185332, 805185351, 805185363,
  805185375, 805185388, 805185417, 805185433, 805185445, 805185459, 805185472, 805185488, 805185560, 805185571, 805185592, 805185608, 805185616,
  805185629, 805185645, 805185659, 805185670, 805185689, 805185699, 805185759, 805185767, 805185777, 805185783, 805185806, 805185873, 805185890,
  805185904, 805185923, 805185934, 805185946, 805185955, 805186012, 805186022, 805186036, 805186050, 805186077, 805186087, 805186104, 805186118,
  805186136, 805186150, 805186161, 805186185, 805186253, 805186266, 805186282, 805186291, 805186303, 805186318, 805186349,
];

export default assets;
