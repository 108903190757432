import React from 'react';

const IconApartment = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' className='w-8 h-8'>
      <path
        opacity='.6'
        d='M176 0c-26.5 0-48 21.5-48 48V96H48C21.5 96 0 117.5 0 144V464c0 26.5 21.5 48 48 48h80 32 96V416c0-17.7 14.3-32 32-32s32 14.3 32 32v96h96 32 80c26.5 0 48-21.5 48-48V240c0-26.5-21.5-48-48-48H448V48c0-26.5-21.5-48-48-48H176zM368 320H336c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zm144 80c0 8.8-7.2 16-16 16H464c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zM112 320H80c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zm16 80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zm112-80H208c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zM384 80v32c0 8.8-7.2 16-16 16H336c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM240 64c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H208c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h32zM384 208c0 8.8-7.2 16-16 16H336c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zM496 320H464c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zM128 208c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zm112 16H208c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16z'
        fill='currentColor'
      />
      <path
        d='M384 80c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V80zM112 320c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32zm272-16V272c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16zm128 96V368c0-8.8-7.2-16-16-16H464c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16zm-384 0V368c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16zm112-80c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32zm0-256H208c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16zM384 208V176c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16zM496 320c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H464c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32zM128 208V176c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16zm112 16c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconApartment;
