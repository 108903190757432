import profileAchievements from './profile';
import adventureAchievements from './adventures';
import bountyAchievements from './bounties';
import cityAchievements from './cities';
import discordAchievements from './discord';
import twitterAchievements from './twitter';
import kittyAchievements from './kitties';
import accessoryAchievements from './accessories';

const achievements = [
  ...profileAchievements,
  ...kittyAchievements,
  ...cityAchievements,
  ...accessoryAchievements,
  ...adventureAchievements,
  ...twitterAchievements,
  ...discordAchievements,
  ...bountyAchievements,
];

export default achievements;
