import React from 'react';

const IconHeart = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='w-8 h-8'>
      <path
        d='M39.8 263.8L64 288 256 480 448 288l24.2-24.2c25.5-25.5 39.8-60 39.8-96C512 92.8 451.2 32 376.2 32c-36 0-70.5 14.3-96 39.8L256 96 231.8 71.8c-25.5-25.5-60-39.8-96-39.8C60.8 32 0 92.8 0 167.8c0 36 14.3 70.5 39.8 96z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconHeart;
