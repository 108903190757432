import React from 'react';

const IconCamera = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='w-8 h-8'>
      <path
        opacity='.6'
        d='M0 128v32H256 512V96c0-35.3-28.7-64-64-64H271.1c-9.9 0-19.7 2.3-28.6 6.8L192 64H64C28.7 64 0 92.7 0 128z'
        fill='currentColor'
      />
      <path
        d='M64 48c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16V64H64V48zM0 416V160H512V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64zm256-16a96 96 0 1 0 0-192 96 96 0 1 0 0 192z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconCamera;
