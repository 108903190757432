const accessories = [
  {
    id: 699371763,
    unitName: 'SKA001',
    claimed: 0,
    quantity: 50,
    mustHaveKitties: 1,
    name: 'Shitty Litter Pan',
    image: '/accessories/shitty-litter-pan.png',
  },
  {
    id: 700882175,
    unitName: 'SKA002',
    claimed: 0,
    quantity: 50,
    mustHaveKitties: 1,
    name: 'Shitty Cat Tower',
    image: '/accessories/cat-tower.png',
  },
  {
    id: 700882780,
    unitName: 'SKA003',
    claimed: 0,
    quantity: 10,
    mustHaveKitties: 5,
    name: 'Less Shitty Cat Tower',
    image: '/accessories/cat-tower-large.png',
  },
  {
    id: 704485483,
    unitName: 'SKA004',
    claimed: 0,
    quantity: 50,
    mustHaveKitties: 1,
    name: 'Shitty Feather Toy',
    image: '/accessories/feather-toy.png',
  },
  {
    id: 704486275,
    unitName: 'SKA005',
    claimed: 0,
    quantity: 25,
    mustHaveKitties: 2,
    name: 'Shitty Roller Toy',
    image: '/accessories/roller-toy.png',
  },
  {
    id: 722364568,
    unitName: 'SKA006',
    claimed: 0,
    quantity: 25,
    mustHaveKitties: 2,
    name: 'Shitty Food & Water Bowl',
    image: '/accessories/food-water-bowl.png',
  },
  {
    id: 795387138,
    unitName: 'SKA007',
    claimed: 0,
    quantity: 16,
    mustHaveKitties: 3,
    name: 'Shitty Cat Tunnel',
    image: '/accessories/cat-tunnel.png',
  },
  {
    id: 809528563,
    unitName: 'SKA008',
    claimed: 0,
    quantity: 25,
    mustHaveKitties: 2,
    name: 'Shitty Cat Bed',
    image: '/accessories/cat-bed.png',
  },
  {
    id: 833778238,
    unitName: 'SKA009',
    claimed: 0,
    quantity: 50,
    mustHaveKitties: 1,
    name: 'Shitty Balls',
    image: '/accessories/balls.png',
  },
  {
    id: 843747214,
    unitName: 'SKA010',
    claimed: 0,
    quantity: 10,
    mustHaveKitties: 5,
    name: 'Less Shitty Cat Tower 2',
    image: '/accessories/cat-tower-larger.png',
  },
  {
    id: 844776290,
    unitName: 'SKA011',
    claimed: 0,
    quantity: 25,
    mustHaveKitties: 2,
    name: 'Shitty Cat Hut',
    image: '/accessories/cat-hut.png',
  },
  {
    id: 867652025,
    unitName: 'SKA012',
    claimed: 0,
    quantity: 16,
    mustHaveKitties: 3,
    name: 'Shitty Hammock',
    image: '/accessories/hammock.png',
  },
  {
    id: 867652300,
    unitName: 'SKA013',
    claimed: 0,
    quantity: 50,
    mustHaveKitties: 1,
    name: 'Shitty Collars',
    image: '/accessories/collar.png',
  },
  {
    id: 2177691712,
    unitName: 'SKA014',
    claimed: 25,
    quantity: 25,
    mustHaveKitties: 2,
    name: 'Shitty Laser Pointer',
    image: '/accessories/laser-pointer.png',
  },
  {
    id: 2177697311,
    unitName: 'SKA015',
    claimed: 50,
    quantity: 50,
    mustHaveKitties: 1,
    name: 'Shitty Cat Grass',
    image: '/accessories/cat-grass.png',
  },
];

export default accessories;
