const MEMES = [
  {
    name: 'Happy New Year: 2023',
    image: '/memes/third-litter/new-years-2023.png',
    disableHeads: true,
    disableClothes: false,
    disableAccessory: true,
    gens: [3],
  },
  {
    name: 'Happy New Year: 2023',
    image: '/memes/third-litter/new-years-2023-2.png',
    disableHeads: true,
    disableClothes: false,
    disableAccessory: true,
    gens: [3],
  },
  {
    name: `Valentine's Day`,
    image: '/memes/third-litter/valentines-day.png',
    disableHeads: false,
    disableClothes: true,
    disableAccessory: true,
    gens: [3],
  },
  {
    name: `Valentine's Day`,
    image: '/memes/third-litter/valentines-day-2.png',
    disableHeads: false,
    disableClothes: true,
    disableAccessory: true,
    gens: [3],
  },
  {
    name: `Valentine's Day`,
    image: '/memes/third-litter/valentines-day-3.png',
    disableHeads: false,
    disableClothes: true,
    disableAccessory: true,
    gens: [3],
  },
  {
    name: `St. Patrick's Day`,
    image: '/memes/third-litter/st-patricks-day.png',
    disableHeads: true,
    disableClothes: true,
    disableAccessory: true,
    gens: [3],
  },
  {
    name: `GM Flag`,
    image: '/memes/fourth-litter/flag-gm.png',
    disableAccessory: true,
    gens: [4],
    position1: {
      width: 5000,
      height: 5000,
      x: -1700,
      y: 100,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `GN Flag`,
    image: '/memes/fourth-litter/flag-gn.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `LOL Flag`,
    image: '/memes/fourth-litter/flag-lol.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `WEN Flag`,
    image: '/memes/fourth-litter/flag-wen.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `WOW Flag`,
    image: '/memes/fourth-litter/flag-wow.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Algorand Flag`,
    image: '/memes/fourth-litter/flag-algorand.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `GM Flag`,
    image: '/memes/first-litter/flag-gm.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `GN Flag`,
    image: '/memes/first-litter/flag-gn.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `LOL Flag`,
    image: '/memes/first-litter/flag-lol.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `WEN Flag`,
    image: '/memes/first-litter/flag-wen.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `WOW Flag`,
    image: '/memes/first-litter/flag-wow.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Algorand Flag`,
    image: '/memes/first-litter/flag-algorand.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Speech Bubble`,
    image: '/memes/fourth-litter/bubble-blank.png',
    gens: [1, 2, 3, 4],
    isSpeechBubble: true,
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -100,
    },
    position2: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
    editable: {
      defaultFontSize: 200,
      fontSizes: [100, 150, 200, 250],
      width: 1000,
      align: 'center',
      x: 1820,
      y: 370,
    },
  },
  {
    name: `Have a Shitty Day`,
    image: '/memes/fourth-litter/bubble-shitty-day.png',
    gens: [1, 2, 3, 4],
    isSpeechBubble: true,
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -100,
    },
    position2: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Congrats!`,
    image: '/memes/fourth-litter/bubble-congrats.png',
    gens: [1, 2, 3, 4],
    isSpeechBubble: true,
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -100,
    },
    position2: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Thanks!`,
    image: '/memes/fourth-litter/bubble-thanks.png',
    gens: [1, 2, 3, 4],
    isSpeechBubble: true,
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -100,
    },
    position2: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Shitty!`,
    image: '/memes/fourth-litter/shitty-full.png',
    foregroundImage: '/memes/fourth-litter/shitty.png',
    backgroundImage: '/memes/fourth-litter/going-up.png',
    gens: [1, 2, 3, 4],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -100,
    },
    position2: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Do Something`,
    image: '/memes/fourth-litter/do-something.png',
    disableAccessory: true,
    forceBackground: 'White',
    gens: [4],
    position4: {
      width: 3000,
      height: 3000,
      x: -775,
      y: -200,
    },
  },
  {
    name: `This is Fine`,
    image: '/memes/fourth-litter/this-is-fine-full.png',
    foregroundImage: '/memes/fourth-litter/this-is-fine-fg.png',
    backgroundImage: '/memes/fourth-litter/this-is-fine-bg.png',
    gens: [4],
    position4: {
      width: 2800,
      height: 2800,
      x: 0,
      y: 120,
    },
  },
  {
    name: `Change My Mind`,
    image: '/memes/fourth-litter/change-mind-blank-full.png',
    foregroundImage: '/memes/fourth-litter/change-mind-blank.png',
    backgroundImage: '/memes/fourth-litter/change-mind-bg.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 3000,
      height: 3000,
      x: -760,
      y: -130,
    },
    editable: {
      defaultFontSize: 150,
      fontSizes: [100, 150],
      width: 1000,
      align: 'center',
      x: 1140,
      y: 2070,
    },
  },
  {
    name: `Shittiest NFTS on Algorand`,
    image: '/memes/fourth-litter/change-mind-shitty-full.png',
    foregroundImage: '/memes/fourth-litter/change-mind-shitty.png',
    backgroundImage: '/memes/fourth-litter/change-mind-bg.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 3000,
      height: 3000,
      x: -760,
      y: -130,
    },
  },
];

export default MEMES;
