const ASSETS = [
  812513319, 812513398, 812513450, 812513534, 812513617, 812513673, 812513742, 812513809, 812513873, 812513920, 812520467, 812520527, 812520605,
  812520710, 812520787, 812520834, 812520901, 812520993, 812521053, 812521133, 812521220, 812521291, 812521366, 812521427, 812521497, 812521567,
  812521665, 812521720, 812521830, 812521920, 812521983, 812522060, 812522132, 812522216, 812522318, 812522381, 812522469, 812522526, 812522610,
  812522687, 812522778, 812522845, 812522923, 812522997, 812523052, 812523125, 812523187, 812523243, 812523330, 812523382, 812523468, 812523510,
  812523671, 812523728, 812523806, 812523900, 812523947, 812524004, 812524071, 812524156, 812524224, 812524301, 812524373, 812524435, 812524543,
  812524615, 812524719, 812524821, 812524896, 812524950, 812524999, 812525066, 812525146, 812525223, 812525285, 812525345, 812525409, 812525459,
  812525528, 812525588, 812525680, 812525728, 812525805, 812525875, 812525921, 812525992, 812526071, 812526158, 812526223, 812526293, 812526340,
  812526421, 812526484, 812526556, 812526619, 812526694, 812526764, 812526813, 812526902, 812526953, 812527007, 812527094, 812527156, 812527211,
  812527296, 812527372, 812527427, 812527512, 812527588, 812527647, 812527717, 812527789, 812527868, 812527951, 812528018, 812528176, 812528240,
  812528348, 812528458, 812528544, 812528622, 812528681, 812528762, 812528830, 812528909, 812528977, 812529037, 812529122, 812529160, 812529224,
  812529296, 812529329, 812529400, 812529453, 812529506, 812529568, 812529633, 812529701, 812529752, 812529819, 812529881, 812529947, 812529997,
  812530066, 812530116, 812530188, 812530254, 812530317, 812530362, 812530422, 812530479, 812530523, 812530577, 812530633, 812530693, 812530737,
  812530814, 812530890, 812530934, 812531170, 812531270, 812531353, 812531424, 812531501, 812531585, 812531631, 812531704, 812531780, 812531834,
  812531931, 812532024, 812532097, 812532181, 812532267, 812532315, 812532388, 812532441, 812532529, 812532627, 812532705, 812532757, 812532843,
  812532899, 812532999, 812533062, 812533123, 812533202, 812533283, 812533376, 812533418, 812533484, 812533556, 812533617, 812533699, 812533768,
  812533810, 812533873, 812533962, 812534033, 812534108, 812534190, 812534258, 812534320, 812534387, 812534435, 812534501, 812534570, 812534709,
  812534766, 812534844, 812534914, 812534981, 812535038, 812535127, 812535196, 812535265, 812535333, 812535398, 812535472, 812535544, 812535636,
  812535690, 812535765, 812535853, 812535898, 812535995, 812536066, 812536142, 812536221, 812536327, 812536431, 812536500, 812536582, 812536645,
  812536702, 812536755, 812536805, 812536887, 812536954, 812537010, 812537080, 812537138, 812537208, 812537268, 812537319, 812537439, 812537572,
  812537654, 812537724, 812537797, 812537896, 812538016, 812538098, 812538157, 812538234, 812538336, 812538437, 812538526, 812538601, 812538676,
  812538741, 812538822, 812538909, 812538996, 812539047, 812539122, 812539276, 812539348, 812539428, 812539506, 812539556, 812539614, 812539696,
  812539738, 812539805, 812539879, 812539938, 812540002, 812540071, 812540142, 812540208, 812540279, 812540382, 812540446, 812540515, 812540590,
  812540660, 812540716, 812540783, 812540879, 812540940, 812541012, 812541079, 812541158, 812541206, 812541280, 812541349, 812541430, 812541512,
  812541548, 812541620, 812541689, 812541738, 812542223, 812542289, 812542368, 812542442, 812542519, 812542616, 812542666, 812542745, 812542833,
  812542924, 812542986, 812543060, 812543135, 812543193, 812543268, 812543337, 812543409, 812543470, 812543519, 812543609, 812543685, 812543744,
  812543800, 812543880, 812543961, 812544029, 812544108, 812544176, 812544229, 812544301, 812544377, 812544451, 812544521, 812544613, 812544741,
  812544790, 812544884, 812544950, 812545028, 812545096, 812545149, 812545282, 812545346, 812545403, 812545463, 812545530, 812545586, 812545641,
  812545702, 812545769, 812545835, 812545894, 812545939, 812545991, 812546057, 812546123, 812546181, 812546232, 812546295, 812546372, 812546432,
  812546486, 812546553, 812546616, 812546694, 812546827, 812546896, 812546940, 812547019, 812547123, 812547212, 812547337, 812547420, 812547502,
  812547627, 812547707, 812547791, 812547862, 812547959, 812548039, 812548129, 812548217, 812548294, 812548393, 812548464, 812548564, 812548644,
  812548727, 812548818, 812548911, 812548991, 812549088, 812549196, 812549320, 812549396, 812549479, 812549581, 812549688, 812549825, 812549991,
  812550096, 812550183, 812550228, 812550307, 812550355, 812550408, 812550476, 812550523, 812550575, 812550627, 812550688, 812550755, 812550805,
  812550878, 812550946, 812550995, 812551074, 812551131, 812551199, 812551310, 812551360, 812551418, 812551490, 812551539, 812551596, 812551680,
  812551741, 812551846, 812551888, 812551938, 812551985, 812552065, 812552196, 812552323, 812552468, 812552564, 812552621, 812552688, 812552769,
  812552847, 812552969, 812553034, 812553115, 812553264, 812553355, 812553434, 812553513, 812553575, 812553617, 812553752, 812553813, 812553866,
  812553910, 812554109, 812554225, 812554370, 812554634, 812554815, 812555015, 812555098, 812555212, 812555278, 812555337, 812555390, 812555449,
  812555485, 812555546, 812555595, 812555621, 812555661, 812555717, 812555769, 812555800, 812555858, 812555900, 812555941, 812555969, 812555998,
  812556032, 812556069, 812556268, 812556329, 812556454, 812556555, 812556624, 812556709, 812556753, 812556824, 812556871, 812556942, 812556989,
  812557044, 812557092, 812557161, 812557245, 812557355, 812557408, 812557468, 812557526, 812557584, 812557779, 812558017, 812558128, 812558203,
  812558329, 812558527, 812558673, 812558903, 812559253, 812559449, 812559577, 812559655, 812559714, 812559775, 812559822, 812559876, 812559929,
  812559968, 812560052, 812560100, 812560203, 812560260, 812560306, 812560347, 812560428, 812560485, 812560533, 812560585, 812560645, 812560697,
  812560752, 812560824, 812560879, 812560978, 812561053, 812561113, 812561393, 812561494, 812561683, 812561780, 812561830, 812561892, 812561937,
  812562014, 812562080, 812562146, 812562191, 812562234, 812562283, 812562346, 812562393, 812562433, 812562477, 812562527, 812562567, 812562607,
  812562659, 812562702, 812562738, 812562773, 812562896, 812562977, 812563033, 812563121, 812563157, 812563195, 812563255, 812563297, 812563347,
  812563385, 812563432, 812563473, 812563591, 812563648, 812563753, 812563880, 812563915, 812563970, 812564091, 812564194, 812564305, 812564364,
  812564426, 812564464, 812564518, 812564591, 812564657, 812564699, 812564759, 812564828, 812564896, 812564937, 812565001, 812565035, 812565132,
  812565176, 812565230, 812565281, 812565339, 812565572, 812565687, 812565775, 812565856, 812566050, 812566239, 812566304, 812566370, 812566451,
  812566545, 812566602, 812566708, 812566781, 812566855, 812566920, 812566982, 812567040, 812567100, 812567141, 812567230, 812567297, 812567353,
  812567413, 812567459, 812567512, 812567559, 812567626, 812567705, 812567746, 812567799, 812567866, 812567963, 812568028, 812568109, 812568173,
  812568231, 812568302, 812568348, 812568391, 812568448, 812568495, 812568536, 812568610, 812568738, 812568791, 812568827, 812568882, 812568943,
  812569002, 812569050, 812569096, 812569157, 812569198, 812569258, 812569340, 812569398, 812569464, 812569535, 812569601, 812569646, 812569705,
  812569753, 812569811, 812569867, 812569912, 812569975, 812570026, 812570102, 812570212, 812570261, 812570332, 812570391, 812570443, 812570481,
  812570540, 812570589, 812570648, 812570731, 812570794, 812570847, 812570925, 812571020, 812571082, 812571122, 812571182, 812571242, 812571369,
  812571465, 812571537, 812571587, 812571668, 812571718, 812571770, 812571826, 812571915, 812571966, 812572072, 812572103, 812572173, 812572252,
  812572348, 812572650, 812572723, 812572798, 812572857, 812572926, 812572999, 812573055, 812573110, 812573186, 812573247, 812573301, 812573363,
  812573422, 812573497, 812573553, 812573595, 812573649, 812573708, 812573783, 812573849, 812573929, 812573987, 812574058, 812574134, 812574199,
  812574269, 812574322, 812574398, 812574464, 812574750, 812574939, 812574992, 812575078, 812575218, 812575289, 812575369, 812575422, 812575478,
  812575534, 812575609, 812575642, 812575716, 812575763, 812575844, 812575887, 812575943, 812575989, 812576034, 812576089, 812576154, 812576208,
  812576261, 812576322, 812576387, 812576456, 812576518, 812576576, 812576642, 812576693, 812576749, 812576823, 812576883, 812576955, 812577011,
  812577081, 812577147, 812577206, 812577244, 812577315, 812577375, 812577413, 812577462, 812577512, 812577557, 812577695, 812577773, 812577879,
  812577940, 812577991, 812578045, 812578171, 812578215, 812578276, 812578345, 812578390, 812578445, 812578509, 812578567, 812578609, 812578645,
  812578713, 812578765, 812578808, 812578859, 812578916, 812578979, 812579057, 812579126, 812579195, 812579263, 812579318, 812579377, 812579424,
  812579534, 812579569, 812579617, 812579681, 812579732, 812579782, 812579850, 812579906, 812579941, 812579997, 812580063, 812580103, 812580169,
  812580216, 812580274, 812580308, 812580368, 812580424, 812580483, 812580533, 812580586, 812580638, 812580709, 812580762, 812580841, 812580899,
  812580957, 812581033, 812581089, 812581151, 812581202, 812581279, 812581493, 812582111, 812583260, 812583916, 812584496, 812585094, 812585686,
  812586258, 812586847, 812587429, 812588034, 812588611, 812589195, 812589795, 812590375, 812590983, 812591618, 812592211, 812592816, 812593426,
  812594050, 812594656, 812595302, 812595851, 812596428, 812597021, 812597607, 812598193, 812598789, 812599977, 812600572, 812601179, 812601762,
  812602995, 812603582, 812604162, 812604739, 812605334, 812605917, 812606423, 812606946, 812607532, 812608112, 812608170, 812608359, 812608937,
  812609552, 812609807, 812609873, 812610406, 812611001, 812611514, 812612097, 812612664, 812613237, 812613820, 812614396, 812614973, 812615528,
  812616091, 812616698, 812617278, 812617884, 812618507, 812619101, 812619670, 812620258, 812620834, 812621463, 812622010, 812622602, 812623191,
  812623807, 812624367, 812624952, 812625566, 812626133, 812626713, 812627287, 812627865, 812628417, 812629005, 812629586, 812630130, 812630682,
  812631254, 812631788, 812632364, 812632910, 812633498, 812634040, 812634618, 812635199, 812635729, 812636837, 812637415, 812637979, 812638521,
  812639063, 812639574, 812639654, 812639777, 812639818, 812639940, 812639994, 812640097, 812640168, 812640226, 812640313, 812640368, 812640443,
  812640526, 812640576, 812640659, 812640719, 812640780, 812640843, 812640902, 812640968, 812641021, 812641083, 812641136, 812641179, 812641231,
  812641276, 812641430, 812641586, 812641683, 812641888, 812642189, 812642356, 812642514, 812642640, 812642769, 812642896, 812642966, 812643036,
  812643091, 812643150, 812643196, 812643249, 812643328, 812643390, 812643459, 812643498, 812643561, 812643612, 812643685, 812643755, 812643823,
  812643887, 812643942, 812644021, 812644103, 812644191, 812644238, 812644292, 812644365, 812644420, 812644536, 812644598, 812644642, 812644711,
  812644778, 812644891, 812644967, 812645048, 812645130, 812645291, 812645327, 812645392, 812645484, 812645546, 812645601, 812645657, 812645751,
  812645796, 812645839, 812645907, 812645974, 812646054, 812646138, 812646188, 812646363, 812646468, 812646616, 812646693, 812646744, 812646846,
  812646900, 812646958, 812647021, 812647096, 812647155, 812647221, 812647287, 812647356, 812647441, 812647510, 812647590, 812647649, 812647712,
  812647762, 812647803, 812647908, 812647980, 812648048, 812648158, 812648293, 812648361, 812648437, 812648523, 812648591, 812648667, 812648811,
  812648861, 812648924, 812649009, 812649062, 812649132, 812649201, 812649248, 812649354, 812649415, 812649495, 812649569, 812649640, 812649691,
  812649768, 812649835, 812649910, 812649995, 812650049, 812650119, 812650192, 812650242, 812650423, 812650500, 812650559, 812650597, 812650732,
  812650813, 812650915, 812650973, 812651076, 812651128, 812651173, 812651271, 812651347, 812651523, 812651594, 812651660, 812651743, 812651811,
  812651870, 812651961, 812652015, 812652079, 812652175, 812652227, 812652288, 812652355, 812652491, 812652556, 812652642, 812652700, 812652765,
  812652844, 812652971, 812653041, 812653101, 812653153, 812653209, 812653267, 812653443, 812653519, 812653598, 812653656, 812653708, 812653774,
  812653843, 812653941, 812654018, 812654104, 812654150, 812654304, 812654408, 812654494, 812654583, 812654654, 812654733, 812654777, 812654913,
  812654985, 812655041, 812655109, 812655163, 812655249, 812655348, 812655407, 812655456, 812655545, 812655975, 812656245, 812656373, 812656466,
  812656530, 812656580, 812656637, 812656703, 812656753, 812656839, 812656976, 812657048, 812657106, 812657176, 812657234, 812657305, 812657366,
  812657412, 812657484, 812657553, 812657627, 812657699, 812657760, 812657826, 812657902, 812657966, 812658031, 812658104, 812658203, 812658291,
  812658352, 812658407, 812658480, 812658549, 812658604, 812658663, 812658719, 812658814, 812658887, 812658953, 812659074, 812659162, 812659251,
  812659328, 812659424, 812659492, 812659564, 812659618, 812659692, 812659762, 812659847, 812659939, 812660003, 812660080, 812660159, 812660564,
  812660645, 812660704, 812660810, 812660877, 812660966, 812661025, 812661084, 812692943, 812693046, 812693120, 812693183, 812693238, 812693293,
  812693372, 812693448, 812693488, 812693569, 812693662, 812693737, 812693817, 812693926, 812694190, 812694381, 812694531, 812694609, 812694679,
  812694756, 812694861, 812694923, 812694998, 812695071, 812695130, 812695204, 812695281, 812695381, 812695465, 812695535, 812695627, 812695696,
  812695757, 812695834, 812695943, 812696025, 812696069, 812696145, 812696245, 812696290, 812696357, 812696418, 812696526, 812696592, 812696682,
  812696747, 812696829, 812696911, 812696972, 812697052, 812697123, 812697400, 812697506, 812697573, 812697638, 812697715, 812697806, 812697887,
  812697939, 812698040, 812698088, 812698155, 812698232, 812698299, 812698355, 812698429, 812698493, 812698559, 812698616, 812698696, 812698774,
  812698824, 812698897, 812698972, 812699056, 812699152, 812699211, 812699310, 812699439, 812699703, 812699768, 812699849, 812699905, 812699973,
  812700055, 812700120, 812700270, 812700430, 812700609, 812700761, 812700925, 812701102, 812701235, 812701313, 812701393, 812701462, 812701534,
  812701605, 812701682, 812702040, 812737968, 812738033, 812738092, 812738177, 812738266, 812738344, 812738441, 812738538, 812738604, 812738784,
  812738890, 812738988, 812739070, 812739174, 812739264, 812739345, 812739436, 812739533, 812739644, 812739725, 812739796, 812739873, 812739953,
  812740032, 812740166, 812740252, 812740323, 812740402, 812740487, 812740575, 812740669, 812740745, 812740838, 812740921, 812740994, 812741072,
  812741181, 812741259, 812741328, 812741398, 812741481, 812741561, 812741650, 812741768, 812741879, 812741993, 812742120, 812742239, 812742341,
  812773940, 812773986, 812774120, 812774198, 812774281, 812774380, 812774441, 812774537, 812774626, 812774725, 812774786, 812775096, 812775165,
  812775244, 812775310, 812775391, 812775462, 812775575, 812775790, 812775840, 812775903, 812775962, 812776026, 812776100, 812776176, 812776333,
  812776384, 812776448, 812776527, 812776594, 812776766, 812776848, 812776920, 812776983, 812777055, 812777132, 812777256, 812777337, 812777468,
  812777537, 812777599, 812777656, 812777717, 812777789, 812777870, 812777936, 812778000, 812778098, 812778174, 812778263, 812778347, 812778424,
  812778520, 812778608, 812778662, 812778742, 812778834, 812778912, 812779023, 812779115, 812779194, 812779310, 812779475, 812779569, 812779651,
  812779716, 812779801, 812779859, 812779941, 812780011, 812780053, 812780134, 812780197, 812780273, 812780342, 812780418, 812780515, 812780574,
  812780667, 812780733, 812780795, 812780862, 812780939, 812781129, 812781241, 812781335, 812781407, 812781500, 812781595, 812781707, 812781801,
  812781899, 812781997, 812782093, 812782190, 812782310, 812782421, 812782517, 812782573, 812782667, 812782890, 812782997, 812783065, 812783158,
  812783248, 812783369, 812783588, 812783670, 812783772, 812783868, 812783969, 812784063, 812784156, 812784223, 812784283, 812784352, 812784422,
  812784499, 812784562, 812784638, 812784704, 812784775, 812784885, 812784963, 812785069, 812785147, 812785230, 812785355, 812785431, 812785517,
  812785612, 812785730, 812785787, 812785865, 812785927, 812786004, 812786080, 812786149, 812786309, 812786387, 812786466, 812786541, 812786608,
  812786667, 812786741, 812786816, 812786913, 812786981, 812787099, 812787173, 812787239, 812787301, 812787431, 812787485, 812787561, 812787642,
  812787706, 812787791, 812787856, 812787941, 812788012, 812788106, 812788186, 812788286, 812788357, 812788422, 812788485, 812788573, 812788656,
  812788732, 812788807, 812788891, 812788961, 812789205, 812789294, 812789384, 812789470, 812789568, 812789648, 812789727, 812789806, 812789870,
  812789955, 812790028, 812790105, 812790185, 812790283, 812790366, 812790446, 812790540, 812790615, 812790714, 812790832, 812791007, 812791096,
  812791227, 812791319, 812791513, 812791598, 812791676, 812791751, 812791820, 812791879, 812791973, 812792057, 812792134, 812792228, 812792290,
  812792421, 812792478, 812792545, 812792667, 812792794, 812792960, 812793029, 812793131, 812793213, 812793297, 812793778, 812794253, 812794691,
  812794895, 812795104, 812795228, 812795308, 812795436, 812795578, 812795699, 812795852, 812795951, 812796098, 812796268, 812796424, 812796578,
  812796745, 812796844, 812796983, 812797066, 812797145, 812797214, 812797300, 812797458, 812797602, 812797745, 812799243, 812799379, 812799435,
  812799529, 812799611, 812799713, 812799814, 812799912, 812800096, 812800190, 812800309, 812800418, 812800568, 812800710, 812800842, 812800995,
  812801109, 812801198, 812801319, 812801471, 812801642, 812802131, 812802439, 812802653, 812802798, 812803079, 812803441, 812803704, 812803838,
  812804077, 812804396, 812804721, 812804972, 812805329, 812805550, 812805790, 812805914, 812806039, 812806158, 812806262, 812806363, 812806499,
  812806588, 812806680, 812806737, 812806860, 812806945, 812807037, 812807141, 812807255, 812807326, 812807460, 812807568, 812807665, 812807762,
  812807892, 812808036, 812808114, 812808426, 812808809, 812808961, 812809150, 812809296, 812809428, 812809596, 812809742, 812809964, 812810151,
  812810255, 812810411, 812810529, 812810669, 812810801, 812811127, 812811229, 812811318, 812811440, 812811517, 812811632, 812811737, 812811883,
  812811995, 812812118, 812812217, 812812352, 812812467, 812812543, 812812662, 812812781, 812812867, 812813110, 812813244, 812813346, 812813535,
  812813625, 812813750, 812813868, 812813972, 812814049, 812814149, 812814279, 812814372, 812814467, 812814604, 812814683, 812814741, 812814832,
  812814894, 812814977, 812815065, 812815203, 812815295, 812815387, 812815477, 812815695, 812815917, 812816007, 812816085, 812816178, 812816344,
  812816460, 812816575, 812816677, 812816763, 812816867, 812817035, 812817108, 812817304, 812817397, 812817460, 812817562, 812817662, 812817751,
  812817846, 812818047, 812818143, 812818319, 812818424, 812818514, 812818691, 812818778, 812818895, 812818989, 812819062, 812819145, 812819250,
  812819337, 812819415, 812819529, 812819607, 812819718, 812819867, 812819993, 812820079, 812820234, 812820380, 812820463, 812820575, 812820681,
  812820790, 812820887, 812821001, 812821143, 812821293, 812821375, 812821508, 812821617, 812821696, 812821762, 812821853, 812821989, 812822158,
  812822233, 812822346, 812822424, 812822508, 812822610, 812822746, 812822849, 812822969, 812823111, 812823193, 812823369, 812823501, 812823603,
  812823697, 812823793, 812823865, 812823957, 812824055, 812824184, 812824297, 812824440, 812824547, 812824707, 812824802, 812824890, 812825000,
  812825125, 812825223, 812825291, 812825361, 812825447, 812825525, 812825623, 812825713, 812825821, 812825919, 812826005, 812826091, 812826289,
  812826367, 812826472, 812826544, 812826624, 812826703, 812826801, 812826894, 812826980, 812827089, 812827190, 812827287, 812827394, 812827492,
  812827563, 812827648, 812827746, 812827831, 812827952, 812828050, 812828152, 812828290, 812828355, 812828443, 812828555, 812828655, 812828739,
  812828854, 812828974, 812829055, 812829129, 812829219, 812829279, 812829377, 812829460, 812829546, 812829627, 812829721, 812829796, 812829857,
  812829955, 812830048, 812830138, 812830242, 812830319, 812830948, 812831025, 812831107, 812831211, 812831308, 812831421, 812831511, 812831625,
  812831730, 812831849, 812831950, 812832041, 812832170, 812832273, 812832338, 812832402, 812832479, 812832571, 812832643, 812832738, 812832833,
  812832915, 812833004, 812833083, 812833130, 812833209, 812833292, 812833486, 812833567, 812833688, 812833775, 812833853, 812833929, 812833998,
  812834090, 812834169, 812834267, 812834334, 812834400, 812834490, 812834577, 812834645, 812834710, 812834774, 812834878, 812835055, 812835148,
  812835205, 812835376, 812835466, 812835580, 812835696, 812835784, 812835853, 812835914, 812836024, 812836153, 812836239, 812836341, 812836418,
  812836502, 812836584, 812836690, 812836778, 812836883, 812836963, 812837046, 812837130, 812837198, 812837279, 812837346, 812837517, 812837638,
  812837720, 812837780, 812837833, 812837937, 812838022, 812838079, 812838169, 812838246, 812838306, 812838366, 812838435, 812838558, 812838623,
  812838736, 812838795, 812838857, 812838941, 812839027, 812839119, 812839210, 812839305, 812839386, 812839444, 812839563, 812839654, 812839737,
  812839849, 812839953, 812840060, 812840138, 812840217, 812840311, 812840390, 812840495, 812840583, 812840679, 812840773, 812840922, 812841069,
  812841402, 812841478, 812841541, 812841672, 812841737, 812841819, 812841907, 812841992, 812842064, 812842136, 812842205, 812842269, 812842351,
  812842430, 812842504, 812842573, 812842654, 812842705, 812842769, 812842843, 812842995, 812843049, 812843118, 812843205, 812843284, 812843349,
  812843450, 812843509, 812843587, 812843665, 812843753, 812843818, 812843899, 812844001, 812844102, 812844316, 812844402, 812844475, 812844719,
  812844801, 812844990, 812845084, 812845181, 812845278, 812845394, 812845464, 812845539, 812845609, 812845695, 812845860, 812845964, 812846061,
  812846131, 812846258, 812846336, 812846421, 812846512, 812846622, 812846809, 812846970, 812847192, 812847309, 812847390, 812847470, 812847560,
  812847640, 812847710, 812847784, 812847852, 812847910, 812847997, 812848082, 812848146, 812848238, 812848334, 812848404, 812848463, 812848613,
  812848667, 812848737, 812848835, 812848917, 812849032, 812849083, 812849168, 812849284, 812849348, 812849433, 812849511, 812849587, 812849687,
  812849749, 812849810, 812849992, 812850050, 812850137, 812850224, 812850312, 812850386, 812850471, 812850564, 812850687, 812850843, 812850964,
  812851058, 812851140, 812851216, 812851270, 812851352, 812851452, 812851544, 812851626, 812851698, 812851777, 812851895, 812851991, 812852082,
  812852156, 812852224, 812852321, 812852428, 812852534, 812852607, 812852668, 812852768, 812852870, 812852957, 812853064, 812853183, 812853268,
  812853346, 812853424, 812853557, 812853632, 812853758, 812853872, 812853955, 812854014, 812854116, 812854302, 812854580, 812854675, 812854786,
  812854888, 812854975, 812855182, 812855283, 812855397, 812855523, 812855617, 812855758, 812855879, 812856047, 812856146, 812856218, 812856398,
  812856475, 812856662, 812856746, 812856830, 812856945, 812856998, 812857082, 812857159, 812857216, 812857294, 812857405, 812857467, 812857540,
  812857626, 812857699, 812857784, 812857953, 812858038, 812858143, 812858198, 812858278, 812858328, 812858417, 812858526, 812858595, 812858673,
  812858765, 812858933, 812859003, 812859094, 812859177, 812859255, 812859412, 812859485, 812859596, 812859749, 812859826, 812859899, 812859977,
  812860069, 812860136, 812860265, 812860331, 812860416, 812860506, 812860567, 812860650, 812860710, 812860777, 812860852, 812860945, 812861013,
  812861069, 812861128, 812861244, 812861326, 812861418, 812861490, 812861574, 812861663, 812861767, 812861918, 812862016, 812862101, 812862176,
  812862253, 812862335, 812862405, 812862473, 812862535, 812862614, 812862768, 812862875, 812862933, 812863018, 812863119, 812863190, 812863264,
  812863360, 812863420, 812863586, 812863659, 812863741, 812863837, 812863896, 812863972, 812864068, 812864160, 812864255, 812864311, 812864365,
  812864437, 812864501, 812864554, 812864626, 812864708, 812864775, 812864858, 812864995, 812865087, 812865292, 812865347, 812865436, 812865508,
  812865606, 812865694, 812865764, 812865836, 812865909, 812865974, 812866039, 812866146, 812866226, 812866306, 812866391, 812866469, 812866524,
  812866621, 812866676, 812866742, 812866841, 812866905, 812866996, 812867059, 812867117, 812867226, 812867367, 812867452, 812867559, 812867622,
  812867685, 812867755, 812867857, 812868067, 812868151, 812868233, 812868324, 812868380, 812868481, 812868560, 812868647, 812868722, 812868787,
  812868873, 812868960, 812869030, 812869089, 812869157, 812869216, 812869274, 812869362, 812869460, 812869530, 812869600, 812869675, 812869736,
  812869821, 812869911, 812870018, 812870085, 812870138, 812870210, 812870282, 812870372, 812870442, 812870510, 812870631, 812870698, 812870790,
  812870855, 812870918, 812870989, 812871063, 812871129, 812871171, 812871240, 812871287, 812871345, 812871404, 812871469, 812871540, 812871588,
  812871651, 812871706, 812871773, 812871825, 812871903, 812872021, 812872195, 812872348, 812872497, 812872639, 812872712, 812872768, 812872818,
  812872888, 812872963, 812873073, 812873138, 812873233, 812873353, 812873419, 812873527, 812873622, 812873689, 812873750, 812873833, 812873943,
  812874069, 812874181, 812874252, 812874348, 812874414, 812874495, 812874589, 812874691, 812874939, 812875033, 812875112, 812875309, 812875385,
  812875481, 812875582, 812875661, 812875750, 812875837, 812875914, 812875972, 812876058, 812876185, 812876255, 812876390, 812876476, 812876669,
  812876786, 812876878, 812876942, 812877054, 812877105, 812877169, 812877225, 812877302, 812877369, 812877446, 812877519, 812877582, 812877666,
  812877789, 812877880, 812877960, 812878042, 812878103, 812878185, 812878235, 812878306, 812878364, 812878418, 812878511, 812878578, 812878711,
  812878792, 812878872, 812878943, 812879066, 812879117, 812879181, 812879254, 812879419, 812879542, 812879626, 812879752, 812879835, 812879955,
  812880103, 812880209, 812880277, 812880336, 812880392, 812880464, 812880553, 812880626, 812880781, 812880853, 812880905, 812880961, 812881023,
  812881082, 812881149, 812881279, 812881355, 812881432, 812881502, 812881593, 812881679, 812881820, 812881918, 812881995, 812882064, 812882203,
  812882294, 812882379, 812882451, 812882524, 812882837, 812883081, 812883289, 812883381, 812883469, 812883533, 812883605, 812883673, 812883755,
  812883846, 812883954, 812884039, 812884161, 812884366, 812884410, 812884485, 812884640, 812884725, 812884798, 812884971, 812885049, 812885115,
  812885166, 812885240, 812885363, 812885467, 812885563, 812885723, 812885804, 812886001, 812886081, 812886159, 812886267, 812886350, 812886423,
  812886515, 812886600, 812886690, 812886824, 812887009, 812887124, 812887217, 812887285, 812887384, 812887476, 812887582, 812887692, 812887773,
  812887831, 812887937, 812888002, 812888449, 812888544, 812888642, 812888708, 812888814, 812888953, 812889031, 812889151, 812889377, 812889452,
  812889530, 812889622, 812889786, 812890110, 812890216, 812890454, 812890545, 812890627, 812890732, 812890830, 812890908, 812891016, 812891131,
  812891225, 812891304, 812891375, 812891564, 812891682, 812891781, 812891881, 812891966, 812892048, 812892164, 812892390, 812892484, 812892688,
  812892852, 812892965, 812893029, 812893140, 812893247, 812893322, 812893409, 812893478, 812893586, 812893650, 812893743, 812893842, 812893946,
  812894042, 812894134, 812894223, 812894318, 812894410, 812894504, 812894596, 812894673, 812894791, 812894890, 812894994, 812895076, 812895168,
  812895245, 812895343, 812895478, 812895592, 812895691, 812895785, 812895879, 812895974, 812896039, 812896111, 812896195, 812896261, 812896347,
  812896454, 812896570, 812896648, 812896771, 812896861, 812896929, 812897013, 812897098, 812897191, 812897262, 812897343, 812897436, 812897514,
  812897577, 812897652, 812897726, 812897786, 812897848, 812897916, 812897975, 812898054, 812898142, 812898217, 812898308, 812898373, 812898453,
  812898758, 812899553, 812899622, 812899723, 812899800, 812899862, 812899939, 812900030, 812900095, 812900180, 812900261, 812900340, 812900421,
  812900498, 812900550, 812900645, 812900725, 812900775, 812900849, 812900920, 812901014, 812901074, 812901188, 812901262, 812901337, 812901434,
  812901540, 812901627, 812901702, 812901762, 812902003, 812902085, 812902162, 812902358, 812902422, 812902525, 812902617, 812902716, 812902820,
  812902901, 812903021, 812903102, 812903197, 812903323, 812903390, 812903463, 812903627, 812903706, 812903844, 812903909, 812903991, 812904078,
  812904164, 812904232, 812904323, 812904518, 812904603, 812904698, 812904775, 812904870, 812905001, 812905063, 812905171, 812905270, 812905373,
  812905475, 812905570, 812905680, 812905802, 812905933, 812906044, 812906140, 812906235, 812906342, 812906423, 812906544, 812906657, 812906782,
  812906885, 812907000, 812907111, 812907208, 812907319, 812907432, 812907511, 812907606, 812907713, 812907926, 812908085, 812908184, 812908307,
  812908418, 812908576, 812908673, 812908805, 812908914, 812908956, 812909082, 812909150, 812909380, 812909513, 812909658, 812909774, 812909880,
  812909960, 812910030, 812910102, 812910164, 812910219, 812910296, 812910384, 812910474, 812910552, 812910608, 812910676, 812910767, 812910924,
  812910990, 812911064, 812911130, 812911207, 812911291, 812911504, 812911648, 812911717, 812911796, 812911871, 812912024, 812912125, 812912221,
  812912299, 812912351, 812912409, 812912481, 812912603, 812912679, 812912751, 812912830, 812912976, 812913037, 812913111, 812913179, 812913247,
  812913314, 812913389, 812913621, 812913683, 812913747, 812913821, 812913911, 812913991, 812914065, 812914114, 812914179, 812914273, 812914361,
  812914426, 812914502, 812914586, 812914635, 812914713, 812914764, 812914811, 812914884, 812914925, 812914984, 812915036, 812915097, 812915161,
  812915245, 812915292, 812915356, 812915405, 812915472, 812915545, 812915681, 812915743, 812915817, 812915870, 812915922, 812915964, 812916040,
  812916102, 812916173, 812916265, 812916336, 812916391, 812916450, 812916515, 812916575, 812916644, 812916714, 812916785, 812916841, 812916897,
  812916968, 812917019, 812917165, 812917231, 812917284, 812917343, 812917414, 812917489, 812917541, 812917611, 812917667, 812917736, 812917801,
  812917892, 812917955, 812918036, 812918114, 812918182, 812918328, 812918417, 812918619, 812918731, 812918825, 812918890, 812918990, 812919093,
  812919178, 812919233, 812919307, 812919358, 812919442, 812919500, 812919560, 812919619, 812919757, 812919888, 812919942, 812920038, 812920103,
  812920159, 812920223, 812920298, 812920373, 812920435, 812920582, 812920655, 812920728, 812920800, 812920864, 812920984, 812921042, 812921126,
  812921209, 812921276, 812921333, 812921404, 812921487, 812921574, 812921646, 812921710, 812921773, 812921861, 812921957, 812922025, 812922091,
  812922164, 812922234, 812922381, 812922458, 812922509, 812922567, 812922645, 812922739, 812922797, 812922874, 812923027, 812923130, 812923262,
  812923359, 812923466, 812923565, 812923690, 812923875, 812924760, 812924850, 812924949, 812925027, 812925125, 812925193, 812925253, 812925346,
  812925509, 812925583, 812925651, 812925721, 812925835, 812925955, 812926077, 812926159, 812926227, 812926284, 812926367, 812926448, 812926531,
  812926607, 812926657, 812926732, 812926818, 812926895, 812927004, 812927197, 812927282, 812927352, 812927422, 812927483, 812927539, 812927634,
  812927694, 812927746, 812927825, 812927909, 812927975, 812928116, 812928201, 812928249, 812928303, 812928370, 812928423, 812928494, 812928554,
  812928647, 812928695, 812928761, 812928829, 812928873, 812928945, 812929031, 812929082, 812929177, 812929241, 812929328, 812929420, 812929479,
  812929589, 812929660, 812929741, 812929815, 812929949, 812930049, 812930157, 812930228, 812930282, 812930339, 812930404, 812930449, 812930524,
  812930592, 812930660, 812930733, 812930814, 812930893, 812930950, 812931031, 812931115, 812931274, 812931346, 812931417, 812931467, 812931529,
  812931590, 812931632, 812931679, 812931755, 812931811, 812931874, 812931944, 812932101, 812932193, 812932345, 812933577, 812933661, 812933711,
  812933781, 812933925, 812933980, 812934786, 812934874, 812934975, 812935063, 812935150, 812935234, 812935325, 812935449, 812935512, 812935580,
  812935636, 812935695, 812935803, 812935863, 812935930, 812936097, 812936193, 812936272, 812936355, 812936427, 812936496, 812936556, 812936794,
  812936861, 812936949, 812937035, 812937098, 812937163, 812937228, 812937284, 812937360, 812937423, 812937502, 812937568, 812937609, 812937721,
  812937804, 812937853, 812937927, 812937980, 812938056, 812938115, 812938188, 812938255, 812938340, 812938489, 812938563, 812938654, 812938709,
  812938780, 812938858, 812938930, 812939007, 812939093, 812939170, 812939245, 812939289, 812939369, 812939443, 812939514, 812939591, 812939655,
  812939699, 812939804, 812939862, 812939955, 812940028, 812940137, 812940225, 812940345, 812940395, 812940451, 812940525, 812940592, 812940708,
  812940799, 812940946, 812941066, 812941141, 812941208, 812941275, 812941332, 812941411, 812941472, 812941529, 812941626, 812941714, 812941774,
  812941870, 812941929, 812941989, 812942085, 812942221, 812942292, 812942422, 812942505, 812942569, 812942640, 812942684, 812942741, 812942795,
  812942856, 812942914, 812942982, 812943055, 812943112, 812943202, 812943286, 812943371, 812943425, 812943537, 812943648, 812943717, 812943848,
  812943909, 812944001, 812944092, 812944182, 812944249, 812944333, 812944411, 812944485, 812944610, 812944676, 812944766, 812944824, 812944911,
  812944986, 812945076, 812945183, 812945241, 812945344, 812945436, 812945542, 812945615, 812945694, 812945817, 812945876, 812945973, 812946071,
  812946151, 812946275, 812946339, 812946415, 812946493, 812946710, 812946825, 812946937, 812947052, 812947156, 812947263, 812947353, 812947430,
  812947631, 812947731, 812947808, 812947891, 812947958, 812948049, 812948130, 812948220, 812948297, 812948361, 812948447, 812948528, 812948582,
  812948649, 812948701, 812948762, 812948833, 812948884, 812948958, 812949027, 812949072, 812949132, 812949202, 812949243, 812949373, 812949421,
  812949503, 812949565, 812949646, 812949706, 812949781, 812949940, 812950031, 812950112, 812950218, 812950288, 812950362, 812950411, 812950471,
  812950528, 812950620, 812950694, 812950740, 812950810, 812951009, 812951084, 812951148, 812951210, 812951285, 812951345, 812951406, 812951473,
  812951539, 812951600, 812951685, 812951780, 812951864, 812951932, 812952017, 812952073, 812952147, 812952228, 812952298, 812952361, 812952429,
  812952498, 812952609, 812952675, 812952761, 812952826, 812952893, 812952978, 812953066, 812953116, 812953213, 812953319, 812953401, 812953521,
  812953588, 812953673, 812953746, 812953828, 812953916, 812953978, 812954046, 812954131, 812954191, 812954255, 812954301, 812954386, 812954465,
  812954554, 812954657, 812954729, 812954802, 812954898, 812954988, 812955078, 812955121, 812955193, 812955277, 812955362, 812955511, 812955603,
  812955664, 812955732, 812955827, 812955894, 812956064, 812956124, 812956220, 812956283, 812956380, 812956476, 812956554, 812956623, 812956722,
  812956820, 812956900, 812957030, 812957136, 812957189, 812957235, 812957309, 812957391, 812957457, 812957554, 812957612, 812957699, 812957769,
  812957850, 812957925, 812958019, 812958100, 812958161, 812958221, 812958285, 812958412, 812958471, 812958537, 812958669, 812958740, 812958810,
  812958900, 812958966, 812959068, 812959121, 812959204, 812959277, 812959358, 812959418, 812959523, 812959755, 812959808, 812959889, 812959949,
  812960030, 812960088, 812960160, 812960242, 812960487, 812960581, 812960754, 812960857, 812960928, 812960994, 812961097, 812961208, 812961282,
  812961346, 812961416, 812961491, 812961677, 812961750, 812961801, 812961853, 812961896, 812961972, 812962053, 812962128, 812962198, 812962268,
  812962312, 812962365, 812962447, 812962536, 812962596, 812962681, 812962826, 812962900, 812962989, 812963053, 812963120, 812963185, 812963258,
  812963416, 812963476, 812963560, 812963626, 812963672, 812963790, 812963889, 812964031, 812964081, 812964140, 812964201, 812964273, 812964370,
  812964476, 812964543, 812964621, 812964660, 812964734, 812964799, 812964865, 812964912, 812964966, 812965039, 812965122, 812965202, 812965290,
  812965344, 812965444, 812965538, 812965604, 812965690, 812965786, 812965901, 812965976, 812966056, 812966124, 812966219, 812966307, 812966418,
  812966505, 812966593, 812966713, 812966815, 812967042, 812967244, 812967331, 812967489, 812967568, 812967647, 812967715, 812967781, 812967862,
  812967966, 812968034, 812968112, 812968213, 812968300, 812968379, 812968472, 812968544, 812968598, 812968670, 812968743, 812968801, 812968866,
  812968962, 812969182, 812969256, 812969426, 812969595, 812969658, 812969852, 812969916, 812970007, 812970127, 812970211, 812970383, 812970470,
  812970521, 812970608, 812970690, 812970765, 812970826, 812970888, 812970938, 812970996, 812971059, 812971130, 812971202, 812971262, 812971341,
  812971448, 812971505, 812971588, 812971679, 812971741, 812971814, 812971868, 812971920, 812973795, 812973865, 812973975, 812974044, 2153188007,
  2153413237, 2153487400, 2153517622, 2154890151, 2155383823, 2155771639, 2157583031, 2157621936, 2157695951, 2160133312, 2160135652, 2160176689,
  2162171947, 2162247917, 2162336562, 2164776626, 2165086231, 2165180323, 2166482462, 2166504064, 2166562640, 2169219811, 2169324881, 2169392236,
  2172559114, 2172567094, 2172569319, 2174841780, 2174845140, 2175981883, 2177851396, 2177868619, 2177924924, 2180657424, 2181004638, 2181074307,
  2183504455, 2183532765, 2184219535, 2185728301, 2185729325, 2185859474, 2187490354, 2187491063, 2187492017, 2188748171, 2188749229, 2188749692,
  2188750460, 2190878340, 2190890935, 2190891570, 2192911727, 2192915091, 2192919345,
];

export default ASSETS;
