const flipTokenChoices = [
  {
    name: 'ADD',
    id: 925648644,
  },
  {
    name: 'ASS',
    id: 818372984,
  },
  {
    name: 'Algo Noots Coin',
    id: 933539255,
  },
  {
    name: 'BAMBI',
    id: 1034854939,
  },
  {
    name: 'BBUCKS',
    id: 1086216637,
  },
  {
    name: 'BITE',
    id: 875415272,
  },
  {
    name: 'BPM',
    id: 1056720965,
  },
  {
    name: 'COOP',
    id: 796425061,
    divisible: 1000000,
  },
  {
    name: 'Crown',
    id: 1141491210,
  },
  {
    name: 'Crumb',
    id: 751294723,
  },
  {
    name: 'Feathers',
    id: 1091143717,
  },
  {
    name: 'Grass',
    id: 765171236,
    divisible: 100000000,
  },
  {
    name: 'Grub',
    id: 787168529,
  },
  {
    name: 'HOOT',
    id: 797382233,
  },
  {
    name: 'Jelly',
    id: 582345180,
  },
  {
    name: 'MEMO',
    id: 885835936,
  },
  {
    name: 'NANITE',
    id: 891443534,
  },
  {
    name: 'PEPE',
    id: 1096015467,
    divisible: 10000,
  },
  {
    name: 'Poof',
    id: 658399558,
  },
  {
    name: 'Shitty Treats',
    id: parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID),
  },
  {
    name: 'Shrimp',
    id: 360019122,
  },
  {
    name: 'skuli snacks',
    id: 704036829,
  },
  {
    name: 'SQUAD Tokens',
    id: 708624210,
    divisible: 100000000,
  },
  {
    name: 'THICC',
    id: 1194470385,
  },
  {
    name: 'Totally Average Tokens',
    id: 911073983,
  },
  {
    name: 'WOOGIE',
    id: 1042867088,
  },
  {
    name: 'Yarn',
    id: 878951062,
  },
  {
    name: 'Nexus',
    id: 403499324,
  },
];

export default flipTokenChoices;
