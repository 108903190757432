const assets = [
  871107052, 871107163, 871107313, 871107419, 871107504, 871107691, 871107789, 871107903, 871107994, 871108147, 871108558, 871108667, 871108796,
  871108906, 871109017, 871109140, 871109266, 871109392, 871109534, 871109699, 871109855, 871109995, 871110180, 871110298, 871110488, 871110656,
  871110836, 871110961, 871111118, 871111224, 871111333, 871111498, 871111645, 871111747, 871111877, 871112058, 871112188, 871112294, 871112412,
  871112523, 871112623, 871112738, 871112928, 871113103, 871113246, 871113367, 871113505, 871113592, 871113703, 871113819, 871113968, 871114071,
  871114246, 871114370, 871114540, 871114763, 871114925, 871115045, 871115160, 871115318, 871115441, 871115565, 871115746, 871115940, 871116145,
  871116269, 871116378, 871116481, 871116633, 871116772, 871116930, 871117040, 871117153, 871117276, 871117417, 871117561, 871117685, 871117845,
  871117997, 871118114, 871118245, 871118394, 871118504, 871118663, 871118803, 871118912, 871119047, 871119165, 871119293, 871119395, 871119525,
  871119678, 871119792, 871119897, 871119992, 871120078, 871120168, 871120261, 871120347, 871120448, 871120535, 871120627, 871120720, 871120803,
  871120871, 871120960, 871121077, 871121252, 871121326, 871121428, 871121576, 871121714, 871121806, 871121909, 871122064, 871122183, 871122316,
  871122415, 871122535, 871122654, 871122760, 871122840, 871122965, 871123090, 871123230, 871123307, 871123424, 871123537, 871123655, 871123735,
  871123815, 871123948, 871124074, 871124192, 871124337, 871124452, 871124574, 871124685, 871124820, 871124924, 871125048, 871125210, 871125326,
  871125479, 871125591, 871125731, 871125848, 871126005, 871126149, 871126253, 871126386, 871126505, 871126614, 871126753, 871126870, 871127014,
  871127167, 871127289, 871127412, 871127547, 871127657, 871127815, 871127927, 871128061, 871128191, 871128373, 871128537, 871128657, 871128782,
  871128900, 871129037, 871129189, 871129319, 871129476, 871129593, 871129738, 871129862, 871130001, 871130140, 871130287, 871130414, 871130564,
  871130702, 871130880, 871131025, 871131155, 871131272, 871131416, 871131569, 871131741, 871131881, 871132002, 871132132, 871132272, 871132421,
  871132567, 871132724, 871132866, 871133010, 871133152, 871133292, 871133434, 871133568, 871133681, 871133837, 871133975, 871134099, 871134209,
  871134403, 871134533, 871134678, 871134820, 871134951, 871135212, 871135360, 871135486, 871135602, 871135728, 871135829, 871135977, 871136133,
  871136288, 871136400, 871136526, 871136737, 871136868, 871137066, 871137181, 871137351, 871137493, 871137607, 871137748, 871137884, 871138011,
  871138128, 871138240, 871138352, 871138555, 871138663, 871138774, 871138956, 871139064, 871139217, 871139369, 871139581, 871139751, 871139925,
  871140158, 871140300, 871140537, 871140639, 871140838, 871140966, 871141130, 871141244, 871141467, 871141619, 871141735, 871141866, 871141978,
  871142290, 871142421, 871142540, 871142654, 871142780, 871142933, 871143053, 871143146, 871143276, 871143386, 871143480, 871143592, 871143691,
  871143799, 871143913, 871144039, 871144178, 871144282, 871144413, 871144503, 871144605, 871144722, 871144922, 871145027, 871145119, 871145259,
  871145395, 871145467, 871145568, 871145663, 871145772, 871145884, 871145997, 871146120, 871146240, 871146334, 871146428, 871146560, 871146662,
  871146758, 871146858, 871146976, 871147090, 871147199, 871147357, 871147464, 871147586, 871147684, 871147784, 871147888, 871148039, 871148158,
  871148291, 871148439, 871148560, 871148649, 871148763, 871148860, 871148970, 871149059, 871149145, 871149267, 871149361, 871149460, 871149587,
  871149701, 871149815, 871149920, 871149985, 871150108, 871150192, 871150312, 871150421, 871150561, 871150682, 871150807, 871150945, 871151093,
  871151194, 871151291, 871151382, 871151490, 871151599, 871151711, 871151820, 871151918, 871152015, 871152103, 871152199, 871152312, 871152402,
  871152491, 871152580, 871152782, 871152898, 871153008, 871153074, 871153170, 871153286, 871153420, 871153509, 871153618, 871153712, 871153782,
  871153895, 871153991, 871154111, 871154204, 871154310, 871154419, 871154535, 871154623, 871154744, 871154831, 871154937, 871155132, 871155227,
  871155372, 871155522, 871155754, 871155867, 871155961, 871156083, 871156183, 871156300, 871156375, 871156468, 871156582, 871156671, 871156770,
  871156872, 871156965, 871157041, 871157177, 871157288, 871157374, 871157496, 871157600, 871157758, 871157853, 871157959, 871158066, 871158176,
  871158254, 871158430, 871158537, 871158640, 871158736, 871158839, 871158936, 871159038, 871159135, 871159225, 871159305, 871159377, 871159465,
  871159564, 871159744, 871159841, 871159946, 871160038, 871160126, 871160212, 871160412, 871160556, 871160653, 871160743, 871160867, 871160947,
  871161032, 871161120, 871161206, 871161288, 871161390, 871161477, 871161557, 871161670, 871161774, 871161889, 871161992, 871162070, 871162183,
  871162251, 871162354, 871162430, 871162534, 871162632, 871162736, 871162849, 871162927, 871163039, 871163162, 871163273, 871163363, 871163472,
  871163605, 871163714, 871163795, 871163893, 871163986, 871164084, 871164184, 871164279, 871164448, 871164553, 871164653, 871164752, 871164853,
  871164951, 871165058, 871165153, 871165280, 871165383, 871165489, 871165580, 871165694, 871165883, 871166006, 871166125, 871166219, 871166320,
  871166435, 871166593, 871166705, 871166804, 871166925, 871167059, 871167144, 871167266, 871167358, 871167469, 871167585, 871167694, 871167803,
  871167911, 871168010, 871168104, 871168200, 871168298, 871168364, 871168537, 871168648, 871168745, 871168854, 871168950, 871169078, 871169204,
  871169347, 871169452, 871169546, 871169649, 871169747, 871169838, 871169939, 871170047, 871170127, 871170238, 871170344, 871170435, 871170549,
  871170629, 871170731, 871170818, 871170904, 871171003, 871171090, 871171182, 871171243, 871171316, 871171398, 871171484, 871171559, 871171666,
  871171780, 871171950, 871172045, 871172144, 871172227, 871172318, 871172416, 871172507, 871172636, 871172747, 871172834, 871172937, 871173051,
  871173193, 871173317, 871173424, 871173560, 871173679, 871173813, 871173959, 871174104, 871174207, 871174349, 871174448, 871174553, 871174651,
  871174734, 871174851, 871174963, 871175090, 871175207, 871175333, 871175419, 871175524, 871175620, 871175714, 871175982, 871176238, 871176351,
  871176459, 871176573, 871176689, 871176806, 871176904, 871177012, 871177141, 871177238, 871177350, 871177446, 871177583, 871177676, 871177782,
  871177867, 871177975, 871189289, 871189397, 871189498, 871189636, 871189742, 871189824, 871189936, 871190037, 871190160, 871190251, 871190420,
  871190521, 871190631, 871190722, 871190814, 871190968, 871191091, 871191203, 871191308, 871191415, 871191534, 871191601, 871191707, 871191899,
  871191994, 871192096, 871192191, 871192281, 871192402, 871192519, 871192647, 871192741, 871192827, 871192917, 871192974, 871193106, 871193200,
  871193352, 871193467, 871193577, 871193715, 871193804, 871193937, 871194075, 871194183, 871194293, 871194406, 871194525, 871194629, 871194736,
  871194838, 871194961, 871195066, 871195164, 871195296, 871195443, 871195537, 871195642, 871195751, 871195869, 871195957, 871196053, 871196164,
  871196271, 871196399, 871196499, 871196591, 871196726, 871196824, 871196954, 871197055, 871197170, 871197314, 871197405, 871197505, 871197630,
  871197801, 871197896, 871198026, 871198134, 871198294, 871198433, 871198582, 871198706, 871198849, 871198993, 871199188, 871199338, 871199463,
  871199628, 871199763, 871199895, 871200030, 871200191, 871200322, 871200440, 871200569, 871200694, 871200815, 871200945, 871201076, 871201149,
  871201247, 871201369, 871201492, 871201598, 871201730, 871201841, 871202030, 871202204, 871202337, 871202463, 871202631, 871202738, 871202909,
  871203034, 871203189, 871203331, 871203491, 871203627, 871203803, 871203999, 871204170, 871204299, 871204449, 871204585, 871204770, 871204914,
  871205053, 871205213, 871205378, 871205461, 871205594, 871205737, 871205992, 871206228, 871206438, 871206670, 871206878, 871207010, 871207147,
  871207284, 871207422, 871207612, 871207741, 871207895, 871207998, 871208172, 871208259, 871208387, 871208482, 871208627, 871208755, 871208856,
  871209036, 871209154, 871209359, 871209630, 871209753, 871209878, 871210101, 871210242, 871210355, 871210553, 871210627, 871210738, 871210945,
  871211115, 871211392, 871211533, 871211666, 871211796, 871212017, 871213260, 871213388, 871213589, 871213728, 871213918, 871214037, 871214160,
  871214334, 871214461, 871214564, 871214727, 871214840, 871214955, 871215081, 871215203, 871215344, 871215476, 871215581, 871215752, 871215857,
  871216067, 871216174, 871216292, 871216467, 871216599, 871216707, 871216850, 871216959, 871217107, 871217266, 871217404, 871217545, 871217662,
  871217760, 871217908, 871218122, 871218304, 871218488, 871218759, 871218908, 871219075, 871219230, 871219487, 871219863, 871220171, 871220468,
  871220694, 871220879, 871221250, 871221518, 871221751, 871221975, 871222297, 871222494, 871222716, 871222892, 871223168, 871223419, 871223613,
  871223758, 871223944, 871224079, 871224218, 871224354, 871224525, 871224660, 871224806, 871225005, 871225227, 871225360, 871225506, 871225668,
  871225829, 871225968, 871226125, 871226293, 871226469, 871226606, 871226788, 871226944, 871227092, 871227244, 871227390, 871227554, 871227755,
  871227891, 871228002, 871228137, 871228264, 871228386, 871228503, 871228615, 871228764, 871228914, 871229036, 871229161, 871229296, 871229420,
  871229656, 871229859, 871230017, 871230233, 871230362, 871230512, 871230648, 871230793, 871231858, 871232000, 871232129, 871232361, 871232507,
  871232637, 871232774, 871232904, 871233020, 871233231, 871233376, 871233489, 871233655, 871233807, 871233970, 871234114, 871234278, 871234447,
  871234573, 871234724, 871234867, 871235019, 871235162, 871235280, 871235418, 871235566, 871235736, 871235848, 871235985, 871236151, 871236299,
  871236435, 871236584, 871236725, 871236864, 871237039, 871237214, 871237342, 871237497, 871237607, 871237705, 871237838, 871237990, 871238160,
  871238334, 871238516, 871238633, 871238788, 871238939, 871239117, 871239274, 871239437, 871239575, 871239730, 871239850, 871239984, 871240232,
  871240369, 871240519, 871240720, 871240886, 871241027, 871241197, 871241313, 871241459, 871241596, 871241710, 871241859, 871241962, 871242080,
  871242278, 871242418, 871242548, 871242716, 871242870, 871243064, 871243197, 871243376, 871243542, 871243670, 871243817, 871243954, 871244171,
  871244266, 871244415, 871244555, 871244713, 871244944, 871245055, 871245268, 871245481, 871245585, 871245766, 871245964, 871246089, 871246258,
  871246421, 871246501, 871246664, 871246818, 871246966, 871247084, 871248409, 871248604, 871248761, 871248858, 871249220, 871249407, 871249537,
  871249668, 871249824, 871249942, 871250099, 871250242, 871250417, 871250551, 871250671, 871250827, 871250956, 871251090, 871251270, 871251377,
  871251554, 871251676, 871251818, 871251934, 871252034, 871252137, 871252247, 871252334, 871252480, 871252611, 871252780, 871252915, 871253036,
  871253248, 871253370, 871253540, 871253674, 871253844, 871253965, 871254104, 871254235, 871254392, 871254536, 871254669, 871254835, 871254990,
  871255114, 871255291, 871255395, 871255522, 871255652, 871255815, 871255909, 871256010, 871256162, 871256287, 871256495, 871256678, 871256875,
  871257010, 871257141, 871257262, 871257511, 871257646, 871257810, 871257914, 871258048, 871258207, 871258378, 871258494, 871258620, 871258729,
  871258892, 871258997, 871259144, 871259258, 871259365, 871259484, 871259651, 871259737, 871259860, 871259934, 871260046, 871260137, 871260256,
  871260366, 871260477, 871260643, 871260809, 871260930, 871261021, 871261119, 871261223, 871261344, 871261469, 871261592, 871261696, 871261805,
  871261898, 871261979, 871262074, 871262179, 871262271, 871262376, 871262496, 871262647, 871262758, 871262893, 871262978, 871263033, 871263162,
  871263257, 871263364, 871263496, 871263579, 871263665, 871263770, 871263959, 871264068, 871264153, 871264265, 871264357, 871264459, 871264542,
  871264645, 871264721, 871264870, 871264960, 871265055, 871265165, 871265260, 871265382, 871265461, 871265555, 871265643, 871265779, 871265868,
  871265949, 871265993, 871266065, 871266151, 871266226, 871266322, 871266485, 871266580, 871266667, 871266755, 871266828, 871266932, 871267014,
  871267120, 871267189, 871267277, 871267378, 871267467, 871267568, 871267669, 871267780, 871267873, 871267972, 871268049, 871268153, 871268236,
  871268380, 871268482, 871268585, 871268673, 871268751, 871268882, 871268975, 871269119, 871269192, 871269265, 871269357, 871269528, 871269634,
  871269737, 871269832, 871269916, 871270009, 871270120, 871270244, 871270347, 871270463, 871270552, 871270647, 871270737, 871270810, 871270897,
  871271001, 871271090, 871271206, 871271282, 871271375, 871271557, 871271626, 871271719, 871271787, 871271885, 871271969, 871272053, 871272151,
  871272277, 871272411, 871272510, 871272599, 871272709, 871272821, 871272898, 871273010, 871273119, 871273207, 871273285, 871273371, 871273454,
  871273532, 871273648, 871273735, 871273824, 871273932, 871274027, 871274110, 871274193, 871274291, 871274437, 871274566, 871274665, 871274753,
  871274877, 871274970, 871275071, 871275192, 871275270, 871275368, 871275473, 871275581, 871275681, 871275829, 871275908, 871275993, 871276085,
  871276173, 871276284, 871276449, 871276603, 871276735, 871276815, 871276893, 871276970, 871277065, 871277176, 871277249, 871277358, 871277444,
  871277541, 871277664, 871277801, 871277908, 871278024, 871278138, 871278246, 871278369, 871278482, 871278599, 871278709, 871278800, 871278914,
  871279003, 871279102, 871279213, 871279298, 871279387, 871279497, 871279653, 871279738, 871279837, 871280055, 871280162, 871280276, 871280425,
  871280519, 871280648, 871280772, 871280903, 871281010, 871281108, 871281209, 871281314, 871281420, 871281506, 871281624, 871281731, 871281855,
  871281952, 871282091, 871282229, 871282332, 871282453, 871282545, 871282653, 871282749, 871282857, 871283032, 871283136, 871283254, 871283351,
  871283504, 871283614, 871283696, 871283780, 871283873, 871283992, 871284129, 871284202, 871284308, 871284398, 871284497, 871284579, 871284701,
  871284809, 871284934, 871285049, 871285129, 871285225, 871285325, 871285429, 871285553, 871285691, 871285791, 871285892, 871285996, 871286112,
  871286218, 871286390, 871286515, 871286618, 871286719, 871286832, 871286948, 871287057, 871287179, 871287356, 871287517, 871287622, 871287721,
  871287833, 871287941, 871288048, 871288165, 871288301, 871288411, 871288519, 871288619, 871288750, 871288871, 871288991, 871289112, 871289237,
  871289330, 871289430, 871289523, 871289632, 871289738, 871289838, 871289976, 871290085, 871290186, 871290295, 871290400, 871290525, 871290658,
  871290846, 871290955, 871291068, 871291195, 871291301, 871291432, 871291523, 871291629, 871291741, 871291850, 871291969, 871292075, 871292275,
  871292410, 871292550, 871292660, 871292797, 871292871, 871292981, 871293095, 871293206, 871293333, 871293460, 871293602, 871293735, 871293857,
  871293982, 871294128, 871294231, 871294358, 871294475, 871294628, 871294805, 871294935, 871295077, 871295244, 871295358, 871295477, 871295607,
  871295740, 871295877, 871296128, 871296246, 871296380, 871296514, 871296632, 871296748, 871296859, 871296983, 871297120, 871297270, 871297407,
  871297547, 871297669, 871297797, 871297934, 871298035, 871298163, 871298291, 871298410, 871298551, 871298671, 871298815, 871298980, 871299105,
  871299250, 871299427, 871299562, 871299696, 871299794, 871299939, 871300053, 871300175, 871300273, 871300359, 871300548, 871300652, 871300774,
  871300875, 871301001, 871301123, 871301271, 871301397, 871301536, 871301650, 871301768, 871301902, 871302049, 871302185, 871302350, 871302485,
  871302618, 871302741, 871302901, 871303020, 871303131, 871303266, 871303403, 871303526, 871303653, 871303777, 871303926, 871304018, 871304143,
  871304248, 871304352, 871304473, 871304604, 871304708, 871304858, 871304963, 871305084, 871305199, 871305467, 871305596, 871305695, 871305830,
  871305940, 871306067, 871306148, 871308004, 871308117, 871308232, 871308330, 871308428, 871308534, 871308693, 871308820, 871308906, 871309028,
  871309134, 871309245, 871309357, 871309483, 871309594, 871309734, 871309828, 871309959, 871310041, 871310159, 871310265, 871310379, 871310489,
  871310594, 871310698, 871310785, 871310914, 871310983, 871311098, 871311193, 871311304, 871311416, 871311505, 871311588, 871311725, 871311827,
  871311922, 871312002, 871312112, 871312198, 871312264, 871312365, 871312486, 871312581, 871312688, 871312788, 871312937, 871313031, 871313149,
  871313233, 871313332, 871313434, 871313540, 871313630, 871313742, 871313835, 871313995, 871314141, 871314220, 871314312, 871314393, 871314489,
  871314592, 871314690, 871314794, 871314913, 871315003, 871315161, 871315250, 871315346, 871315452, 871315560, 871315705, 871315809, 871315941,
  871316042, 871316168, 871316279, 871316393, 871316522, 871316617, 871316719, 871316831, 871316926, 871317029, 871317120, 871317207, 871317297,
  871317423, 871317538, 871317689, 871317796, 871317907, 871318017, 871318122, 871318235, 871318352, 871318477, 871318581, 871318719, 871318835,
  871318949, 871319085, 871319207, 871319388, 871319508, 871319615, 871319709, 871319847, 871319971, 871320060, 871320210, 871320296, 871320392,
  871320507, 871320624, 871320735, 871320825, 871320920, 871321035, 871321188, 871321287, 871321393, 871321506, 871321629, 871321720, 871321800,
  871321961, 871322230, 871322348, 871322442, 871322548, 871322664, 871322761, 871322869, 871322971, 871323083, 871323204, 871323324, 871323424,
  871323546, 871323652, 871323979, 871324125, 871324264, 871324386, 871324530, 871324670, 871324824, 871324954, 871325076, 871325223, 871325368,
  871325487, 871325573, 871325686, 871325806, 871325960, 871326091, 871326228, 871326350, 871326481, 871326602, 871326742, 871326869, 871326992,
  871327137, 871327289, 871327419, 871327544, 871327646, 871327779, 871327924, 871328057, 871328171, 871328303, 871328420, 871328572, 871328674,
  871328825, 871328945, 871329055, 871329172, 871329320, 871329427, 871329551, 871329685, 871329801, 871329914, 871330071, 871330205, 871330328,
  871330454, 871330580, 871330687, 871330808, 871330963, 871331078, 871331222, 871331358, 871331479, 871331574, 871331690, 871331819, 871331941,
  871332066, 871332167, 871332277, 871332401, 871332510, 871332663, 871332758, 871332859, 871332978, 871333099, 871333206, 871333347, 871333448,
  871333611, 871333739, 871333874, 871334012, 871334135, 871334255, 871334378, 871334490, 871334592, 871334709, 871334808, 871334935, 871335052,
  871335185, 871335295, 871335417, 871335520, 871335634, 871335731, 871335875, 871336050, 871336134, 871336210, 871336337, 871336444, 871336559,
  871336688, 871336800, 871336925, 871337073, 871337162, 871337259, 871337347, 871337440, 871337535, 871337686, 871337795, 871337896, 871338039,
  871338142, 871338279, 871338416, 871338523, 871338642, 871338747, 871338850, 871338973, 871339158, 871339293, 871339406, 871339546, 871339654,
  871339776, 871339902, 871340039, 871340170, 871340275, 871340396, 871340535, 871340626, 871340756, 871340880, 871341017, 871341164, 871341275,
  871341412, 871341576, 871341733, 871341888, 871342080, 871342227, 871342435, 871342588, 871342721, 871342867, 871342956, 871343074, 871343195,
  871343318, 871343473, 871343601, 871343733, 871343853, 871343996, 871344119, 871344245, 871344397, 871344510, 871344598, 871344710, 871344874,
  871345010, 871345102, 871345222, 871345353, 871345449, 871345564, 871345686, 871345790, 871345903, 871346040, 871346154, 871346262, 871346358,
  871346481, 871346602, 871346738, 871346864, 871347167, 871347305, 871347477, 871347593, 871347721, 871347874, 871348014, 871348120, 871348199,
  871348337, 871348484, 871348585, 871348705, 871348844, 871349043, 871349138, 871349256, 871349397, 871349484, 871349600, 871349713, 871349842,
  871349967, 871350127, 871350303, 871350416, 871350521, 871350635, 871350738, 871350870, 871350987, 871351126, 871351232, 871351376, 871351585,
  871351718, 871351802, 871351887, 871351983, 871352081, 871352202, 871352305, 871352421, 871352545, 871352714, 871352800, 871352895, 871353000,
  871353163, 871353275, 871353393, 871353514, 871353598, 871353701, 871353811, 871353937, 871354062, 871354189, 871354288, 871354418, 871354505,
  871354596, 871354705, 871354804, 871354922, 871355017, 871355117, 871355206, 871355312, 871355405, 871355504, 871355609, 871355704, 871355810,
  871355899, 871355997, 871356082, 871356199, 871356314, 871356423, 871356509, 871356662, 871356769, 871356850, 871357013, 871357117, 871357256,
  871357376, 871357534, 871357682, 871357823, 871357977, 871358084, 871358190, 871358362, 871358442, 871358628, 871358757, 871358930, 871359173,
  871359332, 871359496, 871359617, 871359784, 871359928, 871360045, 871360161, 871360274, 871360370, 871360476, 871360613, 871360749, 871360864,
  871360981, 871361081, 871361172, 871361280, 871361408, 871361525, 871361614, 871361734, 871361849, 871361951, 871362060, 871362135, 871362247,
  871362339, 871362445, 871362529, 871362636, 871362728, 871362836, 871362915, 871363013, 871363133, 871363224, 871363326, 871363426, 871363564,
  871363663, 871363780, 871363879, 871363958, 871364072, 871364170, 871364260, 871364341, 871364432, 871364535, 871364662, 871364769, 871364871,
  871364938, 871365018, 871365085, 871365170, 871365286, 871365377, 871365500, 871365609, 871365696, 871365829, 871365934, 871366056, 871366125,
  871366199, 871366242, 871366293, 871366398, 871366455, 871366501, 871366547, 871366672, 871366753, 871366830, 871366889, 871366971, 871367061,
  871367243, 871367360, 871367471, 871368175, 871369321, 871369405, 871369520, 871369615, 871369729, 871369801, 871369902, 871369981, 871370085,
  871370169, 871370251, 871370364, 871370450, 871370517, 871370624, 871370724, 871370805, 871370886, 871370967, 871371073, 871371156, 871371256,
  871371380, 871371498, 871371596, 871371722, 871371854, 871371956, 871372047, 871372153, 871372228, 871372350, 871372431, 871372523, 871372598,
  871372706, 871372876, 871373000, 871373097, 871373198, 871373324, 871373434, 871373539, 871373682, 871373795, 871373895, 871374018, 871374114,
  871374196, 871374302, 871374407, 871374517, 871374638, 871374756, 871374867, 871374990, 871375097, 871375203, 871375329, 871375414, 871375539,
  871375634, 871375717, 871375881, 871376004, 871376126, 871376262, 871376391, 871376476, 871376603, 871376733, 871376851, 871377146, 871377261,
  871377358, 871377543, 871377662, 871377784, 871377917, 871378015, 871378103, 871378244, 871378375, 871378500, 871378642, 871378750, 871378849,
  871378933, 871379093, 871379264, 871379361, 871379447, 871379554, 871379816, 871379978, 871380155, 871380273, 871380431, 871380571, 871380702,
  871380820, 871380911, 871381032, 871381129, 871381268, 871381370, 871381498, 871381591, 871381713, 871381937, 871382054, 871382149, 871382225,
  871382416, 871382518, 871382633, 871382746, 871382868, 871382995, 871383109, 871383166, 871383279, 871383383, 871383500, 871383608, 871383728,
  871383805, 871383924, 871384011, 871384097, 871384188, 871384303, 871384408, 871384521, 871384685, 871384774, 871384887, 871384971, 871385084,
  871385190, 871385299, 871385398, 871385493, 871385627, 871385733, 871385846, 871385942, 871386049, 871386148, 871386226, 871386303, 871386413,
  871386558, 871386669, 871386784, 871386915, 871387015, 871387106, 871387216, 871387330, 871387437, 871387556, 871387673, 871387810, 871387962,
  871388079, 871388199, 871388311, 871388438, 871388561, 871388708, 871388814, 871388961, 871389084, 871389208, 871389326, 871389445, 871389588,
  871389690, 871389827, 871389908, 871390017, 871390142, 871390459, 871390665, 871390770, 871390888, 871391016, 871391129, 871391250, 871391379,
  871391489, 871391632, 871391754, 871391883, 871392001, 871392141, 871392280, 871392399, 871392583, 871392694, 871392810, 871392923, 871393064,
  871393212, 871393330, 871393432, 871393573, 871393836, 871393985, 871394100, 871394217, 871394353, 871394472, 871394577, 871394765, 871394876,
  871394989, 871395104, 871395274, 871395880, 871396240, 871396529, 871396705, 871396879, 871397008, 871397201, 871397340, 871397450, 871397617,
  871397753, 871397878, 871398000, 871398158, 871398311, 871398411, 871398589, 871398699, 871398792, 871398927, 871399034, 871399133, 871399242,
  871399402, 871399533, 871399695, 871399812, 871399926, 871400072, 871400177, 871400280, 871400406, 871400524, 871400634, 871400770, 871400936,
  871401066, 871401248, 871401361, 871401459, 871401579, 871401717, 871401838, 871401921, 871402013, 871402139, 871402302, 871402420, 871402530,
  871402642, 871402756, 871402880, 871402992, 871403103, 871403259, 871403362, 871403526, 871403653, 871403782, 871403889, 871403998, 871404114,
  871404218, 871404344, 871404508, 871404637, 871404731, 871404857, 871405010, 871405121, 871405270, 871405361, 871405497, 871405666, 871405779,
  871405939, 871406084, 871406209, 871406364, 871406482, 871406634, 871406732, 871406828, 871406976, 871407086, 871407186, 871407325, 871407423,
  871407538, 871407704, 871407787, 871407873, 871407967, 871408125, 871408280, 871408439, 871408549, 871408654, 871408818, 871408925, 871409040,
  871409125, 871409292, 871409423, 871409652, 871409740, 871409843, 871409935, 871410034, 871410172, 871410264, 871410400, 871410484, 871410581,
  871410667, 871410757, 871410855, 871410945, 871411026, 871411106, 871411267, 871411356, 871411497, 871411723, 871411830, 871411918, 871412074,
  871412183, 871412269, 871412366, 871412470, 871412562, 871413322, 871413414, 871413510, 871413725, 871413832, 871413980, 871414042, 871414149,
  871414257, 871414369, 871414468, 871414570, 871414668, 871414763, 871414893, 871414995, 871415091, 871415204, 871415302, 871415447, 871415585,
  871415689, 871415794, 871415957, 871416073, 871416234, 871416373, 871416478, 871416623, 871416752, 871416960, 871417061, 871417184, 871417303,
  871417415, 871417579, 871417684, 871417832, 871417941, 871418110, 871418266, 871418370, 871418476, 871418555, 871418724, 871418855, 871418932,
  871419036, 871419201, 871419302, 871419414, 871419606, 871419791, 871419891, 871419976, 871420065, 871420208, 871420329, 871420418, 871420494,
  871420570, 871420667, 871420765, 871420951, 871421044, 871421140, 871421245, 871421412, 871421505, 871421648, 871421728, 871421978, 871422055,
  871422165, 871422283, 871422367, 871422490, 871422613, 871422714, 871422831, 871422943, 871423053, 871423161, 871423271, 871423389, 871423495,
  871423610, 871423735, 871423851, 871423947, 871424057, 871424185, 871424310, 871424406, 871424521, 871424638, 871424752, 871424856, 871424975,
  871425075, 871425205, 871425398, 871425543, 871425983, 871426136, 871426258, 871426373, 871426491, 871426582, 871426684, 871426812, 871426917,
  871427073, 871427172, 871427264, 871427383, 871427478, 871427581, 871427692, 871427789, 871427880, 871427988, 871428077, 871428218, 871428322,
  871428402, 871428486, 871428602, 871428687, 871428781, 871428868, 871428977, 871429085, 871429171, 871429316, 871429408, 871429507, 871429628,
  871429737, 871429883, 871430465, 871430500, 871430582, 871430726, 871430845, 871430947, 871431045, 871431161, 871431248, 871431334, 871431399,
  871431441, 871431496, 871431565, 871431632, 871431748, 871431826, 871431958, 871432073, 871432183, 871432273, 871432362, 871432449, 871432561,
  871432680, 871432828, 871432963, 871433083, 871433195, 871433291, 871433412, 871433540, 871433644, 871433762, 871433886, 871433987, 871434089,
  871434189, 871434329, 871434428, 871434554, 871434657, 871434736, 871434918, 871435015, 871435135, 871435227, 871435337, 871435447, 871435537,
  871435640, 871435776, 871435881, 871436011, 871436188, 871436308, 871436393, 871436504, 871436596, 871436691, 871436804, 871436880, 871436993,
  871437115, 871437212, 871437306, 871437409, 871437523, 871437642, 871437746, 871437879, 871438048, 871438153, 871438242, 871438352, 871438432,
  871438567, 871438657, 871438767, 871438873, 871438967, 871439084, 871439202, 871439287, 871439403, 871439488, 871439588, 871439693, 871439827,
  871439946, 871440069, 871440225, 871440402, 871440544, 871440689, 871440913, 871441038, 871441133, 871441300, 871441416, 871441594, 871441827,
  871442007, 871442163, 871442282, 871442389, 871442563, 871442733, 871442868, 871443009, 871443098, 871443190, 871443402, 871443529, 871443861,
  871444096, 871444227, 871444356, 871444491, 871444632, 871444777, 871444914, 871445067, 871445187, 871445355, 871445469, 871445611, 871445751,
  871445887, 871446017, 871446262, 871446373, 871446526, 871446678, 871446809, 871446948, 871447089, 871447259, 871447391, 871447583, 871447715,
  871447833, 871447978, 871448108, 871448492, 871448696, 871448837, 871448966, 871449143, 871449296, 871449423, 871449627, 871449763, 871449901,
  871450025, 871450195, 871450363, 871450533, 871450644, 871450778, 871450911, 871451043, 871451169, 871451326, 871451461, 871451578, 871451691,
  871451820, 871451935, 871452083, 871452229, 871452359, 871452498, 871452628, 871452781, 871452929, 871453041, 871453160, 871453317, 871453426,
  871453532, 871453652, 871453809, 871453926, 871454072, 871454244, 871454404, 871454531, 871454679, 871454809, 871454920, 871455037, 871455167,
  871455290, 871455427, 871455555, 871455678, 871455789, 871455921, 871456047, 871456201, 871456317, 871456459, 871456567, 871456707, 871456821,
  871456958, 871457075, 871457189, 871457315, 871457440, 871457556, 871457689, 871457822, 871457956, 871458058, 871458167, 871458318, 871458447,
  871458591, 871458685, 871458830, 871458965, 871459090, 871459225, 871459329, 871459416, 871459536, 871459643, 871459769, 871459886, 871460003,
  871460115, 871460220, 871460367, 871460486, 871460613, 871460729, 871460891, 871460988, 871461138, 871461258, 871461385, 871461496, 871461606,
  871461723, 871461830, 871461952, 871462090, 871462229, 871462370, 871462505, 871462633, 871462760, 871462871, 871463016, 871463134, 871463267,
  871463390, 871463511, 871463627, 871463756, 871463894, 871464025, 871464132, 871464240, 871464393, 871464535, 871464667, 871464806, 871464936,
  871465056, 871465174, 871465311, 871465467, 871465632, 871465739, 871465844, 871465970, 871466077, 871466178, 871466318, 871466447, 871466559,
  871466741, 871466859, 871466973, 871467080, 871467165, 871467251, 871467365, 871467470, 871467581, 871467697, 871467829, 871467945, 871468078,
  871468163, 871468255, 871468371, 871468499, 871468631, 871468741, 871468842, 871468936, 871469050, 871469138, 871469232, 871469331, 871469412,
  871469518, 871469645, 871469733, 871469842, 871469937, 871470034, 871470154, 871470285,
];

export default assets;
