import algosdk from 'algosdk';

export const algodClient = new algosdk.Algodv2('', 'https://xna-mainnet-api.algonode.cloud', '');

export function encodeNote(input) {
  const enc = new TextEncoder();
  const secretNoteStr = JSON.stringify(input);
  const secretNoteEncoded = Buffer.from(secretNoteStr).toString('base64');
  const note = enc.encode(secretNoteEncoded);
  return note;
}
