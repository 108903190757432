import IconApartment from '../../components/icons/IconApartment';
import IconCastle from '../../components/icons/IconCastle';
import IconCityHouse from '../../components/icons/IconCityHouse';
import IconExcavatar from '../../components/icons/IconExcavatar';
import IconFort from '../../components/icons/IconFort';
import IconHelmet from '../../components/icons/IconHelmet';
import IconHouse from '../../components/icons/IconHouse';
import IconHouseApartment from '../../components/icons/IconHouseApartment';
import IconHouseTree from '../../components/icons/IconHouseTree';
import IconHouseTurret from '../../components/icons/IconHouseTurret';
import IconMovingTruck from '../../components/icons/IconMovingTruck';
import { achievementLevel } from './config';

const cityAchievements = [
  {
    name: 'Shitty City Holder',
    description: 'Hold 1 Shitty City',
    category: 'Cities',
    level: achievementLevel.bronze,
    icon: <IconCityHouse />,
    requirements: {
      cities: 1,
    },
  },
  {
    name: 'Shitty City Holder 5',
    description: 'Hold 5 Shitty Cities',
    category: 'Cities',
    level: achievementLevel.silver,
    icon: <IconCityHouse />,
    requirements: {
      cities: 5,
    },
  },
  {
    name: 'Shitty City Holder 10',
    description: 'Hold 10 Shitty Cities',
    category: 'Cities',
    level: achievementLevel.gold,
    icon: <IconCityHouse />,
    requirements: {
      cities: 10,
    },
  },
  {
    name: 'Shitty City Holder 20',
    description: 'Hold 20 Shitty Cities',
    category: 'Cities',
    level: achievementLevel.platinum,
    icon: <IconCityHouse />,
    requirements: {
      cities: 20,
    },
  },
  {
    name: 'City King',
    description: 'Hold Rank 1 Shitty City',
    category: 'Cities',
    level: achievementLevel.diamond,
    icon: <IconFort />,
    requirements: {
      rank: 1,
    },
  },
  {
    name: 'Move-in Day',
    description: 'Move a Shitty Kitty into a city',
    category: 'Cities',
    level: achievementLevel.bronze,
    icon: <IconMovingTruck />,
    requirements: {
      cities: 1,
      citizens: 1,
    },
  },
  {
    name: 'Crowded Move-in Day',
    description: 'Move 26 Shitty Kitties or Collabs into a city',
    category: 'Cities',
    level: achievementLevel.silver,
    icon: <IconMovingTruck />,
    requirements: {
      cities: 1,
      citizens: 26,
    },
  },
  {
    name: 'Crowded Move-in Day 2',
    description: 'Move 26 Shitty Kitties or Collabs into 2 cities',
    category: 'Cities',
    level: achievementLevel.silver,
    icon: <IconMovingTruck />,
    requirements: {
      cities: 2,
      citizens: 26,
    },
  },
  {
    name: 'Crowded Move-in Day 5',
    description: 'Move 26 Shitty Kitties or Collabs into 5 cities',
    category: 'Cities',
    level: achievementLevel.gold,
    icon: <IconMovingTruck />,
    requirements: {
      cities: 5,
      citizens: 26,
    },
  },
  {
    name: 'Shitty Builder',
    description: 'Hold a city with 9 Shitty City Blocks',
    category: 'Cities',
    level: achievementLevel.silver,
    icon: <IconHelmet />,
    requirements: {
      cities: 1,
      blocksInCity: 9,
    },
  },
  {
    name: 'Shitty Builder 5',
    description: 'Hold 5 cities with 9 Shitty City Blocks',
    category: 'Cities',
    level: achievementLevel.platinum,
    icon: <IconHelmet />,
    requirements: {
      cities: 5,
      blocksInCity: 9,
    },
  },
  {
    name: 'Shitty Builder 10',
    description: 'Hold 10 cities with 9 Shitty City Blocks',
    category: 'Cities',
    level: achievementLevel.diamond,
    icon: <IconHelmet />,
    requirements: {
      cities: 10,
      blocksInCity: 9,
    },
  },
  {
    name: 'Shitty City Block Holder',
    description: 'Collect 10 different Shitty City Blocks',
    category: 'Cities',
    level: achievementLevel.bronze,
    icon: <IconHouse />,
    requirements: {
      blocks: 10,
    },
  },
  {
    name: 'Shitty City Block Holder 25',
    description: 'Collect 25 different Shitty City Blocks',
    category: 'Cities',
    level: achievementLevel.silver,
    icon: <IconHouseTree />,
    requirements: {
      blocks: 25,
    },
  },
  {
    name: 'Shitty City Block Holder 50',
    description: 'Collect 50 different Shitty City Blocks',
    category: 'Cities',
    level: achievementLevel.gold,
    icon: <IconApartment />,
    requirements: {
      blocks: 50,
    },
  },
  {
    name: 'Shitty City Block Holder 100',
    description: 'Collect 100 different Shitty City Blocks',
    category: 'Cities',
    level: achievementLevel.platinum,
    icon: <IconHouseTurret />,
    requirements: {
      blocks: 100,
    },
  },
  {
    name: 'Shitty City Block Holder 150',
    description: 'Collect 150 different Shitty City Blocks',
    category: 'Cities',
    level: achievementLevel.diamond,
    icon: <IconCastle />,
    requirements: {
      blocks: 150,
    },
  },
  {
    name: 'Shitty Engineer',
    description: 'Upgrade a Shitty City',
    category: 'Cities',
    level: achievementLevel.gold,
    icon: <IconExcavatar />,
    requirements: {
      upgrade: true,
    },
  },
];

export default cityAchievements;
