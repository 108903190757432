/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';

import clsx from 'clsx';
import Alert from './Alert';
import IconEdit from './IconEdit';
import NftImage from './NftImage';

export default function KittySelectorAll({ onSelect, index, kitty, kitties = [], disabled, width }) {
  const [selectedKitty, setSelectedKitty] = useState(kitty);

  useEffect(() => {
    if (selectedKitty) {
      onSelect(index, selectedKitty);
    }
  }, [selectedKitty]);

  useEffect(() => {
    setSelectedKitty(kitty);
  }, [kitty]);

  return (
    <Menu as='div' className='relative text-left flex-shrink-0' style={{ width, height: width }}>
      <Menu.Button
        disabled={kitties?.length === 0}
        className={clsx(`w-full text-xl font-bold rounded-lg bg-neutral-800 aspect-square`, disabled && 'pointer-events-none')}
      >
        {selectedKitty ? (
          <div className='relative'>
            <NftImage nft={selectedKitty} className='rounded-lg' />
            <div className='w-8 h-8 absolute top-1.5 right-1.5 bg-gray-900 rounded-full flex items-center justify-center'>
              <IconEdit className='w-4 h-4' />
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-0.5'>
            <p className='text-base font-normal text-white/50'>Click to select a</p>
            <p>Shitty Kitty</p>
          </div>
        )}
      </Menu.Button>

      {kitties?.length === 0 && !selectedKitty && (
        <div className='absolute inset-0 w-full h-full z-20'>
          <Alert type='danger' className='w-full h-full flex items-center text-center'>
            You don't have any Shitty Kitties.
          </Alert>
        </div>
      )}

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute left-0 z-[999] max-h-[350px] w-full md:w-[300px] lg:w-[350px] overflow-y-scroll  mt-2 origin-top-left rounded-lg shadow-lg bg-neutral-800 ring-1 ring-black ring-opacity-5 focus:outline-none overflow-x-hidden'>
          <div className='py-1'>
            {kitties?.map((kitty, index) => (
              <KittySelectorButton kitty={kitty} key={index} onSelect={() => setSelectedKitty(kitty)} />
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

const KittySelectorButton = ({ kitty, onSelect }) => {
  const onClick = () => {
    onSelect(kitty);
  };

  return (
    <Menu.Item>
      <button
        type='button'
        className={clsx('flex items-center w-full px-4 py-2 space-x-5 text-sm text-left text-gray-300 hover:bg-gray-900')}
        onClick={onClick}
      >
        <div className='relative w-20 lg:w-32 h-20 lg:h-32 bg-gray-500 overflow-hidden rounded-md flex-shrink-0'>
          <NftImage nft={kitty} className='absolute inset-0 object-cover rounded-md' />
        </div>

        <div>
          <div className='flex flex-col font-medium'>
            <span>{kitty?.bio?.bio?.name || kitty.name}</span>

            <div className='flex gap-3'>
              <div className='text-sm text-neutral-500 flex flex-col'>
                <p>Level</p> <p>{kitty?.bio?.level || 1}</p>
              </div>
              <div className='text-sm text-neutral-500 flex flex-col'>
                <p>Adventures</p> <p>{kitty?.bio?.stats?.finishedAdventures || 0}</p>
              </div>
            </div>
          </div>
        </div>
      </button>
    </Menu.Item>
  );
};
