import clsx from 'clsx';
import React from 'react';

const IconCityHouse = ({ color }) => {
  return (
    <svg className={clsx('w-8 h-8', color)} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
      <path
        d='M640 0H320V161l64 53.3V192h64v64H416v32h32v64H416V512H640V0zM512 192h64v64H512V192zm64 96v64H512V288h64zM448 96v64H384V96h64zm64 0h64v64H512V96zM0 256V512H384V256L192 96 0 256zm240 16v96H144V272h96z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconCityHouse;
