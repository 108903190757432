import { Portal } from '@headlessui/react';
import { motion } from 'framer-motion';
import React from 'react';
import Spinner from './Spinner';

const FullScreenLoader = ({ loading, label, children }) => {
  if (!loading) return <> </>;

  return (
    <Portal>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className='fixed z-[9999] bg-gray-900/95 inset-0 w-full h-full flex items-center justify-center'
      >
        <div className='flex flex-col justify-center items-center gap-10'>
          {children}
          <Spinner />
          <p className='text-2xl font-medium max-w-sm mx-auto text-center'>{label}</p>
        </div>
      </motion.div>
    </Portal>
  );
};

export default FullScreenLoader;
