import { exp } from 'mathjs';
import { calculateShouldReceiveUpgradeBenefits } from './calculateShouldReceiveUpgradeBenefits';

export default function calculateMaxPopulation({ city, blockCount }) {
  let maxPopulation = 0;

  const rating = city.rarityScore;

  if (typeof blockCount !== 'number') {
    blockCount = city.metadata?.properties?.['Block Count'] || 0;
  }

  if (blockCount === 0) {
    maxPopulation = 0;
  } else if (blockCount === 9) {
    maxPopulation = 20;
  } else if (blockCount === 8) {
    maxPopulation = 15;
  } else if (blockCount >= 6 && blockCount <= 7) {
    maxPopulation = 10;
  } else if (blockCount >= 3 && blockCount <= 5) {
    maxPopulation = 5;
  } else if (blockCount >= 1 && blockCount <= 2) {
    maxPopulation = 2;
  }

  const { shouldReceiveBenefits, theUpgrade } = calculateShouldReceiveUpgradeBenefits(city);
  if (shouldReceiveBenefits === 'positive') {
    maxPopulation += theUpgrade.populationIncrease;
  } else if (shouldReceiveBenefits === 'negative') {
    maxPopulation -= theUpgrade.populationIncrease;
  }

  // add modifier based on rating
  if (rating > 0) {
    // Calculate modifier based on rating using sigmoid function
    const modifier = 1 / (1 + exp(-0.1 * (rating - 50)));
    const upperLimit = 1.5;
    const scaledModifier = 1 + (upperLimit - 1) * modifier;
    const bonus = (blockCount * scaledModifier) / 2;
    maxPopulation = Math.floor(maxPopulation + bonus);
  }

  return maxPopulation;
}
