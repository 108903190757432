import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAssets } from '../utils/getAssets';
import { useAlgo } from './useAlgo';

const RafflesContext = createContext({});
export const useRaffles = () => useContext(RafflesContext);

export const RafflesProvider = ({ children }) => {
  const [balance, setBalance] = useState(0);
  const { address, loading } = useAlgo();

  const joinRaffle = async (signedTxns, id, address, tickets) => {
    await axios.post(
      `/api/raffles/buy`,
      { signedTxns, raffleId: id, address, tickets: parseInt(tickets) },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: process.env.NEXT_PUBLIC_API_KEY,
        },
      }
    );
  };

  const getEntries = async (id) => {
    const { data } = await axios.get(`/api/raffles/entries?id=${id}`, {
      headers: {
        Authorization: process.env.NEXT_PUBLIC_API_KEY,
      },
    });

    // sort by transaction.['round-time']
    return [...data].sort((a, b) => {
      const aTime = a.transaction['round-time'];
      const bTime = b.transaction['round-time'];
      return bTime - aTime;
    });
  };

  const getUpdatedBalance = async () => {
    const assets = await getAssets(address);
    const theTokenAsset = assets.find((asset) => asset['asset-id'] === parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID));

    if (theTokenAsset?.['asset-id'] === parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID)) {
      setBalance(theTokenAsset?.amount);
    }
  };

  useEffect(() => {
    if (!address) return;

    getUpdatedBalance();
  }, [address]);

  return (
    <RafflesContext.Provider
      value={{
        balance,
        setBalance,
        loading,
        joinRaffle,
        getEntries,
        getUpdatedBalance,
      }}
    >
      {children}
    </RafflesContext.Provider>
  );
};
