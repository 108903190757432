import { SessionProvider } from 'next-auth/react';
import { AdventuresProvider } from '../hooks/useAdventures';
import { AlgoProvider } from '../hooks/useAlgo';
import { MyCitiesProvider } from '../hooks/useMyCities';
import { RafflesProvider } from '../hooks/useRaffles';
import { UserProvider } from '../hooks/useUser';

export default function Providers({ children, session }) {
  return (
    <SessionProvider session={session}>
      <UserProvider>
        <AlgoProvider>
          <RafflesProvider>
            <AdventuresProvider>
              <MyCitiesProvider>{children}</MyCitiesProvider>
            </AdventuresProvider>
          </RafflesProvider>
        </AlgoProvider>
      </UserProvider>
    </SessionProvider>
  );
}
