import cityUpgrades from '../config/cityUpgrades';

/**
 * Returns 'positive' or 'negative' based on if the city should receive benefits from the upgrade
 * @param {object} city
 * @returns {string} 'positive' or 'negative'
 */
export const calculateShouldReceiveUpgradeBenefits = (city) => {
  let shouldReceiveBenefits = false;

  if (city.metadata?.properties?.['Upgrade']) {
    const theUpgradeName = city.metadata?.properties?.['Upgrade'];
    const theUpgrade = cityUpgrades.find((upgrade) => upgrade.name === theUpgradeName);
    const requirements = theUpgrade.requirements;
    const metadata = city.metadata.properties;

    if (!requirements) return { shouldReceiveBenefits: 'positive', theUpgrade };

    const hasCorrectBlocks = requirements.blocks.filter((block) =>
      Object.values(metadata).some((meta) => typeof meta === 'string' && meta.includes(block))
    );
    const hasEnoughBlocks = Object.values(metadata).filter((meta) => typeof meta === 'string' && meta.includes(' - ')).length === 9;
    const hasEnoughCitizens = (city?.citizens ?? []).length >= 26;
    shouldReceiveBenefits = hasEnoughCitizens && hasEnoughBlocks && hasCorrectBlocks.length > 0 ? 'positive' : 'negative';
    return { shouldReceiveBenefits, theUpgrade };
  }

  return { shouldReceiveBenefits, theUpgrade: null };
};
