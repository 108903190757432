import axios from 'axios';

async function updateCityDetails(txn) {
  if (!txn) {
    throw new Error('No txn provided');
  }

  await axios.post(
    '/api/cities/update-details',
    {
      txn,
    },
    {
      headers: {
        Authorization: process.env.NEXT_PUBLIC_API_KEY,
      },
    }
  );
}

export default updateCityDetails;
