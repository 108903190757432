import { Menu, Transition } from '@headlessui/react';
import { useWallet } from '@txnlab/use-wallet';
import clsx from 'clsx';
import { signIn, signOut } from 'next-auth/react';
import Link from 'next/link';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useAlgo } from '../hooks/useAlgo';
import LoginDiscordButton from './LoginDiscordButton';
import LoginTwitterButton from './LoginTwitterButton';
import ProfileAvatar from './ProfileAvatar';

const UserMenu = () => {
  const { address } = useAlgo();
  const { providers, activeAccount } = useWallet();
  const formattedAddress = activeAccount ? `${activeAccount.address.slice(0, 4)}...${activeAccount.address.slice(-4)}` : '';

  const onConnect = async (provider) => {
    await Promise.all(providers.map((provider) => provider.disconnect()));
    await provider.connect();
  };

  const onDisconnect = async (provider) => {
    provider.disconnect();
    signOut({
      callbackUrl: '/',
    });
  };

  const onSignIn = useCallback(async () => {
    if (!address) return;
    try {
      await signIn('wallet', {
        wallet: address,
        redirect: false,
      });
    } catch (error) {
      console.error(error);
    }
  }, [address]);

  const activeProviders = (providers || []).filter((provider) => provider.isActive);
  const visibleProviders = activeProviders.length > 0 ? activeProviders : providers;

  useEffect(() => {
    onSignIn();
  }, [onSignIn]);

  return (
    <div>
      <Menu as='div' className='relative text-left'>
        {({ open }) => (
          <>
            <Menu.Button className='rounded-md bg-gray-700 w-10 h-10 flex items-center justify-center'>
              {address ? (
                <ProfileAvatar address={address} width={40} />
              ) : (
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='w-5 h-5'>
                  <path
                    d='M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L80 128c-8.8 0-16-7.2-16-16s7.2-16 16-16l368 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L64 32zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z'
                    fill='currentColor'
                  />
                </svg>
              )}
              <span className='sr-only'>Login</span>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 w-56 !z-[9999] mt-2 origin-top-right rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='relative py-1'>
                  {address && (
                    <div>
                      <h2 className='px-4 text-gray-500 mt-2 mb-1 text-sm'>Profile</h2>

                      <Link href='/profile'>
                        <a className='flex items-center px-4 py-2 space-x-3 transition-opacity duration-200 hover:opacity-75 w-full text-gray-100'>
                          <ProfileAvatar address={address} width={36} />
                          <span>View Profile</span>
                        </a>
                      </Link>
                    </div>
                  )}

                  <h2 className='px-4 text-gray-500 mt-2 mb-1 text-sm'>Connect Wallet</h2>
                  <div>
                    {(visibleProviders || []).map((provider) => (
                      <Menu.Item key={provider.metadata.id}>
                        <div className={clsx('flex items-center gap-2', provider.isActive && 'bg-gray-900')}>
                          <button
                            type='button'
                            className='flex items-center px-4 py-2 space-x-3 transition-opacity duration-200 hover:opacity-75 w-full text-gray-100'
                            disabled={provider.isActive}
                            onClick={provider.isActive ? undefined : () => onConnect(provider)}
                          >
                            <div className='w-8'>
                              {/* {provider.metadata.id === 'pera' && <LogoPeraConnect />}
                          {provider.metadata.id === 'defly' && <LogoDefly />} */}
                              <img src={provider.metadata.icon} alt={provider.metadata.id} className='rounded-md' />
                            </div>
                            {provider.isActive ? <span>{formattedAddress}</span> : <span>{provider.metadata.name}</span>}
                          </button>
                          {provider.isActive && (
                            <button type='button' onClick={() => onDisconnect(provider)} className='px-4' title='Disconnect'>
                              <span className='sr-only'>Disconnect</span>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 512 512'
                                className='w-5 h-5 text-gray-500 hover:text-gray-100 transition duration-200'
                              >
                                <path
                                  d='M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z'
                                  fill='currentColor'
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      </Menu.Item>
                    ))}
                  </div>

                  {address && (
                    <>
                      <h2 className='px-4 text-gray-500 mt-2 mb-1 text-sm'>Socials</h2>
                      <div>
                        <Menu.Item>
                          <div className={clsx('flex items-center gap-2')}>
                            <LoginDiscordButton />
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className={clsx('flex items-center gap-2')}>
                            <LoginTwitterButton />
                          </div>
                        </Menu.Item>
                      </div>
                    </>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

export default UserMenu;
