import IconCrown from '../../components/icons/IconCrown';
import IconHeart from '../../components/icons/IconHeart';
import IconKitty from '../../components/icons/IconKitty';
import IconWhale from '../../components/icons/IconWhale';
import { achievementLevel } from './config';

const kittyAchievements = [
  {
    name: 'First Litter Holder',
    description: 'Hold 1 First Litter Shitty Kitty',
    category: 'Kitties',
    level: achievementLevel.bronze,
    icon: <IconKitty />,
    requirements: {
      firstLitter: 1,
    },
  },
  {
    name: 'First Litter Aristocat',
    description: 'Hold 5 First Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.silver,
    icon: <IconKitty />,
    requirements: {
      firstLitter: 5,
    },
  },
  {
    name: 'First Litter Fat Cat',
    description: 'Hold 10 First Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.gold,
    icon: <IconKitty />,
    requirements: {
      firstLitter: 10,
    },
  },
  {
    name: 'First Litter Crazy Cat Lover',
    description: 'Hold 20 First Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.platinum,
    icon: <IconHeart />,
    requirements: {
      firstLitter: 20,
    },
  },
  {
    name: 'Second Litter Holder',
    description: 'Hold 1 Second Litter Shitty Kitty',
    category: 'Kitties',
    level: achievementLevel.bronze,
    icon: <IconKitty />,
    requirements: {
      secondLitter: 1,
    },
  },
  {
    name: 'Second Litter Aristocat',
    description: 'Hold 5 Second Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.silver,
    icon: <IconKitty />,
    requirements: {
      secondLitter: 5,
    },
  },
  {
    name: 'Second Litter Fat Cat',
    description: 'Hold 10 Second Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.gold,
    icon: <IconKitty />,
    requirements: {
      secondLitter: 10,
    },
  },
  {
    name: 'Second Litter Crazy Cat Lover',
    description: 'Hold 20 Second Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.platinum,
    icon: <IconHeart />,
    requirements: {
      secondLitter: 20,
    },
  },
  {
    name: 'Third Litter Holder',
    description: 'Hold 1 Third Litter Shitty Kitty',
    category: 'Kitties',
    level: achievementLevel.bronze,
    icon: <IconKitty />,
    requirements: {
      thirdLitter: 1,
    },
  },
  {
    name: 'Third Litter Aristocat',
    description: 'Hold 5 Third Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.silver,
    icon: <IconKitty />,
    requirements: {
      thirdLitter: 5,
    },
  },
  {
    name: 'Third Litter Fat Cat',
    description: 'Hold 10 Third Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.silver,
    icon: <IconKitty />,
    requirements: {
      thirdLitter: 10,
    },
  },
  {
    name: 'Third Litter Crazy Cat Lover',
    description: 'Hold 20 Third Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.gold,
    icon: <IconHeart />,
    requirements: {
      thirdLitter: 20,
    },
  },
  {
    name: 'Third Litter Whale',
    description: 'Hold 50 Third Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.platinum,
    icon: <IconWhale />,
    requirements: {
      thirdLitter: 50,
    },
  },
  {
    name: 'Fourth Litter Holder',
    description: 'Hold 1 Fourth Litter Shitty Kitty',
    category: 'Kitties',
    level: achievementLevel.bronze,
    icon: <IconKitty />,
    requirements: {
      fourthLitter: 1,
    },
  },
  {
    name: 'Fourth Litter Aristocat',
    description: 'Hold 5 Fourth Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.silver,
    icon: <IconKitty />,
    requirements: {
      fourthLitter: 5,
    },
  },
  {
    name: 'Fourth Litter Fat Cat',
    description: 'Hold 10 Fourth Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.gold,
    icon: <IconKitty />,
    requirements: {
      fourthLitter: 10,
    },
  },
  {
    name: 'Fourth Litter Crazy Cat Lover',
    description: 'Hold 20 Fourth Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.gold,
    icon: <IconHeart />,
    requirements: {
      fourthLitter: 20,
    },
  },
  {
    name: 'Fourth Litter Whale',
    description: 'Hold 50 Fourth Litter Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.platinum,
    icon: <IconWhale />,
    requirements: {
      fourthLitter: 50,
    },
  },
  {
    name: 'Fourth Litter Legend',
    description: 'Hold 1 Legendary Fourth Litter Shitty Kitty',
    category: 'Kitties',
    level: achievementLevel.diamond,
    icon: <IconKitty />,
    requirements: {
      legendary: 1,
    },
  },
  {
    name: 'Litter King',
    description: 'Hold 100 Shitty Kitties',
    category: 'Kitties',
    level: achievementLevel.diamond,
    icon: <IconCrown />,
    requirements: {
      anyLitter: 100,
    },
  },
  {
    name: 'Kitty Collector',
    description: 'Hold at least one kitty from each Litter (First, Second, Third, and Fourth)',
    category: 'Kitties',
    level: achievementLevel.gold,
    icon: <IconKitty />,
    requirements: {
      allLitters: 1,
    },
  },
];

export default kittyAchievements;
