const ASSETS = [
  1048503107, 1048503262, 1048503412, 1048503504, 1048503698, 1048503976, 1048504064, 1048504137, 1048504262, 1048504387, 1048504455, 1048504615,
  1048504783, 1048504892, 1048504979, 1048505110, 1048505229, 1048505320, 1048505414, 1048505508, 1048505611, 1048505707, 1048505828, 1048505886,
  1048505946, 1048506060, 1048506151, 1048506263, 1048506318, 1048506448, 1048506552, 1048506659, 1048506810, 1048506988, 1048507076, 1048507503,
  1048507617, 1048507697, 1048507792, 1048507951, 1048508147, 1048508221, 1048508354, 1048508463, 1048508525, 1048508585, 1048508716, 1048508787,
  1048508851, 1048508931, 1048509034, 1048509133, 1048509231, 1048509409, 1048509472, 1048509567, 1048509691, 1048509778, 1048510172, 1048510223,
  1048510651, 1048510915, 1048511174, 1048511249, 1048511352, 1048511443, 1048511548, 1048511620, 1048511719, 1048511804, 1048511892, 1048511952,
  1048512057, 1048512141, 1048512280, 1048512379, 1048512540, 1048512649, 1048512747, 1048512928, 1048513092, 1048513202, 1048513271, 1048513396,
  1048513501, 1048513553, 1048513703, 1048513899, 1048514088, 1048514346, 1048514660, 1048514808, 1048514932, 1048515021, 1048515091, 1048515295,
  1048515436, 1048515552, 1048515636, 1048515745, 1048515833, 1048515979, 1048516041, 1048516097, 1048516209, 1048516286, 1048516361, 1048516455,
  1048516617, 1048516711, 1048516781, 1048516929, 1048517043, 1048517256, 1048517366, 1048517446, 1048517510, 1048517694, 1048517812, 1048517855,
  1048517947, 1048518154, 1048518399, 1048518584, 1048518756, 1048518843, 1048518955, 1048519324, 1048519466, 1048520151, 1048520229, 1048520306,
  1048520488, 1048520565, 1048520667, 1048520769, 1048520911, 1048520982, 1048521102, 1048521225, 1048521338, 1048521464, 1048521543, 1048521673,
  1048521783, 1048521898, 1048521941, 1048522030, 1048522151, 1048522277, 1048522426, 1048522578, 1048522685, 1048522777, 1048522873, 1048523042,
  1048523134, 1048523253, 1048523318, 1048523438, 1048523550, 1048523679, 1048523790, 1048523876, 1048523978, 1048524142, 1048524204, 1048524310,
  1048524518, 1048524678, 1048524775, 1048524941, 1048525041, 1048525137, 1048525250, 1048525365, 1048525476, 1048525580, 1048525679, 1048525793,
  1048525863, 1048525999, 1048526211, 1048526284, 1048526418, 1048526586, 1048526701, 1048526974, 1048527064, 1048527153, 1048527235, 1048527324,
  1048527455, 1048527511, 1048527873, 1048527969, 1048528085, 1048528222, 1048528523, 1048528572, 1048528666, 1048528730, 1048528835, 1048528953,
  1048529050, 1048529353, 1048529603, 1048530265, 1048530385, 1048530786, 1048530976, 1048531475, 1048531688, 1048531845, 1048531921, 1048532037,
  1048532110, 1048532263, 1048532498, 1048532981, 1048533147, 1048533309, 1048533481, 1048533598, 1048533685, 1048533898, 1048534091, 1048534272,
  1048534389, 1048534555, 1048534674, 1048534764, 1048534843, 1048534984, 1048535089, 1048535179, 1048535321, 1048535618, 1048535771, 1048535892,
  1048536142, 1048536518, 1048536738, 1048536912, 1048537028, 1048537267, 1048537383, 1048537625, 1048537749, 1048537851, 1048537968, 1048538099,
  1048538168, 1048538328, 1048538412, 1048538523, 1048538612, 1048538720, 1048538794, 1048538908, 1048539036, 1048539133, 1048539202, 1048539258,
  1048539366, 1048539543, 1048539627, 1048539736, 1048539864, 1048540026, 1048540156, 1048540267, 1048540368, 1048540514, 1048540608, 1048540694,
  1048540807, 1048540896, 1048541005, 1048541140, 1048541291, 1048541408, 1048541544, 1048541653, 1048541767, 1048541902, 1048542001, 1048542107,
  1048542281, 1048542399, 1048542512, 1048542664, 1048542754, 1048542877, 1048543058, 1048543149, 1048543246, 1048543339, 1048543421, 1048543528,
  1048543635, 1048543748, 1048543814, 1048543892, 1048543984, 1048544091, 1048544191, 1048544353, 1048544447, 1048544539, 1048544635, 1048544710,
  1048544805, 1048544890, 1048544999, 1048545085, 1048545203, 1048545322, 1048545430, 1048545507, 1048545609, 1048545711, 1048545799, 1048545900,
  1048546121, 1048546210, 1048546294, 1048546396, 1048546518, 1048546746, 1048546831, 1048546959, 1048547074, 1048547183, 1048547348, 1048547463,
  1048547591, 1048547689, 1048547840, 1048547970, 1048548123, 1048548249, 1048548369, 1048548446, 1048548733, 1048549047, 1048549144, 1048549232,
  1048549316, 1048549448, 1048549580, 1048549665, 1048549725, 1048549867, 1048549960, 1048550115, 1048550215, 1048550304, 1048550406, 1048550468,
  1048550579, 1048550729, 1048550842, 1048550921, 1048551007, 1048551127, 1048551258, 1048551428, 1048551500, 1048551621, 1048551706, 1048551778,
  1048551866, 1048551992, 1048552177, 1048552290, 1048552434, 1048552563, 1048552725, 1048552858, 1048552940, 1048553081, 1048553232, 1048553511,
  1048553673, 1048554832, 1048555304, 1048555597, 1048555691, 1048555773, 1048555893, 1048556031, 1048556291, 1048556408, 1048556683, 1048556883,
  1048557160, 1048557226, 1048557315, 1048557438, 1048557498, 1048557610, 1048557710, 1048557956, 1048558161, 1048558323, 1048558434, 1048558497,
  1048558618, 1048558725, 1048558805, 1048559313, 1048559409, 1048559512, 1048559582, 1048559651, 1048559715, 1048560371, 1048561197, 1048561340,
  1048561551, 1048561740, 1048561829, 1048561887, 1048561992, 1048562110, 1048562190, 1048562253, 1048562360, 1048562429, 1048562490, 1048562541,
  1048562626, 1048562716, 1048562792, 1048562861, 1048562957, 1048563060, 1048563157, 1048563217, 1048563313, 1048563406, 1048563593, 1048563648,
  1048563735, 1048563799, 1048563963, 1048564064, 1048564165, 1048564245, 1048564315, 1048564394, 1048564511, 1048564597, 1048564667, 1048564768,
  1048564838, 1048564941, 1048565015, 1048565081, 1048565148, 1048565237, 1048565331, 1048565422, 1048565486, 1048565617, 1048565688, 1048565762,
  1048565877, 1048565972, 1048566040, 1048566135, 1048566223, 1048566319, 1048566382, 1048566469, 1048566590, 1048566661, 1048566781, 1048566873,
  1048566960, 1048567036, 1048567123, 1048567272, 1048567403, 1048567498, 1048567719, 1048567873, 1048567950, 1048568097, 1048568202, 1048568305,
  1048568383, 1048568467, 1048568551, 1048568675, 1048568803, 1048568988, 1048569067, 1048569173, 1048569374, 1048569494, 1048569568, 1048569654,
  1048569740, 1048569809, 1048569896, 1048569990, 1048570121, 1048570186, 1048570255, 1048570341, 1048570424, 1048570485, 1048570577, 1048570666,
  1048570777, 1048570860, 1048570937, 1048571050, 1048571132, 1048571234, 1048571306, 1048571418, 1048571478, 1048571583, 1048571741, 1048571809,
  1048571905, 1048571979, 1048572091, 1048572240, 1048572354, 1048572414, 1048572491, 1048572598, 1048572719, 1048572805, 1048572873, 1048572988,
  1048573099, 1048573246, 1048573400, 1048573520, 1048573636, 1048573764, 1048573961, 1048574065, 1048574176, 1048574284, 1048574354, 1048574459,
  1048574529, 1048574605, 1048574716, 1048574793, 1048574864, 1048574933, 1048575056, 1048575233, 1048575475, 1048575786, 1048576101, 1048576274,
  1048577614, 1048577827, 1048577960, 1048578069, 1048578193, 1048578472, 1048578703, 1048578851, 1048578932, 1048579062, 1048579327, 1048579418,
  1048579628, 1048579761, 1048579889, 1048579969, 1048580133, 1048580250, 1048580325, 1048580400, 1048580513, 1048580589, 1048580668, 1048580775,
  1048581010, 1048581111, 1048581178, 1048581349, 1048581500, 1048581650, 1048581762, 1048581889, 1048581985, 1048582081, 1048582191, 1048582277,
  1048582549, 1048582655, 1048582838, 1048583027, 1048583099, 1048583222, 1048583346, 1048583477, 1048583582, 1048583663, 1048583891, 1048584027,
  1048584138, 1048584230, 1048584378, 1048584473, 1048584569, 1048584664, 1048584827, 1048585000, 1048585131, 1048585199, 1048585300, 1048585373,
  1048585460, 1048585555, 1048585661, 1048585760, 1048585847, 1048585906, 1048586480, 1048586599, 1048586716, 1048586828, 1048586928, 1048587027,
  1048587113, 1048587222, 1048587296, 1048587406, 1048587599, 1048587687, 1048587859, 1048587946, 1048588119, 1048588284, 1048588386, 1048588520,
  1048588675, 1048588797, 1048588903, 1048589024, 1048589118, 1048589223, 1048589356, 1048589434, 1048589530, 1048589595, 1048589770, 1048589929,
  1048590037, 1048590185, 1048590423, 1048590509, 1048590668, 1048590732, 1048590828, 1048590930, 1048591051, 1048591120, 1048591203, 1048591321,
  1048591412, 1048591523, 1048591614, 1048591721, 1048591806, 1048591908, 1048591996, 1048592129, 1048592197, 1048592270, 1048592363, 1048592447,
  1048592542, 1048592627, 1048592763, 1048592832, 1048592899, 1048592990, 1048593084, 1048593144, 1048593212, 1048593320, 1048593394, 1048593552,
  1048593647, 1048593797, 1048593926, 1048593979, 1048594088, 1048594247, 1048594347, 1048594422, 1048594560, 1048594623, 1048594664, 1048594715,
  1048594789, 1048594908, 1048594963, 1048595049, 1048595139, 1048595288, 1048595519, 1048595583, 1048595651, 1048595737, 1048595819, 1048595932,
  1048596004, 1048596080, 1048596148, 1048596491, 1048596560, 1048596635, 1048596735, 1048596801, 1048597287, 1048597423, 1048597526, 1048597594,
  1048597750, 1048597812, 1048597938, 1048598022, 1048598389, 1048598535, 1048598640, 1048598724, 1048598915, 1048599045, 1048599117, 1048599184,
  1048599285, 1048599384, 1048599488, 1048599542, 1048599661, 1048599730, 1048599805, 1048599895, 1048599996, 1048600085, 1048600192, 1048600270,
  1048600339, 1048600403, 1048600477, 1048600560, 1048600635, 1048600726, 1048600932, 1048601021, 1048601118, 1048601218, 1048601292, 1048601403,
  1048601478, 1048601570, 1048601672, 1048601728, 1048601805, 1048602023, 1048602115, 1048602226, 1048602382, 1048602496, 1048602614, 1048602693,
  1048602788, 1048602825, 1048602975, 1048603076, 1048603233, 1048603314, 1048603407, 1048603489, 1048603551, 1048603647, 1048603779, 1048603885,
  1048603971, 1048604027, 1048604090, 1048604175, 1048604238, 1048604324, 1048604419, 1048604689, 1048604857, 1048604926, 1048605003, 1048605090,
  1048605186, 1048605278, 1048605370, 1048605425, 1048605506, 1048605554, 1048605622, 1048605691, 1048605768, 1048605851, 1048605946, 1048606041,
  1048606151, 1048606229, 1048606341, 1048606439, 1048606895, 1048607080, 1048607160, 1048607263, 1048607361, 1048607417, 1048607489, 1048607565,
  1048607619, 1048607690, 1048607735, 1048607818, 1048607895, 1048607980, 1048608062, 1048608153, 1048608286, 1048608372, 1048608497, 1048608592,
  1048608699, 1048608822, 1048608919, 1048609027, 1048609085, 1048609141, 1048609203, 1048609288, 1048609380, 1048609602, 1048609663, 1048609718,
  1048609802, 1048609885, 1048609959, 1048610046, 1048610106, 1048610172, 1048610273, 1048610363, 1048610508, 1048610614, 1048610711, 1048610797,
  1048610868, 1048611071, 1048611110, 1048611245, 1048611323, 1048611411, 1048611483, 1048611573, 1048611666, 1048611767, 1048611847, 1048611907,
  1048611987, 1048612042, 1048612146, 1048612211, 1048612267, 1048612357, 1048612431, 1048612578, 1048612639, 1048612747, 1048612961, 1048613061,
  1048613137, 1048613221, 1048613305, 1048613351, 1048613443, 1048613536, 1048613713, 1048613775, 1048613902, 1048613964, 1048614069, 1048614160,
  1048614237, 1048614337, 1048614422, 1048614501, 1048614600, 1048614724, 1048614862, 1048615011, 1048615117, 1048615209, 1048615267, 1048615380,
  1048615477, 1048615543, 1048615663, 1048615832, 1048615967, 1048616034, 1048616135, 1048616218, 1048616304, 1048616422, 1048616525, 1048616652,
  1048616728, 1048616840, 1048616930, 1048617029, 1048617123, 1048617247, 1048617388, 1048617479, 1048617540, 1048617643, 1048617715, 1048617795,
  1048617892, 1048617987, 1048618078, 1048618162, 1048618281, 1048618365, 1048618523, 1048618601, 1048618710, 1048618811, 1048618968, 1048619083,
  1048619214, 1048619325, 1048619428, 1048619519, 1048619580, 1048619659, 1048619798, 1048619861, 1048619974, 1048620078, 1048620196, 1048620258,
  1048620349, 1048620411, 1048620492, 1048620565, 1048620665, 1048620727, 1048620812, 1048620918, 1048621009, 1048621123, 1048621185, 1048621248,
  1048621370, 1048621527, 1048621606, 1048621971, 1048622045, 1048622212, 1048622341, 1048622412, 1048622510, 1048622630, 1048622701, 1048622760,
  1048622839, 1048622983, 1048623073, 1048623161, 1048623259, 1048623324, 1048623424, 1048623505, 1048623607, 1048624122, 1048624474, 1048624583,
  1048624654, 1048624846, 1048624919, 1048625000, 1048625109, 1048625356, 1048625473, 1048625608, 1048625678, 1048625788, 1048625892, 1048625971,
  1048626074, 1048626170, 1048626260, 1048626307, 1048626401, 1048626485, 1048626633, 1048626715, 1048626827, 1048626925, 1048627030, 1048627206,
  1048627306, 1048627499, 1048627663, 1048627886, 1048627982, 1048628060, 1048628132, 1048628232, 1048628339, 1048628469, 1048628588, 1048628687,
  1048628824, 1048628894, 1048628980, 1048629062, 1048629173, 1048629246, 1048629347, 1048629450, 1048629553, 1048629744, 1048629823, 1048630014,
  1048630067, 1048630144, 1048630224, 1048630298, 1048630417, 1048630491, 1048630609, 1048630719, 1048630887, 1048631051, 1048631167, 1048631257,
  1048631335, 1048631424, 1048631545, 1048631623, 1048631734, 1048631815, 1048631914, 1048632009, 1048632084, 1048632152, 1048632225, 1048632330,
  1048632453, 1048632521, 1048632620, 1048632724, 1048632827, 1048632903, 1048633133, 1048633236, 1048633445, 1048633630, 1048633721, 1048633815,
  1048633891, 1048634012, 1048634103, 1048634181, 1048634262, 1048634345, 1048634455, 1048634556, 1048634644, 1048634725, 1048634803, 1048634886,
  1048634968, 1048635049, 1048635122, 1048635250, 1048635350, 1048635431, 1048635545, 1048635712, 1048635825, 1048635903, 1048636039, 1048636146,
  1048636241, 1048636335, 1048636427, 1048636527, 1048636624, 1048636692, 1048636785, 1048636887, 1048636969, 1048637023, 1048637144, 1048637239,
  1048637327, 1048637406, 1048637486, 1048637565, 1048637649, 1048637789, 1048637908, 1048637993, 1048638080, 1048638165, 1048638252, 1048638367,
  1048638436, 1048638548, 1048638662, 1048638749, 1048638849, 1048638978, 1048639076, 1048639165, 1048639226, 1048639389, 1048639487, 1048639560,
  1048639638, 1048639719, 1048639810, 1048639872, 1048639966, 1048640052, 1048640237, 1048640310, 1048640398, 1048640485, 1048640612, 1048640697,
  1048640777, 1048640819, 1048640894, 1048640978, 1048641041, 1048641141, 1048641217, 1048641308, 1048641402, 1048641495, 1048641560, 1048641640,
  1048641763, 1048641866, 1048641965, 1048642055, 1048642177, 1048642262, 1048642311, 1048642404, 1048642476, 1048642553, 1048642611, 1048642720,
  1048642804, 1048643020, 1048643114, 1048643203, 1048643277, 1048643391, 1048643489, 1048643597, 1048643662, 1048643706, 1048643842, 1048644062,
  1048644208, 1048644316, 1048644404, 1048644583, 1048644677, 1048644850, 1048645072, 1048645209, 1048645312, 1048645522, 1048645694, 1048645761,
  1048645851, 1048645895, 1048645960, 1048646032, 1048646145, 1048646283, 1048646333, 1048646422, 1048646510, 1048646608, 1048646705, 1048646814,
  1048646904, 1048646971, 1048647050, 1048647131, 1048647223, 1048647324, 1048647410, 1048647484, 1048647551, 1048647615, 1048647735, 1048647805,
  1048647874, 1048647989, 1048648181, 1048648307, 1048648368, 1048648470, 1048648528, 1048648617, 1048648697, 1048648777, 1048648884, 1048648967,
  1048649059, 1048649139, 1048649234, 1048649310, 1048649395, 1048649471, 1048649580, 1048649665, 1048649757, 1048650100, 1048650455, 1048651007,
  1048651448, 1048652125, 1048652983, 1048653068, 1048653151, 1048653234, 1048653397, 1048653551, 1048653623, 1048653744, 1048653804, 1048653945,
  1048654004, 1048654070, 1048654139, 1048654185, 1048654318, 1048654457, 1048654666, 1048654758, 1048654927, 1048655038, 1048655163, 1048655353,
  1048655492, 1048655557, 1048655733, 1048655866, 1048655969, 1048656048, 1048656192, 1048656332, 1048656425, 1048656473, 1048656533, 1048656631,
  1048656757, 1048656819, 1048656908, 1048656994, 1048657068, 1048657180, 1048657642, 1048658028, 1048658494, 1048658871, 1048659160, 1048659249,
  1048659442, 1048659502, 1048659554, 1048659692, 1048659782, 1048659896, 1048660005, 1048660147, 1048660496, 1048660605, 1048660996, 1048661064,
  1048661346, 1048661625, 1048661783, 1048661913, 1048661979, 1048662090, 1048662186, 1048662241, 1048662346, 1048662491, 1048662605, 1048662681,
  1048662812, 1048662870, 1048662962, 1048663038, 1048663115, 1048663190, 1048663275, 1048663377, 1048663441, 1048663533, 1048663591, 1048663669,
  1048663729, 1048663792, 1048663908, 1048663987, 1048664064, 1048664164, 1048664264, 1048664554, 1048664614, 1048664796, 1048664927, 1048665017,
  1048665078, 1048665130, 1048665243, 1048665341, 1048665464, 1048665505, 1048665640, 1048665719, 1048665779, 1048665851, 1048665939, 1048666066,
  1048666165, 1048666366, 1048666529, 1048666650, 1048666760, 1048666854, 1048666924, 1048667050, 1048667135, 1048667205, 1048667273, 1048667363,
  1048667422, 1048667665, 1048667770, 1048667887, 1048667950, 1048668021, 1048668092, 1048668284, 1048668366, 1048668524, 1048668674, 1048668795,
  1048668865, 1048668950, 1048669041, 1048669213, 1048669395, 1048669506, 1048669627, 1048669707, 1048669785, 1048669845, 1048669908, 1048669973,
  1048670066, 1048670119, 1048670202, 1048670266, 1048670344, 1048670411, 1048670511, 1048670569, 1048670638, 1048670722, 1048670802, 1048670924,
  1048670958, 1048671055, 1048671118, 1048671229, 1048671310, 1048671394, 1048671430, 1048671517, 1048671579, 1048671666, 1048671754, 1048671864,
  1048671941, 1048672002, 1048672066, 1048672179, 1048672273, 1048672376, 1048672460, 1048672497, 1048672600, 1048672683, 1048672801, 1048672891,
  1048673006, 1048673414, 1048673706, 1048673753, 1048673821, 1048673886, 1048673990, 1048674058, 1048674191, 1048674314, 1048674371, 1048674429,
  1048674501, 1048674563, 1048674632, 1048674819, 1048674919, 1048674998, 1048675037, 1048675139, 1048675210, 1048675265, 1048675351, 1048675532,
  1048675686, 1048675737, 1048675805, 1048675862, 1048675915, 1048675977, 1048676052, 1048676127, 1048676198, 1048676272, 1048676322, 1048676359,
  1048676399, 1048676461, 1048676516, 1048676635, 1048676694, 1048676809, 1048676867, 1048676954, 1048677014, 1048677166, 1048677242, 1048677276,
  1048677349, 1048677397, 1048677485, 1048677546, 1048677615, 1048677696, 1048677774, 1048677844, 1048677900, 1048677963, 1048678028, 1048678147,
  1048678258, 1048678364, 1048678473, 1048678564, 1048678622, 1048678780, 1048678884, 1048679021, 1048679096, 1048679175, 1048679274, 1048679371,
  1048679459, 1048679523, 1048679575, 1048679681, 1048679774, 1048679823, 1048679902, 1048679998, 1048680068, 1048680152, 1048680235, 1048680305,
  1048680383, 1048680437, 1048680513, 1048680586, 1048680670, 1048680797, 1048680875, 1048680963, 1048681008, 1048681141, 1048681185, 1048681242,
  1048681297, 1048681437, 1048681514, 1048681599, 1048681677, 1048681825, 1048681953, 1048681998, 1048682077, 1048682140, 1048682218, 1048682325,
  1048682417, 1048682485, 1048682543, 1048682663, 1048682751, 1048682821, 1048682952, 1048683034, 1048683100, 1048683190, 1048683257, 1048683334,
  1048683432, 1048683534, 1048683593, 1048683725, 1048683801, 1048683912, 1048683992, 1048684054, 1048684127, 1048684253, 1048684361, 1048684421,
  1048684786, 1048684907, 1048685202, 1048685267, 1048685334, 1048685407, 1048685588, 1048685730, 1048685854, 1048685943, 1048686222, 1048686268,
  1048686756, 1048686839, 1048686974, 1048687051, 1048687121, 1048687294, 1048687378, 1048687470, 1048687553, 1048687626, 1048687720, 1048687822,
  1048687906, 1048687982, 1048688052, 1048688158, 1048688235, 1048688293, 1048688345, 1048688432, 1048688511, 1048688597, 1048688658, 1048688763,
  1048688890, 1048688965, 1048689129, 1048689222, 1048689315, 1048689402, 1048689502, 1048689607, 1048689685, 1048689787, 1048689904, 1048690024,
  1048690084, 1048690207, 1048690265, 1048690343, 1048690419, 1048690518, 1048690608, 1048690716, 1048690798, 1048690908, 1048690991, 1048691065,
  1048691150, 1048691267, 1048691337, 1048691421, 1048691517, 1048691650, 1048691762, 1048691873, 1048691982, 1048692101, 1048692176, 1048692318,
  1048692445, 1048692589, 1048692645, 1048692749, 1048692954, 1048693064, 1048693219, 1048693361, 1048693479, 1048693561, 1048693678, 1048693822,
  1048694016, 1048694135, 1048694243, 1048694379, 1048694473, 1048694589, 1048694718, 1048694847, 1048695167, 1048695313, 1048695439, 1048695566,
  1048695632, 1048695735, 1048695818, 1048695927, 1048696024, 1048696147, 1048696630, 1048697012, 1048697124, 1048697378, 1048697511, 1048697608,
  1048697690, 1048697902, 1048698054, 1048698101, 1048698356, 1048698450, 1048698549, 1048698830, 1048698915, 1048699006, 1048699102, 1048699174,
  1048699250, 1048699365, 1048699479, 1048699549, 1048699606, 1048699677, 1048699751, 1048699842, 1048699908, 1048699978, 1048700126, 1048700211,
  1048700283, 1048700342, 1048700423, 1048700543, 1048700612, 1048700681, 1048700795, 1048700881, 1048701005, 1048701094, 1048701182, 1048701290,
  1048701329, 1048701509, 1048701668, 1048701732, 1048701881, 1048702018, 1048702084, 1048702163, 1048702281, 1048702384, 1048702467, 1048702518,
  1048702636, 1048702731, 1048702794, 1048702854, 1048702957, 1048703045, 1048703123, 1048703207, 1048703303, 1048703428, 1048703516, 1048703612,
  1048703746, 1048703861, 1048703944, 1048704002, 1048704063, 1048704123, 1048704199, 1048704334, 1048704453, 1048704529, 1048704624, 1048704717,
  1048704796, 1048704934, 1048705020, 1048705123, 1048705212, 1048705314, 1048705395, 1048705489, 1048705568, 1048705673, 1048705841, 1048705967,
  1048706023, 1048706125, 1048706325, 1048706380, 1048706453, 1048706515, 1048706596, 1048706657, 1048706740, 1048706811, 1048706942, 1048707071,
  1048707165, 1048707363, 1048707566, 1048707658, 1048707744, 1048707814, 1048707893, 1048707978, 1048708051, 1048708157, 1048708231, 1048708298,
  1048708408, 1048708472, 1048708533, 1048708608, 1048708681, 1048708779, 1048708845, 1048708907, 1048708982, 1048709030, 1048709117, 1048709207,
  1048709279, 1048709353, 1048709406, 1048709472, 1048709514, 1048709614, 1048709661, 1048709761, 1048709847, 1048709895, 1048710002, 1048710180,
  1048710307, 1048710359, 1048710492, 1048710651, 1048710745, 1048710820, 1048710883, 1048710940, 1048711015, 1048711074, 1048711134, 1048711202,
  1048711262, 1048711322, 1048711452, 1048711523, 1048711605, 1048711672, 1048711754, 1048711832, 1048711908, 1048711983, 1048712084, 1048712154,
  1048712268, 1048712370, 1048712482, 1048712552, 1048712669, 1048712761, 1048712842, 1048712947, 1048713044, 1048713138, 1048713215, 1048713289,
  1048713451, 1048713518, 1048713604, 1048713687, 1048713784, 1048713892, 1048714015, 1048714107, 1048714195, 1048714268, 1048714347, 1048714436,
  1048714536, 1048714622, 1048714717, 1048714841, 1048714913, 1048714994, 1048715096, 1048715159, 1048715232, 1048715316, 1048715415, 1048715524,
  1048715626, 1048715716, 1048715834, 1048715943, 1048716544, 1048716862, 1048717048, 1048717167, 1048717427, 1048717600, 1048717730, 1048718472,
  1048718603, 1048718717, 1048718851, 1048719132, 1048719275, 1048719381, 1048719468, 1048719550, 1048719651, 1048719772, 1048719867, 1048719951,
  1048720048, 1048720158, 1048720225, 1048720278, 1048720368, 1048720443, 1048721422, 1048721583, 1048721978, 1048722579, 1048722672, 1048723013,
  1048723180, 1048723309, 1048723428, 1048723495, 1048723621, 1048723752, 1048723947, 1048724123, 1048724215, 1048724336, 1048724477, 1048724559,
  1048724622, 1048724716, 1048724794, 1048724886, 1048725229, 1048725397, 1048725544, 1048725680, 1048725761, 1048725884, 1048726003, 1048726158,
  1048726333, 1048726457, 1048726546, 1048726635, 1048726864, 1048726983, 1048727101, 1048727214, 1048727379, 1048727459, 1048727596, 1048727692,
  1048727752, 1048727823, 1048727900, 1048728029, 1048728142, 1048728230, 1048728311, 1048728399, 1048728489, 1048728573, 1048728718, 1048728805,
  1048728895, 1048728984, 1048729108, 1048729190, 1048729266, 1048729555, 1048729619, 1048729683, 1048729734, 1048729836, 1048729904, 1048729999,
  1048730180, 1048730247, 1048730367, 1048730414, 1048730486, 1048730562, 1048730629, 1048730718, 1048730792, 1048730883, 1048730949, 1048731014,
  1048731151, 1048731259, 1048731344, 1048731418, 1048731481, 1048731537, 1048731701, 1048731791, 1048731932, 1048732049, 1048732189, 1048732269,
  1048732332, 1048732500, 1048732597, 1048732716, 1048732807, 1048732867, 1048732938, 1048733019, 1048733090, 1048733136, 1048733198, 1048733261,
  1048733357, 1048733405, 1048733500, 1048733565, 1048733664, 1048733701, 1048733796, 1048733897, 1048734000, 1048734094, 1048734182, 1048734312,
  1048734433, 1048734528, 1048734600, 1048734652, 1048734746, 1048734817, 1048734901, 1048734979, 1048735029, 1048735101, 1048735228, 1048735309,
  1048735387, 1048735448, 1048735551, 1048735623, 1048735727, 1048735771, 1048735886, 1048735941, 1048736016, 1048736087, 1048736163, 1048736212,
  1048736303, 1048736391, 1048736458, 1048736522, 1048736572, 1048736652, 1048736715, 1048736783, 1048736862, 1048736915, 1048736974, 1048737058,
  1048737140, 1048737200, 1048737266, 1048737405, 1048737483, 1048737543, 1048737594, 1048737627, 1048737720, 1048737827, 1048737881, 1048737980,
  1048738044, 1048738112, 1048738170, 1048738277, 1048738352, 1048738432, 1048738510, 1048738572, 1048738668, 1048738736, 1048738904, 1048739002,
  1048739078, 1048739129, 1048739196, 1048739279, 1048739373, 1048739447, 1048739530, 1048739608, 1048739755, 1048739799, 1048739884, 1048739960,
  1048740066, 1048740133, 1048740196, 1048740260, 1048740302, 1048740351, 1048740389, 1048740474, 1048740551, 1048740631, 1048740742, 1048740801,
  1048740902, 1048740954, 1048741058, 1048741161, 1048741304, 1048741538, 1048741676, 1048741747, 1048741826, 1048741906, 1048741971, 1048742025,
  1048742118, 1048742171, 1048742287, 1048742324, 1048742396, 1048742465, 1048742534, 1048742654, 1048742722, 1048742845, 1048742958, 1048743057,
  1048743160, 1048743267, 1048743375, 1048743520, 1048743852, 1048744028, 1048744132, 1048744268, 1048744436, 1048744541, 1048744633, 1048744741,
  1048744867, 1048744995, 1048745177, 1048745312, 1048745423, 1048745569, 1048745708, 1048745794, 1048745891, 1048746042, 1048746148, 1048746221,
  1048746340, 1048746442, 1048746543, 1048746640, 1048746845, 1048746973, 1048747140, 1048747258, 1048747556, 1048747665, 1048747766, 1048748380,
  1048748533, 1048748611, 1048748786, 1048748869, 1048748954, 1048749057, 1048749112, 1048749204, 1048749431, 1048749542, 1048749616, 1048749704,
  1048749771, 1048749851, 1048749917, 1048750061, 1048750144, 1048750206, 1048750275, 1048750367, 1048750491, 1048750568, 1048750644, 1048750731,
  1048750813, 1048750952, 1048751031, 1048751145, 1048751257, 1048751335, 1048751375, 1048751476, 1048751559, 1048751657, 1048751730, 1048751937,
  1048752020, 1048752145, 1048752284, 1048752520, 1048752739, 1048752878, 1048753027, 1048753089, 1048753164, 1048753254, 1048753327, 1048753390,
  1048753450, 1048753561, 1048753634, 1048753712, 1048753796, 1048753898, 1048753947, 1048754028, 1048754144, 1048754241, 1048754348, 1048754429,
  1048754507, 1048754606, 1048754657, 1048754718, 1048754791, 1048754853, 1048754951, 1048755056, 1048755179, 1048755249, 1048755313, 1048755372,
  1048755441, 1048755505, 1048755645, 1048755787, 1048755869, 1048755930, 1048755984, 1048756032, 1048756119, 1048756276, 1048756369, 1048756439,
  1048756542, 1048756613, 1048756693, 1048756753, 1048756847, 1048756910, 1048757042, 1048757134, 1048757186, 1048757317, 1048757385, 1048757476,
  1048757580, 1048757648, 1048757688, 1048757764, 1048757859, 1048757932, 1048758033, 1048758082, 1048758163, 1048758265, 1048758317, 1048758402,
  1048758473, 1048758520, 1048758579, 1048758632, 1048758715, 1048758781, 1048758839, 1048758907, 1048758983, 1048759044, 1048759123, 1048759192,
  1048759272, 1048759349, 1048759428, 1048759496, 1048759553, 1048759610, 1048759662, 1048759745, 1048759814, 1048759852, 1048759938, 1048759996,
  1048760075, 1048760164, 1048760212, 1048760271, 1048760368, 1048760442, 1048760506, 1048760568, 1048760633, 1048760700, 1048760766, 1048760834,
  1048760916, 1048760955, 1048761128, 1048761223, 1048761327, 1048761401, 1048761475, 1048761534, 1048761590, 1048761648, 1048761711, 1048761773,
  1048761833, 1048761887, 1048762045, 1048762210, 1048762362, 1048762546, 1048762645, 1048762703, 1048762745, 1048762823, 1048762884, 1048762937,
  1048763007, 1048763080, 1048763172, 1048763245, 1048763344, 1048763436, 1048763529, 1048763660, 1048763789, 1048763905, 1048763986, 1048764065,
  1048764133, 1048764184, 1048764276, 1048764321, 1048764386, 1048764443, 1048764498, 1048764618, 1048764688, 1048764754, 1048764811, 1048764864,
  1048764952, 1048765002, 1048765080, 1048765136, 1048765186, 1048765245, 1048765276, 1048765320, 1048765374, 1048765507, 1048765597, 1048765658,
  1048765744, 1048765825, 1048765880, 1048766008, 1048766074, 1048766155, 1048766209, 1048766280, 1048766335, 1048766444, 1048766512, 1048766592,
  1048766686, 1048766753, 1048766832, 1048766892, 1048766962, 1048767024, 1048767080, 1048767184, 1048767260, 1048767360, 1048767469, 1048767541,
  1048767639, 1048767695, 1048767769, 1048767850, 1048767925, 1048767990, 1048768043, 1048768172, 1048768254, 1048768375, 1048768558, 1048768708,
  1048768811, 1048768867, 1048768971, 1048769049, 1048769154, 1048769247, 1048769313, 1048769374, 1048769447, 1048769506, 1048769582, 1048769644,
  1048769694, 1048769777, 1048769858, 1048769894, 1048769972, 1048770054, 1048770113, 1048770212, 1048770306, 1048770405, 1048770493, 1048770561,
  1048770679, 1048770750, 1048770834, 1048770904, 1048771030, 1048771087, 1048771153, 1048771256, 1048771428, 1048771548, 1048771612, 1048771701,
  1048771791, 1048771884, 1048771968, 1048772057, 1048772147, 1048772223, 1048772274, 1048772329, 1048772397, 1048772467, 1048772535, 1048772592,
  1048772672, 1048772772, 1048772885, 1048772939, 1048773043, 1048773146, 1048773260, 1048773398, 1048773478, 1048773540, 1048773626, 1048773726,
  1048773815, 1048773889, 1048773960, 1048774049, 1048774111, 1048774228, 1048774290, 1048774446, 1048774556, 1048774691, 1048774778, 1048774841,
  1048774934, 1048774998, 1048775084, 1048775159, 1048775256, 1048775348, 1048775444, 1048775527, 1048775647, 1048775747, 1048775863, 1048775993,
  1048776077, 1048776144, 1048776195, 1048776294, 1048776364, 1048776491, 1048776559, 1048776643, 1048776703, 1048776780, 1048776824, 1048776957,
  1048777055, 1048777118, 1048777185, 1048777239, 1048777415, 1048777490, 1048777564, 1048777637, 1048777877, 1048778014, 1048778087, 1048778175,
  1048778236, 1048778350, 1048778428, 1048778528, 1048778605, 1048778723, 1048778868, 1048779031, 1048779135, 1048779194, 1048779265, 1048779369,
  1048779498, 1048779617, 1048779719, 1048779778, 1048779989, 1048780053, 1048780229, 1048780320, 1048780365, 1048780461, 1048780520, 1048780571,
  1048780647, 1048780742, 1048780795, 1048780903, 1048780984, 1048781064, 1048781115, 1048781279, 1048781378, 1048781439, 1048781537, 1048781603,
  1048781659, 1048781738, 1048781792, 1048781866, 1048781959, 1048782050, 1048782095, 1048782185, 1048782252, 1048782322, 1048782377, 1048782471,
  1048782530, 1048782594, 1048782656, 1048782743, 1048782839, 1048782949, 1048783059, 1048783159, 1048783239, 1048783304, 1048783368, 1048783445,
  1048783535, 1048783606, 1048783677, 1048783757, 1048783808, 1048783862, 1048783965, 1048784003, 1048784078, 1048784176, 1048784245, 1048784296,
  1048784355, 1048785502, 1048785549, 1048785613, 1048785672, 1048785784, 1048785888, 1048785970, 1048786047, 1048786099, 1048786167, 1048786241,
  1048786295, 1048786383, 1048786535, 1048786598, 1048786669, 1048786778, 1048786841, 1048786941, 1048786996, 1048787089, 1048787151, 1048787258,
  1048787308, 1048787403, 1048787483, 1048787581, 1048787653, 1048787720, 1048787794, 1048787883, 1048787965, 1048788039, 1048788123, 1048788238,
  1048788315, 1048788413, 1048788525, 1048788598, 1048788699, 1048788849, 1048788958, 1048789044, 1048789147, 1048789220, 1048789338, 1048789391,
  1048789474, 1048789520, 1048789644, 1048789738, 1048789826, 1048789929, 1048789971, 1048790023, 1048790080, 1048790158, 1048790249, 1048790323,
  1048790402, 1048790496, 1048790550, 1048790645, 1048790710, 1048790805, 1048790879, 1048790950, 1048791006, 1048791110, 1048791171, 1048791243,
  1048791325, 1048791417, 1048791480, 1048791653, 1048791831, 1048791888, 1048791963, 1048791999, 1048792073, 1048792147, 1048792192, 1048792248,
  1048792330, 1048792393, 1048792456, 1048792537, 1048792621, 1048792673, 1048792739, 1048792798, 1048792915, 1048792975, 1048793124, 1048793225,
  1048793313, 1048793397, 1048793465, 1048793551, 1048793616, 1048793686, 1048793746, 1048793860, 1048794033, 1048794145, 1048794209, 1048794286,
  1048794331, 1048794423, 1048794491, 1048794611, 1048794668, 1048794730, 1048794801, 1048794887, 1048794995, 1048795090, 1048795189, 1048795277,
  1048795376, 1048795471, 1048795594, 1048795659, 1048795854, 1048796152, 1048796258, 1048796639, 1048796950, 1048797354, 1048797493, 1048797591,
  1048797691, 1048797780, 1048797842, 1048797948, 1048798117, 1048798232, 1048798281, 1048798385, 1048798578, 1048798659, 1048798720, 1048798842,
  1048798946, 1048799055, 1048799187, 1048799331, 1048799442, 1048799654, 1048799784, 1048799855, 1048799993, 1048800119, 1048800209, 1048800288,
  1048800373, 1048800490, 1048800603, 1048800718, 1048800833, 1048800918, 1048801033, 1048801180, 1048801306, 1048801434, 1048801554, 1048801637,
  1048801712, 1048801774, 1048801832, 1048801963, 1048802026, 1048802121, 1048802202, 1048802346, 1048802732, 1048803113, 1048803207, 1048803308,
  1048803398, 1048803476, 1048803559, 1048803616, 1048803702, 1048803761, 1048803835, 1048804084, 1048804259, 1048804473, 1048804554, 1048804686,
  1048804781, 1048804881, 1048805340, 1048805526, 1048805650, 1048805764, 1048805866, 1048805956, 1048806052, 1048806183, 1048806329, 1048806422,
  1048806498, 1048806551, 1048806673, 1048806778, 1048806874, 1048806967, 1048807711, 1048807796, 1048807859, 1048807925, 1048807985, 1048808098,
  1048808185, 1048808284, 1048808335, 1048808404, 1048808811, 1048808898, 1048808975, 1048809036, 1048809093, 1048809133, 1048809215, 1048809289,
  1048809354, 1048809420, 1048809482, 1048809520, 1048809626, 1048809681, 1048809759, 1048809839, 1048809924, 1048810024, 1048810094, 1048810176,
  1048810242, 1048810327, 1048810386, 1048810468, 1048810539, 1048810616, 1048810680, 1048810784, 1048810846, 1048810933, 1048811011, 1048811095,
  1048811166, 1048811209, 1048811301, 1048811381, 1048811429, 1048811486, 1048811558, 1048811619, 1048811712, 1048811761, 1048811825, 1048811925,
  1048812034, 1048812122, 1048812202, 1048812287, 1048812381, 1048812460, 1048812602, 1048812739, 1048812807, 1048812879, 1048812936, 1048813014,
  1048813131, 1048813193, 1048813243, 1048813338, 1048813448, 1048813544, 1048813628, 1048813718, 1048813824, 1048813894, 1048813954, 1048814038,
  1048814106, 1048814296, 1048814367, 1048814439, 1048814573, 1048814628, 1048814703, 1048814769, 1048814849, 1048814916, 1048815021, 1048815097,
  1048815155, 1048815273, 1048815381, 1048815457, 1048815560, 1048815630, 1048815793, 1048815861, 1048815928, 1048816021, 1048816079, 1048816139,
  1048816203, 1048816264, 1048816312, 1048816372, 1048816437, 1048816517, 1048816592, 1048816685, 1048816744, 1048816816, 1048816870, 1048816990,
  1048817038, 1048817120, 1048817188, 1048817268, 1048817376, 1048817458, 1048817526, 1048817574, 1048817674, 1048817740, 1048817887, 1048817955,
  1048818065, 1048818133, 1048818261, 1048818335, 1048818405, 1048818509, 1048818564, 1048818616, 1048818690, 1048818788, 1048818823, 1048818895,
  1048818951, 1048818998, 1048819104, 1048819179, 1048819260, 1048819327, 1048819398, 1048819523, 1048819593, 1048819648, 1048819730, 1048819790,
  1048819844, 1048819897, 1048819967, 1048820044, 1048820107, 1048820154, 1048820218, 1048820278, 1048820332, 1048820422, 1048820475, 1048820555,
  1048820675, 1048820763, 1048820878, 1048820944, 1048821009, 1048821105, 1048821181, 1048821266, 1048821333, 1048821438, 1048821497, 1048821600,
  1048821691, 1048821738, 1048821836, 1048821909, 1048821992, 1048822062, 1048822168, 1048822288, 1048822404, 1048822473, 1048822525, 1048822624,
  1048822850, 1048823153, 1048823324, 1048823363, 1048823463, 1048823546, 1048823653, 1048823726, 1048823787, 1048823854, 1048823917, 1048824009,
  1048824085, 1048824207, 1048824272, 1048824376, 1048824427, 1048824502, 1048824573, 1048824704, 1048824793, 1048824854, 1048824937, 1048824988,
  1048825085, 1048825143, 1048825226, 1048825297, 1048825375, 1048825481, 1048825547, 1048825603, 1048825678, 1048825762, 1048825983, 1048826059,
  1048826138, 1048826270, 1048826379, 1048826423, 1048826522, 1048826580, 1048826648, 1048826754, 1048826808, 1048827095, 1048827176, 1048827246,
  1048827317, 1048827393, 1048827496, 1048827574, 1048827710, 1048827842, 1048827913, 1048827977, 1048828049, 1048828108, 1048828199, 1048828272,
  1048828349, 1048828399, 1048828496, 1048828601, 1048828658, 1048828726, 1048828814, 1048828879, 1048828938, 1048828993, 1048829081, 1048829134,
  1048829187, 1048829272, 1048829366, 1048829440, 1048829504, 1048829564, 1048829668, 1048829737, 1048829793, 1048829887, 1048829976, 1048830044,
  1048830112, 1048830187, 1048830284, 1048830352, 1048830444, 1048830499, 1048830584, 1048830740, 1048830817, 1048830943, 1048831125, 1048831489,
  1048831702, 1048831872, 1048831984, 1048832069, 1048832166, 1048832267, 1048832345, 1048832399, 1048832514, 1048832603, 1048832713, 1048832839,
  1048832971, 1048833027, 1048833093, 1048833167, 1048833300, 1048833417, 1048833496, 1048833583, 1048833688, 1048833791, 1048833854, 1048833946,
  1048833991, 1048834061, 1048834103, 1048834215, 1048834285, 1048834357, 1048834401, 1048834505, 1048834561, 1048834631, 1048834721, 1048834777,
  1048834956, 1048835215, 1048835290, 1048835654, 1048835916, 1048836186, 1048836331, 1048836434, 1048836510, 1048836603, 1048836941, 1048836971,
  1048837012, 1048837047, 1048837068, 1048837112, 1048837146, 1048837166, 1048837189, 1048837223, 1048837247, 1048837274, 1048837319, 1048837341,
  1048837365, 1048837408, 1048837449, 1048837476, 1048837500, 1048837544, 1048837566, 1048837600, 1048837632, 1048837671, 1048837745, 1048837767,
  1048837802, 1048837827, 1048837886, 1048837911, 1048837943, 1048837985, 1048838008, 1048838052, 1048838091, 1048838124, 1048838170, 1048838809,
  1048838826, 1048838834, 1048838853, 1048838867, 1048838872, 1048838885, 1048838889, 1048838893, 1048838904, 1048838913, 1048838922, 1048838943,
  1048838946, 1048838951, 1048838957, 1048838963, 1048838968, 1048838999, 1048839006, 1048839019, 1048839028, 1048839036, 1048839051, 1048839054,
  1048839063, 1048839067, 1048839077, 1048839088, 1048839095, 1048839097, 1048839114, 1048839123, 1048839129, 1048839154, 1048839156, 1048839190,
  1048839202, 1048839219, 1048839235, 1048839254, 1048839257, 1048839267, 1048839279, 1048839286, 1048839332, 1048839353, 1048839360, 1048839367,
  1048839380, 1048839387, 1048839390, 1048839397, 1048839406, 1048839422, 1048839432, 1048839436, 1048839439, 1048839445, 1048839450, 1048839460,
  1048839478, 1048839483, 1048839491, 1048839522, 1048839544, 1048839552, 1048839558, 1048839562, 1048839569, 1048839588, 1048839593, 1048839602,
  1048839608, 1048839616, 1048839622, 1048839628, 1048839636, 1048839645, 1048839651, 1048839654, 1048839689, 1048839702, 1048839708, 1048839721,
  1048839738, 1048839746, 1048839751, 1048839760, 1048839770, 1048839779, 1048839795, 1048839801, 1048839805, 1048839808, 1048839812, 1048839825,
  1048839833, 1048839853, 1048839865, 1048839870, 1048839881, 1048839895, 1048839904, 1048839916, 1048839919, 1048839930, 1048839937, 1048839953,
  1048839962, 1048839973, 1048839994, 1048840003, 1048840017, 1048840022, 1048840031, 1048840048, 1048840055, 1048840057, 1048840083, 1048840089,
  1048840111, 1048840123, 1048840139, 1048840143, 1048840152, 1048840167, 1048840173, 1048840190, 1048840192, 1048840196, 1048840211, 1048840219,
  1048840244, 1048840249, 1048840253, 1048840262, 1048840270, 1048840273, 1048840284, 1048840289, 1048840291, 1048840304, 1048840321, 1048840337,
  1048840388, 1048840391, 1048840399, 1048840406, 1048840415, 1048840418, 1048840424, 1048840430, 1048840441, 1048840445, 1048840450, 1048840466,
  1048840478, 1048840485, 1048840503, 1048900276, 1048900284, 1048900292, 1048900306, 1048900313, 1048900330, 1048900338, 1048900344, 1048900352,
  1048900365, 1048900374, 1048900385, 1048900394, 1048900403, 1048900413, 1048900418, 1048900424, 1048900430, 1048900441, 1048900458, 1048900481,
  1048900492, 1048900502, 1048900508, 1048900526, 1048900536, 1048900543, 1048900551, 1048900560, 1048900572, 1048900579, 1048900590, 1048900597,
  1048900601, 1048900604, 1048900625, 1048900641, 1048900659, 1048900666, 1048900678, 1048900685, 1048900692, 1048900699, 1048900709, 1048900714,
  1048900718, 1048900728, 1048900736, 1048900876, 1048900884, 1048900897, 1048900915, 1048900924, 1048900933, 1048900940, 1048900952, 1048900974,
  1048900985, 1048900996, 1048901003, 1048901011, 1048901017, 1048901025, 1048901043, 1048901048, 1048901058, 1048901067, 1048901087, 1048901095,
  1048901104, 1048901116, 1048901124, 1048901143, 1048901152, 1048901158, 1048901162, 1048901187, 1048901228, 1048901235, 1048901260, 1048901266,
  1048901273, 1048901283, 1048901310, 1048901322, 1048901334, 1048901408, 1048901420, 1048901422, 1048901429, 1048901443, 1048901455, 1048901465,
  1048901472, 1048901488, 1048901501, 1048901505, 1048901513, 1048901517, 1048901529, 1048901540, 1048901550, 1048901555, 1048901559, 1048901562,
  1048901571, 1048901573, 1048901581, 1048901585, 1048901592, 1048901612, 1048901620, 1048901629, 1048901633, 1048901644, 1048901657, 1048901667,
  1048901674, 1048901683, 1048901697, 1048901706, 1048901719, 1048901729, 1048901736, 1048901744, 1048901749, 1048901756, 1048901771, 1048901782,
  1048901790, 1048901798, 1048901815, 1048901822, 1048901834, 1048901846, 1048901855, 1048901861, 1048901863, 1048901873, 1048901883, 1048901893,
  1048901904, 1048901909, 1048901919, 1048901928, 1048901933, 1048901938, 1048901953, 1048901964, 1048901976, 1048901990, 1048902004, 1048902041,
  1048902046, 1048902052, 1048902059, 1048902066, 1048902079, 1048902091, 1048902098, 1048902110, 1048902117, 1048902133, 1048902140, 1048902148,
  1048902160, 1048902168, 1048902174, 1048902187, 1048902192, 1048902204, 1048902213, 1048902221, 1048902224, 1048902232, 1048902241, 1048902249,
  1048902254, 1048902268, 1048902273, 1048902282, 1048902293, 1048902312, 1048902317, 1048902322, 1048902328, 1048902355, 1048902364, 1048902383,
  1048902411, 1048902414, 1048902425, 1048902436, 1048902442, 1048902470, 1048902491, 1048902502, 1048902541, 1048902555, 1048902561, 1048902571,
  1048902578, 1048902585, 1048902589, 1048902595, 1048902603, 1048902611, 1048902619, 1048902647, 1048902667, 1048902673, 1048902691, 1048902702,
  1048902715, 1048902724, 1048902755, 1048902760, 1048902764, 1048902778, 1048902786, 1048902821, 1048902905, 1048902914, 1048902933, 1048902946,
  1048902981, 1048903002, 1048903013, 1048903025, 1048903042, 1048903047, 1048903052, 1048903066, 1048903078, 1048903083, 1048903093, 1048903103,
  1048903111, 1048903120, 1048903127, 1048903146, 1048903152, 1048903164, 1048903171, 1048903179, 1048903195, 1048903199, 1048903211, 1048903223,
  1048903247, 1048903259, 1048903267, 1048903270, 1048903279, 1048903296, 1048903305, 1048903310, 1048903315, 1048903441, 1048903449, 1048903463,
  1048903488, 1048903510, 1048903527, 1048903536, 1048903547, 1048903553, 1048903565, 1048903580, 1048903596, 1048903604, 1048903611, 1048903617,
  1048903625, 1048903632, 1048903644, 1048903652, 1048903666, 1048903684, 1048903688, 1048903691, 1048903698, 1048903707, 1048903726, 1048903734,
  1048903740, 1048903746, 1048903753, 1048903764, 1048903773, 1048903785, 1048903787, 1048903794, 1048903801, 1048903809, 1048903813, 1048903820,
  1048903824, 1048903843, 1048903854, 1048903855, 1048903869, 1048903874, 1048903886, 1048903904, 1048903915, 1048903927, 1048903937, 1048903940,
  1048903945, 1048903958, 1048903970, 1048903988, 1048903993, 1048904000, 1048904007, 1048904012, 1048904021, 1048904027, 1048904032, 1048904036,
  1048904058, 1048904064, 1048904073, 1048904078, 1048904083, 1048904089, 1048904097, 1048904112, 1048904120, 1048904129, 1048904136, 1048904142,
  1048904150, 1048904162, 1048904171, 1048904177, 1048904183, 1048904200, 1048904212, 1048904221, 1048904233, 1048904238, 1048904250, 1048904256,
  1048904269, 1048904277, 1048904285, 1048904293, 1048904302, 1048904310, 1048904316, 1048904324, 1048904325, 1048904331, 1048904335, 1048904340,
  1048904348, 1048904366, 1048904374, 1048904432, 1048904442, 1048904453, 1048904459, 1048904488, 1048904498, 1048904507, 1048904513, 1048904517,
  1048904526, 1048904535, 1048904542, 1048904546, 1048904562, 1048904571, 1048904578, 1048904589, 1048904593, 1048904596, 1048904600, 1048904612,
  1048904621, 1048904628, 1048904632, 1048904636, 1048904644, 1048904649, 1048904665, 1048904676, 1048904690, 1048904696, 1048904707, 1048904718,
  1048904725, 1048904730, 1048904737, 1048904747, 1048904751, 1048904757, 1048904769, 1048904780, 1048904795, 1048904804, 1048904817, 1048904822,
  1048904829, 1048904838, 1048904843, 1048904846, 1048904861, 1048904868, 1048904871, 1048904875, 1048904881, 1048904890, 1048904896, 1048904914,
  1048904927, 1048904934, 1048904939, 1048904952, 1048904954, 1048904958, 1048904964, 1048904969, 1048904980, 1048904984, 1048904992, 1048905004,
  1048905011, 1048905019, 1048905026, 1048905042, 1048905051, 1048905060, 1048905081, 1048905086, 1048905093, 1048905099, 1048905104, 1048905111,
  1048905121, 1048905129, 1048905138, 1048905149, 1048905159, 1048905176, 1048905183, 1048905188, 1048905192, 1048905197, 1048905214, 1048905219,
  1048905223, 1048905228, 1048905236, 1048905246, 1048905252, 1048905259, 1048905265, 1048905272, 1048905277, 1048905294, 1048905301, 1048905309,
  1048905312, 1048905319, 1048905324, 1048905334, 1048905343, 1048905349, 1048905356, 1048905364, 1048905372, 1048905377, 1048905390, 1048905394,
  1048905399, 1048905416, 1048905420, 1048905425, 1048905431, 1048905440, 1048905450, 1048905457, 1048905461, 1048905463, 1048905470, 1048905488,
  1048905497, 1048905498, 1048905509, 1048905513, 1048905528, 1048905537, 1048905550, 1048905559, 1048905565, 1048905575, 1048905590, 1048905595,
  1048905600, 1048905607, 1048905616, 1048905619, 1048905631, 1048905635, 1048905640, 1048905657, 1048905663, 1048905673, 1048905681, 1048905685,
  1048905687, 1048905694, 1048905736, 1048905746, 1048905751, 1048905760, 1048905768, 1048905778, 1048905789, 1048905795, 1048905802, 1048905818,
  1048905827, 1048905837, 1048905844, 1048905850, 1048905860, 1048905864, 1048905868, 1048905873, 1048905878, 1048905886, 1048905893, 1048905905,
  1048905911, 1048905927, 1048905944, 1048905957, 1048905963, 1048905973, 1048905983, 1048905993, 1048906002, 1048906009, 1048906014, 1048906026,
  1048906035, 1048906041, 1048906047, 1048906052, 1048906057, 1048906079, 1048906114, 1048906125, 1048906131, 1048906139, 1048906147, 1048906159,
  1048906165, 1048906168, 1048906175, 1048906181, 1048906186, 1048906196, 1048906201, 1048906207, 1048906223, 1048906225, 1048906238, 1048906241,
  1048906247, 1048906249, 1048906252, 1048906263, 1048906265, 1048906273, 1048906276, 1048906289, 1048906298, 1048906308, 1048906314, 1048906327,
  1048906333, 1048906342, 1048906353, 1048906358, 1048906367, 1048906378, 1048906386, 1048906398, 1048906402, 1048906410, 1048906416, 1048906422,
  1048906429, 1048906442, 1048906504, 1048906518, 1048906528, 1048906539, 1048906546, 1048906553, 1048906559, 1048906564, 1048906587, 1048906595,
  1048906599, 1048906605, 1048906610, 1048906619, 1048906630, 1048906635, 1048906643, 1048906656, 1048906664, 1048907045, 1048907061, 1048907070,
  1048907074, 1048907080, 1048907086, 1048907089, 1048907096, 1048907101, 1048907103, 1048907108, 1048907118, 1048907123, 1048907125, 1048907141,
  1048907149, 1048907160, 1048907168, 1048907176, 1048907181, 1048907184, 1048907197, 1048907204, 1048907211, 1048907220, 1048907226, 1048907235,
  1048907246, 1048907351, 1048907358, 1048907365, 1048907391, 1048907395, 1048907409, 1048907420, 1048907436, 1048907452, 1048907475, 1048907486,
  1048907494, 1048907504, 1048907510, 1048907531, 1048907567, 1048907571, 1048907590, 1048907607, 1048907629, 1048907631, 1048907640, 1048907661,
  1048907677, 1048907691, 1048907701, 1048907703, 1048907723, 1048907731, 1048907735, 1048907750, 1048907761, 1048907768, 1048907772, 1048907782,
  1048907788, 1048907792, 1048907797, 1048907803, 1048907807, 1048907810, 1048907819, 1048907855, 1048907861, 1048907866, 1048907870, 1048907886,
  1048907892, 1048907899, 1048907910, 1048907913, 1048907918, 1048907927, 1048907931, 1048907934, 1048907944, 1048907965, 1048907981, 1048907984,
  1048907993, 1048908000, 1048908019, 1048908027, 1048908035, 1048908043, 1048908051, 1048908056, 1048908062, 1048908076, 1048908086, 1048908095,
  1048908104, 1048908109, 1048908132, 1048908141, 1048908154, 1048908163, 1048908171, 1048908186, 1048908197, 1048908205, 1048908212, 1048908223,
  1048908231, 1048908235, 1048908245, 1048908251, 1048908256, 1048908274, 1048908281, 1048908289, 1048908291, 1048908302, 1048908313, 1048908326,
  1048908347, 1048908354, 1048908361, 1048908371, 1048908382, 1048908393, 1048908406, 1048908418, 1048908431, 1048908436, 1048908455, 1048908461,
  1048908469, 1048908473, 1048908479, 1048908490, 1048908500, 1048908517, 1048908523, 1048908530, 1048908546, 1048908557, 1048908560, 1048908566,
  1048908569, 1048908574, 1048908582, 1048908627, 1048908635, 1048908655, 1048908664, 1048908669, 1048908682, 1048908697, 1048908704, 1048908725,
  1048908738, 1048908751, 1048908757, 1048908766, 1048908785, 1048908792, 1048908805, 1048908813, 1048908821, 1048908828, 1048908831, 1048908838,
  1048908856, 1048908872, 1048908881, 1048908888, 1048908898, 1048908907, 1048908918, 1048908925, 1048908935, 1048908944, 1048908951, 1048908966,
  1048908972, 1048908980, 1048908997, 1048909016, 1048909027, 1048909035, 1048909042, 1048909046, 1048909051, 1048909059, 1048909069, 1048909084,
  1048909097, 1048909114, 1048909127, 1048909132, 1048909163, 1048909173, 1048909184, 1048909190, 1048909197, 1048909216, 1048909228, 1048909237,
  1048909244, 1048909248, 1048909255, 1048909261, 1048909265, 1048909274, 1048909295, 1048909305, 1048909316, 1048909321, 1048909328, 1048909335,
  1048909344, 1048909354, 1048909357, 1048909363, 1048909374, 1048909382, 1048909390, 1048909397, 1048909412, 1048909421, 1048909428, 1048909445,
  1048909451, 1048909454, 1048909468, 1048909475, 1048909479, 1048909483, 1048909490, 1048909504, 1048909519, 1048909526, 1048909533, 1048909555,
  1048909568, 1048909575, 1048909601, 1048909621, 1048909627, 1048909631, 1048909640, 1048909643, 1048909648, 1048909661, 1048909682, 1048909686,
  1048909694, 1048909712, 1048909721, 1048909731, 1048909736, 1048909740, 1048909746, 1048909751, 1048909757, 1048909768, 1048909779, 1048909786,
  1048909793, 1048909795, 1048909804, 1048909807, 1048909816, 1048909830, 1048909836, 1048909859, 1048909872, 1048909881, 1048909891, 1048909905,
  1048909916, 1048909923, 1048909928, 1048909938, 1048909951, 1048909957, 1048909961, 1048909966, 1048909992, 1048909999, 1048910013, 1048910023,
  1048910041, 1048910047, 1048910052, 1048910070, 1048910078, 1048910085, 1048910096, 1048910116, 1048910123, 1048910129, 1048910143, 1048910165,
  1048910178, 1048910196, 1048910208, 1048910219, 1048910231, 1048910242, 1048910251, 1048910260, 1048910270, 1048910278, 1048910283, 1048910302,
  1048910310, 1048910318, 1048910327, 1048910335, 1048910340, 1048910346, 1048910354, 1048910362, 1048910368, 1048910374, 1048910383, 1048910394,
  1048910402, 1048910409, 1048910429, 1048910442, 1048910450, 1048910464, 1048910469, 1048910473, 1048910479, 1048910487, 1048910494, 1048910499,
  1048910507, 1048910520, 1048910530, 1048910536, 1048910544, 1048910562, 1048910576, 1048910588, 1048910595, 1048910606, 1048910615, 1048910620,
  1048910627, 1048910632, 1048910643, 1048910653, 1048910663, 1048910675, 1048910689, 1048910708, 1048910717, 1048910722, 1048910729, 1048910738,
  1048910759, 1048910766, 1048910782, 1048910789, 1048910794, 1048910804, 1048910811, 1048910822, 1048910836, 1048910844, 1048910851, 1048910858,
  1048910863, 1048910869, 1048910881, 1048910890, 1048910894, 1048910904, 1048910912, 1048910923, 1048910926, 1048910934, 1048910948, 1048910960,
  1048910968, 1048910974, 1048910987, 1048910991, 1048911003, 1048911016, 1048911022, 1048911066, 1048911072, 1048911078, 1048911083, 1048911100,
  1048911109, 1048911123, 1048911124, 1048911145, 1048911147, 1048911151, 1048911156, 1048911162, 1048911180, 1048911187, 1048911189, 1048911195,
  1048911196, 1048911202, 1048911208, 1048911212, 1048911214, 1048911230, 1048911244, 1048911249, 1048911255, 1048911268, 1048911278, 1048911282,
  1048911293, 1048911301, 1048911309, 1048911312, 1048911319, 1048911347, 1048911351, 1048911354, 1048911370, 1048911377, 1048911385, 1048911392,
  1048911397, 1048911423, 1048911435, 1048911440, 1048911448, 1048911456, 1048911464, 1048911465, 1048911473, 1048911539, 1048911551, 1048911557,
  1048911592, 1048911606, 1048911616, 1048911626, 1048911635, 1048911645, 1048911655, 1048911664, 1048911674, 1048911684, 1048911697, 1048911709,
  1048911713, 1048911720, 1048911725, 1048911743, 1048911746, 1048911753, 1048911776, 1048911787, 1048911791, 1048911795, 1048911802, 1048911807,
  1048911816, 1048911822, 1048911829, 1048911849, 1048911856, 1048911862, 1048911870, 1048911892, 1048911921, 1048911930, 1048911941, 1048911952,
  1048911962, 1048911967, 1048911973, 1048911982, 1048911989, 1048911997, 1048912001, 1048912008, 1048912012, 1048912024, 1048912028, 1048912041,
  1048912046, 1048912066, 1048912072, 1048912079, 1048912084, 1048912089, 1048912093, 1048912098, 1048912105, 1048912109, 1048912117, 1048912121,
  1048912127, 1048912141, 1048912142, 1048912158, 1048912163, 1048912174, 1048912177, 1048912185, 1048912191, 1048912199, 1048912209, 1048912218,
  1048912230, 1048912236, 1048912240, 1048912248,
];

export default ASSETS;
