import React from 'react';

const IconMindBlown = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='w-8 h-8'>
      <path
        opacity='.6'
        d='M481.4 265.2L512 253.5c0 .8 0 1.7 0 2.5c0 141.4-114.6 256-256 256S0 397.4 0 256c0-.8 0-1.7 0-2.5l30.5 11.7c14.1 5.4 29.9 3.9 42.7-4.1l20.7-12.9c5.2-3.2 11.8-3.2 17 0L128.2 259c15.6 9.7 35.3 9.7 50.9 0l17.3-10.8c5.2-3.2 11.8-3.2 17 0L230.6 259c15.6 9.7 35.3 9.7 50.9 0l17.3-10.8c5.2-3.2 11.8-3.2 17 0L333 259c15.6 9.7 35.3 9.7 50.9 0l17.3-10.8c5.2-3.2 11.8-3.2 17 0l20.7 12.9c12.8 8 28.6 9.5 42.7 4.1zM176.4 368a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm192-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-123 48c-29.5 0-53.3 23.9-53.3 53.3c0 5.9 4.8 10.7 10.7 10.7H309.3c5.9 0 10.7-4.8 10.7-10.7c0-29.5-23.9-53.3-53.3-53.3H245.3z'
        fill='currentColor'
      />
      <path
        d='M320 197.3c0 7.3 2.1 14.3 5.9 20.2c-14.3-6.1-30.8-4.9-44.2 3.5l-17.3 10.8c-5.2 3.2-11.8 3.2-17 0L230.2 221c-13.4-8.4-29.9-9.5-44.2-3.5c3.8-6 5.9-13 5.9-20.2V192c0-17.7-14.3-32-32-32H120c-30.9 0-56-25.1-56-56s25.1-56 56-56c8.9 0 17.3 2.1 24.8 5.8C149.7 23.3 176.1 0 208 0c19.1 0 36.3 8.4 48 21.7C267.7 8.4 284.9 0 304 0c31.9 0 58.3 23.3 63.2 53.8c7.5-3.7 15.9-5.8 24.8-5.8c30.9 0 56 25.1 56 56s-25.1 56-56 56H352c-17.7 0-32 14.3-32 32v5.3zM144.4 336a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm160 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconMindBlown;
