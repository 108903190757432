const assets = [
  926593442, 926593444, 926593446, 926593448, 926593450, 926593452, 926593454, 926593456, 926593458, 926593460, 926593462, 926593464, 926593466,
  926593468, 926593471, 926593473, 926593475, 926593477, 926593479, 926593481, 926593483, 926593485, 926593487, 926593489, 926593491, 926593493,
  926593495, 926593497, 926593500, 926593502, 926593504, 926593506, 926593508, 926593510, 926593512, 926593515, 926593517, 926593519, 926593521,
  926593523, 926593525, 926593527, 926593529, 926593531, 926593533, 926593535, 926593537, 926593539, 926593541, 926593543, 926593612, 926593614,
  926593616, 926593618, 926593620, 926593622, 926593624, 926593628, 926593630, 926593632, 926593634, 926593636, 926593638, 926593640, 926593644,
  926593646, 926593648, 926593650, 926593652, 926593654, 926593656, 926593659, 926593661, 926593663, 926593665, 926593667, 926593669, 926593671,
  926593675, 926593677, 926593679, 926593681, 926593683, 926593685, 926593687, 926593689, 926593691, 926593693, 926593695, 926593697, 926593699,
  926593701, 926593704, 926593706, 926593708, 926593710, 926593712, 926593714, 926593716, 926593718, 926593774, 926593776, 926593778, 926593780,
  926593782, 926593784, 926593786, 926593790, 926593792, 926593794, 926593796, 926593798, 926593800, 926593802, 926593805, 926593807, 926593809,
  926593811, 926593813, 926593815, 926593817, 926593821, 926593823, 926593825, 926593827, 926593829, 926593831, 926593833, 926593837, 926593839,
  926593841, 926593843, 926593845, 926593847, 926593849, 926593852, 926593854, 926593856, 926593858, 926593860, 926593862, 926593864, 926593869,
  926593871, 926593873, 926593875, 926593877, 926593879, 926593881, 926593883, 926593950, 926593952, 926593954, 926593956, 926593958, 926593960,
  926593962, 926593965, 926593967, 926593969, 926593971, 926593973, 926593975, 926593977, 926593983, 926593985, 926593987, 926593989, 926593991,
  926593993, 926593995, 926593998, 926594000, 926594002, 926594004, 926594006, 926594008, 926594010, 926594012, 926594014, 926594016, 926594018,
  926594020, 926594022, 926594024, 926594027, 926594029, 926594031, 926594033, 926594035, 926594037, 926594039, 926594044, 926594046, 926594048,
  926594050, 926594052, 926594054, 926594056, 926594058, 926594102, 926594104, 926594106, 926594108, 926594110, 926594112, 926594114, 926594128,
  926594130, 926594132, 926594134, 926594136, 926594138, 926594140, 926594142, 926594144, 926594146, 926594148, 926594150, 926594152, 926594154,
  926594157, 926594159, 926594161, 926594163, 926594165, 926594167, 926594169, 926594173, 926594175, 926594177, 926594179, 926594181, 926594183,
  926594185, 926594187, 926594189, 926594191, 926594193, 926594195, 926594197, 926594199, 926594202, 926594204, 926594206, 926594208, 926594210,
  926594212, 926594214, 926594217, 926594274, 926594276, 926594278, 926594280, 926594282, 926594284, 926594286, 926594291, 926594293, 926594295,
  926594297, 926594299, 926594301, 926594303, 926594306, 926594308, 926594310, 926594312, 926594314, 926594316, 926594318, 926594321, 926594323,
  926594325, 926594327, 926594329, 926594331, 926594333, 926594337, 926594339, 926594341, 926594343, 926594345, 926594347, 926594349, 926594353,
  926594355, 926594357, 926594359, 926594361, 926594363, 926594365, 926594370, 926594372, 926594374, 926594376, 926594378, 926594380, 926594382,
  926594384, 926594451, 926594453, 926594455, 926594457, 926594459, 926594461, 926594463, 926594467, 926594469, 926594471, 926594473, 926594475,
  926594477, 926594479, 926594483, 926594485, 926594487, 926594489, 926594491, 926594493, 926594495, 926594500, 926594502, 926594504, 926594506,
  926594508, 926594510, 926594512, 926594515, 926594517, 926594519, 926594521, 926594523, 926594525, 926594527, 926594535, 926594537, 926594539,
  926594541, 926594543, 926594545, 926594547, 926594551, 926594553, 926594555, 926594557, 926594559, 926594561, 926594563, 926594566, 926594612,
  926594614, 926594616, 926594618, 926594620, 926594622, 926594624, 926594632, 926594634, 926594636, 926594638, 926594640, 926594642, 926594644,
  926594650, 926594652, 926594654, 926594656, 926594658, 926594660, 926594662, 926594667, 926594669, 926594671, 926594673, 926594675, 926594677,
  926594679, 926594683, 926594685, 926594687, 926594689, 926594691, 926594693, 926594695, 926594698, 926594700, 926594702, 926594704, 926594706,
  926594708, 926594710, 926594713, 926594715, 926594717, 926594719, 926594721, 926594723, 926594725, 926594728, 926594802, 926594804, 926594806,
  926594808, 926594810, 926594812, 926594814, 926594817, 926594819, 926594821, 926594823, 926594825, 926594827, 926594829, 926594833, 926594835,
  926594837, 926594839, 926594841, 926594843, 926594845, 926594851, 926594853, 926594855, 926594857, 926594859, 926594861, 926594863, 926594869,
  926594871, 926594873, 926594875, 926594877, 926594879, 926594881, 926594886, 926594888, 926594890, 926594892, 926594894, 926594896, 926594898,
  926594904, 926594906, 926594908, 926594910, 926594912, 926594914, 926594916, 926594919, 926594979, 926594981, 926594983, 926594985, 926594987,
  926594989, 926594991, 926595003, 926595005, 926595007, 926595009, 926595011, 926595013, 926595015, 926595018, 926595020, 926595022, 926595024,
  926595026, 926595028, 926595030, 926595034, 926595036, 926595038, 926595040, 926595042, 926595044, 926595046, 926595052, 926595054, 926595056,
  926595058, 926595060, 926595062, 926595064, 926595075, 926595077, 926595079, 926595081, 926595083, 926595085, 926595087, 926595091, 926595093,
  926595095, 926595097, 926595099, 926595101, 926595103, 926595106, 926595178, 926595180, 926595182, 926595184, 926595186, 926595188, 926595190,
  926595200, 926595202, 926595204, 926595206, 926595208, 926595210, 926595212, 926595218, 926595220, 926595222, 926595224, 926595226, 926595228,
  926595230, 926595237, 926595239, 926595241, 926595243, 926595245, 926595247, 926595249, 926595261, 926595263, 926595265, 926595267, 926595269,
  926595271, 926595273, 926595284, 926595286, 926595288, 926595290, 926595292, 926595294, 926595296, 926595303, 926595305, 926595307, 926595309,
  926595311, 926595313, 926595315, 926595319, 926595408, 926595410, 926595412, 926595414, 926595416, 926595418, 926595420, 926595429, 926595431,
  926595433, 926595435, 926595437, 926595439, 926595441, 926595446, 926595448, 926595450, 926595452, 926595454, 948278980, 996214254, 1113811615,
  1114407003, 1117266016, 1135602318,
];

export default assets;
