const assets = [
  1201491885, 1201491887, 1201491889, 1201491891, 1201491893, 1201503272, 1201503278, 1201503281, 1201503290, 1201503292, 1201503295, 1201503297,
  1201503299, 1201503301, 1201503306, 1201503308, 1201503310, 1201503312, 1201503314, 1201503316, 1201503318, 1201503320, 1201503322, 1201503324,
  1201503326, 1201503328, 1201503330, 1201503332, 1201503334, 1201503336, 1201503338, 1201503341, 1201503344, 1201503346, 1201503348, 1201503350,
  1201503352, 1201503354, 1201503356, 1201503358, 1201503360, 1201503362, 1201503364, 1201503366, 1201503369, 1201503371, 1201503374, 1201503376,
  1201503378, 1201503380, 1201570373, 1201570375, 1201570377, 1201570379, 1201570381, 1201570383, 1201570385, 1201570387, 1201570389, 1201570391,
  1201570393, 1201570396, 1201570398, 1201570400, 1201570402, 1201570404, 1201570407, 1201570409, 1201570411, 1201570413, 1201570415, 1201570417,
  1201570419, 1201570421, 1201570423, 1201570425, 1201570427, 1201570429, 1201570431, 1201570433, 1201570435, 1201570437, 1201570439, 1201570441,
  1201570443, 1201570445, 1201570447, 1201570454, 1201570456, 1201570458, 1201570460, 1201570462, 1201570464, 1201570466, 1201570468, 1201570471,
  1201570473, 1201570475, 1201570478, 1201570480, 1201570482, 1201570484, 1201570486, 1201570488, 1201570490, 1201570493, 1201570496, 1201570507,
  1201570509, 1201570511, 1201570515, 1201570517, 1201570519, 1201570521, 1201570526, 1201570528, 1201570530, 1201570533, 1201570535, 1201570537,
  1201570540, 1201570542, 1201570544, 1201570546, 1201570548, 1201570550, 1201570552, 1201570554, 1201570557, 1201570559, 1201570563, 1201570565,
  1201570567, 1201570569, 1201570571, 1201570573, 1201570576, 1201570578, 1201570584, 1201570586, 1201570588, 1201570591, 1201570593, 1201570595,
  1201570598, 1201570601, 1201570604, 1201570606, 1201570608, 1201570610, 1201570612, 1201570614, 1201570616, 1201570618, 1201570621, 1201570623,
  1201570625, 1201570627, 1201570629, 1201570631, 1201570633, 1201570635, 1201570639, 1201570641, 1201570643, 1201570645, 1201570647, 1201570649,
  1201570656, 1201570658, 1201570660, 1201570662, 1201570665, 1201570667, 1201570669, 1201570671, 1201570675, 1201570677, 1201570679, 1201570682,
  1201570685, 1201570688, 1201570690, 1201570692, 1201570694, 1201570702, 1201570704, 1201570706, 1201570708, 1201570710, 1201570712, 1201570714,
  1201570717, 1201570719, 1201570721, 1201570723, 1201570725, 1201570728, 1201570730, 1201570732, 1201570734, 1201570736, 1201570738, 1201570740,
  1201570742, 1201570744, 1201570746, 1201570748, 1201570750, 1201570752, 1201570754, 1201570756, 1201570759, 1201570761, 1201570763, 1201570765,
  1201570767, 1201570769, 1201570772, 1201570774, 1201570776, 1201570778, 1201570783, 1201570785, 1201570787, 1201570789, 1201570791, 1201570793,
  1201570795, 1201570798, 1201570800, 1201570802, 1201570804, 1201570806, 1201570808, 1201570810, 1201570812, 1201570814, 1201570816, 1201570818,
  1201570820, 1201570823, 1201570825, 1201570827, 1201570829, 1201570831, 1201570834, 1201570836, 1201570838, 1201570840, 1201570843, 1201570845,
  1201570847, 1201570849, 1201570851, 1201570853, 1201570855, 1201570857, 1201570859, 1201570861, 1201570863, 1201570865, 1201570868, 1201570870,
  1201570872, 1201570874, 1201570876, 1201570880, 1201570882, 1201570884, 1201570886, 1201570888, 1201570890, 1201570892, 1201570895, 1201570899,
  1201570901, 1201570903, 1201570912, 1201570915, 1201570917, 1201570919, 1201570921, 1201570923, 1201570925, 1201570928, 1201570930, 1201570932,
  1201570934, 1201570936, 1201570938, 1201570941, 1201570943, 1201570945, 1201570947, 1201570949, 1201570951, 1201570953, 1201570960, 1201570962,
  1201570964, 1201570966, 1201570968, 1201570970, 1201570975, 1201570977, 1201570979, 1201570981, 1201570983, 1201570985, 1201570987, 1201570989,
  1201570991, 1201570993, 1201570995, 1201570997, 1201571000, 1201571002, 1201571004, 1201571006, 1201571008, 1201571011, 1201571015, 1201571017,
  1201571019, 1201571021, 1201571024, 1201571026, 1201571028, 1201571031, 1201571033, 1201571035, 1201571037, 1201571039, 1201571044, 1201571046,
  1201571048, 1201571050, 1201571052, 1201571054, 1201571057, 1201571059, 1201571061, 1201571063, 1201571066, 1201571068, 1201571070, 1201571072,
  1201571074, 1201571076, 1201571078, 1201571080, 1201571082, 1201571084, 1201571088, 1201571090, 1201571094, 1201571096, 1201571098, 1201571100,
  1201571102, 1201571104, 1201571116, 1201571118, 1201571121, 1201571123, 1201571125, 1201571127, 1201571129, 1201571131, 1201571133, 1201571135,
  1201571142, 1201571144, 1201571146, 1201571148, 1201571150, 1201571152, 1201571154, 1201571156, 1201571158, 1201571160, 1201571162, 1201571164,
  1201571167, 1201571169, 1201571171, 1201571173, 1201571175, 1201571178, 1201571180, 1201571182, 1201571184, 1201571186, 1201571188, 1201571191,
  1201571193, 1201571195, 1201571197, 1201571199, 1201571201, 1201571203, 1201571205, 1201571207, 1201571209, 1201571211, 1201571213, 1201571215,
  1201571217, 1201571220, 1201571222, 1201571224, 1201571226, 1201571228, 1201571231, 1201571233, 1201571235, 1201571237, 1201571239, 1201571241,
  1201571243, 1201571245, 1201571247, 1201571251, 1201571257, 1201571259, 1201571261, 1201571263, 1201571265, 1201571267, 1201571271, 1201571273,
  1201571275, 1201571277, 1201571280, 1201571282, 1201571286, 1201571288, 1201571293, 1201571295, 1201571297, 1201571299, 1201571303, 1201571305,
  1201571307, 1201571309, 1201571311, 1201571313, 1201571315, 1201571317, 1201571320, 1201571322, 1201571324, 1201571326, 1201571328, 1201571332,
  1201571334, 1201571336, 1201571338, 1201571340, 1201571342, 1201571344, 1201571346, 1201571349, 1201571351, 1201571353, 1201571355, 1201571358,
  1201571360, 1201571362, 1201571369, 1201571373, 1201571375, 1201571377, 1201571379, 1201571381, 1201571383, 1201571385, 1201571387, 1201571389,
  1201571391, 1201571393, 1201571395, 1201571397, 1201571399, 1201571401, 1201571403, 1201571406, 1201571408, 1201571410, 1201571413, 1201571415,
  1201571417, 1201571419, 1201571422, 1201571424, 1201571426, 1201571431, 1201571433, 1201571435, 1201571437, 1201571439, 1201571441, 1201571443,
  1201571445, 1201571447, 1201571449, 1201571452, 1201571454, 1201571456, 1201571458, 1201571460, 1201571463, 1201571465, 1201571467, 1201571472,
  1201571474, 1201571476, 1201571478, 1201571480, 1201571482, 1201571484, 1201571486, 1201571488, 1201571490, 1201571492, 1201571494, 1201571496,
  1201571498, 1201571500, 1201571502, 1201571504, 1201571506, 1201571508, 1201571511, 1201571513, 1201571516, 1201571518, 1201571520, 1201571522,
  1201571524, 1201571526, 1201571528, 1201571531, 1201571533, 1201571535, 1201571537, 1201571539, 1201571541, 1201571543, 1201571548, 1201571552,
  1201571554, 1201571556, 1201571559, 1201571561, 1201571563, 1201571565, 1201571567, 1201571569, 1201571571, 1201571574, 1201571576, 1201571578,
  1201571585, 1201571587, 1201571589, 1201571591, 1201571593, 1201571596, 1201571598, 1201571601, 1201571603, 1201571606, 1201571608, 1201571611,
  1201571613, 1201571615, 1201571617, 1201571619, 1201571622, 1201571624, 1201571626, 1201571628, 1201571630, 1201571633, 1201571635, 1201571637,
  1201571640, 1201571645, 1201571650, 1201571655, 1201571660, 1201571665, 1201571669, 1201571673, 1201571677, 1201571684, 1201571689, 1201571694,
  1201571698, 1201571702, 1201571707, 1201571711, 1201571717, 1201571721, 1201571726, 1201571730, 1201571734, 1201571753, 1201571755, 1201571758,
  1201571761, 1201571765, 1201571767, 1201571771, 1201571773, 1201571776, 1201571779, 1201571781, 1201571792, 1201571794, 1201571796, 1201571798,
  1201571800, 1201571802, 1201571804, 1201571806, 1201571808, 1201571810, 1201571812, 1201571814, 1201571816, 1201571820, 1201571823, 1201571825,
  1201571827, 1201571829, 1201571831, 1201571833, 1201571835, 1201571837, 1201571839, 1201571841, 1201571843, 1201571845, 1201571849, 1201571852,
  1201571856, 1201571858, 1201571860, 1201571862, 1201571864, 1201571866, 1201571871, 1201571873, 1201571875, 1201571881, 1201571884, 1201571887,
  1201571894, 1201571896, 1201571898, 1201571900, 1201571902, 1201571905, 1201571907, 1201571910, 1201571912, 1201571914, 1201571916, 1201571918,
  1201571920, 1201571922, 1201571924, 1201571927, 1201571929, 1201571931, 1201571933, 1201571935, 1201571939, 1201571941, 1201571944, 1201571949,
  1201571952, 1201571955, 1201571958, 1201571961, 1201571964, 1201571968, 1201571973, 1201571977, 1201571981, 1201571987, 1201571990, 1201571994,
  1201572000, 1201572005, 1201572012, 1201572021, 1201572029, 1201572034, 1201572041, 1201572046, 1201572050, 1201572055, 1201572059, 1201572062,
  1201572065, 1201572068, 1201572071, 1201572073, 1201572075, 1201572078, 1201572081, 1201572083, 1201572086, 1201572088, 1201572092, 1201572095,
  1201572099, 1201572102, 1201572105, 1201572107, 1201572109, 1201572112, 1201572114, 1201572117, 1201572119, 1201572122, 1201572125, 1201572127,
  1201572130, 1201572132, 1201572135, 1201572138, 1201572141, 1201572144, 1201572146, 1201572148, 1201572150, 1201572154, 1201572157, 1201572164,
  1201572169, 1201572171, 1201572173, 1201572175, 1201572177, 1201572179, 1201572183, 1201572185, 1201572195, 1201572198, 1201572200, 1201572202,
  1201572204, 1201572206, 1201572208, 1201572210, 1201572212, 1201572214, 1201572217, 1201572219, 1201572221, 1201572223, 1201572225, 1201572228,
  1201572231, 1201572234, 1201572238, 1201572241, 1201572243, 1201572245, 1201572248, 1201572250, 1201572254, 1201572257, 1201572260, 1201572262,
  1201572264, 1201572266, 1201572269, 1201572271, 1201572273, 1201572275, 1201572277, 1201572279, 1201572281, 1201572284, 1201572286, 1201572289,
  1201572291, 1201572293, 1201572296, 1201572298, 1201572300, 1201572304, 1201572306, 1201572308, 1201572310, 1201572314, 1201572317, 1201572319,
  1201572321, 1201572330, 1201572332, 1201572335, 1201572337, 1201572340, 1201572343, 1201572346, 1201572348, 1201572350, 1201572352, 1201572355,
  1201572358, 1201572360, 1201572362, 1201572364, 1201572366, 1201572369, 1201572371, 1201572373, 1201572375, 1201572377, 1201572379, 1201572382,
  1201572384, 1201572386, 1201572388, 1201572390, 1201572394, 1201572396, 1201572400, 1201572403, 1201572406, 1201572408, 1201572411, 1201572413,
  1201572416, 1201572419, 1201572424, 1201572427, 1201572430, 1201572432, 1201572434, 1201572436, 1201572443, 1201572445, 1201572447, 1201572450,
  1201572452, 1201572456, 1201572458, 1201572460, 1201572462, 1201572464, 1201572466, 1201572470, 1201572473, 1201572475, 1201572477, 1201572480,
  1201572482, 1201572485, 1201572487, 1201572491, 1201572493, 1201572495, 1201572497, 1201572499, 1201572501, 1201572503, 1201572514, 1201572516,
  1201572519, 1201572521, 1201572524, 1201572528, 1201572533, 1201572540, 1201572542, 1201572544, 1201572546, 1201572548, 1201572550, 1201572553,
  1201572555, 1201572558, 1201572560, 1201572562, 1201572564, 1201572567, 1201572571, 1201572574, 1201572577, 1201572580, 1201572584, 1201572586,
  1201572588, 1201572590, 1201572592, 1201572595, 1201572597, 1201572599, 1201572601, 1201572603, 1201572608, 1201572612, 1201572614, 1201572616,
  1201572619, 1201572622, 1201572626, 1201572628, 1201572632, 1201572637, 1201572641, 1201572646, 1201572648, 1201572669, 1201572673, 1201572680,
  1201572682, 1201572684, 1201572687, 1201572689, 1201572692, 1201572695, 1201572698, 1201572702, 1201572705, 1201572713, 1201572720, 1201572726,
  1201572731, 1201572735, 1201572742, 1201572744, 1201572748, 1201572755, 1201572760, 1201572764, 1201572766, 1201572772, 1201572775, 1201572777,
  1201572784, 1201572794, 1201572797, 1201572799, 1201572803, 1201572810, 1201572815, 1201572818, 1201572824, 1201572827, 1201572833, 1201572839,
  1201572843, 1201572850, 1201572856, 1201572860, 1201572871, 1201572877, 1201572889, 1201572896, 1201572904, 1201572911, 1201572918, 1201572926,
  1201572937, 1201572951, 1201572959, 1201572967, 1201572978, 1201572987, 1201572999, 1201573010, 1201573017, 1201573028, 1201573035, 1201573043,
  1201573052, 1201573062, 1201573074, 1201573085, 1201573093, 1201573108, 1201573117, 1201573135, 1201573153, 1201573163, 1201573182, 1201573195,
  1201573202, 1201573204, 1201573206, 1201573208,
];

export default assets;
