const ASSETS = [
  381590141, 381591638, 381593013, 381594882, 381596781, 381598326, 381599614, 381600800, 381601935, 381603008, 381604601, 381605726, 381606846,
  381608161, 381609582, 381610780, 381611751, 381612909, 381614104, 381615099, 381616500, 381618019, 381619308, 381620621, 381621682, 381622890,
  381623942, 381624974, 381626356, 381627419, 384107351, 384109528, 384110512, 390388134, 390388883, 390390854, 390391910, 390392899, 390393525,
  390394239, 390395142, 390395767, 390396785, 390397177, 390397706, 390399477, 390399853, 390400336, 390400831, 390402286, 390402909, 390403414,
  390403875, 390404319, 390404983, 390405796, 390406084, 390406731, 390407689, 390408211, 390408714, 390409506, 390410009, 390411195, 390411569,
  390412369, 390412847, 390413215, 390413536, 390414596, 390415153, 390415615, 390416161, 390416532, 390416909, 390417348, 390417664, 390417983,
  390418557, 390418912, 390419364, 390419914, 390420377, 390420867, 390421194, 390421606, 390422432, 390422886, 390423987, 390425212, 390425570,
  390426069, 390426601, 391622188, 405902653, 406892235, 406893025, 406893789, 406894869, 406897148, 406898010, 406899064, 406900571, 406902432,
  406905143, 406906082, 406911215, 406911984, 406912616, 406913260, 406913770, 406914212, 406914596, 406915197, 406915581, 406916165, 406917056,
  406917825, 406918340, 406918738, 406919396, 406924764, 406925422, 406926143, 406926640, 406927619, 406928107, 406928632, 407043596, 407044222,
  407045012, 407045478, 407045980, 407046439, 407046983, 407047409, 407047823, 407048260, 407048845, 407049212, 407049695, 407050158, 407050579,
  407051027, 407051388, 407051977, 407052743, 407053381, 407054137, 407055065, 407056041, 407056728, 407057512, 407058247, 407059222, 415317410,
  416910623, 416911655, 416912805, 416913964, 416914998, 416915727, 416918230, 416919158, 416920119, 416920988, 416921656, 416929086, 416931899,
  416933120, 416933728, 416948324, 416956858, 416959030, 416959770, 416960719, 416961473, 416964286, 416964968, 416965467, 416966496, 416967409,
  416968090, 416968772, 416969416, 416971422, 416972993, 416973593, 416974141, 416974725, 416975290, 416975964, 416976681, 416977405, 416978329,
  416979360, 416980486, 416981521, 416982813, 416983730, 416985654, 416986653, 416988340, 416989534, 416990629, 416993086, 416994520, 416995332,
  416996744, 416997878, 416998825, 416999475, 417000132, 417000754, 417001363, 417002146, 417002853, 417003398, 417004617, 417005107, 417006571,
  417007195, 417008030, 417186403, 417186846, 417187261, 417187682, 417188175, 417189593, 417190062, 417190576, 417191033, 417191444, 417192068,
  417192739, 417193339, 417193708, 417194081, 417194436, 417194803, 417195287, 417195751, 417196390, 417197081, 417198337, 417198861, 417199246,
  417199661, 417200337, 417200909, 417201623, 417202210, 417202828, 417203409, 417204078, 417206485, 417207272, 417208542, 417209213, 417209802,
  417210295, 417210823, 417211316, 417213239, 417213907, 426034417, 426035266, 426037384, 426037817, 426038293, 426038882, 426039601, 426040385,
  426040998, 426041874, 426042530, 426043229, 426043995, 426044718, 426045588, 426046295, 426046901, 426047531, 426048247, 426057477, 426060742,
  426062913, 426065129, 426066371, 426068075, 426069496, 426071375, 426072241, 426073386, 426074497, 426075468, 426077509, 426078266, 426079142,
  426079911, 426084875, 426085844, 426086612, 426087391, 426088268, 426089355, 426090473, 426096275, 426096849, 426097840, 426098519, 426098987,
  426099451, 438692732, 438693504, 438694128, 438694795, 438695419, 438696008, 438696450, 438697232, 438697874, 438698340, 438698730, 438699164,
  438699921, 438700638, 438701379, 438701767, 438702173, 438702654, 438703341, 438703757, 781796606, 781797778, 594830051, 594830657, 594831119,
  594831702, 594832101, 594832856, 594833539, 594833807, 594834181, 594834647, 595098351, 595098753, 595099430, 595099914, 595100211, 595100378,
  595100550, 595100772, 595101020, 595101178, 595101350, 595101709, 595101990, 595102221, 595102410, 595102601, 595102775, 595103059, 595103329,
  595103650, 595104053, 595104289, 595104584, 595104856, 595105116, 595105286, 595105531, 595105806, 595106136, 595106480, 595107248, 595107680,
  595107950, 595108221, 595108452, 595108735, 595109110, 595109545, 595109862, 595110300, 595110534, 595110804, 595111055, 595111342, 595111667,
  595112067, 595112425, 595112758, 595113075, 595113501, 595114240, 595114682, 595115151, 595115606, 595116005, 595116325, 595116735, 595117064,
  595117361, 595118088, 595118432, 595118815, 595119378, 595120055, 595120599, 595120895, 595121213, 595121455, 595121879, 595122306, 595122614,
  595122909, 595123272, 595123539, 595123761, 595124284, 595125315, 595127742, 595128082, 595128610, 595315787, 595316508, 595317046, 595317548,
  595318216, 595318935, 595319437, 595319973, 595320573, 595321208, 595321781, 595322445, 595323430, 595324605, 595325760, 595326275, 595327028,
  595327554, 595328281, 595328946, 595329889, 595330711, 595331655, 595332455, 595333382, 595342065, 595342767, 595343431, 595344075, 595344670,
  595345316, 596136324, 596150439, 596150710, 596151041, 596204367, 596205033, 596205516, 596205978, 596206664, 596215505, 596218097, 596219162,
  596241386, 596241980, 596243517, 596244169, 596244794, 596245586, 596246673, 596512180, 596513159, 596702869, 596703195, 596703543, 596703889,
  596704183, 596704558, 596704975, 596705308, 596823474, 596823883, 596824477, 596824987, 596825407, 596825846, 596826445, 596827014, 596827327,
  596827707, 596828041, 596828466, 596829370, 596990919, 596991533, 596992088, 596992986, 596993608, 596994339, 596994899, 596995463, 596996989,
  596997731, 596998230, 596998668, 596999117, 596999654, 597000234, 597000896, 597001500, 597002105, 597003002, 597003639, 597004344, 597005085,
  597006825, 597007344, 597007939, 597008489, 597009077, 597009664, 597010270, 597012015, 597012820, 597013419, 597014108, 597014765, 597015306,
  597015993, 597016775, 597017539, 597018584, 597019287, 597019830, 597020626, 597021379, 597022122, 597023075, 597023886, 597024989, 597025771,
  597026572, 597027327, 597028101, 597028881, 597029871, 597030479, 597031280, 597031987, 597032876, 597033697, 597034839, 597039292, 597040262,
  597041032, 597042806, 597043261, 597043838, 597045628, 597047440, 597051211, 597051926, 598019153, 598024710, 598028617, 598029597, 598030338,
  598038908, 598039364, 598039938, 598040805, 598042071, 598042719, 598043318, 598045136, 598051487, 598053957, 598056105, 598057635, 598058902,
  598059577, 598060247, 598060950, 598068258, 598068940, 598069672, 598070408, 598071151, 598071905, 598072773, 598283061, 598283690, 598284172,
  598284558, 598285042, 598285862, 598286769, 598288938, 598289335, 598289679, 598323461, 598323917, 598324372, 598428351, 598452869, 598453251,
  598453629, 598454770, 598455250, 598455688, 598579361, 598580328, 598587126, 598587853, 598626389, 598626735, 598629921, 598630704, 598631140,
  598631601, 598632294, 598632839, 598635196, 598635820, 598636554, 598637208, 598638107, 598639671, 598640517, 598775570, 598776186, 598776658,
  598777180, 598777670, 598778097, 598778498, 598779186, 598779928, 598780356, 598780720, 598781022, 598781606, 598782091, 598783505, 598943530,
  598944462, 598946487, 598947296, 598948754, 598950794, 598953469, 598955223, 598957841, 598958718, 598960162, 598961117, 598962014, 598963564,
  598965015, 598966240, 598967095, 598968923, 598969800, 598971153, 598972067, 598973251, 598974844, 598976668, 598977814, 598978826, 598979591,
  598980319, 598981764, 598982538, 598983473, 598985230, 598985907, 598986529, 598987300, 598988003, 598988723, 598989909, 598990717, 598991609,
  598992145, 598992688, 598994144, 598995366, 598997480, 598998358, 598999528, 599000427, 599001254, 599003556, 599004416, 599008865, 599009597,
  599011115, 599012512, 599014531, 599015624, 599016675, 599017943, 599019522, 599021223, 599022138, 599025793, 599028092, 599029203, 599031727,
  599033636, 599035180, 599035899, 599038088, 599040057, 599040825, 599041430, 599042026, 599043004, 599044153, 599045323, 599046232, 599047399,
  599048315, 599049060, 599049711, 599050165, 599050459, 599051082, 600129188, 600131593, 600132306, 600132866, 600133929, 600134814, 600135628,
  600136184, 600136908, 600137416, 600140521, 600141280, 600145932, 600344800, 600345202, 600345691, 600346164, 600346578, 600346949, 600347445,
  600378635, 600381145, 600381800, 600383055, 600383699, 600384212, 600384784, 600385247, 600385806, 600386243, 600386711, 600387158, 600387716,
  600388219, 600388821, 600389217, 600389767, 600390274, 600390782, 600391336, 600391811, 600393336, 600393912, 600394476, 600395190, 600396152,
  600396773, 600398049, 600398661, 600399132, 600399796, 600400331, 600400906, 600401647, 600402461, 600403047, 600403578, 600404096, 600404703,
  600405246, 600405849, 600406402, 600406952, 600407913, 600409753, 600410473, 600411044, 600411552, 600412216, 600412759, 600413387, 600414199,
  600414711, 600415225, 600415576, 600416253, 600416731, 600417249, 600417972, 600418512, 600419262, 600419952, 600420517, 600421441, 600422031,
  600424553, 600425141, 600425737, 600426272, 600426763, 628089615, 635361518, 677068294, 733612543,
];

export default ASSETS;
