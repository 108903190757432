const assets = [
  779361334, 779361437, 779361596, 779361705, 779361799, 779361896, 779362009, 779362098, 779362210, 779362314, 779362448, 779362564, 779362666,
  779362772, 779362857, 779362971, 779363059, 779363143, 779363244, 779363347, 779363467, 779363635, 779363834, 779363966, 779364096, 779364254,
  779364402, 779364597, 779364718, 779364894, 779365217, 779365381, 779365480, 779365721, 779365829, 779365962, 779366105, 779366262, 779366363,
  779366490, 779366627, 779366704, 779366848, 779367105, 779367257, 779367374, 779367484, 779367645, 779367950, 779368211, 779368383, 779368496,
  779368657, 779368783, 779369034, 779369188, 779369280, 779369374, 779369456, 779369541, 779369642, 779369729, 779369815, 779369918, 779369994,
  779370072, 779370174, 779370313, 779370428, 779370538, 779370642, 779370724, 779370813, 779370892, 779370971, 779371088, 779371171, 779371230,
  779371358, 779371452, 779371542, 779371624, 779371709, 779371835, 779371925, 779372057, 779372143, 779372229, 779372310, 779372453, 779372615,
  779372693, 779372779, 779372854, 779372941, 779373036, 779373110, 779373205, 779373288, 779373361, 779373462, 779373589, 779373663, 779373735,
  779373818, 779373901, 779374061, 779374166, 779374329, 779374418, 779374537, 779374618, 779374701, 779374801, 779374896, 779375061, 779375153,
  779375276, 779375377, 779375476, 779375582, 779375693, 779375851, 779375944, 779376050, 779376175, 779376279, 779376364, 779376461, 779376596,
  779376684, 779376772, 779376881, 779376999, 779377083, 779377177, 779377296, 779377383, 779377537, 779377629, 779377725, 779377828, 779377976,
  779378077, 779378231, 779378435, 779378573, 779378729, 779378912, 779379033, 779379175, 779379435, 779379532, 779379621, 779379704, 779379794,
  779379928, 779380020, 779380111, 779380183, 779380296, 779380375, 779380457, 779380548, 779380678, 779380783, 779380863, 779380953, 779381057,
  779381170, 779381282, 779381387, 779381645, 779381825, 779381944, 779382051, 779382145, 779382271, 779382373, 779382496, 779382603, 779382721,
  779382818, 779382906, 779382997, 779383051, 779383167, 779383290, 779383368, 779383502, 779384480, 779384568, 779384654, 779384714, 779384798,
  779384881, 779384955, 779385033, 779385138, 779385210, 779385284, 779385364, 779385442, 779385528, 779385606, 779385689, 779385770, 779385844,
  779385927, 779386012, 779386076, 779386152, 779386269, 779386344, 779386434, 779386519, 779386605, 779386717, 779386794, 779386891, 779386990,
  779387064, 779387151, 779387228, 779387323, 779387403, 779387501, 779387603, 779387741, 779387967, 779388143, 779388244, 779388449, 779388576,
  779388781, 779388989, 779389086, 779389172, 779389263, 779389360, 779389482, 779389706, 779389849, 779390063, 779390202, 779390309, 779390405,
  779390512, 779390595, 779390695, 779390791, 779390899, 779390988, 779391121, 779391237, 779391342, 779391461, 779391560, 779391676, 779391809,
  779391901, 779392040, 779392178, 779392283, 779392389, 779392477, 779392589, 779392686, 779392769, 779392893, 779393007, 779401059, 779401251,
  779401365, 779401459, 779401536, 779401631, 779401729, 779401869, 779401967, 779402068, 779402158, 779402242, 779402325, 779402415, 779402503,
  779402594, 779402786, 779402892, 779402961, 779403109, 779403219, 779403325, 779403414, 779403515, 779403624, 779403739, 779403847, 779404001,
  779404166, 779404269, 779404359, 779404507, 779404600, 779404689, 779404778, 779404852, 779404945, 779405032, 779405119, 779405200, 779405281,
  779405370, 779405462, 779405552, 779405648, 779405729, 779405823, 779405913, 779405995, 779406086, 779406204, 779406277, 779406395, 779406470,
  779406583, 779406667, 779406806, 779406960, 779407097, 779407214, 779407308, 779407397, 779407466, 779407549, 779407672, 779407754, 779407847,
  779408053, 779408184, 779408293, 779408568, 779408654, 779408754, 779408852, 779408926, 779409031, 779409161, 779409326, 779409454, 779409665,
  779409955, 779410147, 779410281, 779410357, 779410478, 779410621, 779410708, 779410810, 779410887, 779410967, 779411152, 779411277, 779411442,
  779411609, 779411769, 779411908, 779412034, 779412246, 779412363, 779412511, 779412617, 779412711, 779412894, 779413032, 779413126, 779413247,
  779413480, 779413623, 779413986, 779414165, 779414285, 779414791, 779414876, 779414999, 779415104, 779415203, 779415314, 779415410, 779415503,
  779697203, 779697309, 779697462, 779697551, 779697664, 779697829, 779698045, 779698153, 779698278, 779698404, 779698525, 779698614, 779698755,
  779698895, 779699020, 779699127, 779699254, 779699370, 779699496, 779699655, 779699756, 779699880, 779700153, 779700257, 779700428, 779700563,
  779700686, 779700810, 779700930, 779701047, 779701161, 779701345, 779701465, 779701588, 779701700, 779701853, 779702018, 779702153, 779702324,
  779702443, 779702612, 779702753, 779702880, 779702998, 779703218, 779703372, 779703533, 779703787, 779703993, 779704139, 779704358, 779704541,
  779704927, 779705140, 779705255, 779705419, 779705605, 779705771, 779705996, 779706181, 779706371, 779706595, 779706787, 779707076, 779707386,
  779709327, 779709695, 779709956, 779710231, 779710384, 779710546, 779710718, 779711016, 779711248, 779711479, 779711652, 779711837, 779711985,
  779712235, 779712382, 779712528, 779712682, 779712891, 779713170, 779713439, 779713593, 779713716, 779713862, 779713968, 779714116, 779714254,
  779714378, 779714575, 779714717, 779714927, 779715078, 779715180, 779715280, 779715614, 779716030, 779716300, 779716468, 779716633, 779716832,
  779716984, 779717139, 779717508, 779717745, 779717923, 779718047, 779718249, 779718438, 779718558, 779718687, 779718784, 779719036, 779719135,
  779719258, 779719401, 779719506, 779719620, 779719719, 779719843, 779720013, 779720192, 779720511, 779720715, 779720818, 779720991, 779721129,
  779721265, 779721361, 779721484, 779721594, 779721666, 779721794, 779721866, 779722013, 779722159, 779727327, 779727455, 779727643, 779727996,
  779728248, 779728351, 779728480, 779728663, 779728756, 779728848, 779728953, 779729122, 779729317, 779729473, 779729585, 779729753, 779729930,
  779730064, 779730180, 779730311, 779730496, 779730637, 779730790, 779730932, 779731036, 779731173, 779731304, 779731396, 779731489, 779731585,
  779731696, 779731785, 779731930, 779732085, 779732218, 779732320, 779732429, 779732528, 779732673, 779732780, 779732893, 779733004, 779733131,
  779733248, 779733409, 779733497, 779733610, 779733738, 779733838, 779733960, 779734138, 779734242, 779734354, 779734457, 779734553, 779734730,
  779734912, 779735087, 779735207, 779735355, 779735487, 779735648, 779735771, 779735897, 779736035, 779736120, 779736247, 779736345, 779736466,
  779736613, 779736798, 779736897, 779737048, 779737153, 779737275, 779737389, 779737555, 779737652, 779737789, 779737899, 779738026, 779738120,
  779738218, 779738370, 779738450, 779738554, 779738676, 779738779, 779738871, 779739026, 779739455, 779739566, 779739650, 779739769, 779739887,
  779739988, 779740107, 779740226, 779740319, 779740443, 779740530, 779740631, 779740740, 779740921, 779741152, 779741291, 779741392, 779741523,
  779741649, 779741785, 779741962, 779742126, 779742272, 779742396, 779742521, 779742617, 779742738, 779742825, 779742923, 779743037, 779743164,
  779743368, 779743463, 779743572, 779743665, 779743779, 779743912, 779744006, 779744105, 779744211, 779744417, 779744557, 779744663, 779744756,
  779744874, 779745003, 779745157, 779745259, 779745380, 779745486, 779745628, 779745738, 779745828, 779745933, 779746038, 779746127, 779746258,
  779746503, 779746631, 779746724, 779746837, 779746907, 779747044, 779747158, 779747259, 779747383, 779747530, 779747626, 779747738, 779747832,
  779747948, 779748032, 779748129, 779748232, 779748408, 779748530, 779748642, 779748755, 779748867, 779748980, 779749082, 779749182, 779749273,
  779749351, 779749490, 779749602, 779749713, 779749841, 779749958, 779750051, 779750135, 779759579, 779759658, 779759766, 779759893, 779760062,
  779760182, 779760279, 779760393, 779760488, 779760593, 779760707, 779760834, 779760949, 779761083, 779761203, 779761337, 779761466, 779761604,
  779761802, 779761946, 779762072, 779762169, 779762281, 779762408, 779762549, 779762673, 779762778, 779762893, 779763040, 779763273, 779763445,
  779763674, 779763861, 779797911,
];

export default assets;
