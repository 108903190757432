const ASSETS = [
  525014427, 525021300, 525496044, 552208924, 552212416, 560926427, 560932684, 560948248, 560952153, 560963179, 560966655, 564975540, 564984946,
  564989123, 567011639, 571246579, 574771881, 574775298, 574779637, 589175346, 596827746, 596838594, 596844984, 613079453, 613612972, 620390106,
  620393266, 630104622, 635441279, 644376819, 649380505, 649395531, 653132015, 661469199, 661470757, 664311421, 665616328, 665623609, 665625368,
  665627308, 665627936, 665631553, 665633236, 665633909, 665636367, 665637124, 665638673, 665639104, 665640677, 665641193, 665643166, 665643780,
  665646355, 665647019, 665647475, 672011958, 672206102, 672206515, 672209581, 675789947, 675790984, 675968884, 678927929, 678934354, 678935045,
  678936198, 678937077, 679232615, 687097906, 687098532, 687118831, 687899737, 688073797, 688074672, 688076372, 688077363, 688078469, 688080478,
  688082546, 689669502, 690939027, 694869731, 694870567, 696438177, 697309619, 697310580, 697313209, 697314792, 697315284, 702687204, 702687639,
  702688376, 702688832, 702689281, 702689779, 702690235, 702690655, 703474287, 704728628, 704730608, 704731220, 704731881, 704732359, 704732776,
  704733680, 704734081, 704734475, 704735001, 704735455, 704735955, 704736425, 704736831, 704737235, 704737603, 704737880, 704822820, 704823137,
  704823412, 704823707, 704824004, 705703666, 705710366, 711289510, 712319597, 717120709, 717121017, 717121305, 717121621, 717121923, 718213641,
  719379161, 720867923, 723547625, 723548041, 723548486, 723549250, 723549972, 723550443, 723559392, 727780574, 747665459, 747666011, 747666295,
  747666608, 748374839, 761130189, 761130602, 761130955, 761131327, 761132012, 761132338, 761132635, 761132940, 764583010, 777864287, 777865734,
  777866607, 777867404, 777869231, 777874526, 777875754, 777876450, 777877223, 778064848, 778066688, 788445319, 788463134, 788463385, 792197307,
  792197687, 792197963, 792198355, 792198650, 792198913, 792199226, 792199581, 792200128, 792200345, 792200530, 792200768, 792202702, 814924320,
  823937052, 823938861, 827723988, 827725724, 827727347, 827728311, 827729544, 827730858, 827731826, 827732706, 827734047, 827735251, 837987628,
  855590241, 860559103, 860560072, 872735305, 878273926, 878274445, 878274849, 878275306, 906046566, 906052131, 906054090, 906066637, 906067683,
  906068631, 906069251, 906069834, 906070473, 906071291, 922633806, 922635980, 922636864, 926582825, 934568476, 936920381, 959673591, 959675122,
  959676130, 959678472, 959679443, 959680145, 959680719, 963028135, 989545633, 989546217, 989546638, 989547354, 989548613, 989550042, 989603046,
  989603658, 989604092, 989604490, 989604977, 989605953, 989606309, 989606709, 989645820, 989712409, 989713434, 989714114, 989714892, 989715540,
  989716077, 989747889, 989750933, 993033961, 997317550, 997321355, 1013113820, 1013584207, 1018164458, 1019004733, 1019807697, 1019821799,
  1019825618, 1023562653, 1025333132, 1025336059, 1027694898, 1027695739, 1027696093, 1027696670, 1039114040, 1041060687, 1065794810, 1069706926,
  1073097439, 1080289975, 1080290184, 1080290994, 1080291365, 1080291674, 1080292061, 1080292448, 1082959523, 1090502961, 1097960910, 1097961556,
  1098959833, 1098960747, 1098961026, 1108767939, 1140677193, 1141308425, 1151811065, 1151813375, 1151813835, 1151814404, 1153612112, 1158789094,
  1158789523, 1158790146, 1158790771, 1158791066, 1158791456, 1159209090, 1159209716, 1163643064, 1169179469, 1172214031, 1179155871, 1188264700,
  1189082630, 1189083571, 1189084639, 1189085240, 1189086216, 1189703912, 1189704067, 1189704229, 1189704350, 1189704468, 1264348959, 1290457383,
  1352784108, 1390305270, 1404402866, 1421031609, 1421037060, 1429080479, 1461808405, 1493639544, 1573081104, 1617964227, 1620851669, 1628269672,
  1647185504, 1653793384, 1662203298, 1662204038, 1665616495, 1665616718, 1665617018, 1665617568, 1665620267, 1665623035, 1665623506, 1665623943,
  1665624280, 1665625824, 1665626719, 1665627200, 1665628050, 1665628676, 1665628960, 1665629348, 1665629693, 1665655460, 1665655776, 1665656152,
  1665656530, 1665656888, 1665657228, 1665657740, 1666801487, 1666801936, 1668005973, 1670379109, 1670380533, 1670470568, 1683149220, 1684446318,
  1686042053, 1687515532, 1707223677, 1710149235, 1733563457, 1748722007, 1748729231, 1748738001, 1784869659, 1785211388, 1796235765, 1796657309,
  1802331175, 1810171204, 1834121608, 1852634853, 1863515905, 1863516170, 1866217358, 1876016139, 1904578851, 2007683245, 2152951035, 2166359264,
  2200988337, 2217714846, 2244765547, 2254941314, 714975753, 714977056, 714977405, 714977726, 714978007, 714978339, 715007079, 719345240, 719346100,
  719346530, 719953143, 719954495, 719955469, 719957615, 719958296, 719958623, 719959543, 719960778, 720795443, 720797286, 720799198, 720849671,
  720849971, 720850809, 720851590, 720851901, 720852298, 720852592, 722197152, 722197793, 722198250, 722200381, 722200789, 722201217, 722202130,
  722202544, 722203168, 722203629, 722203978, 722204386, 722204777, 722205234, 722488157, 722488518, 722488943, 722490395, 722491085, 722492884,
  741638098, 741638457, 741638909, 741639197, 741639584, 741639998, 741640325, 741640669, 741644000, 741644265, 741644562, 741644812, 750688030,
  750688475, 750689036, 750690958, 750691571, 750692064, 750692661, 770858926, 770917847, 770918255, 770918632, 770919076, 770920215, 770920583,
  770921817, 770922305, 770922592, 770922980, 770923373, 770923848, 812011274, 812013660, 812013965, 812014434, 812015176, 812015419, 812015680,
  812015951, 812016204, 812016436, 855554326, 855554690, 855555111, 855555398, 855555744, 855556358, 855556594, 855556949, 855557184, 856508552,
  856508809, 856509244, 856509542, 856509733, 857072832, 870728914, 1021147896, 1021148562, 1021149164, 1021149742, 1021150958, 1021162113,
  1021162839, 1028558519, 1148916835, 1148917680, 1148918261, 1148918668, 1148920468, 1148920744, 1148921039, 1148921835, 1148922734, 1148923050,
  1148923354, 1148923846, 1148924478, 1148924830, 1148925094, 1148925906, 1148926514, 1148927011, 1148927277, 1148928136, 1148928403, 1148928752,
  1148929210, 1148929653, 1148929926, 1148931112, 1148931799, 1149512720, 1149513793, 1149514110, 1149515466, 1149516484, 1149517775, 1149518658,
  1149519428, 1149520100, 1149521333, 1149521750, 1149524649, 1149526199, 1149527117, 1149527809, 1149530192, 1149530562, 1149550358, 1153953470,
  1153953877, 1153954354, 1153957928, 1153958238, 1153958602, 1153959481, 1153960099, 1153960677, 1154005621, 1154005872, 1154006131, 1154006596,
  1154007068, 1154007856, 1154008847, 1154009240, 1154010634, 1154010982, 1154011358, 1154011621, 1154011791, 1154012617, 1154013077, 1154013341,
  1154013570, 1154013754, 1154014154, 1154014437, 1154016263, 1154016530, 1154016850, 1154017053, 1154017202, 1154017417, 1154017803, 1154017955,
  1154341059, 1154341742, 1154342152, 1154343339, 1154343622, 1154343904, 1154344670, 1154914814, 1154917069, 1155254914, 1158795857, 1158796472,
  1158796849, 1162239342, 1162239711, 1169190533, 1172215849, 1172216434, 1172216759, 1172217564, 1183326385, 1183326678, 1195543141, 1195543317,
  1195543422, 1195543565, 1195544007, 1195544138, 1195544330, 1195544424, 1195544590, 1195544733, 1195545067, 1195545785, 1195545994, 1195546065,
  1195546147, 1199415420, 1199415627, 1199415738, 1199415797, 1199415993, 1199417214, 1199418533, 1199418636, 1199418813, 1199418943, 1199419095,
  1199419194, 1199419258, 1199419448, 1226360899, 1226361682, 1226362546, 1226362792, 1226363179, 1226363400, 1226363579, 1226364253, 1470220062,
  1470220839, 1470223170, 1470227209, 1470232515, 1470233486, 1470233947, 1470236767, 1470242998, 1470244059, 1470246363, 1470250365, 1596362672,
  2186740406, 2200990911, 2200991660, 2203916845, 2203918063, 2203919648, 2205424593, 2205426462, 2205427249, 2205430046, 2205430632, 2205431596,
  2205433251, 2205434596, 2205435101, 2205435671, 2205436029, 2205436632, 2205437215, 2205437537, 2205438598, 2205439044, 2205864255, 2205864952,
  2205865672, 2205866047, 2205866669, 2205867125, 2205868103, 2205868419, 2205868966, 2205869280, 2205869719, 2205870362, 2205870887, 2205871755,
  2205872183, 2205873008, 2205873301, 2205873585, 2205874028, 2205874454, 2211322907, 2211323563, 2211324637, 2211326321, 2211326845, 2211340344,
  2214514082, 2214517217, 2214518563, 2214520762, 2214523089, 2214523592, 2214524812, 2214525820, 2214526676, 2214528796, 2214531714, 2214532484,
  2214534394, 2214535193, 2214535775, 2214536268, 2214538406, 2214540332, 2214542444, 2214543805, 2214549290, 2214550646, 2214553647, 2214556309,
  2214557412, 2214558097, 2214558900, 2214559416, 2214561403, 2214565888, 2214566656, 2214567045, 2214569183, 2214570158, 2214571280, 2214572030,
  2214572719, 2214575363, 2214576099, 2214576724, 2214577883, 2214579754, 2214580402, 2214580810, 2214582713, 2214583848, 2214584380, 2214585843,
  2214586283, 2214586697, 2214588357, 2214590407, 2214592484, 2214593391, 2214595991, 2223159856, 2232362631, 2259084768,
];

export default ASSETS;
