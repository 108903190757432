import IconComments from '../../components/icons/IconComments';
import IconDiscord from '../../components/icons/IconDiscord';
import IconFire from '../../components/icons/IconFire';
import IconMindBlown from '../../components/icons/IconMindBlown';
import IconParty from '../../components/icons/IconParty';
import IconRocket from '../../components/icons/IconRocket';
import IconThumb from '../../components/icons/IconThumb';
import { achievementLevel } from './config';

const discordAchievements = [
  {
    name: 'Discord User',
    description: 'Connect your Discord account to your Shitty Kitty profile',
    category: 'Discord',
    level: achievementLevel.bronze,
    icon: <IconDiscord />,
    requirements: {
      discord: true,
    },
  },
  {
    name: 'Chatty Catty',
    description: 'Send a message in Discord',
    category: 'Discord',
    level: achievementLevel.bronze,
    icon: <IconComments />,
    requirements: {
      messages: 1,
    },
  },
  {
    name: 'Chatty Catty 100',
    description: 'Send 100 messages in Discord',
    category: 'Discord',
    level: achievementLevel.silver,
    icon: <IconComments />,
    requirements: {
      messages: 100,
    },
  },
  {
    name: 'Chatty Catty 500',
    description: 'Send 500 messages in Discord',
    category: 'Discord',
    level: achievementLevel.gold,
    icon: <IconComments />,
    requirements: {
      messages: 500,
    },
  },
  {
    name: 'Chatty Catty 1,000',
    description: 'Send 1,000 messages in Discord',
    category: 'Discord',
    level: achievementLevel.platinum,
    icon: <IconComments />,
    requirements: {
      messages: 1000,
    },
  },
  {
    name: 'Reactor 10',
    description: 'React to 10 messages in Discord',
    category: 'Discord',
    level: achievementLevel.bronze,
    icon: <IconThumb />,
    requirements: {
      reactions: 10,
    },
  },
  {
    name: 'Reactor 50',
    description: 'React to 50 messages in Discord',
    category: 'Discord',
    level: achievementLevel.silver,
    icon: <IconParty />,
    requirements: {
      reactions: 50,
    },
  },
  {
    name: 'Reactor 100',
    description: 'React to 100 messages in Discord',
    category: 'Discord',
    level: achievementLevel.silver,
    icon: <IconFire />,
    requirements: {
      reactions: 100,
    },
  },
  {
    name: 'Reactor 500',
    description: 'React to 500 messages in Discord',
    category: 'Discord',
    level: achievementLevel.gold,
    icon: <IconRocket />,
    requirements: {
      reactions: 500,
    },
  },
  {
    name: 'Reactor 1,000',
    description: 'React to 1,000 messages in Discord',
    category: 'Discord',
    level: achievementLevel.platinum,
    icon: <IconMindBlown />,
    requirements: {
      reactions: 1000,
    },
  },
];

export default discordAchievements;
