import clsx from 'clsx';
import React from 'react';

const IconMovingTruck = ({ color }) => {
  return (
    <svg className={clsx('w-8 h-8', color)} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
      <path
        opacity='.6'
        d='M294.5 209.2c8.7-2.3 17.4-2.1 25.5 .2V334.8l-38.7 10.4L126.7 386.6 64.9 403.2c-17.1 4.6-34.6-5.6-39.2-22.6L13.3 334.2c-6.9-25.6 8.3-51.9 33.9-58.8s51.9 8.3 58.8 33.9l4.1 15.5 154.5-41.4-4.1-15.5c-6.9-25.6 8.3-51.9 33.9-58.8zM8 252.8c-9.1-34.1 11.1-69.2 45.3-78.4l185.5-49.7c34.1-9.1 69.2 11.1 78.4 45.3l1.7 6.4c-10.6-1.6-21.6-1.1-32.6 1.9c-37.4 10-61.5 44.9-59.2 82.1L131.2 286c-16.6-33.4-54.9-51.5-92.3-41.5c-11 2.9-20.8 8-29.2 14.7L8 252.8zM544 320a96 96 0 1 1 0 192 96 96 0 1 1 0-192z'
        fill='currentColor'
      />
      <path
        d='M416.7 402.5C423.4 338.2 477.9 288 544 288c38.2 0 72.5 16.8 96 43.3V0H416c-35.3 0-64 28.7-64 64V353.8L31.6 441.1c-17.1 4.6-27.1 22.2-22.5 39.3s22.2 27.1 39.3 22.5L416.7 402.5z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconMovingTruck;
