const ASSETS = [
  358524324, 358524476, 358524606, 358524716, 358524800, 358524923, 358525112, 358525283, 358525369, 358525454, 358525555, 358525667, 358525803,
  358525928, 358526021, 358526111, 358526200, 358526291, 358526369, 358526479, 358526581, 358526691, 358526792, 358526905, 358527000, 358527113,
  358527229, 358527324, 358527444, 358527545, 358527678, 358527816, 358527927, 358528095, 358528214, 358528295, 358528395, 358528519, 358528645,
  358528774, 358528887, 358528974, 358529086, 358529200, 358529301, 358529428, 358529523, 358529596, 358529677, 358529774, 358529893, 358530015,
  358530104, 358530166, 358530256, 358530325, 358530435, 358530571, 358530701, 358530862, 358530989, 358531100, 358531227, 358531359, 358531451,
  358531533, 358531661, 358531814, 358531929, 358532045, 358532152, 358532286, 358532398, 358532541, 358532665, 358532796, 358532892, 358532981,
  358533083, 358533211, 358533321, 358533443, 358533551, 358533642, 358533746, 358533858, 358533969, 358534089, 358534218, 358534354, 358534463,
  358534605, 358534691, 358534810, 358535007, 358535196, 358535299, 358535380, 358535516, 358535631, 358535740, 358535824, 358535921, 358536042,
  358536159, 358536266, 358536393, 358536498, 358536609, 358536701, 358536803, 358536907, 358537007, 358537144, 358537330, 358537486, 358537566,
  358537691, 358537815, 358537920, 358538032, 358538145, 358538232, 358538311, 358538413, 358538521, 358538635, 358538748, 358538852, 358538977,
  358539082, 358539198, 358539313, 358539436, 358539570, 358539689, 358539813, 358539922, 358540037, 358540129, 358540234, 358540340, 358540449,
  358540578, 358540677, 358540757, 358540860, 358540986, 358541082, 358541207, 358541331, 358541423, 358541541, 358541637, 358541735, 358541815,
  358541923, 358542063, 358542215, 358542352, 358542473, 358542542, 358542671, 358542764, 358542870, 358543048, 358543201, 358543333, 358543448,
  358543615, 358543742, 358543846, 358543947, 358544026, 358544148, 358544291, 358544456, 358544619, 358544747, 358544860, 358544946, 358545070,
  358545196, 358545295, 358545370, 358545446, 358545539, 358545619, 358545766, 358545915, 358546114, 358546227, 358546331, 358546436, 358546551,
  358546643, 358546758, 358546880, 358547006, 358547120, 358547309, 358547541, 358547704, 358547807, 358547934, 358548065, 358548207, 358548351,
  358548474, 358548577, 358548656, 358548766, 358548864, 358548989, 358549132, 358549241, 358549344, 358549457, 358549582, 358549682, 358549818,
  358549971, 358550080, 358550228, 358550401, 358550509, 358550650, 358550817, 358550932, 358551033, 358551386, 358551562, 358551668, 358551751,
  358551842, 358551925, 358552018, 358552111, 358552174, 358552254, 358552367, 358552475, 358552560, 358552665, 358552760, 358552816, 358552929,
  358552990, 358553134, 358553289, 358553377, 358553496, 358553603, 358553697, 358553803, 358553905, 358554029, 358554119, 358554213, 358554322,
  358554428, 358554511, 358554605, 358554716, 358554826, 358554940, 358555184, 358555345, 358555463, 358555579, 358555692, 358555825, 358555921,
  358556025, 358556138, 358556193, 358556301, 358556438, 358556612, 358556724, 358556830, 358556921, 358557012, 358557111, 358557199, 358557313,
  358557452, 358557552, 358557676, 358557750, 358557858, 358557936, 358558039, 358558164, 358558265, 358558351, 358558458, 358558567, 358558667,
  358558841, 358559028, 358559147, 358559254, 358559369, 358559482, 358559591, 358559700, 358559816, 358559942, 358560042, 358560152, 358560219,
  358560308, 358560431, 358560569, 358560700, 358560871, 358561015, 358561130, 358561236, 358561343, 358561446, 358561639, 358561818, 358561952,
  358562065, 358562159, 358562276, 358562383, 358562459, 358562548, 358562685, 358562785, 358562879, 358562996, 358563105, 358563224, 358563322,
  358563439, 358563569, 358563666, 358563805, 358563938, 358564106, 358564290, 358564433, 358564567, 358564665, 358564785, 358564930, 358565019,
  358565129, 358565261, 358565377, 358565461, 358565557, 358565755, 358565924, 358566081, 358566195, 358566312, 358566415, 358566514, 358566605,
  358566682, 358566762, 358566880, 358566958, 358567042, 358567128, 358567211, 358567322, 358567404, 358567495, 358567619, 358567700, 358567792,
  358567881, 358567967, 358568076, 358568180, 358568286, 358568431, 358568519, 358568644, 358568772, 358568890, 358569034, 358569268, 358569407,
  358569522, 358569622, 358569700, 358569841, 358569962, 358570101, 358570207, 358570301, 358570424, 358570555, 358570677, 358570882, 358571022,
  358571180, 358571315, 358571432, 358571563, 358571678, 358571771, 358571961, 358572098, 358572238, 358572362, 358572523, 358572612, 358572787,
  358572886, 358573025, 358573267, 358573451, 358573558, 358573657, 358573838, 358573998, 358574129, 358574257, 358574398, 358574553, 358574673,
  358574788, 358574935, 358575038, 358575133, 358575227, 358575340, 358575446, 358575572, 358575732, 358575844, 358575972, 358576098, 358576221,
  358576327, 358576442, 358576609, 358576768, 358576884, 358576994, 358577124, 358577248, 358577331, 358577428, 358577544, 358577656, 358577758,
  358577888, 358577977, 358578063, 358578127, 358578210, 358578329, 358578405, 358578486, 358578572, 358578672, 358578756, 358578853, 358578991,
  358579123, 358579223, 358579394, 358579496, 358579634, 358579751, 358579842, 358579931, 358580049, 358580244, 358580394, 358580546, 358580730,
  358580879, 358581029, 358581174, 358581287, 358581410, 358581563, 358581723, 358581883, 358582007, 358582145, 358582232, 358582342, 358582533,
  358582652, 358582768, 358582871, 358583052, 358583143, 358583238, 358588018, 358588146, 358588276, 358588418, 358588525, 358588675, 358588786,
  358588977, 358589107, 358589209, 358589359, 358589518, 358589639, 358589830, 358590003, 358590100, 358590179, 358590272, 358590460, 358590641,
  358590810, 358590944, 358591101, 358591193, 358591321, 358591482, 358591587, 358591765, 358591937, 358592036, 358592106, 358592198, 358592321,
  358592430, 358592624, 358592748, 358592885, 358593018, 358593126, 358593249, 358593352, 358593481, 358593652, 358593754, 358593862, 358594040,
  358594194, 358594311, 358594448, 358594632, 358595220, 358595475, 358595615, 358595751, 358595850, 358596044, 358596183, 358596348, 358596498,
  358596596, 358596721, 358596861, 358597025, 358597197, 358597300, 358597457, 358597597, 358597707, 358597875, 358598067, 358598215, 358598320,
  358598424, 358598574, 358598743, 358598852, 358598997, 358599103, 358599262, 358599426, 358599515, 358599653, 358599844, 358599986, 358600175,
  358600327, 358600421, 358600525, 358600674, 358600841, 358600961, 358601048, 358601175, 358601332, 358601516, 358601674, 358601847, 358601948,
  358602084, 358602190, 358602310, 358602464, 358602615, 358602775, 358602951, 358603151, 358603271, 358603436, 358603576, 358603809, 358603955,
  358604075, 358604203, 358604356, 358604488, 358604637, 358604782, 358605030, 358605189, 358605333, 358605459, 358605568, 358605660, 358605804,
  358605952, 358606135, 358606231, 358606353, 358606514, 358606660, 358606822, 358607044, 358607203, 358607359, 358607472, 358607604, 358607746,
  358607890, 358608074, 358608211, 358608301, 358608429, 358608566, 358608719, 358608841, 358609012, 358609126, 358609261, 358609432, 358609558,
  358609675, 358609912, 358610026, 358610160, 358610326, 358610488, 358610584, 358610691, 358610798, 358610901, 358611038, 358611192, 358611321,
  358611475, 358611590, 358611736, 358611885, 358611995, 358612143, 358612291, 358612426, 358612565, 358612698, 358612849, 358612949, 358613023,
  358613186, 358613300, 358613409, 358613561, 358613719, 358613920, 358614075, 358614196, 358614359, 358614491, 358614647, 358614793, 358614913,
  358615040, 358615164, 358615304, 358615464, 358615581, 358615719, 358615868, 358615980, 358616154, 358616250, 358616356, 358616478, 358616619,
  358616740, 358616879, 358616942, 358617105, 358617277, 358617421, 358617504, 358617601, 358617735, 358617861, 358618036, 358618224, 358618374,
  358618509, 358618650, 358618939, 358619083, 358619248, 358619420, 358619580, 358619705, 358619850, 358620002, 358620153, 358620329, 358620413,
  358620565, 358620718, 358620876, 358621047, 358621180, 358621310, 358621482, 358621615, 358621704, 358621815, 358621954, 358622075, 358622225,
  358622358, 358622560, 358622716, 358622869, 358622964, 358623114, 358623265, 358623435, 358623648, 358623779, 358623904, 358624006, 358624162,
  358624313, 358624454, 358624565, 358624699, 358624858, 358624981, 358625104, 358625205, 358625364, 358625507, 358625624, 358625751, 358625877,
  358625990, 358626097, 358626248, 358626422, 358626573, 358626728, 358626856, 358626989, 358627142, 358627287, 358627394, 358627509, 358627719,
  358627820, 358627915, 358628008, 358628104, 358628220, 358628385, 358628506, 358628649, 358628790, 358628940, 358629096, 358629254, 358629341,
  358629492, 358629691, 358629848, 358630026, 358630194, 358630332, 358630415, 358630526, 358630649, 358630808, 358630919, 358631030, 358631168,
  358631340, 358631453, 358631606, 358631761, 358631920, 358632066, 358632308, 358632399, 358632554, 358632732, 358632906, 358633085, 358633171,
  358633278, 358633375, 358633492, 358633603, 358633715, 358633896, 358633984, 358634071, 358634166, 358634359, 358634500, 358634655, 358634796,
  358634928, 358635090, 358635296, 358635479, 358635672, 358635806, 358636024, 358636198, 358636372, 358636589, 358636777, 358636941, 358637135,
  358637269, 358637401, 358637561, 358637742, 358637893, 358638034, 358638142, 358638275, 358638405, 358638560, 358638691, 358638795, 358638939,
  358639078, 358639238, 358639415, 358639616, 358639921, 358640066, 358640207, 358640369, 358640510, 358640652, 358640859, 358641075, 358641234,
  358641372, 358641543, 358641688, 358641825, 358641966, 358642089, 358642246, 358642396, 358642542, 358642720, 358642877, 358643009, 358643169,
  358643328, 358643493, 358643661, 358643829, 358643997, 358644135, 358644292, 358644429, 358644621, 358644805, 358644962, 358645126, 358645247,
  358645350, 358645492, 358645704, 358645783, 358645900, 358646049, 358646208, 358646390, 358646512, 358646668, 358646856, 358647023, 358647159,
  358647325, 358647549, 358647724, 358647885, 358648016, 358648153, 358648322, 358648428, 358648570, 358648727, 358648900, 358649037, 358649233,
  358649392, 358649520, 358649656, 358649888, 358650018, 358650212, 358650452, 358650645, 358650784, 358650950, 358651128, 358651317, 358651461,
  358651649, 358651813, 358652004, 358652132, 358652272, 358652431, 358652583, 358652812, 358652979, 358653182, 358653314, 358653471, 358653619,
  358653752, 358653915, 358654081, 358654248, 358654361, 358654528, 358654711, 358654878, 358654976, 358655103, 358655286, 358655460, 358655603,
  358655768, 358655867, 358655997, 358656124, 358656281, 358656477, 358656598, 358656730, 358656888, 358656982, 358657094, 358657260, 358657496,
  358657647, 358657833, 358657982, 358658158, 358658302, 358658427, 358658592, 358658765, 358658874, 358659032, 358659187, 358659326, 358659511,
  358659682, 358659813, 358659988, 358660154, 358660274, 358660385, 358660566, 358660726, 358660874, 358661022, 358671781, 358672271, 358672376,
  358672501, 358672626, 358672718, 358672821, 358672963, 358673087, 358673219, 358673360, 358673474, 358673654, 358673791, 358673895, 358673999,
  358674151, 358674280, 358674367, 358674471, 358674574, 358674727, 358674837, 358674930, 358675036, 358675166, 358675256, 358675382, 358675478,
  358675572, 358675692, 358675822, 358675955, 358676072, 358676157, 358676256, 358676343, 358676474, 358676591, 358676742, 358676860, 358676942,
  358677056, 358677214, 358677340, 358677445, 358677550, 358677637, 358677766, 358677884, 358677970, 358678080, 358678225, 358678329, 358678414,
  358678505, 358678600, 358678708, 358678825, 358678951, 358679037, 358679153, 358679261, 358679397, 358679527, 358679705, 358679804, 358679932,
  358680040, 358680172, 358680303, 358680401, 358680510, 358680643, 358680841, 358681351, 358681620, 358681757, 358681873, 358681971, 358682066,
  358682171, 358682263, 358682362, 358682486, 358682585, 358682715, 358682832, 358682927, 358683034, 358683124, 358683224, 358683352, 358683530,
  358683641, 358683749, 358683853, 358683963, 358684077, 358684194, 358684306, 358684405, 358684533, 358684632, 358684760, 358684884, 358685015,
  358685145, 358685300, 358685401, 358685505, 358685629, 358685731, 358685810, 358685930, 358686044, 358686137, 358686321, 358686469, 358686579,
  358686703, 358686862, 358686992, 358687087, 358687213, 358687321, 358687463, 358687634, 358687805, 358687935, 358688113, 358688246, 358688332,
  358688437, 358688527, 358688621, 358688705, 358688794, 358688883, 358689010, 358689166, 358689316, 358689478, 358689636, 358689747, 358689860,
  358689978, 358690096, 358690201, 358690311, 358690406, 358690502, 358690599, 358690723, 358690780, 358690868, 358690964, 358691095, 358691250,
  358691417, 358691548, 358691650, 358691747, 358691864, 358692040, 358692256, 358692367, 358692468, 358692558, 358692663, 358692760, 358692872,
  358692979, 358693072, 358693196, 358693302, 358693419, 358693538, 358693647, 358693735, 358693842, 358693946, 358694020, 358694115, 358694211,
  358694320, 358694410, 358694510, 358694597, 358694740, 358694824, 358694933, 358695050, 358695179, 358695305, 358695435, 358695560, 358695654,
  358695781, 358695882, 358695958, 358696053, 358696153, 358696262, 358696362, 358696474, 358696576, 358696704, 358696813, 358696919, 358697019,
  358697141, 358697248, 358697362, 358697450, 358697544, 358697636, 358697749, 358697829, 358697934, 358698034, 358698152, 358698283, 358698444,
  358698538, 358698652, 358698782, 358698876, 358698952, 358699026, 358699131, 358699244, 358699368, 358699468, 358699573, 358699666, 358699764,
  358699881, 358699984, 358700122, 358700212, 358700304, 358700393, 358700459, 358700568, 358700684, 358700810, 358700902, 358700999, 358701057,
  358701164, 358701302, 358701434, 358701581, 358701715, 358701849, 358701988, 358702083, 358702179, 358702291, 358702461, 358702608, 358702705,
  358702778, 358702866, 358702957, 358703072, 358703157, 358703296, 358703424, 358703541, 358703637, 358703727, 358703903, 358704039, 358704173,
  358704276, 358704371, 358704506, 358704599, 358704694, 358704759, 358704845, 358704918, 358705011, 358705102, 358705185, 358705275, 358705374,
  358705460, 358705528, 358705617, 358705703, 358705802, 358705952, 358706095, 358706193, 358706408, 358706494, 358706591, 358706723, 358706875,
  358706967, 358707073, 358707173, 358707284, 358707405, 358707535, 358707668, 358707726, 358707827, 358707916, 358708026, 358708103, 358708212,
  358708314, 358708401, 358708509, 358708574, 358708684, 358708796, 358708880, 358708968, 358709064, 358709151, 358709257, 358709423, 358709480,
  358709563, 358709635, 358709703, 358709782, 358709849, 358709981, 358710074, 358710182, 358710281, 358710419, 358710533, 358710632, 358710708,
  358710820, 358710937, 358711121, 358711208, 358711326, 358711402, 358711471, 358711570, 358711687, 358711866, 358711984, 358712084, 358712161,
  358712248, 358712363, 358712462, 358712546, 358712644, 358712741, 358712833, 358712931, 358713007, 358713087, 358713184, 358713324, 358713466,
  358713548, 358713667, 358713752, 358713841, 358713947, 358714040, 358714156, 358714263, 358714346, 358714424, 358714542, 358714678, 358714811,
  358714901, 358714980, 358715081, 358715150, 358715247, 358715338, 358715436, 358715540, 358715614, 358715712, 358715793, 358715909, 358715994,
  358716097, 358716201, 358716327, 358716469, 358716556, 358716657, 358716801, 358716908, 358716996, 358717095, 358717214, 358717306, 358717401,
  358717492, 358717579, 358717656, 358717762, 358717849, 358717948, 358718028, 358718120, 358718204, 358718304, 358718400, 358718488, 358718615,
  358718713, 358718809, 358718896, 358718993, 358719091, 358719182, 358719280, 358719426, 358719520, 358719646, 358719760, 358719880, 358720012,
  358720124, 358720299, 358720447, 358720538, 358720630, 358720713, 358720791, 358720898, 358720981, 358721056, 358721154, 358721279, 358721416,
  358721512, 358721624, 358721711, 358721788, 358721892, 358721987, 358722085, 358722173, 358722258, 358722657, 358722787, 358722917, 358723011,
  358723094, 358723165, 358723228, 358723313, 358723383, 358723457, 358723520, 358723601, 358723672, 358723782, 358723928, 358724028, 358724124,
  358724259, 358724368, 358724484, 358724572, 358724676, 358724787, 359023227, 359023307, 359023437, 359023549, 359023599, 359023657, 359023738,
  359023798, 359023893, 359023953, 359024016, 359024102, 359024192, 359024267, 359024325, 359024373, 359024471, 359024576, 359024692, 359024761,
  359024815, 359024870, 359024915, 359024969, 359025046, 359025149, 359025227, 359025297, 359025366, 359025452, 359025541, 359025656, 359025778,
  359025880, 359025960, 359026039, 359026116, 359026196, 359026267, 359026331, 359026395, 359026473, 359026540, 359026618, 359026713, 359026802,
  359026883, 359027010, 359027122, 359027235, 359027325, 359027402, 359027479, 359027577, 359027657, 359027748, 359027812, 359027890, 359027996,
  359028050, 359028119, 359028214, 359028265, 359028322, 359028418, 359028487, 359028543, 359028611, 359028661, 359028718, 359028772, 359028844,
  359028916, 359028957, 359029016, 359029065, 359029124, 359029193, 359029238, 359029298, 359029359, 359029417, 359029491, 359029550, 359029598,
  359029646, 359029694, 359029758, 359029836, 359029905, 359029963, 359030028, 359030093, 359030194, 359030286, 359030395, 359030469, 359030552,
  359030941, 359031034, 359031104, 359031170, 359031244, 359031310, 359031413, 359031489, 359031569, 359031644, 359031740, 359031817, 359031907,
  359031984, 359032072, 359032153, 359032247, 359032328, 359032432, 359032538, 359032613, 359032675, 359032774, 359032845, 359032928, 359032971,
  359033081, 359033248, 359033314, 359033372, 359033439, 359033510, 359033590, 359033682, 359033744, 359033813, 359033860, 359033933, 359033982,
  359034050, 359034129, 359034183, 359034255, 359034325, 359034374, 359034414, 359034472, 359034526, 359034578, 359034629, 359034688, 359034758,
  359034807, 359034873, 359034928, 359034991, 359035054, 359035110, 359035166, 359035224, 359035298, 359035350, 359035408, 359035469, 359035530,
  359035598, 359035671, 359035762, 359035835, 359035897, 359035981, 359036106, 359036175, 359036243, 359036321, 359036411, 359036495, 359036573,
  359036661, 359036749, 359036830, 359036908, 359037034, 359037193, 359037266, 359037344, 359037423, 359037501, 359037589, 359037668, 359037741,
  359037795, 359037889, 359037978, 359038028, 359038087, 359038161, 359038234, 359038294, 359038355, 359038419, 359038480, 359038564, 359038615,
  359038678, 359038727, 359038799, 359038857, 359038920, 359038971, 359039036, 359039102, 359039215, 359039316, 359039366, 359039432, 359039490,
  359039590, 359039660, 359039711, 359039767, 359039831, 359039890, 359039992, 359040043, 359040107, 359040170, 359040230, 359040321, 359040405,
  359040521, 359040620, 359040687, 359040735, 359040806, 359040886, 359040964, 359041083, 359041178, 359041282, 359041398, 359041483, 359041622,
  359041700, 359041823, 359041900, 359041976, 359042048, 359042121, 359042220, 359042301, 359042367, 359042477, 359042560, 359042711, 359042891,
  359043011, 359043148, 359043248, 359043314, 359043438, 359043530, 359043610, 359043723, 359043835, 359043995, 359044137, 359044248, 359044302,
  359044415, 359044493, 359044605, 359044754, 359044859, 359044998, 359045100, 359045176, 359045260, 359045421, 359045535, 359045644, 359045722,
  359045802, 359045898, 359046007, 359046111, 359046239, 359046372, 359046456, 359046552, 359046674, 359046859, 359046938, 359047090, 359047199,
  359047331, 359047448, 359047619, 359047749, 359047907, 359048040, 359048155, 359048290, 359048400, 359048507, 359048610, 359048750, 359049088,
  359049374, 359056792, 359056895, 359056972, 359057022, 359057129, 359057293, 359057452, 359057572, 359057727, 359057838, 359057939, 359058044,
  359058149, 359058245, 359058325, 359058432, 359058602, 359058699, 359058765, 359058859, 359058999, 359059079, 359059163, 359059264, 359059333,
  359059403, 359059485, 359059624, 359059753, 359059865, 359059976, 359060073, 359060209, 359060309, 359060427, 359060582, 359060680, 359060833,
  359061034, 359064112, 359064227, 359064312, 359064429, 359064534, 359064636, 359064769, 359064891, 359064970, 359065089, 359065215, 359065326,
  359065430, 359065515, 359065680, 359065774, 359065865, 359066033, 359066140, 359066263, 359066407, 359066561, 359066622, 359066703, 359067136,
  359067243, 359067399, 359067504, 359067581, 359067720, 359067878, 359068024, 359068164, 359068294, 359068473, 359068652, 359068831, 359068979,
  359069173, 359069338, 359069515, 359069655, 359069810, 359070001, 359070277, 359070426, 359070566, 359070741, 359070944, 359071122, 359071298,
  359071402, 359071551, 359071625, 359071721, 359071852, 359071991, 359072152, 359072284, 359072355, 359072441, 359072512, 359072669, 359072811,
  359072938, 359073097, 359073193, 359073387, 359073510, 359073607, 359073739, 359073851, 359073953, 359074116, 359074276, 359074377, 359074492,
  359074700, 359074874, 359075035, 359075298, 359075796, 359076321, 359076812, 359077238, 359077616, 359082101, 359082318, 359082558, 359082758,
  359082964, 359083161, 359083389, 359083552, 359083748, 359083915, 359084061, 359095606, 359095743, 359095915, 359096090, 359096237, 359096418,
  359096609, 359096792, 359097004, 359097180, 359097339, 359097480, 359097624, 359097782, 359097931, 359098162, 359098346, 359098523, 359098709,
  359098889, 359099045, 359099170, 359099303, 359099431, 359099569, 359099711, 359099818, 359099923, 359100002, 359100130, 359100265, 359100354,
  359100460, 359100558, 359100657, 359100772, 359100934, 359101050, 359101139, 359101231, 359101303, 359101387, 359101487, 359101569, 359101691,
  359101758, 359101842, 359101934, 359102012, 359102119, 359102228, 359102323, 359102456, 359102581, 359102709, 359102830, 359102966, 359103159,
  359103323, 359103523, 359103714, 359103904, 359104056, 359104176, 359104338, 359104460, 359104563, 359104718, 359104841, 359104958, 359105063,
  359105179, 359105292, 359105401, 359105534, 359105658, 359105805, 359105945, 359112138, 359112242, 359112349, 359112591, 359112680, 359112814,
  359112938, 359113055, 359113204, 359113332, 359113459, 359113612, 359113771, 359113872, 359114053, 359114166, 359114320, 359114479, 359114626,
  359114744, 359114855, 359114989, 359115138, 359115366, 359115468, 359115639, 359115861, 359116037, 359116145, 359116274, 359116401, 359116586,
  359116760, 359116938, 359117079, 359117205, 359117297, 359117443, 359117622, 359117725, 359117881, 359118049, 359118169, 359118260, 359118376,
  359118518, 359118685, 359118804, 359118966, 359119186, 359119294, 359119374, 359119505, 359119655, 359119771, 359119878, 359119971, 359120097,
  359120204, 359120286, 359120394, 359120528, 359120673, 359120804, 359120936, 359121035, 359121135, 359121237, 359121340, 359121415, 359121490,
  359121555, 359121664, 359121799, 359121947, 359158973, 359159097, 359159193, 359159514, 359159621, 359159709, 359159808, 359159927, 359160019,
  359160644, 359160735, 359160835, 359160926, 359161035, 359161131, 359161401, 359161518, 359161636, 359161742, 359161879, 359161989, 359162353,
  359162456, 359162537, 359162611, 359162740, 359162859, 359163260, 359163410, 359163489, 359163591, 359163679, 359164005, 359164109, 359164204,
  359164295, 359164408, 359164514, 359165119, 359165236, 359165366, 359165520, 359165651, 359165755, 359165858, 359166321, 359166434, 359166531,
  359166609, 359166692, 359166791, 359167411, 359167522, 359167634, 359167716, 359167823, 359167914, 359168257, 359168342, 359168470, 359168569,
  359168683, 359168767, 359169159, 359169277, 359169480, 359169571, 359169672, 359169771, 359170277, 359170391, 359170472, 359170563, 359170671,
  359170751, 359170828, 359171163, 359171261, 359171353, 359171474, 359171603, 359171695, 359171809, 359172270, 359172446, 359172575, 359172665,
  359172783, 359173167, 359173274, 359173391, 359173466, 359173550, 359173657, 359173969, 359174046, 359174128, 359174242, 359174314, 359174398,
  359176164, 359176296, 359176383, 359176745, 359176826, 359176927, 359177064, 359177178, 359177273, 359177681, 359177781, 359177854, 359177941,
  359178040, 359178128, 359178526, 359178662, 359178780, 359178887, 359179000, 359179461, 359179575, 359179691, 359180328, 359181102, 359181888,
  359184765, 359185794, 359186553, 359187358, 359188145, 359188934, 359190964, 359191078, 359191176, 359191279, 359191371, 359191494, 359191843,
  359191972, 359192057, 359192148, 359192248, 359192367, 359192697, 359192812, 359192930, 359193034, 359193160, 359193257, 359193684, 359193840,
  359193928, 359194012, 359194072, 359194690, 359194778, 359194874, 359194961, 359195035, 359195136, 359195493, 359195914, 359195993, 359196114,
  359196218, 359196304, 359196625, 359196715, 359196788, 359196868, 359196951, 359197202, 359197294, 359197358, 359197432, 359197499, 359197588,
  359197909, 359198000, 359198083, 359198300, 359198396, 359198518, 359198630, 359199217, 359199279, 359199351, 359199422, 359199496, 359199578,
  359199631, 359199945, 359200042, 359200119, 359200197, 359200254, 359200317, 359200593, 359200673, 359200736, 359200814, 359200942, 359201344,
  359201449, 359201568, 359201677, 359201770, 359201874, 359202269, 359202421, 359202507, 359202607, 359202706, 359202795, 359203172, 359203263,
  359203347, 359203429, 359203528, 359203976, 359204074, 359204181, 359204243, 359204307, 359204366, 359204695, 359204782, 359204876, 359204976,
  359205039, 359205447, 359205589, 359205685, 359205793, 359205926, 359206043, 359206141, 359206419, 359206524, 359206616, 359206726, 359206837,
  359206918, 359207256, 359207359, 359207432, 359207548, 359207649, 359207755, 359208047, 359208194, 359221102, 359221583, 359221709, 359221798,
  359221881, 359221979, 359222075, 359222388, 359222464, 359222559, 359222624, 359222690, 359222751, 359222822, 359223377, 359223500, 359223573,
  359223641, 359223709, 359223777, 359224010, 359224080, 359224152, 359224223, 359224283, 359224343, 359224629, 359224712, 359224792, 359224852,
  359224923, 359225530, 359225648, 359225759, 359225846, 359225932, 359226046, 359226621, 359227992, 359228124, 359228255, 359228366, 359228504,
  359229005, 359229124, 359229199, 359229276, 359229379, 359229487, 359229605, 359229996, 359230095, 359230213, 359230314, 359230424, 359230549,
  359231018, 359231168, 359231269, 359231406, 359231508, 359231994, 359232118, 359232203, 359232291, 359232388, 359232497, 359232914, 359233030,
  359233156, 359233271, 359233395, 359233522, 359233950, 359234406, 359234533, 359234656, 359234764, 359235250, 359235410, 359235538, 359235687,
  359235835, 359235968, 359237358, 359237753, 359237853, 359237952, 359238087, 359238237, 359238356, 359238926, 359239008, 359239101, 359239243,
  359239362, 359239461, 359240147, 359240279, 359240368, 359240491, 359240597, 359240719, 359241183, 359241284, 359241419, 359241547, 359241684,
  359241813, 359242497, 359242612, 359242713, 359242832, 359242944, 359243056, 359243647, 359243807, 359243919, 359244062, 359244169, 359244284,
  359244774, 359244964, 359245063, 359245176, 359245282, 359245396, 359246048, 359246158, 359246271, 359246379, 359246490, 359246612, 359246722,
  359247396, 359247504, 359247615, 359247727, 359247865, 359247974, 359248401, 359248517, 359248623, 359248728, 359248839, 359248944, 359249357,
  359249469, 359249599, 359249702, 359249823, 359249957, 359250075, 359250371, 359250446, 359250566, 359250671, 359250779, 359250915, 359251696,
  359251846, 359252016, 359252155, 359252304, 359252439, 359252597, 359253090, 359253214, 359253339, 359253448, 359253542, 359253646, 359253730,
  359254101, 359254325, 359254429, 359254542, 359254876, 359255027, 359255134, 359255248, 359255360, 359255458, 359256037, 359256145, 359256266,
  359256384, 359256469, 359256603, 359257225, 359257334, 359257446, 359257549, 359257647, 359262211, 359262322, 359262430, 359262523, 359262799,
  359262899, 359263001, 359263095, 359263195, 359263539, 359263627, 359263740, 359263848, 359263973, 359264038, 359264143, 359264554, 359264704,
  359264808, 359264893, 359264999, 359265098, 359265195, 359265715, 359265860, 359265966, 359266065, 359266150, 359266284, 359266404, 359266694,
  359266833, 359266974, 359267085, 359267213, 359267345, 359267813, 359267927, 359268038, 359268148, 359268243, 359268548, 359268648, 359268744,
  359268853, 359268965, 359269063, 359269135, 359269422, 359269521, 359269616, 359269705, 359269805, 359269903, 359270303, 359270379, 359270464,
  359270563, 359270661, 359270762, 359271107, 359271209, 359271318, 359271404, 359271516, 359271608, 359271999, 359272132, 359272247, 359272364,
  359272446, 359272967, 359273086, 359273182, 359273292, 359273412, 359273528, 359273610, 359274015, 359274127, 359274273, 359274446, 359274573,
  359274696, 359275065, 359275173, 359275262, 359275366, 359275462, 359275561, 359276966, 359277079, 359277189, 359277263, 359277398, 359277481,
  359277574, 359277979, 359278136, 359278241, 359278325, 359278444, 359278883, 359279057, 359279141, 359279265, 359279730, 359280027, 359280597,
  359280723, 359280829, 359280936, 359281048, 359281784, 359281919, 359282058, 359282208, 359282314, 359282410, 359282914, 359283160, 359283276,
  359283414, 359283533, 359283647, 359284044, 359284149, 359284261, 359284338, 359284421, 359284540, 359284988, 359285078, 359285205, 359285320,
  359285417, 359285498, 359285568, 359285881, 359286022, 359286106, 359286214, 359286320, 359286421, 359286792, 359286968, 359287072, 359287190,
  359287315, 359287449, 359287776, 359287900, 359288016, 359288161, 359288290, 359288377, 359288796, 359288909, 359288996, 359289058, 359289135,
  359289216, 359289673, 359289810, 359289929, 359290052, 359290166, 359290302, 359290797, 359290912, 359291006, 359291095, 359291196, 359291270,
  359291361, 359291649, 359291784, 359291872, 359291979, 359292057, 359292539, 359292670, 359292764, 359292845, 359293024, 359293105, 359293445,
  359293542, 359293643, 359293739, 359293860, 359293938, 359294328, 359294426, 359294513, 359294646, 359294734, 359294846, 359295189, 359295326,
  359295436, 359295565, 359295672, 359296123, 359296243, 359296378, 359296500, 359296642, 359296781, 359296903, 359297433, 359297572, 359297710,
  359297819, 359297954, 359298035, 359298454, 359298583, 359299671, 359299824, 359299927, 359300235, 359300387, 359300490, 359300595, 359300719,
  359300828, 359301537, 359301625, 359301848, 359301942, 359302029, 359302539, 359302624, 359302700, 359302775, 359302844, 359302934, 359303353,
  359303464, 359304396, 359304516, 359304635, 359304726, 359304832, 359305247, 359305359, 359305454, 359305527, 359305596, 359305679, 359305986,
  359306064, 359306153, 359306251, 359306318, 359306413, 359306753, 359306851, 359306961, 359307040, 359307145, 359307238, 359307645, 359307798,
  359307876, 359307991, 359308091, 359308199, 359308672, 359308823, 359308899, 359308983, 359309106, 359309234, 359309309, 359310025, 359310173,
  359310290, 359310360, 359310452, 359310561, 359310646, 359311449, 359311595, 359311714, 359311820, 359311897, 359312449, 359312543, 359312662,
  359312746, 359312834, 359313237, 359313339, 359313393, 359313505, 359313576, 359319153, 359319270, 359319338, 359319428, 359320051, 359320165,
  359320244, 359320337, 359320464, 359321502, 359321941, 359322005, 359322413, 359322530, 359322667, 359322755, 359322830, 359323151, 359323259,
  359323343, 359323419, 359323514, 359323621, 359324249, 359324376, 359324475, 359324615, 359324748, 359324883, 359325474, 359325646, 359325767,
  359325901, 359326029, 359326441, 359326552, 359326650, 359326765, 359326859, 359326959, 359327318, 359327465, 359327562, 359327689, 359327759,
  359327829, 359328137, 359328220, 359328299, 359328393, 359328465, 359328575, 359329013, 359329125, 359329247, 359329360, 359329454, 359329840,
  359329941, 359330024, 359330133, 359330241, 359330327, 359330780, 359330876, 359331001, 359331100, 359331235, 359331363, 359331461, 359331968,
  359332112, 359332194, 359332327, 359332443, 359332594, 359333277, 359333392, 359333519, 359333621, 359333745, 359333847, 359333952, 359334251,
  359334381, 359334468, 359334518, 359334605, 359334901, 359335026, 359336627, 359336761, 359337101, 359337215, 359337292, 359337371, 359337448,
  359337547, 359337898, 359337976, 359338077, 359338188, 359339370, 359339501, 359340006, 359340098, 359340203, 359340312, 359340425, 359340534,
  359340652, 359341150, 359341228, 359341320, 359341414, 359341491, 359341971, 359342095, 359342187, 359342323, 359342421, 359342520, 359343000,
  359343136, 359343215, 359343308, 359343411, 359343484, 359343878, 359343962, 359344074, 359344203, 359344304, 359344711, 359344834, 359344960,
  359345055, 359345165, 359345250, 359345632, 359345820, 359345944, 359346074, 359346198, 359346346, 359346786, 359346954, 359347089, 359347230,
  359347356, 359347730, 359347827, 359347904, 359347988, 359348095, 359348187, 359348562, 359348681, 359348794, 359348930, 359349014, 359349327,
  359349429, 359349527, 359349616, 359349720, 359349813, 359350146, 359350267, 359350372, 359350454, 359350554, 359350668, 365008450, 365008736,
  367160306, 367226158, 367226761, 367226881, 367227040, 367227147, 367227296, 372314947, 392748865,
];

export default ASSETS;
