import IconFishing from '../../components/icons/IconFishing';
import IconKittySpace from '../../components/icons/IconKittySpace';
import { achievementLevel } from './config';

const adventureAchievements = [
  {
    name: 'Adventure Time',
    description: 'Complete your first adventure',
    category: 'Adventures',
    level: achievementLevel.bronze,
    icon: <IconFishing />,
    requirements: {
      adventures: 1,
    },
  },
  {
    name: 'Adventure Time 5',
    description: 'Complete 5 adventures',
    category: 'Adventures',
    level: achievementLevel.silver,
    icon: <IconFishing />,
    requirements: {
      adventures: 5,
    },
  },
  {
    name: 'Adventure Time 25',
    description: 'Complete 25 adventures',
    category: 'Adventures',
    level: achievementLevel.gold,
    icon: <IconFishing />,
    requirements: {
      adventures: 25,
    },
  },
  {
    name: 'Adventure Time 100',
    description: 'Complete 100 adventures',
    category: 'Adventures',
    level: achievementLevel.platinum,
    icon: <IconFishing />,
    requirements: {
      adventures: 100,
    },
  },
  {
    name: 'Adventure Time 200',
    description: 'Complete 200 adventures',
    category: 'Adventures',
    level: achievementLevel.diamond,
    icon: <IconKittySpace />,
    requirements: {
      adventures: 200,
    },
  },
];

export default adventureAchievements;
