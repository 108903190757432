const assets = [
  644460533, 644523500, 644531138, 644541597, 644542418, 664042284, 674167205, 674172059, 674173568, 674177966, 674178881, 674334776, 674337291,
  674341314, 674345273, 674347504, 675555464, 675557260, 675560444, 675562325, 675563741, 678499439, 678501005, 678528290, 678530034, 678531290,
  704361969, 707726045, 707726824, 707727567, 707728104, 707728727, 707733430, 707734334, 707734740, 707735199, 707736256, 707737091, 707737845,
  707738291, 707743623, 707744668, 707745336, 707746018, 707746926, 707918016, 707919844, 713997072, 717952691, 718089549, 718093428, 718099499,
  718100565, 718113084, 718113650, 718115179, 718116220, 718119346, 718127037, 718129390, 718130635, 718132189, 718134472, 718135889, 718137009,
  718139209, 718169030, 718170524, 718171125, 722324205, 735486518, 735488119, 735489016, 735490099, 735492177, 735494275, 735495987, 735497342,
  735498486, 735499554, 735500782, 735501844, 735503304, 735504302, 735505781, 735507082, 735508094, 735510866, 735513954, 735515383, 747040397,
  752022431, 752023734, 752024221, 752024737, 752026890, 752028756, 752029856, 752031050, 752031602, 752033380, 752034021, 752034597, 752034936,
  752035469, 752035967, 752036384, 752036919, 752037981, 752038520, 752038885, 765384753, 768300206, 768302103, 768304802, 768306160, 768307376,
  768321639, 768323622, 768330879, 768333314, 768334451, 768337166, 768338266, 768340079, 768343389, 768345824, 768348653, 768351597, 768352965,
  768353968, 768355553, 768357901, 768360195, 768363462, 768364539, 768365723, 773265828, 786734552, 786735496, 786736377, 786750210, 786750977,
  786751962, 786753328, 786753795, 786754896, 786755795, 786756550, 786757020, 786757486, 786765787, 786766982, 786767433, 786768014, 786768708,
  786769180, 786769754, 786770943, 786771632, 786772162, 786772857, 786773470, 797983565, 798656070, 798657760, 798659001, 798660100, 798661528,
  798663562, 798665077, 798668071, 798672579, 798675352, 798677042, 798678548, 798679502, 798680261, 798682531, 798684422, 798687970, 798691035,
  798691798, 798692397, 798695723, 798696859, 798698100, 798698496, 798699295, 808372277, 810086341, 810087060, 810087568, 810088148, 810088497,
  810089057, 810089483, 810089806, 810090279, 810090923, 810091336, 810092751, 810096920, 810100084, 810100440, 810101315, 815002640, 815003745,
  815004261, 815004895, 815005226, 815005563, 815005912, 815006183, 815006501, 827575985, 827577015, 827577814, 827580230, 827583864, 827584445,
  827586415, 827588161, 827589939, 827591723, 827593017, 827596660, 827598027, 827598665, 827600072, 827600884, 827602410, 827603225, 827603760,
  827604581, 827606144, 827610221, 827610804, 827611632, 827626194, 827627449, 827628279, 827628844, 827629519, 827630459, 827634483, 835718214,
  840390556, 840391083, 840392546, 840393029, 840393453, 840393877, 840394582, 840395463, 840395899, 840396188, 840397264, 840397647, 840398211,
  840400855, 840401202, 840401695, 840402283, 840402655, 840403467, 840403787, 840404424, 840404972, 840405447, 840405952, 840406343, 840406659,
  840408400, 840414630, 840415018, 840415385, 840415740, 840416397, 841204528, 841260662, 845361872, 845363710, 845364122, 845364583, 845365085,
  845365410, 845365778, 845366068, 845366492, 845366838, 845367221, 845371246, 845372011, 845372342, 845372954, 845373348, 845373704, 845374147,
  845374532, 845375157, 845375570, 845376077, 845376467, 845377348, 845378114, 845378501, 845378953, 845379270, 845379560, 845379835, 845380113,
  845380379, 845380658, 845381533, 845381822, 845382101, 845382377, 845383041, 845383488, 845383828, 845384126, 845384402, 845384903, 845385170,
  845385450, 845385963, 845386313, 861192303, 861192698, 861193086, 861201554, 861202138, 861202545, 861203236, 861203745, 861204521, 861204947,
  861205749, 861207409, 861209671, 861210235, 861211215, 861211833, 861212439, 861213244, 861213685, 861214523, 861215504, 861216016, 861257615,
  861258091, 861258630, 861259059, 861259749, 861261237, 861261760, 861262335, 861263068, 861263655, 861264418, 861264942, 861265453, 861266196,
  861267241, 861267693, 861268123, 861268635, 861269400, 861269806, 861270127, 861270507, 861293588, 861293946, 861294593, 861294966, 861295318,
  861295830, 861296516, 861298349, 861298749, 861300609, 861300955, 861304511, 861304966, 861305889, 861306785, 861307431, 861308483, 861308881,
  861309335, 861309798, 861310122, 861310431, 861315773, 861316283, 861316625, 861322726, 861324642, 861327052, 861328551, 861328981, 861333045,
  861333407, 861333773, 861334335, 861334905, 861335362, 861336028, 861336415, 861336679, 861337041, 861337391, 861337812, 861338091, 861338363,
  861339002, 861339298, 861339562, 861339872, 861340192, 861340500, 861341570, 861341987, 861342281, 861342475, 861342736, 861343104, 898606251,
  898607010, 898608172, 898609371, 898610093, 898610971, 898612291, 898613410, 898614142, 898615608, 898616230, 898616718, 898617206, 898617618,
  898618644, 898619537, 898620142, 898620716, 898621333, 898621724, 898622678, 898623800, 898624279, 898625150, 898626056, 898626926, 898627890,
  898801423, 898808122, 898809010, 898810279, 898811905, 898823305, 898824486, 898826799, 898827188, 898827717, 898828193, 898831024, 898831480,
  898831931, 898844564, 898846363, 898847525, 898848637, 898849036, 898849450, 898849881, 898850571, 898851144, 898853543, 898854402, 898856357,
  898856653, 898857317, 898858166, 898858506, 898858840, 898859096, 898859359, 898862050, 898862587, 898863635, 898864062, 898864911, 898865191,
  898866680, 898866969, 898867244, 898867654, 898868160, 898869146, 898869676, 898869944, 898870780, 898871265, 898871770, 898872073, 898872374,
  898872759, 898873440, 898874219, 898874559, 898875501, 898878347, 898880147, 898881359, 898883239, 898884533, 898887636, 898888598, 898907583,
  898908991, 898910103, 898911361, 898911840, 898912694, 898913039, 898913426, 898927343, 898927911, 898928286, 898929472, 898929936, 898930820,
  898931308, 898931699, 898932090, 898937550, 898937850, 898938259, 898938568, 898938994, 899033669, 899034429, 899035547, 899036752, 899038564,
  899039442, 899040520, 899041023, 899041597, 899042325, 899042858, 899043284, 899043697, 899044197, 899044692, 899045272, 899045780, 899046769,
  899047131, 899047833, 899048859, 899049465, 899050180, 899050599, 899051234, 899051647, 899052108, 899052560, 899053057, 899053505, 899054146,
  899054598, 899055815, 899056316, 899056748, 899059620, 899060077, 899060891, 899061453, 899062511, 899062903, 899063420, 899063799, 899064447,
  899064818, 899065254, 899065954, 899066598, 899066989, 899067651, 899068514, 899068850, 899069230, 899069543, 899069789, 899071135, 899071778,
  899072136, 899072701, 899073100, 899074140, 899074451, 899074664, 899074951, 899075186, 899075404, 899075711, 899076276, 899076717, 899076982,
  899077188, 899077396, 899077692, 899081142, 899082247, 958310935, 958311200, 958311477, 958311831, 958312089, 958312379, 958312714, 958313521,
  958313878, 958314196, 958314615, 958315224, 958315436, 958315669, 958315832, 958315997, 958316181, 958316465, 958316727, 958317373, 958317638,
  958317987, 958318166, 958318372, 958318600, 958318863, 958319144, 958319382, 958319612, 958319922, 958320247, 958320435, 958320642, 958324607,
  958324807, 958325005, 958325241, 958325455, 958325725, 958326019, 958326275, 958326502, 958326711, 958326967, 958363861, 958364335, 958364729,
  958365348, 958365635, 958366131, 958366450, 958366702, 958366938, 958367199, 958367615, 958368375, 958368621, 958368868, 958369405, 958369670,
  958369944, 958370174, 958370387, 958370610, 958370853, 958371223, 958394150, 958394406, 958394703, 958395019, 958395290, 958395580, 958396512,
  958396900, 958397344, 958397567, 958397802, 958398047, 958398245, 958398464, 958398764, 958399047, 958399354, 958400397, 958454120, 958454449,
  958455683, 958456557, 958456981, 958457583, 958457890, 958458304, 958458700, 958459089, 958459367, 958459596, 958459855, 958460364, 958460600,
  958460859, 958461087, 958461311, 1005329848, 1005330590, 1005331088, 1005332614, 1005333303, 1005334154, 1005334813, 1005335312, 1005336191,
  1005336643, 1005337085, 1005337503, 1005337841, 1005338114, 1005338436, 1005338709, 1005339350, 1005340901, 1005341306, 1005341532, 1005341828,
  1005342098, 1005342703, 1005343087, 1005343521, 1005343956, 1005344236, 1005344501, 1005344892, 1005345269, 1005345692, 1005346150, 1005346564,
  1005348330, 1005359160, 1005359858, 1005363107, 1005363606, 1005364044, 1005364780, 1005365128, 1005365408, 1005365756, 1005366128, 1005370642,
  1005372029, 1005372436, 1005372955, 1005373422, 1005373850, 1005374190, 1005374624, 1005374929, 1005375251, 1005375662, 1005376761, 1005377075,
  1005627451, 1005628200, 1005628777, 1005629295, 1005629638, 1005629905, 1005630304, 1005630684, 1005631017, 1005631918, 1005632483, 1005632722,
  1005633713, 1005634015, 1005634510, 1005634850, 1005635172, 1005635564, 1005636014, 1005636413, 1005637170, 1005637535, 1005638019, 1005639166,
  1005640272, 1005641285, 1005641631, 1005641982, 1005642486, 1005643037, 1005644339, 1005645482, 1005646051, 1005646764, 1005647225, 1005647682,
  1005648247, 1005648549, 1005648882, 1005649265, 1005649783, 1005650217, 1005651057, 1005651914, 1005652299, 1005652607, 1005653190, 1005653505,
  1005653829, 1005654156, 1005655112, 1005655444, 1005655815, 1005656455, 1005656822, 1005657138, 1005657572, 1005658024, 1005658286, 1005658880,
  1005660290, 1005660771, 1005661368, 1005661771, 1005662613, 1006105635, 1006107075, 1006107596, 1006109552, 1006110974, 1006112853, 1006113222,
  1006113933, 1006114327, 1006114771, 1006115131, 1006116441, 1006117045, 1006117752, 1006118190, 1006118653, 1006119326, 1006119821, 1006120242,
  1006120700, 1006121056, 1006121555, 1006124622, 1006125142, 1006125555, 1006126266, 1006127100, 1006127609, 1006128073, 1006129365, 1006129769,
  1006130197, 1006130607, 1006130926, 1006131459, 1006131819, 1006132889, 1006133209, 1006157104, 1006157471, 1006157913, 1006158968, 1006159350,
  1006615576, 1006616139, 1006619603, 1006621129, 1006650523, 1006652865, 1006654366, 1006655000, 1006655963, 1006656419, 1006657403, 1006658461,
  1006658809, 1006659239, 1006659755, 1006661203, 1006662740, 1006663092, 1006663521, 1006664194, 1006665049, 1006665461, 1006665809, 1006666147,
  1006666419, 1006666727, 1006667162, 1006667564, 1006667976, 1006668313, 1006668644, 1006669640, 1006670225, 1006670582, 1006670878, 1011391086,
  1011391400, 1011391643, 1011392467, 1011393305, 1011393653, 1030701949, 1030703610, 1030704489, 1030705950, 1030707978, 1030711331, 1030712338,
  1030713594, 1030714648, 1030716821, 1030717760, 1030718015, 1030718660, 1030718995, 1030719222, 1030719578, 1030720019, 1030720227, 1030720613,
  1030720803, 1030720966, 1030721262, 1030721558, 1030721802, 1030722033, 1030722309, 1030722649, 1030722844, 1030723139, 1030723412, 1030723950,
  1030724889, 1030725101, 1030725394, 1030725934, 1030726566, 1030726839, 1030727432, 1030727687, 1030728207, 1030728456, 1030728808, 1030729052,
  1030729491, 1031860326, 1031860510, 1031860660, 1031860845, 1031860960, 1031861152, 1031861300, 1031861585, 1031861867, 1031862335, 1031862806,
  1031863316, 1031863702, 1031864443, 1031865388, 1031865846, 1031866708, 1031867088, 1031867724, 1031867948, 1031868564, 1031869527, 1031869950,
  1031871129, 1031871304, 1031871436, 1031871747, 1031874149, 1031874899, 1031875170, 1031875624, 1031876001, 1031876290, 1031876498, 1031876790,
  1031877332, 1031877787, 1031878120, 1031878326, 1031878517, 1031879074, 1031879876, 1031880593, 1031880874, 1031881188, 1031881535, 1031881897,
  1031882258, 1031882679, 1031883129, 1149541286,
];

export default assets;
