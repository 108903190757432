import { DeflyWalletConnect } from '@blockshake/defly-connect';
import { PeraWalletConnect } from '@perawallet/connect';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PROVIDER_ID, WalletProvider, useInitializeProviders } from '@txnlab/use-wallet';
import { WalletConnectModalSign } from '@walletconnect/modal-sign-html';
import { DefaultSeo } from 'next-seo';
import { Toaster } from 'react-hot-toast';
import 'tailwindcss/tailwind.css';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PageProgress from '../components/PageProgress';
import Providers from '../components/Providers';
import { Resolvers } from '../components/Resolvers';
import '../styles/globals.css';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const title = 'Shitty Kitties - The Shittiest NFT community on Algorand';
  const description = `Shitty Kitties is an NFT community consisting of 4,444 Shitty Kitties, 500 Shitty Cities, and tons of utility. Let's get shitty together!`;

  const providers = useInitializeProviders({
    providers: [
      { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
      { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
      {
        id: PROVIDER_ID.WALLETCONNECT,
        clientStatic: WalletConnectModalSign,
        clientOptions: {
          projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_ID,
          metadata: {
            name: title,
            description,
            url: 'https://www.shittykitties.art',
            icons: ['https://walletconnect.com/walletconnect-logo.png', 'https://www.shittykitties.art/shitty-kitties-logo.png'],
          },
        },
      },
      // { id: PROVIDER_ID.DAFFI, clientStatic: DaffiWalletConnect },
      // { id: PROVIDER_ID.EXODUS },
    ],
  });

  return (
    <>
      <DefaultSeo
        title={title}
        description={description}
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: 'https://www.shittykitties.art',
          site_name: 'Shitty Kitties',
          title,
          description,
          images: [
            {
              url: 'https://www.shittykitties.art/twitter-share-image.png',
            },
          ],
        }}
        twitter={{
          handle: '@MinnerAlgo',
          site: '@MinnerAlgo',
          cardType: 'summary_large_image',
        }}
      />

      <QueryClientProvider client={queryClient}>
        <WalletProvider value={providers}>
          <Providers session={session}>
            <Resolvers />
            <PageProgress />
            <Header />
            <main>
              <Component {...pageProps} />
            </main>
            <Footer />
            <Toaster position='bottom-center' toastOptions={{ duration: 3000 }} />
          </Providers>
        </WalletProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
