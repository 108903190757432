import { useBalanceResolver } from '../hooks/useBalanceStore';
import { useBlocksResolver } from '../hooks/useBlocksStore';
import { useCitiesResolver } from '../hooks/useCitiesStore';
import { useKittiesResolver } from '../hooks/useKittiesStore';
import { useUserAssetsResolver } from '../hooks/useUserAssetsStore';

export const Resolvers = () => {
  useUserAssetsResolver();
  useKittiesResolver();
  useBlocksResolver();
  useCitiesResolver();
  useBalanceResolver();

  return <></>;
};
