import axios from 'axios';
import invariant from 'tiny-invariant';

async function finalizeBuild(txn) {
  invariant(txn, 'txn not found');

  const { data } = await axios.post(
    '/api/cities/finalize',
    { txn },
    {
      headers: {
        Authorization: process.env.NEXT_PUBLIC_API_KEY,
      },
    }
  );

  return data;
}

export default finalizeBuild;
