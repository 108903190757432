const allItems = [
  {
    id: 1075152506,
    unitName: 'SHITPASS',
    name: 'Shittier Mint Pass',
    image: 'https://ipfs.algonode.xyz/ipfs/QmQN76pmLXRGHti2h7Md2LWyQBveXoFrXJNoXuYjQ3u8Ni',
  },
  {
    id: 809610090,
    unitName: 'SHITBOX',
    name: 'Shitty Treasure Box (Old)',
    image: 'https://ipfs.algonode.xyz/ipfs/bafybeig6xtwpxpnbuoambspsq2grwsv3w3f3ff6viciczvdbfltccsqkf4#i',
  },
  {
    id: 1080000898,
    unitName: 'SHITBOX',
    name: 'Shitty Treasure Box (New)',
    image: 'https://ipfs.algonode.xyz/ipfs/QmU3RaheKgyDYHX9bjBeXYvbhymWZQVYq8VRWGoptFTx4W',
  },
  {
    id: 1080001698,
    unitName: 'POOOFBOX',
    name: 'Shitty Pooof Treasure Box',
    image: '/boxes/poof-treasure.png',
  },
  {
    id: 1080002810,
    unitName: 'SKULIBOX',
    name: 'Shitty Skuli Frens Treasure Box',
    image: '/boxes/skuli-treasure.png',
  },
  {
    id: 1086653827,
    unitName: 'TUCANBOX',
    name: 'Shitty Tucan Treasure Box',
    image: '/boxes/tucan-treasure.png',
  },
  {
    id: 1181063592,
    unitName: 'ROYALBOX',
    name: 'Shitty Royals Treasure Box',
    image: '/boxes/little-royals.jpeg',
  },
];

export default allItems;
