import { useMutation } from '@tanstack/react-query';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useAlgo } from '../hooks/useAlgo';
import FullScreenLoader from './FullScreenLoader';
import { useState } from 'react';

export default function LoginDiscordButton() {
  const { data: session } = useSession();
  const { updateShittyUser } = useAlgo();
  const router = useRouter();
  const discordConnected = session?.user?.discord?.id;
  const [loading, setLoading] = useState(false);

  const disconnectMutation = useMutation({
    mutationFn: async () => {
      setLoading(true);
      await updateShittyUser({
        discord: null,
      });
    },
    onSuccess: () => {
      window.location.reload();
    },
    onError: () => {
      setLoading(false);
    },
  });

  const onDisconnect = () => {
    disconnectMutation.mutate();
  };

  if (!!session && discordConnected) {
    return (
      <button
        onClick={onDisconnect}
        className='flex items-center justify-between px-4 py-2 gap-3 transition-opacity duration-200 hover:opacity-75 w-full text-gray-100'
      >
        <FullScreenLoader loading={loading} label='Disconnecting Discord...' />
        <div className='justify-center items-center flex gap-3'>
          <div className='w-8'>
            <img src={session.user.discord.image} alt={session.user.discord.name} className='rounded-md' />
          </div>
          <div className='flex flex-col text-left'>
            <span className='text-sm text-gray-500 leading-0 -mb-1'>Discord</span>
            <span className='leading-0'>{session.user.discord.name}</span>
          </div>
        </div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 640 512'
          fill='currentColor'
          className='w-5 h-5 text-gray-500 hover:text-gray-100 transition duration-200'
          title='Disconnect Discord'
        >
          <path d='M48.4 14.8L29.4 .1 0 38 19 52.7 591.5 497.2l19 14.7L639.9 474l-19-14.7-131-101.7 90.3-90.3c56.2-56.2 56.2-147.4 0-203.6S432.8 7.4 376.6 63.7L365.3 75l45.3 45.3 11.3-11.3c31.2-31.2 81.9-31.2 113.1 0s31.2 81.9 0 113.1l-96 96-32.9-25.5c23.8-53 14-117.4-29.5-160.9c-52.4-52.4-135.1-56-191.6-10.7L48.4 14.8zM238.2 162.2c30.1-15.4 67.9-10.6 93.1 14.6c20.1 20.1 27.3 48.4 21.5 74.3L238.2 162.2zM116.6 187.9L59.8 244.7c-56.2 56.2-56.2 147.4 0 203.6s147.4 56.2 203.6 0L274.7 437l-45.3-45.3-11.3 11.3c-31.2 31.2-81.9 31.2-113.1 0s-31.2-81.9 0-113.1l62.2-62.2-50.6-39.9zm104.9 82.6c-2.2 39.5 11.7 79.7 41.9 109.9c38.8 38.8 94.3 50.8 143.4 36.1l-185.3-146z' />
        </svg>
      </button>
    );
  }

  return (
    <button
      onClick={() =>
        signIn('discord', {
          callbackUrl: `${window.location.origin}${router.asPath}`,
        })
      }
      className='flex items-center px-4 py-2 space-x-3 transition-opacity duration-200 hover:opacity-75 w-full text-gray-100'
    >
      <div className='w-8 h-8 bg-gray-700 rounded-md p-2 items-center justify-center'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' className='transition-colors duration-200 mt-0.5' fill='currentColor'>
          <path d='M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z' />
        </svg>
      </div>
      <span>Connect Discord</span>
    </button>
  );
}
