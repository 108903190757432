import React, { createContext, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import updateCityDetailsApi from '../utils/updateCityDetails';
import { useAlgo } from './useAlgo';
import { useCitiesStore } from './useCitiesStore';
import { useUserAssetsStore } from './useUserAssetsStore';

const MyCitiesContext = createContext({});
export const useMyCities = () => {
  const context = useContext(MyCitiesContext);
  if (!context) {
    throw new Error('useMyCities must be used within a MyCitiesProvider');
  }
  return context;
};

export const MyCitiesProvider = ({ children }) => {
  const { allCities } = useCitiesStore();
  const { optInForUpdateCityDetails } = useAlgo();
  const [updateLoading, setUpdateLoading] = useState(false);
  const { userAssets } = useUserAssetsStore();

  const myAssetIds = userAssets.map((asset) => asset['asset-id']);
  const myCities = allCities.filter((city) => myAssetIds.includes(city.index));

  const checkIfMyCity = (index) => {
    const myCity = myCities.find((myCity) => myCity.index === index);
    return myCity;
  };

  const updateMyCity = async (input) => {
    try {
      setUpdateLoading(true);
      const txn = await optInForUpdateCityDetails(input);
      await updateCityDetailsApi(txn);
      toast.success('City details updated');
      window.location.reload();
    } catch (error) {
      toast.error(error.response.data || 'Something went wrong');
      console.error(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <MyCitiesContext.Provider
      value={{
        myCities,
        checkIfMyCity,
        allCities,
        updateMyCity,
        updateLoading,
      }}
    >
      {children}
    </MyCitiesContext.Provider>
  );
};
