import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { create } from 'zustand';
import { getAssets } from '../utils/getAssets';
import { useAlgo } from './useAlgo';

export const useUserAssetsQuery = ({ address }) => {
  return useQuery({
    enabled: !!address,
    queryKey: ['userAssets', address],
    queryFn: () => getAssets(address),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    staleTime: 20000,
  });
};

export const useUserAssetsStore = create((set) => ({
  userAssets: [],
  setUserAssets: (userAssets) => set({ userAssets }),
  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),
  isError: false,
  setIsError: (isError) => set({ isError }),
}));

export const useUserAssetsResolver = () => {
  const { address } = useAlgo();
  const { data, isLoading, isError } = useUserAssetsQuery({ address });
  const { setUserAssets, setIsLoading, setIsError } = useUserAssetsStore();

  useEffect(() => {
    if (data) {
      setUserAssets(data);
    }
  }, [data]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setIsError(isError);
  }, [isError]);
};
