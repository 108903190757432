import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { get } from 'lodash';
import React, { createContext, useContext } from 'react';
import { useAlgo } from './useAlgo';

const AdventuresContext = createContext({});
export const useAdventures = () => useContext(AdventuresContext);

export const AdventuresProvider = ({ children }) => {
  const { address, optInToAssetsForAdventureStart, optInForClaimReward } = useAlgo();

  const adventuresQuery = useQuery({
    queryKey: ['adventures', address],
    queryFn: async () => {
      const { data } = await axios.get(`/api/adventures?address=${address}`);
      return data;
    },
    enabled: !!address,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
  });
  const adventures = get(adventuresQuery, 'data', []);
  const adventuresLoading = get(adventuresQuery, 'isLoading', false);

  const reloadAdventures = async () => {
    await adventuresQuery.refetch();
  };

  const startAdventure = async (adventure, party) => {
    if (!adventure) {
      throw new Error('Id is required');
    }

    if (party.length !== adventure.requirements.partySize) {
      throw new Error(`Your party isn't big enough to start the adventure.`);
    }

    const partyKitties = party.map((kitty) => kitty.id);
    const uniqueKitties = [...new Set(partyKitties)];
    if (partyKitties.length !== uniqueKitties.length) {
      throw new Error('You cannot have the same kitty in your party twice.');
    }

    try {
      const txns = await optInToAssetsForAdventureStart(adventure);
      await axios.post(
        '/api/adventures/start',
        {
          adventureId: adventure.id,
          party,
          address,
          txn: txns[0].txId,
        },
        {
          headers: {
            Authorization: process.env.NEXT_PUBLIC_API_KEY,
          },
        }
      );
      await reloadAdventures();
    } catch (error) {
      throw new Error(get(error, 'response.data.error'));
    }
  };

  const claimReward = async (entry, rewardId) => {
    try {
      const txns = await optInForClaimReward(rewardId);
      const { data } = await axios.post(
        '/api/adventures/claim',
        {
          adventureId: entry.adventureId,
          address: entry.address,
          txn: txns[0].txId,
        },
        {
          headers: {
            Authorization: process.env.NEXT_PUBLIC_API_KEY,
          },
        }
      );
      return data;
    } catch (error) {
      console.error(error);
      throw new Error(error.response.data.error);
    }
  };

  const resetAdventure = async (adventureId) => {
    try {
      const txns = await optInToAssetsForAdventureStart(true);
      await axios.post(
        '/api/adventures/reset',
        {
          adventureId,
          address,
          txn: txns[0].txId,
        },
        {
          headers: {
            Authorization: process.env.NEXT_PUBLIC_API_KEY,
          },
        }
      );
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  };
  return (
    <AdventuresContext.Provider
      value={{
        reloadAdventures,
        adventures,
        adventuresLoading,
        startAdventure,
        claimReward,
        resetAdventure,
      }}
    >
      {children}
    </AdventuresContext.Provider>
  );
};
