const assets = [
  326189642, 326197463, 328494610, 328495176, 328496756, 328497556, 328498815, 328499699, 328500390, 328501539, 328502288, 328503002, 328503506,
  328504167, 328505062, 328505825, 328506449, 328507319, 328507916, 328517537, 328520459, 328521744, 328522422, 328523922, 328524762, 328526043,
  328527340, 328528518, 328530483, 328536863, 328537993, 328539074, 328539965, 328540781, 328541524, 328542664, 328543527, 328544786, 328545759,
  328546381, 328550271, 328558722, 328559225, 328559956, 328560895, 328562025, 328562778, 328563689, 328565014, 328566193, 329839470, 330597170,
  330598116, 330598680, 330599549, 330600155, 334782287, 334783124, 334794211, 334794938, 334795728, 334797107, 334797672, 334798386, 334799499,
  334800560, 334801479, 334802763, 335090720, 335091495, 335119178, 335121926, 335127139, 335128012, 335130906, 335131430, 335136344, 335136993,
  335137631, 335138360, 335138808, 335139687, 335140288, 335141021, 335141503, 335149637, 335150149, 335150903, 335151590, 335153323, 335154671,
  335155248, 335155944, 335166547, 335166961, 335167490, 335180015, 335181136, 335181726, 337012363, 337012926, 337013855, 337015250, 337015576,
  337015864, 337016411, 342574076, 342574643, 342575058, 342575821, 342576301, 342576919, 342577575, 342578683, 342579388, 342579915, 342581684,
  342582343, 342583034, 342583957, 342584684, 342589913, 342591190, 342592000, 342593153, 342594016, 342594798, 342597464, 342598236, 342599162,
  342600767, 342601496, 342602142, 342602925, 342604022, 342610168, 342610860, 342612630, 342613206, 342614640, 342616181, 342617512, 342618350,
  342629086, 342629720, 342630781, 342631450, 342632405, 342633196, 342634204, 342634860, 342635929, 342636903, 342638033, 342638767, 342639884,
  348421186, 350960333, 350961963, 350986326, 350986888, 350987324, 350988162, 350988713, 350989295, 350989983, 350990941, 350992075, 350992944,
  351018216, 351035082, 351035582, 351036049, 351036402, 351036782, 351045432, 351045938, 351046296, 351046627, 351047051, 351048078, 351049039,
  351049598, 351056027, 351056633, 351057416, 351058084, 351058627, 351059326, 351120479, 351121162, 351121689, 351127310, 351128646, 351137771,
  351140993, 351142474, 351143875, 351147149, 351147615, 351148747, 351149755, 351152918, 351159041, 351165061, 351165507, 351165928, 357805558,
  357806075, 357806679, 357807254, 357807687, 357810208, 357810515, 357811089, 357811513, 357812204, 357812643, 357813060, 357813498, 357814858,
  357815902, 357820088, 357820613, 357820948, 357821399, 357822102, 357828954, 357829470, 357857342, 357857972, 357877467, 357878164, 357878647,
  357879213, 357880107, 357880623, 357881145, 357881713, 357882255, 357883312, 358119742, 358120030, 358120725, 358121671, 358122092, 358122475,
  364041108, 364045626, 364046945, 364048155, 364049436, 364051097, 370138614, 370139149, 370139978, 370140425, 370140833, 370141226, 370141772,
  370142504, 370146144, 370174670, 370175275, 370175829, 370177059, 370178088, 370179699, 370180272, 370192715, 370193543, 370194099, 370194766,
  370200032, 370201662, 370202895, 370203657, 370204471, 370207486, 370208074, 370208620, 370209227, 370211487, 370212202, 370214171, 370216009,
  370216600, 370220151, 370221172, 370222171, 370222671, 378144244, 378144874, 378145365, 378147187, 378147789, 378148381, 378154517, 378156031,
  378156497, 378157329, 378157733, 378158613, 378159296, 378159732, 378160339, 378161184, 378162620, 378175401, 378176036, 378176471, 378177995,
  378178789, 378179429, 378180154, 378181799, 378182338, 378182828, 378184850, 378185763, 378186307, 378186905, 378187510, 378188233, 378188959,
  378189282, 378189742, 384115563, 386676057, 386676630, 386678024, 386678534, 386679027, 386679558, 386680355, 386681637, 386682222, 386694998,
  386695758, 386697020, 386698016, 386698707, 386703284, 386704623, 386705461, 386706145, 386707457, 386708332, 386754623, 386755533, 386756478,
  386941384, 386941848, 386942367, 386942977, 386954015, 386954778, 386955578, 386956116, 387779639, 387780523, 390940467, 396523835, 398263290,
  398266816, 398267736, 398268460, 398269458, 398270277, 398271233, 398307142, 398307950, 398309860, 398310628, 398311670, 398312573, 398313581,
  398315817, 398316892, 398317819, 398318793, 398319545, 398320385, 398321353, 398322208, 398323043, 398324040, 398327453, 398328641, 398329775,
  398330377, 398331199, 398331833, 398332587, 398333216, 404269571, 404270194, 404271051, 404271649, 404272362, 404273247, 404274138, 404274994,
  404275773, 404277289, 404278239, 404278986, 404281390, 404282104, 404283294, 404284041, 404284741, 404285472, 404286338, 404287266, 404288146,
  404289065, 404290245, 404290882, 411413044, 418136017, 418136923, 418137888, 418139488, 418140351, 418141283, 418142231, 418143075, 418143875,
  418147060, 418147746, 418148320, 418148879, 418217057, 418217851, 418219030, 418222343, 418223077, 418223699, 418224422, 418225505, 418226238,
  418226954, 418227757, 418228431, 424727286, 435015927, 435062343, 435062845, 435063502, 435064009, 435064696, 435065284, 435065868, 435066409,
  435066977, 435067688, 435068291, 435068763, 435069474, 435070411, 435071083, 435071772, 435072993, 435073627, 435074224, 435074828, 435075432,
  435076139, 435077024, 445431829, 445432410, 445437581, 445437910, 445438349, 445439081, 445439401, 445439766, 445440046, 445440302, 445440739,
  445441164, 445442004, 445442449, 445442820, 445443099, 445444339, 445444628, 445444972, 445445226, 445445646, 445446049, 445446541, 445446786,
  445447240,
];

export default assets;
