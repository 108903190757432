const assets = [
  608403840, 608405608, 608407991, 681583333, 681587190, 681588105, 681592996, 681596789, 681601077, 681628298, 681636769, 681644420, 681649977,
  681684967, 681716211, 681737100, 681760842, 684928078, 691999601, 692854106, 692877321, 692881043, 692887501, 692891344, 692894207, 692895849,
  692898945, 692902368, 692904483, 692906726, 692908216, 692910637, 692921377, 692932513, 692939220, 692947751, 692949851, 692952306, 692958093,
  693617237, 696186302, 699274849, 701667006, 701667672, 701669308, 701670340, 701671205, 701671886, 701672534, 701673362, 701674983, 701675745,
  701676675, 701677567, 701678329, 701679118, 701679909, 701680470, 701681217, 701682275, 701683403, 701741920, 705536592, 707627626, 707628011,
  707632102, 707635872, 707639007, 709151521, 709152609, 709154006, 709154782, 709155779, 709156738, 709158425, 709159244, 709159783, 709160598,
  709161727, 709162188, 709162861, 709164612, 709165831, 709166517, 709167041, 709167557, 709168029, 709168551, 723357509, 723680282, 723680998,
  723681595, 723682361, 723683608, 723700950, 723701919, 723705653, 723706791, 723707551, 723708419, 723710814, 723711603, 723713385, 723714328,
  723715147, 723717809, 723722123, 724343973, 735285856, 735939842, 735941299, 735941867, 735943125, 735944607, 735945385, 735946297, 735947222,
  735948731, 735949401, 735949932, 735951151, 735952966, 735954820, 735956975, 735957940, 735958482, 735958846, 735959612, 735960789, 736081236,
  738928382, 742924821, 743889051, 744822243, 745650073, 745651403, 745652219, 745652893, 745654601, 745655294, 745656726, 745657684, 745658557,
  745659189, 745659961, 745660715, 745661632, 745662348, 745663786, 745664569, 745665838, 745666924, 745667514, 745668691, 747443439, 751764263,
  751765506, 751766817, 753376294, 753376883, 753377381, 753377867, 753378220, 753378626, 753379044, 753379282, 753379751, 753380115, 753382027,
  753383120, 753383786, 753384181, 753386792, 753387768, 753388583, 753388994, 753406682, 753406961, 753888035, 759647767, 761220247, 761221191,
  761221693, 761222396, 761222953, 761223342, 761223724, 761224222, 761225767, 761226170, 761226561, 761335947, 761336638, 761336918, 761337313,
  762013466, 762969876, 768017519, 768018908, 768019976, 768021438, 768022244, 768023465, 769919356, 769919936, 769921272, 769922242, 769922634,
  769923109, 769924717, 769925447, 769926936, 769927514, 770545801, 775689176, 775945240, 776435210, 776436693, 776438746, 778217390, 778217904,
  778218280, 778218525, 778219049, 778219525, 778220890, 778221379, 778221737, 778222125, 780013555, 785246851, 785248205, 785249017, 785806571,
  785807562, 785808285, 785809107, 785810005, 785810371, 785810667, 785811039, 785811717, 785812136, 787229955, 790523593, 790524500, 790525645,
  791373303, 792049373, 792049858, 792050722, 792050976, 792051559, 792051771, 792053380, 792054043, 792054759, 792056117, 797052785, 797252254,
  797253159, 797254265, 798886015, 798886387, 798886875, 798887602, 798888240, 798889565, 798890408, 798891259, 798891904, 798892965, 799496606,
  799506655, 799508708, 799509330, 799510184, 799511410, 803333900, 803334346, 803335026, 803338335, 803338952, 803341280, 803342707, 803343112,
  803344360, 803345880, 803524737, 817894841, 817895129, 817895366, 817991991, 819081157, 819407840, 819928539, 819929177, 819929870, 819937807,
  819938095, 819939071, 819939356, 819939922, 819940253, 819941073, 822159154, 825335133, 825335549, 825335789, 827945776, 827946295, 827955531,
  827956224, 827956520, 827958382, 827963279, 827966017, 827967646, 827968892, 829740356, 832048058, 833025235, 833025457, 833025793, 833026080,
  833026615, 834100323, 834798623, 834798824, 834799155, 834799480, 834800041, 834800501, 834800676, 834801005, 834801597, 834802263, 834802864,
  834803015, 834803451, 834803836, 834804107, 835300392, 836943404, 836944114, 836945350, 836946017, 836947670, 836952240, 836952669, 837107999,
  839017369, 839214364, 839764158, 839764587, 841770362, 841770639, 841770952, 841771472, 841771776, 841772479, 841772730, 841773059, 841773873,
  841774250, 841775451, 841780731, 841867468, 842310304, 842319747, 843039131, 844195141, 848314442, 848330864, 848350461, 849167334, 849167628,
  849167836, 849168157, 849168382, 849168773, 849168928, 849169342, 849169552, 849169873, 850779143, 850780326, 850780560, 850780951, 856091683,
  856244398, 856244816, 856245162, 856647985, 856648424, 856651919, 856652245, 856652740, 856653100, 856653513, 856654450, 856654719, 856655133,
  857582744, 857583058, 857583362, 863777950, 863778630, 863778921, 863779361, 863779781, 863780216, 863780865, 863781453, 863785488, 863787391,
  864411310, 866504877, 867423216, 870681959, 870682719, 870683433, 870684677, 870685546, 870829277, 871736157, 871736456, 871736783, 871737145,
  871737329, 871737838, 871738112, 871738746, 871738983, 871739630, 872416645, 873269989, 873282222, 874457057, 877511579, 877512414, 877513548,
  879041951, 879042572, 879043016, 879044669, 879044950, 879046172, 879046813, 879047622, 879050521, 879051271, 880788809, 880789720, 881048528,
  885200653, 885201222, 885201506, 885201857, 885202484, 886290588, 886450434, 886474303, 886474721, 886475053, 886475833, 886476063, 886476361,
  886477149, 886477532, 886478304, 886478583, 886932442, 887498235, 888245639, 888245877, 888246215, 889175738, 889177117, 889179288, 892406534,
  892407179, 892407590, 892455259, 894052939, 894053344, 894053953, 894054470, 894055797, 894057457, 894058350, 894058846, 894059219, 894059514,
  894607726, 894608069, 894608607, 895956098, 895956555, 895956984, 899351285, 900507422, 900507552, 900507721, 900507865, 900508037, 900773152,
  900773416, 900773693, 900774548, 900775024, 900775243, 900775480, 900775998, 900776425, 900776632, 905675960, 905676308, 905676509, 905676989,
  905677780, 906798935, 906799247, 906800671, 906800908, 906801645, 908380716, 908380905, 908381218, 908381414, 908381986, 908382162, 908383629,
  908383893, 908384142, 908384482, 908871578, 908872231, 915582099, 915847887, 915849270, 915850202, 915851146, 918357159, 918360443, 918361005,
  918361899, 918362612, 918363660, 918364674, 918365511, 918366370, 918367050, 920413117, 920681459, 920682424, 920683387, 924777916, 926933505,
  926940664, 926941429, 927431909, 928099368, 928099885, 928100371, 928100936, 928101445, 928103110, 928103646, 928104170, 928104804, 928105350,
  928825450, 931149232, 934889732, 934890021, 934890486, 934891275, 934892313, 937145930, 937146233, 937146543, 937146993, 937147835, 937151144,
  937151618, 937153597, 937155335, 937156427, 939546933, 944317196, 944334547, 944334846, 944335831, 944336440, 944337073, 945516193, 945516430,
  945516727, 945516931, 945517658, 945517996, 945518417, 945518886, 945519145, 945519431, 947638335, 948793123, 953007233, 953007670, 953007992,
  954681363, 954681630, 954681996, 954682311, 954682706, 954682991, 954683237, 954683579, 954683833, 954684146, 956294214, 956295198, 960849231,
  960849427, 960849835, 961479769, 961480569, 961480971, 961481363, 961481575, 961481812, 961482068, 961482321, 961482549, 961482726, 962714362,
  969918248, 969919407, 969919705, 969920042, 969920338, 969921363, 969921715, 969922935, 969923541, 969924482, 969924811, 969925556, 969925769,
  969926437, 969926962, 969927265, 969928225, 969928809, 969929138, 969929954, 969930915, 969931211, 969931626, 969932065, 969932427, 969932716,
  969933126, 969933566, 976506617, 1003333009, 1006310484, 1006311002, 1007563904, 1007564404, 1007564637, 1007565073, 1007565324, 1007565620,
  1007565861, 1007566050, 1007566273, 1007566639, 1007689720, 1009186210, 1009186748, 1010298015, 1012498692, 1013418109, 1013700061, 1014091544,
  1014092152, 1014092287, 1014092448, 1014092643, 1014092773, 1014092973, 1014093240, 1014093434, 1014093595, 1014776523, 1016232491, 1016232769,
  1017401560, 1018586173, 1018586444, 1019398622, 1019398740, 1019398855, 1019398989, 1019399213, 1019399482, 1019399608, 1019399751, 1019399837,
  1019400023, 1019402381, 1019402632, 1019403398, 1022632736, 1022635741, 1025790756, 1025791327, 1026017210, 1026625904, 1026626068, 1026626385,
  1026627192, 1026628763, 1026629233, 1026629675, 1026630310, 1026630466, 1026630734, 1026630916, 1026631988, 1026632189, 1027898149, 1028744138,
  1031543337, 1031545321, 1032518028, 1032519868, 1032522816, 1032523055, 1032525154, 1032525582, 1032525779, 1032526056, 1032526819, 1032528661,
  1032533059, 1032544431, 1032544820, 1033414410, 1034897063, 1037320354, 1037320961, 1037761787, 1037762013, 1037762210, 1037776813, 1038144974,
  1038145953, 1038146580, 1038146735, 1038147418, 1038147756, 1038147980, 1038148478, 1038148659, 1038148800, 1038148886, 1038148964, 1038149131,
  1039508010, 1040297523, 1042749869, 1042750019, 1044287407, 1044502967, 1044503311, 1044503545, 1044504038, 1044505275, 1044505346, 1044505477,
  1044505609, 1044505669, 1044506240, 1044506348, 1044506423, 1044506554, 1045104304, 1045104441, 1046428365, 1048545642, 1048545896, 1049158396,
  1049167668, 1049199108, 1049199918, 1049200287, 1049200409, 1049201193, 1049201447, 1049202061, 1049202682, 1050962278, 1052879027, 1052879324,
  1067013142, 1067014218, 1067014577, 1075822438, 1075822715, 1075823098, 1077553369, 1077554055, 1081395130, 1081396558, 1081408499, 1082262342,
  1082262486, 1082262860, 1082262970, 1082263102, 1082263208, 1082264495, 1082265654, 1082265808, 1082266051, 1083176774, 1083482433, 1086523666,
  1086524898, 1087169884, 1087170125, 1087170301, 1087170441, 1087170597, 1087170688, 1087170809, 1087170994, 1087171136, 1087171643, 1087892323,
  1089071034, 1091170727, 1091171290, 1091617817, 1091617997, 1091621145, 1091623932, 1091624386, 1091624493, 1091624650, 1091624876, 1091625039,
  1091625214, 1092905226, 1093183725, 1095690436, 1095964283, 1096285852, 1096285984, 1096286424, 1096286755, 1096288001, 1096288381, 1096288525,
  1096288733, 1096288953, 1096289088, 1098244375, 1098244603, 1098244738, 1098245124, 1098245378, 1098252731, 1098696857, 1100668304, 1100668758,
  1101498963, 1101499092, 1101499282, 1101499401, 1101499496, 1101500084, 1101500945, 1101501094, 1101501308, 1101501456, 1102633049, 1103055569,
  1103055757, 1103056776, 1103057020, 1103057160, 1104746711, 1104746965, 1105424821, 1105425210, 1105425491, 1105425544, 1105425658, 1105425813,
  1105425918, 1105425999, 1105426077, 1105426409, 1105975074, 1106755469, 1108468277, 1108468426, 1109171941, 1109172508, 1109192231, 1109192407,
  1109192946, 1109193936, 1109194342, 1109194655, 1109196062, 1109197273, 1111059040, 1112766464, 1112880485, 1112880661, 1113452815, 1113452934,
  1113453113, 1113453338, 1113454381, 1113454909, 1113455043, 1113455141, 1113455651, 1113455829, 1114076616, 1114089444, 1115159038, 1115159116,
  1130076509, 1130077258, 1130078022, 1130078424, 1130079095, 1130079963, 1130080812, 1130081951, 1130082574, 1130083996, 1134665169, 1134665295,
  1134665545, 1135201662, 1135201833, 1135202033, 1135202125, 1135202454, 1135203201, 1135203653, 1135203876, 1135203979, 1135204192, 1135623623,
  1136642807, 1136643109, 1136965036, 1136997332, 1139112110, 1139112202, 1139927056, 1140201520, 1140201669, 1140201913, 1140202005, 1140202130,
  1140202393, 1140202529, 1140202770, 1140203020, 1140203164, 1141325002, 1141914386, 1144337398, 1144337531, 1145175164, 1145175664, 1145176021,
  1145176710, 1145176968, 1145177746, 1145178035, 1145178487, 1145179075, 1145179549, 1147892017, 1149948540, 1149948648, 1150690917, 1150691015,
  1150691107, 1150691206, 1150691356, 1150691579, 1150692005, 1150692199, 1150692519, 1150692586, 1155745757, 1155746292, 1155756392, 1155756585,
  1155756816, 1155757104, 1155757384, 1155757593, 1155757803, 1155758175, 1155758348, 1155758624, 1157745676, 1158361934, 1158728242, 1159327798,
  1160599138, 1160599381, 1161311765, 1161312075, 1161312401, 1161314098, 1161314383, 1161314861, 1161315125, 1161315867, 1161316174, 1161316827,
  1163262405, 1165270235, 1165270412, 1165331710, 1166069488, 1166069957, 1166070350, 1166070526, 1166070952, 1166071092, 1166071173, 1166071443,
  1166071541, 1166071678, 1166072034, 1166072165, 1166072294, 1166072433, 1166072538, 1168457134, 1170576816, 1170577559, 1171191125, 1171191302,
  1171191778, 1171192621, 1171192931, 1171193105, 1171193358, 1171193491, 1171196744, 1171199661, 1173813046, 1176555793, 1176555952, 1177300899,
  1177301810, 1177302067, 1177302275, 1177302462, 1177302702, 1177302960, 1177303270, 1177303563, 1177304064, 1179092367, 1181111050, 1181111916,
  1181680193, 1181680424, 1181680602, 1181680886, 1181681109, 1181681453, 1181681705, 1181681961, 1181682281, 1181682507, 1183712541, 1183817976,
  1184072942, 1184073141, 1184073256, 1184073867, 1184073992, 1184074127, 1184074372, 1184074568, 1184074668, 1184074812, 1184074924, 1184075331,
  1186706307, 1187897918, 1187899065, 1187900595, 1187902886, 1187906507, 1187906698, 1187906782, 1187906921, 1187907117, 1187907206, 1187907357,
  1187907486, 1187939274, 1189306673,
];

export default assets;
