import React from 'react';

const IconRocket = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='w-8 h-8'>
      <path
        opacity='.4'
        d='M198.6 130c-28.5 45.8-60.7 114.5-80.1 158H24c-8.7 0-16.7-4.7-20.9-12.2s-4.1-16.8 .4-24.2l52.8-86.9C69.3 143.2 92.6 130 117.8 130h80.8zM224 391.8c43.9-19.1 112.4-50.3 158-78.4v80.8c0 25.2-13.1 48.5-34.6 61.5l-86.9 52.8c-7.4 4.5-16.7 4.7-24.2 .4s-12.2-12.2-12.2-20.9l0-96.2z'
        fill='currentColor'
      />
      <path
        d='M166.5 394.8l-50.7-50.7c-9.2-9.2-11.9-23-6.7-34.9c18.3-41.9 62.7-140.2 98.1-192.5C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7C342.6 340.5 243 383.9 201 401.7c-11.8 5-25.4 2.2-34.5-6.9zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconRocket;
