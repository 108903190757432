const assets = [
  1000833449, 1000844484, 1000850865, 1000853832, 1000855551, 1002985590, 1002987102, 1002988349, 1011711190, 1011714284, 1011716566, 1011718944,
  1018420317, 1018421620, 1018422993, 1031882261, 1031886992, 1031897251, 1031898482, 1033628554, 1034604632, 1039009170, 1039010021, 1045944674,
  1045945681, 1050325172, 1050326012, 1050327334, 1050328304, 1056145155, 1056146136, 1062170971, 1062172339, 1062174099, 1063514184, 1063517563,
  1067414548, 1067415968, 1067417723, 1067419541, 1073334549, 1073335581, 1073336651, 1078847908, 1078849705, 1078850370, 1078850847, 1088550887,
  1089621582, 1089623033, 1089623949, 1089625252, 1089626240, 1089626921, 1090717161, 1090719099, 1090719810, 1090721580, 1090722376, 1090723125,
  1090724055, 1090725077, 1090726123, 1090726991, 1090729066, 1090730019, 1090731776, 1090733176, 1090734281, 1090735362, 1090736255, 1090737243,
  1090739234, 1090740729, 1090742660, 1090743609, 1090744571, 1090745731, 1090747634, 1090749364, 1090751634, 1090756465, 1090758167, 1094403503,
  1094405227, 1094405909, 1096983981, 1096985004, 1098897845, 1098899238, 1098899913, 1098903293, 1101988585, 1101989551, 1110171279, 1110172315,
  1110173511, 1110174817, 1110175707, 1110176603, 1110177697, 1110178199, 1116885519, 1117785120, 1117785737, 1117786669, 1117789335, 1123833351,
  1123834129, 1123847481, 1123848168, 1123848967, 1123850203, 1123850961, 1123851381, 1123852221, 1123949940, 1123950797, 1123951629, 1123952558,
  1123953549, 1123954866, 1123955810, 1123956465, 1123957367, 1123958083, 1140426038, 1140426843, 1140427593, 1140428321, 1140428970, 1140429694,
  1140430412, 1140431182, 1140432055, 1140432975, 1143605999, 1143607489, 1143608883, 1143609694, 1143610887, 1143611967, 1150587163, 1156658153,
  1156659268, 1156662635, 1156663477, 1156664079, 1156664758, 1156665266, 1156665899, 1156669226, 1156669914, 1161600153, 1161601330, 1161602216,
  1161603047, 1161603819, 1161604745, 1161612769, 1161615768, 1167916582, 1169386715, 1170694861, 1170696359, 1170696907, 1170701951, 1170703301,
  1170705234, 1170705960, 1170706739, 1170707592, 1170708471, 1173202236, 1181322761, 1181324362, 1181325342, 1181326212, 1181327244, 1182075392,
  1182076099, 1182076665, 1182609163, 1186212818, 1187447568, 1188788784, 1188789261, 1188790192, 1190091510, 1190092210,
];

export default assets;
