import React from 'react';

const IconFort = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' className='w-8 h-8'>
      <path
        opacity='.6'
        d='M16 0C7.2 0 0 7.2 0 16V96v16 64c0 26.5 21.5 48 48 48H208c26.5 0 48-21.5 48-48V112 96 16c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16V64H160V16c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16V64H64V16C64 7.2 56.8 0 48 0H16zM448 64V16c0-8.8-7.2-16-16-16H400c-8.8 0-16 7.2-16 16V96v16 64c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V112 96 16c0-8.8-7.2-16-16-16H592c-8.8 0-16 7.2-16 16V64H544V16c0-8.8-7.2-16-16-16H496c-8.8 0-16 7.2-16 16V64H448z'
        fill='currentColor'
      />
      <path
        d='M384 160H256v16c0 26.5-21.5 48-48 48H48c-5.6 0-11-1-16-2.7V464c0 26.5 21.5 48 48 48H256V384c0-35.3 28.7-64 64-64s64 28.7 64 64V512H560c26.5 0 48-21.5 48-48V221.3c-5 1.8-10.4 2.7-16 2.7H432c-26.5 0-48-21.5-48-48V160z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconFort;
