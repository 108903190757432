import React from 'react';

const IconExcavatar = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' className='w-8 h-8'>
      <path
        opacity='.6'
        d='M416 160V288L576 128V336L457.9 454.1c-6.4 6.4-9.9 15-9.9 24c0 18.7 15.2 33.9 33.9 33.9H560c44.2 0 80-35.8 80-80V384 320 45.3C640 20.3 619.7 0 594.7 0c-12 0-23.5 4.8-32 13.3L416 160zM336 416c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16H336zM80 352c-44.2 0-80 35.8-80 80s35.8 80 80 80H336c44.2 0 80-35.8 80-80s-35.8-80-80-80H80z'
        fill='currentColor'
      />
      <path
        d='M238.9 64l64 96H160V64h78.9zM384 208V190.5c0-15.8-4.7-31.2-13.4-44.4L292.1 28.5C280.3 10.7 260.3 0 238.9 0H144C117.5 0 96 21.5 96 48V160H48c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V224 208z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconExcavatar;
