import IconMegaphone from '../../components/icons/IconMegaphone';
import IconTwitter from '../../components/icons/IconTwitter';
import { achievementLevel } from './config';

const twitterAchievements = [
  {
    name: 'X User',
    description: 'Connect your X/Twitter account to your profile',
    category: 'X/Twitter',
    level: achievementLevel.bronze,
    icon: <IconTwitter />,
    requirements: {
      twitter: true,
    },
  },
  {
    name: 'Hype Machine',
    description: 'Submit your first #ShittyHype post',
    category: 'X/Twitter',
    level: achievementLevel.bronze,
    icon: <IconMegaphone />,
    requirements: {
      hype: 1,
    },
  },
  {
    name: 'Hype Machine 10',
    description: 'Submit 10 #ShittyHype posts',
    category: 'X/Twitter',
    level: achievementLevel.silver,
    icon: <IconMegaphone />,
    requirements: {
      hype: 10,
    },
  },
  {
    name: 'Hype Machine 50',
    description: 'Submit 50 #ShittyHype posts',
    category: 'X/Twitter',
    level: achievementLevel.gold,
    icon: <IconMegaphone />,
    requirements: {
      hype: 50,
    },
  },
  {
    name: 'Hype Machine 100',
    description: 'Submit 100 #ShittyHype posts',
    category: 'X/Twitter',
    level: achievementLevel.platinum,
    icon: <IconMegaphone />,
    requirements: {
      hype: 100,
    },
  },
  {
    name: 'Hype Machine 500',
    description: 'Submit 500 #ShittyHype posts',
    category: 'X/Twitter',
    level: achievementLevel.diamond,
    icon: <IconMegaphone />,
    requirements: {
      hype: 500,
    },
  },
];

export default twitterAchievements;
