const ASSETS = [
  579619566, 582914235, 582914242, 582914251, 582914255, 582914259, 582914277, 582914285, 582914294, 582918354, 582918374, 582918393, 582918432,
  582918454, 582918480, 582918503, 582918508, 582918520, 582918538, 582918567, 582918637, 582918658, 582918696, 582918702, 582918713, 582918726,
  582918765, 582918797, 582918810, 582919633, 582919642, 582919660, 582919682, 582919720, 582919736, 582919768, 582919780, 582922224, 582922231,
  582922255, 582922263, 582922268, 582922278, 582922287, 582922417, 582922427, 582922462, 582922479, 582922490, 582922497, 582922500, 582923897,
  582923929, 582923945, 582923962, 582923983, 582923995, 583000848, 583000859, 583000872, 583000904, 583000914, 583000982, 583000997, 583001011,
  583001065, 583001082, 583001150, 583003881, 583003887, 583003897, 583003975, 583003985, 583004033, 583004042, 583004044, 583004051, 583004080,
  583004089, 583004095, 583004122, 583004155, 583004164, 583005330, 583005341, 583005350, 583005368, 583005386, 583005400, 583005415, 583005509,
  583005514, 583005605, 583005611, 583014201, 583014224, 583014244, 583014302, 583014333, 583014338, 583014355, 583014365, 583014377, 583014413,
  583014421, 583014446, 583023402, 583023438, 583023541, 583023576, 583023670, 583023712, 583023736, 583023867, 583023973, 583023994, 583024021,
  583024041, 583027498, 583027580, 583027657, 583027681, 583027787, 583027858, 583028006, 583028030, 583028129, 583028152, 583033103, 583033143,
  583033163, 583033186, 583033206, 583033237, 583033272, 583033284, 583033302, 583033412, 583033432, 583033488, 583033527, 583033565, 583033579,
  583033599, 583033635, 583033688, 583035857, 583035883, 583035993, 583036020, 583036060, 583036077, 583036115, 583036152, 583036201, 583036258,
  583036353, 583036420, 583036445, 583036468, 583036488, 583036503, 583045803, 583045828, 583046554, 583046611, 583046679, 583046756, 583046805,
  583046962, 583047024, 583047330, 583047388, 583052225, 583052249, 583052301, 583052339, 583052430, 583052488, 583052639, 583052698, 583052816,
  583052839, 583052880, 583052902, 583077232, 583077261, 583077294, 583077346, 583077405, 583077466, 583077487, 583077513, 583077605, 583077671,
  583077697, 583077785, 583077838, 583077888, 583105953, 583105989, 583106008, 583106022, 583106047, 583106088, 583106136, 583106160, 583106200,
  583106220, 583106298, 583106335, 583106406, 583106450, 583213496, 583229560, 583255053, 583261614, 583264010, 583266089, 583307801, 583322885,
  583327516, 583336071, 585523340, 585523348, 585523358, 585523380, 585523431, 585523440, 585523448, 585523502, 585523513, 585523517, 585523524,
  585523541, 585523551, 585523605, 585523621, 585523625, 585523660, 585523698, 585523714, 585523728, 585523762, 585523801, 585523831, 585523853,
  585523879, 585523894, 585523911, 585523968, 585523982, 585523988, 585523994, 585524005, 585524014, 585524039, 585524067, 585524078, 585524087,
  585524095, 585524105, 585524121, 585524126, 585524157, 585524194, 585524206, 585524212, 585524233, 585524235, 585524238, 585524267, 585524303,
  585547107, 585547242, 585547360, 585547443, 585547481, 585547626, 585547659, 585547741, 585547841, 585547894, 585548008, 585548097, 585548124,
  585548250, 585548276, 585548367, 585548679, 585548749, 585548788, 585548879, 585548915, 585549032, 585549161, 585549195, 585549333, 585549419,
  585549569, 585549610, 585549712, 585549746, 585549863, 585549918, 585549942, 585549965, 585549993, 585550036, 585550099, 585550153, 585550239,
  585550426, 585550488, 585550519, 585550717, 585550750, 585550783, 585550819, 585550967, 585551028, 585551062, 585565890, 585566454, 585566703,
  585566957, 585567480, 585567701, 585568117, 585568404, 585568547, 585568803, 585569475, 585569502, 585583150, 585583163, 585583257, 585583278,
  585583427, 585583537, 585583603, 585583679, 585583703, 585583729, 585583744, 585583767, 585583812, 585597917, 585597958, 585597998, 585598013,
  585598033, 585598060, 585598067, 585598076, 585598125, 585598143, 585598154, 585598175, 585598183, 585598220, 585598223, 585598227, 585598236,
  585598272, 585598281, 585598296, 585598326, 585598336, 585598338, 585598446, 585598458, 585598492, 585598569, 585598613, 585598646, 585598684,
  585598695, 585598705, 585598714, 585598758, 585598789, 585598860, 585598871, 585598934, 585598941, 585598949, 585598951, 585598954, 585598980,
  585599008, 585599021, 585599033, 585599077, 585599111, 585599122, 585599150, 585599188, 585599232, 585599239, 585599266, 585599278, 585599291,
  585599310, 585599334, 585599363, 585599642, 585599712, 585599731, 585599753, 585599777, 585599811, 585599865, 585599878, 585599885, 585599894,
  585599897, 585599937, 585599945, 585599963, 585599996, 585600015, 585600040, 585611233, 585611327, 585611414, 585611574, 585611691, 585611712,
  585611885, 585611905, 585612114, 585612142, 585612163, 585612190, 585612261, 585612283, 585612342, 585612358, 585612389, 585612401, 585612407,
  585612421, 585612443, 585612466, 585612494, 585612540, 585612597, 585612624, 585612631, 585612654, 585612675, 585612679, 585612685, 585612696,
  585612714, 585612728, 585612798, 585612822, 585612899, 585612910, 585612961, 585613139, 585613219, 585613236, 585613293, 585613338, 585613422,
  585613474, 585613493, 585613516, 585613534, 585613561, 585613572, 585613609, 585613652, 585613657, 585613670, 585613698, 585613726, 585613736,
  585613763, 585613783, 585613804, 585613821, 585613836, 585613852, 585613860, 585613928, 585613974, 585614030, 585614089, 585614111, 585614125,
  585614149, 585614181, 585628612, 585628637, 585628660, 585628697, 585628725, 585628747, 585628981, 585629066, 585629095, 585629163, 585629188,
  585629228, 585629308, 585629374, 585629439, 585629505, 585629587, 585629726, 585629776, 585629799, 585629996, 585630149, 585630245, 585630275,
  587534879, 587534886, 587534902, 587534909, 587534923, 587534941, 587534951, 587535014, 587535070, 587535089, 587535113, 587535126, 587535146,
  587535221, 587535230, 587535244, 587535274, 587535328, 587535340, 587535354, 587535374, 587535385, 587535390, 587535430, 587535436, 587535450,
  587535463, 587535469, 587535474, 587535482, 587535495, 587535529, 587535552, 587535564, 587535568, 587535581, 587535583, 587535598, 587535651,
  587535662, 587535731, 587535763, 587535779, 587535797, 587535807, 587535822, 587535824, 587535828, 587535836, 587535849, 587535865, 587535871,
  587535894, 587535904, 587535919, 587535939, 587535951, 587535975, 587535989, 587536008, 587536024, 587536073, 587536108, 587536115, 587536134,
  587536151, 587536208, 587536217, 587536248, 587536264, 587536269, 587536289, 587536300, 587536311, 587536318, 587536334, 587536401, 587536413,
  587536421, 587536445, 587536448, 587536464, 587536480, 587536487, 587536512, 587536515, 587536523, 587536534, 587536543, 587536553, 587536564,
  587536566, 587536588, 587536612, 587536632, 587536656, 587536661, 587536667, 587536679, 587536691, 587536700, 587536728, 587536737, 587536804,
  587536810, 587536819, 587536821, 587536828, 587536838, 587536846, 587536857, 587536867, 587536880, 587536897, 587536907, 587536925, 587540227,
  587540240, 587540315, 587540329, 587540342, 587540365, 587540372, 587540410, 587540428, 587540430, 587540435, 587540441, 587540460, 587543411,
  587543434, 587543453, 587543476, 587543478, 587543492, 587543505, 587543520, 587543538, 587543544, 587543547, 587543555, 587543562, 587549538,
  587549545, 587549573, 587549610, 587549635, 587549643, 587549668, 587549712, 587549724, 587549758, 587549772, 587549788, 587549820, 587549843,
  587549865, 587549870, 587549934, 587549954, 587550013, 587550034, 587550039, 587550050, 587550084, 587550096, 587550114, 587550125, 587550134,
  587550144, 587550156, 587550164, 587550181, 587550189, 587550191, 587550193, 587550195, 587550223, 587550256, 587550276, 587550292, 587550297,
  587550308, 587550341, 587550369, 587550377, 587550384, 587550391, 587550413, 587550421, 587550432, 587550445, 587550453, 587550488, 587550516,
  587550549, 587550560, 587550565, 587550588, 587550620, 587550636, 587550650, 587550657, 587550677, 587550679, 587550681, 587550718, 587550725,
  587550729, 587550770, 587550794, 587550804, 587550852, 587550888, 587551029, 587551074, 587551131, 587551187, 587551225, 587551451, 587551460,
  587551472, 587551487, 587551492, 587551497, 587551500, 587551527, 587551563, 587551613, 587551615, 587551636, 587551640, 587551646, 587551664,
  587551671, 587551702, 587551706, 587551759, 587551763, 587551822, 587551831, 587551846, 587551911, 587551970, 587551976, 587552000, 587552067,
  587552103, 587552159, 587552176, 587552246, 587552280, 587552348, 587552367, 587552376, 587552386, 587552408, 587552463, 587552476, 587552538,
  587552552, 587558309, 587558314, 587558339, 587558371, 587558375, 587558379, 587558387, 587558393, 587558403, 587558415, 587558425, 587558442,
  587558523, 587558528, 587558541, 587558552, 587558579, 587558617, 587558625, 587558635, 587558640, 587558648, 587558678, 587558704, 587558709,
  587558721, 587558733, 587560669, 587560681, 587560691, 587560703, 587560727, 587560746, 587560767, 587560784, 587560793, 587560812, 587560825,
  587560880, 587560891, 587560898, 587560909, 587560936, 587562289, 587562304, 587562313, 587562346, 587562360, 587562365, 587562377, 587562421,
  587562431, 587562436, 587562447, 587562478, 587562486, 587562490, 587564402, 587564416, 587564436, 587564468, 587564520, 587564532, 587564588,
  587564608, 587564624, 587564669, 587564674, 587565046, 587565062, 587565078, 587565102, 587565110, 587565115, 587565140, 587565255, 587565269,
  587565310, 587565376, 587565383, 587565389, 587565409, 587565418, 587565435, 587574651, 587574667, 587574680, 587574701, 587574706, 587574709,
  587574734, 587574737, 587574742, 587574748, 587574768, 587574780, 587574792, 587574801, 587574811, 587574914, 587574917, 587574935, 587574940,
  587574947, 587574998, 587575011, 587575029, 587575039, 587575076, 587575110, 587575116, 587575123, 587575139, 587575141, 587575169, 587575178,
  587575190, 587575237, 587575271, 587575281, 587575304, 587575315, 587575324, 587575350, 587575372, 587575396, 587575401, 587575416, 587575460,
  587575478, 587575486, 587575491, 587575508, 587575516, 587575545, 587575552, 587575560, 587575608, 587575623, 587575640, 587575678, 587575690,
  587575704, 587575739, 587575765, 587575768, 587575775, 587575785, 587575788, 587575794, 587575819, 587575869, 587575887, 587575925, 587575970,
  587576087, 587576089, 587576099, 587576200, 587576212, 587576267, 587576322, 587576329, 587576368, 587576413, 587576447, 587576481, 587576502,
  587576539, 587576555, 587576563, 587576572, 587576579, 587576584, 587576600, 587576605, 587576623, 587576625, 587576659, 587576679, 587576772,
  587576777, 587576797, 587576815, 587576829, 587576847, 587576853, 587606877, 587607036, 587607051, 587607161, 587607173, 587607178, 587607183,
  587607321, 587607338, 587607350, 587607361, 587612716, 587612728, 587612815, 587612824, 587612880, 587612901, 587612910, 587612929, 587612933,
  587612940, 587612966, 587612974, 587612985, 587613001, 587613018, 587613031, 587613041, 587613052, 587613056, 587613065, 587613073, 587613076,
  587613086, 587613092, 587613116, 587613135, 587613137, 587613173, 587613181, 587613238, 587613242, 587613268, 587613285, 587613290, 587613324,
  587613341, 587613355, 587613402, 587613433, 587613452, 587613464, 587613472, 587613501, 587613520, 587613535, 587613550, 587613560, 587613589,
  587613611, 587613629, 587613659, 587613686, 587613699, 587613710, 587613726, 587613770, 587622347, 587622351, 587622354, 587622356, 587622358,
  587622361, 587622364, 587622387, 587622400, 587622422, 587622435, 587622448, 587622463, 587622468, 587622497, 587622506, 587622508, 587622512,
  587622528, 587622546, 587622557, 587622617, 587622626, 587622634, 587622642, 587622645, 587622650, 587622658, 587622695, 587622711, 587622724,
  587622740, 587622756, 587624645, 587624653, 587624660, 587624664, 587624670, 587624675, 587624691, 587624714, 587624723, 587624735, 587624747,
  587624769, 587624776, 587624789, 587624797, 587624809, 587624817, 587624842, 587624879, 587624882, 587624886, 587624889, 587624905, 587624916,
  587624926, 587624951, 587624955, 587624964, 587624970, 587624982, 587624992, 587625072, 587625076, 587625086, 587625092, 587625116, 587625128,
  587625136, 587625163, 587625166, 587625171, 587625178, 587625213, 587625232, 587625237, 587625257, 587625270, 587625273, 587625278, 587625282,
  587625296, 587625314, 587625324, 587625360, 587625366, 587625372, 587625416, 587625427, 587625437, 587625463, 587625474, 587625486, 587625491,
  587625509, 587625536, 587625540, 587625576, 587625583, 587625591, 587625602, 587625616, 587625620, 587625627, 587625631, 587625640, 587625664,
  587625668, 587625672, 587625701, 587625714, 587625717, 587625734, 587625745, 587625749, 587625759, 587625769, 587625780, 587625804, 587625811,
  587625834, 587625837, 587625848, 587625865, 587625876, 587625919, 587625933, 587625938, 587625942, 587625956, 587625969, 587626002, 587626042,
  587626050, 587626053, 587626074, 587626101, 587626107, 587626115, 587626135, 587626142, 587626155, 587626162, 587626166, 587626169, 587626221,
  587626238, 587626275, 587626278, 587626292, 587626307, 587626322, 587626325, 587626327, 587626342, 587626349, 587626351, 587626358, 587626363,
  587627400, 587627406, 587627413, 587627432, 587627437, 587627447, 587627456, 587627460, 587627462, 587627468, 587627473, 587627483, 587627491,
  587627511, 587627521, 587627539, 587627547, 587627584, 587627599, 587627625, 587627627, 587627632, 587627641, 587627649, 587627656, 587627683,
  587627700, 587627704, 587627710, 587627713, 587627719, 587632257, 587632263, 587632278, 587632286, 587632310, 587632317, 587632332, 587632342,
  587632351, 654561741, 660004771, 676186493, 684438622, 687573278, 687578619, 687591588, 687597589, 687610963, 687614444, 689652667, 697453848,
  698467453, 698480336, 698488421, 698494814, 698501141, 698507762, 698517704, 698526609, 698533302, 698547096, 698657815, 698686953, 704110764,
  704128153, 708924844, 708948058, 708965058, 708969228, 709016655, 713165860, 713170980, 713173804, 721176809, 721195331, 721204629, 721225594,
  721235002, 721243915, 721322235, 737750442, 737756951, 737762728, 737769615, 737778187, 737784336, 739133725, 741026307, 749820378, 750010095,
  750142642, 750934239, 751553397, 751865805, 752200818, 752945710, 752948741, 752961942, 752998901, 753002063, 753873322, 756517824, 756520037,
  756545371, 758345059, 760057914, 760062139, 762205371, 762213644, 762221228, 762227028, 762232337, 762236867, 773063834, 773072296, 773081051,
  773085590, 773114659, 781050181, 781054741, 785720995, 793585294, 793590644, 798634710, 798638793, 798642989, 798891074, 799367574, 804514128,
  806274588, 809304789, 811020055, 812192148, 812194599, 819515831, 819518789, 819535209, 819551980, 821756365, 826108477, 833585953, 835274204,
  835364862, 835366017, 835367463, 835370994, 835371877, 835493754, 837340710, 837345892, 838148671, 838391718, 839144345, 862381532, 862386572,
  862388352, 862487649, 862493977, 863335775, 867796704, 879781046, 931133436, 1002815902, 1002826253, 1007584453, 1013341476, 1029148892,
];

export default ASSETS;
