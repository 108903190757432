import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { create } from 'zustand';
import { useAlgo } from './useAlgo';
import { useUserAssetsStore } from './useUserAssetsStore';

const useBlocksQuery = () => {
  return useQuery({
    queryKey: ['allBlocks'],
    queryFn: async () => {
      const resp = await fetch('/api/blocks/list', {
        headers: {
          Authorization: process.env.NEXT_PUBLIC_API_KEY,
        },
      });
      const data = await resp.json();
      return data;
    },
    refetchInterval: 120000,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useBlocksStore = create((set) => ({
  myBlocks: [],
  loading: true,
  allBlocks: [],
  setMyBlocks: (myBlocks) => set({ myBlocks }),
  setLoading: (loading) => set({ loading }),
  setAllBlocks: (allBlocks) => set({ allBlocks }),
}));

export const useBlocksResolver = () => {
  const { address } = useAlgo();
  const { userAssets } = useUserAssetsStore();
  const query = useBlocksQuery();
  const allBlocks = query.data || [];
  const loading = query.isLoading;

  const { setMyBlocks, setAllBlocks, setLoading } = useBlocksStore();

  const myBlocks = useMemo(() => {
    if (!address || allBlocks.length === 0) return [];
    const myAssetIds = userAssets.map((asset) => asset['asset-id']);
    const them = allBlocks.filter((block) => myAssetIds.includes(block.index));
    const blocksWithAmount = them.map((block) => {
      const asset = userAssets.find((asset) => asset['asset-id'] === block.index);
      return { ...block, amount: asset.amount };
    });
    return blocksWithAmount;
  }, [userAssets, allBlocks]);

  useEffect(() => {
    setMyBlocks(myBlocks);
  }, [myBlocks.length]);

  useEffect(() => {
    setAllBlocks(allBlocks);
  }, [allBlocks.length]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);
};
