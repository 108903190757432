import IconBountyHunter from '../../components/icons/IconBountyHunter';
import { achievementLevel } from './config';

const bountyAchievements = [
  {
    name: 'Bounty Hunter',
    description: 'Sweep a Shitty Bounty off the floor',
    category: 'Bounties',
    level: achievementLevel.bronze,
    icon: <IconBountyHunter />,
    requirements: {
      bounties: 1,
    },
  },
  {
    name: 'Bounty Hunter 5',
    description: 'Sweep 5 Shitty Bounties off the floor',
    category: 'Bounties',
    level: achievementLevel.silver,
    icon: <IconBountyHunter />,
    requirements: {
      bounties: 5,
    },
  },
  {
    name: 'Bounty Hunter 25',
    description: 'Sweep 25 Shitty Bounties off the floor',
    category: 'Bounties',
    level: achievementLevel.gold,
    icon: <IconBountyHunter />,
    requirements: {
      bounties: 25,
    },
  },
  {
    name: 'Bounty Hunter 50',
    description: 'Sweep 50 Shitty Bounties off the floor',
    category: 'Bounties',
    level: achievementLevel.platinum,
    icon: <IconBountyHunter />,
    requirements: {
      bounties: 50,
    },
  },
  {
    name: 'Bounty Hunter 100',
    description: 'Sweep 100 Shitty Bounties off the floor',
    category: 'Bounties',
    level: achievementLevel.diamond,
    icon: <IconBountyHunter />,
    requirements: {
      bounties: 100,
    },
  },
];

export default bountyAchievements;
