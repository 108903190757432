export default function calculateCityRating(city, allCities) {
  const rarityScore = getTraitRarityScore(city, allCities);
  return Math.round(rarityScore);
}

// rarityScore = 1 ÷([Number of Items With That Trait Value] /[Total. Number of Items in Collection])
function getTraitRarityScore(city, allCities) {
  if (!city?.metadata?.properties || Object.keys(city.metadata.properties).length === 0) return 0;

  const total = allCities.length;
  // replace city in allCities so if city traits are modified, it shows correct counts dynamically
  const modAllCities = allCities.map((c) => {
    if (c.index === city.index) {
      return city;
    }
    return c;
  });
  const traitFrequencies = getTraitFrequencies(modAllCities);
  const rarityScore = Object.entries(city.metadata.properties).reduce((acc, [trait, value]) => {
    const traitFrequency = traitFrequencies[`${trait}:${value}`];
    const rarity = 1 / (traitFrequency / total);
    return acc + rarity;
  }, 0);
  return rarityScore;
}

function getTraitFrequencies(allCities) {
  const formattedCities = allCities.map((c) => {
    if (!c.metadata?.properties) {
      return {};
    }
    return {
      ...c.metadata?.properties,
    };
  });

  const traitFrequencies = {};
  formattedCities.forEach((nft) => {
    Object.entries(nft).forEach(([trait, value]) => {
      traitFrequencies[`${trait}:${value}`] = (traitFrequencies[`${trait}:${value}`] || 0) + 1;
    });
  });
  return traitFrequencies;
}
