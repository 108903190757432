import clsx from 'clsx';

export default function Alert({ children, type, className }) {
  let colors = {
    bg: 'bg-green-50',
    text: 'text-green-800',
  };

  if (type === 'danger') {
    colors = {
      bg: 'bg-red-50',
      text: 'text-red-800',
    };
  }

  if (type === 'info') {
    colors = {
      bg: 'bg-blue-50',
      text: 'text-blue-800',
    };
  }

  if (type === 'warning') {
    colors = {
      bg: 'bg-orange-50',
      text: 'text-orange-800',
    };
  }

  return (
    <div className={clsx(`p-4 rounded-md ${colors.bg}`, className)}>
      <div className='flex'>
        <div className={`text-sm font-medium ${colors.text}`}>{children}</div>
      </div>
    </div>
  );
}
