const assets = [
  468799185, 468834060, 468842227, 468845337, 468849986, 468852549, 468853832, 468857391, 468864911, 468870267, 468954732, 468977720, 468994883,
  469013568, 469030450, 469044036, 469062315, 469076130, 469086450, 469097210, 514016754, 534814583, 534818767, 544936147, 544938412, 544943151,
  544949366, 558607124, 558620106, 558622239, 600426520, 600429887, 600432611, 600438304, 600440553, 600443806, 600446464, 600449494, 600453736,
  660322862, 660325560, 660326553, 660327828, 660328908, 660330567, 660331568, 660332870, 660335178, 660336741, 670507331, 670510700, 670512654,
  670514539, 670516433, 675241110, 675243964, 675246707, 682266196, 682268620, 682270035, 682271403, 682272580, 691997851, 692003531, 692007653,
  692341327, 692343802, 701182673, 701184610, 701187338, 701191728, 701192945, 702696587, 702698256, 702699534, 703882237, 703883408, 703885027,
  703886320, 703887461, 707867007, 707868201, 707869100, 708285165, 711103865, 711105326, 711106367, 711108052, 711109765, 712773631, 715947814,
  715953060, 715955380, 715958429, 715960001, 718030964, 718031800, 718032845, 718033998, 718035302, 718555838, 718558931, 721067040, 723350424,
  726427386, 726430494, 728276548, 730475647, 730477661, 730479933, 730483824, 730486367, 732253431, 735050093, 735385412, 735421962, 738692169,
  740508671, 741844340, 741845395, 741846450, 741848110, 741848776, 742871622, 746470596, 748263441, 748264831, 748267613, 748269072, 748270323,
  751690211, 754916188, 755063520, 755064621, 755142032, 757548889, 757550073, 757550993, 757552105, 757552902, 758221445, 763440461, 764641797,
  765392854, 765805096, 765805988, 765807334, 765808729, 765809647, 769968630, 769969805, 770685493, 772552425, 772553700, 774187421, 774188499,
  774189890, 774190901, 774228024, 776401315, 779601530, 781549613, 781550430, 781551338, 781552340, 794873964, 794874733, 794875234, 794875818,
  794876414, 794913900, 795200296, 795305628, 807443874, 807445002, 807446162, 807447333, 813830496, 813831369, 826058992, 834277082, 835483553,
  835484411, 835485070, 835485685, 835486483, 843348721, 843349541, 843350387, 843351128, 843351975, 850658706, 850676771, 850680592, 850682741,
  850686680, 850687718, 850689094, 850895568, 850897030, 860008815, 860009634, 860010554, 860011547, 860012553, 862969171, 865086026, 865086918,
  865615993, 871275100, 872545110, 873592450, 873593304, 873594463, 876353136, 877114439, 888326149, 896103460, 904053467, 930697119, 932630114,
  932630981, 932631820, 932632493, 942016276, 942017010, 942017644, 942018592, 942019338, 950579353, 950581084, 950582004, 950583475, 950586900,
  958194877, 958195922, 958197187, 958199084, 958200257, 962783270, 965914028, 965917774, 965921634, 965923682, 965925391, 973235148, 973236240,
  973237524, 973238701, 978638295, 978639410, 979672451, 979674433, 979675629, 979676094, 1004630228, 1011371351, 1011372164, 1011373083, 1011373781,
  1018865067, 1019368144, 1019368646, 1019369115, 1019369541, 1026609619, 1026610511, 1026611355, 1026613102, 1030030776, 1031566145, 1032243759,
  1032247906, 1037288676, 1038069594, 1038072513, 1038073404, 1038075125, 1048538063, 1049100997, 1049101644, 1049102782, 1049105161, 1049105787,
  1065275082, 1066382208, 1066383453, 1066384414, 1075279369, 1075411572, 1075412353, 1075927386, 1085195682, 1091085187, 1091891626, 1091893925,
  1091896010, 1091897240, 1101486991, 1101488046, 1101497722, 1109291723, 1109303496, 1109304894, 1117202260, 1118381085, 1118382526, 1118383617,
  1141452946, 1144307335, 1145434879, 1149616826, 1151547579, 1151549355, 1159940834, 1161199281, 1161201794, 1177349279, 1178735155, 1181027701,
];

export default assets;
