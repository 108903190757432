import { useQuery } from '@tanstack/react-query';

const useAllKittiesQuery = () => {
  return useQuery({
    queryKey: ['allKitties'],
    queryFn: async () => {
      const resp = await fetch('/api/kitties/list', {
        headers: {
          Authorization: process.env.NEXT_PUBLIC_API_KEY,
        },
      });
      const data = await resp.json();
      return data;
    },
    refetchInterval: 120000,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useAllKitties = () => {
  const allKittiesQuery = useAllKittiesQuery();
  return {
    allKitties: allKittiesQuery.data || [],
    isLoading: allKittiesQuery.isLoading,
    isError: allKittiesQuery.isError,
  };
};
