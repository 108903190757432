import IconCamera from '../../components/icons/IconCamera';
import { achievementLevel } from './config';

const profileAchievement = [
  {
    name: 'Take a Selfie',
    description: 'Setup your profile picture',
    category: 'Profile',
    level: achievementLevel.bronze,
    icon: <IconCamera />,
    requirements: {
      profilePicture: true,
    },
  },
];

export default profileAchievement;
