import React from 'react';

const IconHouseTurret = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' className='w-8 h-8'>
      <path
        opacity='.6'
        d='M24 0C10.7 0 0 10.7 0 24V96v8 93.5c0 17 6.7 33.3 18.7 45.3L64 288V464c0 26.5 21.5 48 48 48H240c-10-13.4-16-30-16-48V277.1c0-22.4 9.4-43.8 25.9-59L352 124.6V104 96 24c0-13.3-10.7-24-24-24H280c-13.3 0-24 10.7-24 24V96H224V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24V96H96V24C96 10.7 85.3 0 72 0H24zM176 160c22.1 0 40 17.9 40 40v24c0 8.8-7.2 16-16 16H152c-8.8 0-16-7.2-16-16V200c0-22.1 17.9-40 40-40z'
        fill='currentColor'
      />
      <path
        d='M256 464V277.1c0-13.5 5.6-26.3 15.6-35.4l144-132c18.4-16.8 46.5-16.8 64.9 0l144 132c9.9 9.1 15.6 21.9 15.6 35.4V464c0 26.5-21.5 48-48 48H304c-26.5 0-48-21.5-48-48zM424 272c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V296c0-13.3-10.7-24-24-24H424z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconHouseTurret;
