const ASSETS = [
  997207926, 997209409, 1003826234, 1003826774, 1003827285, 1007729418, 1007730816, 1007731229, 1018875865, 1020671854, 1022781506, 1022800092,
  1026721914, 1026723766, 1029157956, 1029161420, 1032601447, 1032602380, 1035051465, 1035278881, 1035279443, 1038134534, 1038151094, 1040508504,
  1040511129, 1044594698, 1044595699, 1046787415, 1046789991, 1049208656, 1049210571, 1051060152, 1051060546, 1060093936, 1060095228, 1062676044,
  1062881663, 1062882108, 1066497693, 1066498356, 1069289381, 1069293247, 1071581110, 1072499767, 1073944331, 1073944885, 1076821187, 1076823260,
  1079328801, 1079329222, 1082374976, 1083872695, 1084596572, 1084596928, 1087222188, 1087222960, 1089490228, 1091981516, 1091981831, 1093978956,
  1093980151, 1096324169, 1096324421, 1098696630, 1098697596, 1106971366, 1127642821, 1128098957, 1132617688, 1132618397, 1137464271, 1137465035,
  1142335295, 1142335547, 1148096604, 1148101702, 1153849122, 1153849761, 1158722038, 1158722849, 1163611299, 1163612830,
];

export default ASSETS;
