import React from 'react';

const IconCastle = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' className='w-8 h-8'>
      <path
        opacity='.6'
        d='M128 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16V64h48V16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16V64h32V16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16V64h48V16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16V64 208v16H496 464 176 144 128V208 64 16z'
        fill='currentColor'
      />
      <path
        d='M0 176c0-8.8 7.2-16 16-16H48c8.8 0 16 7.2 16 16v48H576V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v96V464c0 26.5-21.5 48-48 48H384V384c0-35.3-28.7-64-64-64s-64 28.7-64 64V512H48c-26.5 0-48-21.5-48-48V272 176z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconCastle;
