export const achievementLevel = {
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  platinum: 'Platinum',
  diamond: 'Diamond',
};

export const achievementPointAmounts = {
  bronze: 50,
  silver: 100,
  gold: 300,
  platinum: 600,
  diamond: 1200,
};

export const treatRewards = {
  bronze: 250,
  silver: 900,
  gold: 1800,
  platinum: 4500,
  diamond: 9000,
};
