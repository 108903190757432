import algosdk from 'algosdk';
import axios from 'axios';
import { get } from 'lodash';
import { algodClient } from '../utils/algo';
import { useAlgo } from './useAlgo';
import { useState } from 'react';
import { useWallet } from '@txnlab/use-wallet';
import { toast } from 'react-hot-toast';

const useShittyUser = () => {
  const { address } = useAlgo();
  const { signTransactions } = useWallet();
  const [loading, setLoading] = useState(false);

  const updateProfilePic = async (kitty) => {
    try {
      setLoading(true);
      const params = await algodClient.getTransactionParams().do();

      const enc = new TextEncoder();
      const note = enc.encode(
        JSON.stringify({
          avatar: {
            image: kitty.image,
            gen: kitty.gen,
            id: kitty.id,
            name: kitty.name,
          },
          address,
        })
      );

      const transactions = [
        algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: {
            ...params,
          },
          from: address,
          to: address,
          assetIndex: parseInt(process.env.NEXT_PUBLIC_TREAT_TOKEN_ID),
          amount: 0,
          note,
        }),
      ];

      const encodedTransactions = transactions.map((txn) => algosdk.encodeUnsignedTransaction(txn));
      const signedTxn = await signTransactions(encodedTransactions);
      let sentTransactions = [];
      for (const txn of signedTxn) {
        const sentTransaction = await algodClient.sendRawTransaction(txn).do();
        await algosdk.waitForConfirmation(algodClient, sentTransaction.txId, 10);
        sentTransactions.push(sentTransaction);
      }

      const txnId = get(sentTransactions, '[0].txId');

      await axios({
        method: 'post',
        url: '/api/user/update',
        data: {
          txnId,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: process.env.NEXT_PUBLIC_API_KEY,
        },
      });

      toast.success('Profile picture updated');
    } catch (error) {
      toast.error('Failed to update profile picture');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateProfilePic,
    loading,
  };
};

export default useShittyUser;
