import { signIn, signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useMutation } from '@tanstack/react-query';
import { updateShittyUser } from '../firebase-client';
import { useAlgo } from '../hooks/useAlgo';
import FullScreenLoader from './FullScreenLoader';
import { useState } from 'react';

export default function LoginTwitterButton() {
  const { data: session } = useSession();
  const { updateShittyUser } = useAlgo();
  const router = useRouter();
  const twitterConnected = session?.user?.twitter?.id;
  const [loading, setLoading] = useState(false);

  const disconnectMutation = useMutation({
    mutationFn: async () => {
      setLoading(true);
      await updateShittyUser({
        twitter: null,
      });
    },
    onSuccess: () => {
      window.location.reload();
    },
    onError: (error) => {
      console.log(error);
      setLoading(false);
    },
  });

  const onDisconnect = () => {
    disconnectMutation.mutate();
  };

  if (!!session && twitterConnected) {
    return (
      <button
        onClick={onDisconnect}
        className='flex items-center justify-between px-4 py-2 gap-3 transition-opacity duration-200 hover:opacity-75 w-full text-gray-100'
      >
        <FullScreenLoader loading={loading} label='Disconnecting Twitter...' />
        <div className='justify-center items-center flex gap-3'>
          <div className='w-8'>
            <img src={session.user.twitter.image} alt={session.user.twitter.name} className='rounded-md' />
          </div>
          <div className='flex flex-col text-left'>
            <span className='text-sm text-gray-500 leading-0 -mb-1'>𝕏/Twitter</span>
            <span className='leading-0'>@{session.user.twitter.name}</span>
          </div>
        </div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 640 512'
          fill='currentColor'
          className='w-5 h-5 text-gray-500 hover:text-gray-100 transition duration-200'
          title='Disconnect 𝕏'
        >
          <path d='M48.4 14.8L29.4 .1 0 38 19 52.7 591.5 497.2l19 14.7L639.9 474l-19-14.7-131-101.7 90.3-90.3c56.2-56.2 56.2-147.4 0-203.6S432.8 7.4 376.6 63.7L365.3 75l45.3 45.3 11.3-11.3c31.2-31.2 81.9-31.2 113.1 0s31.2 81.9 0 113.1l-96 96-32.9-25.5c23.8-53 14-117.4-29.5-160.9c-52.4-52.4-135.1-56-191.6-10.7L48.4 14.8zM238.2 162.2c30.1-15.4 67.9-10.6 93.1 14.6c20.1 20.1 27.3 48.4 21.5 74.3L238.2 162.2zM116.6 187.9L59.8 244.7c-56.2 56.2-56.2 147.4 0 203.6s147.4 56.2 203.6 0L274.7 437l-45.3-45.3-11.3 11.3c-31.2 31.2-81.9 31.2-113.1 0s-31.2-81.9 0-113.1l62.2-62.2-50.6-39.9zm104.9 82.6c-2.2 39.5 11.7 79.7 41.9 109.9c38.8 38.8 94.3 50.8 143.4 36.1l-185.3-146z' />
        </svg>
      </button>
    );
  }

  return (
    <button
      onClick={() =>
        signIn('twitter', {
          callbackUrl: `${window.location.origin}${router.asPath}`,
        })
      }
      className='flex items-center px-4 py-2 space-x-3 transition-opacity duration-200 hover:opacity-75 w-full text-gray-100'
    >
      <div className='w-8 h-8 bg-gray-700 rounded-md p-2 items-center justify-center'>
        <svg viewBox='0 0 1200 1227' xmlns='http://www.w3.org/2000/svg' className='text-white transition-colors duration-200' fill='currentColor'>
          <path
            d='M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z'
            fill='currentColor'
          ></path>
        </svg>
      </div>
      <span>Connect 𝕏</span>
    </button>
  );
}
