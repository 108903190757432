import React from 'react';

const IconFishing = () => {
  return (
    <svg className='w-8 h-8' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
      <path
        opacity='.4'
        d='M287 49.2c2.9-.8 5.8-1.2 8.8-1.2C313.6 48 328 62.4 328 80.1V136c0 13.3 10.7 24 24 24s24-10.7 24-24V80.1C376 35.9 340.1 0 295.9 0c-7.4 0-14.9 1-22 3.1L196.7 25.1C103.9 51.6 40 136.4 40 232.8V288H32c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V320c0-17.7-14.3-32-32-32H88V232.8c0-75 49.7-140.9 121.8-161.5L287 49.2z'
        fill='currentColor'
      />
      <path
        d='M345 195.9c-33.5 20.3-89 76.6-89 148.1c0 32 16 80 64 112l-27.3 27.3c-3 3-4.7 7.1-4.7 11.3V496c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16v-1.4c0-4.2-1.7-8.3-4.7-11.3L384 456c48-32 64-80 64-112c0-71.6-55.6-127.8-89-148.1c-4.3-2.6-9.6-2.6-14 0zM360 288a24 24 0 1 1 0 48 24 24 0 1 1 0-48z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconFishing;
