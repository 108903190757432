import React from 'react';

const IconWhale = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' className='w-8 h-8'>
      <path
        d='M160 0l24 104 104 24-80 80-80.8-11.5L90.6 255c-6.7 10.7-8.3 23.9-4.3 35.9c5.8 17.3 22 29 40.3 29h1.1c10.5 0 20.7-3.8 28.6-10.6l103.8-89C329.7 160.8 418.4 128 510.1 128H640V480H162.4C72.7 480 0 407.3 0 317.6c0-39.5 15.7-77.3 43.6-105.2L92 164 80 80 160 0zM400 328a24 24 0 1 0 0-48 24 24 0 1 0 0 48z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconWhale;
