import React from 'react';
import NextNProgress from 'nextjs-progressbar';

const PageProgress = () => {
  return (
    <NextNProgress
      height={2}
      options={{ showSpinner: false }}
      transformCSS={(css) => {
        return (
          <>
            <style>{css}</style>
            <style>
              {`
                    #nprogress .bar { 
                        background-color: #84C341;
                    }
                    #nprogress .spinner-icon {
                        border-top-color: #84C341};
                        border-left-color: #84C341};
                    }
                `}
            </style>
          </>
        );
      }}
    />
  );
};

export default PageProgress;
